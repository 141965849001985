import React, { useState, useEffect } from 'react';

import { UserService } from '../../services/UserService';
import { FolderService } from '../../services/FolderService';
import { FactureService } from '../../services/FactureService';
import { ProformaService } from '../../services/ProformaService';
import { AbonnementService } from '../../services/AbonnementService';
import ColManager from '../../components/table/dynamic-table/col-manager/ColManager';
import { GlobalContext, GlobalDispatchContext } from "../../services/GlobalProvider";
import { useNavigate } from "react-router-dom";
import { Navbar, Container, Nav, NavDropdown, Dropdown, Button, Row, Col, Form, Table, InputGroup, FormControl, OverlayTrigger, Tooltip, DropdownButton } from 'react-bootstrap';
import { Link } from "react-router-dom";
import axios from 'axios';
import ErrorManager from "../../components/error-manager/ErrorManager";
import logo from '../../assets/img/logo/logo-xl.png'


import '../../config.inc';
import './Accueil.scss';


export default function Accueil() {

  const { userDetails, folderDetails, grants, folders, setFolderDetails, preferences, globalContextLoaded } = React.useContext(GlobalContext);
  const [globalError, setGlobalError] = useState()

  const navigate = useNavigate();

  /*   evtSource.onmessage = (event) => {
  
      console.log('-----event')
      console.log(event)
  
      setMessage(event.data)
    } */

  /* 
  ---> APPEL OK : 
  evtSource.addEventListener("time", (event) => {
      console.log(event);
      setMessage(event.data)
    }); */

  //content-type=text/event-stream;charset=UTF-8


  /*   const eventSource = new EventSource(resoureUrl, {
      headers: {
          'Authorization': 'Bearer ' + authorizationToken
      }
    }); */


  /*   const axiosAPI = axios.create({
      baseURL: global.config._API_DOMAIN_,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }) */


  /*   useEffect(() => {
  
      axios.get(`https://api.xlprog.nc/admin/testsse/`)
      .then(res => {
        console.log(res)
      })
    
      
    }, []); */


  /* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */

  /*   const token = localStorage.getItem('access_token');
  
    const evtSource = new EventSource(`${global.config._API_DOMAIN_}/admin/sse?key=${token}`, {
      //withCredentials: true,
      //authorizationHeader: "Bearer " + token,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'text/event-stream',
        'charset': 'charset=UTF-8'
      }
    } ); */

  /* const [message, setMessage] = useState("XX") */


  return (

    <div id="Accueil" className="w-100 h-100 d-flex align-items-center justify-content-center pb-5 bg-gray-100">
      <img src={logo} alt="Logo XL Prog" />
    </div>

  );
}
