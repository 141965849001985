import React, { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from "react-router-dom";
import { Container, Button, Dropdown, DropdownButton, Alert, Col, Row, Spinner, Modal, Tooltip, InputGroup, Form } from 'react-bootstrap';
import { EditionService } from '../../../services/EditionService';
import { UserService } from '../../../services/UserService';
import ErrorManager from "../../../components/error-manager/ErrorManager";

//import './SendMailDialog.scss';

const ChangePasswordDialog = ({ show, setCloseModal }) => {

    const [showModal, setShowModal] = useState(false);
    const [globalError, setGlobalError] = useState();
    const [isSendLoading, setIsSendLoading] = useState(false);
    const [newPassword, setNewPassword] = useState();
    const [newPasswordConfirm, setNewPasswordConfirm] = useState();
    const [isSaved, setIsSaved] = useState(false);

    let inputRef = useRef();

    useEffect(() => {
        setShowModal(show)
        if (show) {
            resetValues()
        }
    }, [show]);

    function resetValues() {
        setGlobalError()
        setIsSendLoading(false)
        setIsSaved(false)
        setNewPassword()
        setNewPasswordConfirm()
    }

    function handleClose(e) {
        setCloseModal()
    }

    function saveNewPassword() {
        setIsSendLoading(true)
        UserService.updatePassword({ motdepasse: newPassword }).then(res => {
            setIsSendLoading(false)
            setIsSaved(true)
        }).catch(error => {
            setGlobalError(error)
            setIsSendLoading(false)
        });

    }

    return (
        <div id="ChangePasswordDialog">
            <Modal show={showModal} onHide={() => handleClose()} centered backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Changer le mot de passe utilisateur</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <ErrorManager error={globalError} />

                    {isSaved &&
                        <Alert variant="success">Votre nouveau mot de passe a bien été enregistré.</Alert>
                    }

                    {!isSaved &&
                        <Form>
                            <Form.Group className="mb-2">
                                <Form.Control disabled={isSendLoading} onChange={e => setNewPassword(e.target.value)} type="password" placeholder="Nouveau mot de passe" />
                            </Form.Group>
                            <Form.Group hasvalidation="true" className="mb-2">
                                <Form.Control disabled={isSendLoading} isInvalid={newPasswordConfirm && (newPassword != newPasswordConfirm)} onChange={e => setNewPasswordConfirm(e.target.value)} type="password" placeholder="Confirmer le mot de passe" />
                                <Form.Control.Feedback type="invalid">
                                    Les mots de passe doivent être identiques
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form>
                    }

                </Modal.Body>
                <Modal.Footer>

                    <Button disabled={isSendLoading} variant="secondary" onClick={handleClose}>
                        Fermer
                    </Button>

                    {!isSaved &&
                        <Button disabled={isSendLoading || !newPassword || !newPasswordConfirm || (newPassword != newPasswordConfirm)} className="ms-1" onClick={() => saveNewPassword()}>
                            Enregistrer
                        </Button>
                    }

                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ChangePasswordDialog;
