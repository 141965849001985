import React, { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { Table, Badge, FormControl, Container, Button, Dropdown, DropdownButton, Form, Col, Row, Alert, OverlayTrigger, Tooltip, Accordion, Modal } from 'react-bootstrap';
import { getFormErrorMsg, formattedDateInput, currencyFormat, useOuterClick } from '../../../helper/Helper'
import { ReglementService } from '../../../services/ReglementService';
import ErrorManager from "../../../components/error-manager/ErrorManager";
import { GlobalContext } from "../../../services/GlobalProvider";
import ConfirmDialog from '../../../components/confirm-dialog/ConfirmDialog';
import { BrouillonService } from '../../../services/BrouillonService';
import './SecteurAnalytique.scss';

const SecteurAnalytique = (props) => {

    const { preferences, folderDetails } = React.useContext(GlobalContext);

    const [inputValue, setInputValue] = useState("")
    const [secteurList, setSecteurList] = useState()
    const [activeRowIndex, setActiveRowIndex] = useState(0)
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const [filterSecteur, setFilterSecteur] = useState("")
    const [isGetSecteurListLoading, setIsGetSecteurListLoading] = useState(false)

    const formEdit = useRef();

    useEffect(() => {
        setInputValue(props.value)
    }, [props.value]);

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);
    
    const outerClick = useOuterClick(ev => {
        setIsDropdownOpen(false)
    });
    
    function handleKeyDown(e) {
        if (activeRowIndex != null && isDropdownOpen) {
            const { code } = e;
            switch (code) {
                case "ArrowUp":
                    e.preventDefault();
                    setActiveRowIndex(activeRowIndex != 0 && activeRowIndex - 1)
                    break;
                case "ArrowDown":
                    e.preventDefault();
                    setActiveRowIndex((activeRowIndex < secteurList.length - 1) ? activeRowIndex + 1 : activeRowIndex)
                    break;
                case "Enter":
                    setSecteur(secteurList[activeRowIndex])
                    setInputValue(secteurList[activeRowIndex])
                    setIsDropdownOpen(false)
                    break;
                case "Escape":
                    setIsDropdownOpen(false)
                    break;
                default:
                    break;
            }
        }
    }

    let updateTimeOut = useRef();
    function getSecteurs(searchValue) {
        window.clearTimeout(updateTimeOut.current);
        if(searchValue){
            updateTimeOut.current = window.setTimeout(function () {
                setIsGetSecteurListLoading(true)
                BrouillonService.getSearchSecteurs(folderDetails?.dossier?._id, searchValue).then(res => {
                    setActiveRowIndex(0)
                    setSecteurList(res.data.data)
                    setIsGetSecteurListLoading(false)
                }).catch(error => {
                    setIsGetSecteurListLoading(false)
                    // setGlobalError(error)
                });
            }, 1000);
        }
    }



    function changeInputValue(e) {
        setIsDropdownOpen(true)
        setInputValue(e.target.value)
        getSecteurs(e.target.value)
    }

    function onBlurInput(e){
        setSecteur(e.target.value)
    }

    function setSecteur(value){
        if(value !== props.value){
            props.setSecteur({
                target: {
                    name: props.name,
                    value: value
                }
            })
        }
    }

    function defineSecteur(secteur) {
        setInputValue(secteur)
        getSecteurs()
        setIsDropdownOpen(false)
        setSecteur(secteur)
    }

    return (
        <div ref={outerClick} className="position-relative">
            <h6 className="text-dark d-flex align-items-center fw-normal mb-3"><i className="text-primary-600 fs-4 material-icons me-2">work_outline</i>Secteur analytique</h6>
            <FormControl
                //isInvalid={props.isInvalid}
                type="text"
                name={props.name}
                value={inputValue}
                onChange={changeInputValue}
                onBlur={onBlurInput}
                placeholder="Secteur analytique"
                autoComplete="off"
                //onClick={() => setIsDropdownOpen(true)}
            />

            {isDropdownOpen && (
                <span className="drop-container zindex-100 border border-gray-500 rounded p-3 position-absolute top-100 bg-white shadow-lg mt-2 w-100">

                    {isGetSecteurListLoading &&
                        <span className="hloader"></span>
                    }

                    {((!isGetSecteurListLoading && !secteurList) || !inputValue) &&
                        <em>- Aucun élément trouvé -</em>
                    }

                    {(inputValue && !isGetSecteurListLoading && secteurList) &&
                        <>
                            {secteurList.map((secteur, i) => (
                                <span key={i} className={"cursor-pointer py-1 px-2 d-flex " + (i == activeRowIndex ? "selected bg-primary-100 " : "")} onClick={() => defineSecteur(secteur)}>{secteur}</span>
                            ))}
                        </>
                    }

                </span>
            )}

        </div>
    );
}

export default SecteurAnalytique;
