// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#col-manager .dropdown-menu {
  width: max-content;
}
#col-manager .draggable {
  top: auto !important;
  left: auto !important;
}`, "",{"version":3,"sources":["webpack://./src/components/table/dynamic-table/col-manager/ColManager.scss"],"names":[],"mappings":"AACI;EACE,kBAAA;AAAN;AAEI;EACE,oBAAA;EACA,qBAAA;AAAN","sourcesContent":["#col-manager{\n    .dropdown-menu{\n      width: max-content;\n    }\n    .draggable {\n      top: auto !important;\n      left: auto !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
