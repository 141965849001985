import React, { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { InputGroup, FormControl, Container, Button, Dropdown, DropdownButton, Badge, Col, Row, Form, OverlayTrigger, Tooltip, Toast, Nav, Spinner } from 'react-bootstrap';

import { ArticleService } from '../../services/ArticleService';
import { ProformaService } from '../../services/ProformaService';
import { EditionService } from '../../services/EditionService';

import DynamicTable from '../../components/table/dynamic-table/DynamicTable';
import Pagination from '../../components/pagination/Pagination';
import SideDetails from '../../components/side-details/SideDetails';
import ProformaDetails from '../../components/side-details/proforma/proforma-details/ProformaDetails';
//import ProformaEdition from '../../components/side-details/proforma/proforma-edition/ProformaEdition';
import ColManager from '../../components/table/dynamic-table/col-manager/ColManager';
import Families from '../../components/families/Families';
import './Proformas.scss';
import { PreferenceService } from '../../services/PreferenceService';
import ConfirmDialog from '../../components/confirm-dialog/ConfirmDialog';
import ErrorManager from "../../components/error-manager/ErrorManager";
import SendMailDialog from "../../components/send-mail-dialog/SendMailDialog";
import { GlobalContext } from "../../services/GlobalProvider";
import { formatTableHeadersFromPrefs, granted, getFile, currencyFormat } from '../../helper/Helper'
import Brouillons from "../../components/brouillons/Brouillons";


const Proformas = () => {

  const { globalContextLoaded, preferences, parametres, folderDetails, grants, getPreferences } = React.useContext(GlobalContext);

  let proformasParams = useRef({
    'start': 0,
    'limit': null,
    'search': null,
    'filters': null,
    'sort': null,
    'order': null,
    'filtre': null,
    'resume': true
  });

  const navigate = useNavigate();

  const [globalError, setGlobalError] = useState()
  const [searchParams, setSearchParams] = useSearchParams();
  const [proformas, setProformas] = useState()
  const [isGetProformasLoading, setIsGetProformasLoading] = useState()
  const [isPDFLoading, setIsPDFLoading] = useState()
  const [sideWidth, setSideWidth] = useState()
  const [tableHeaders, setTableHeaders] = useState()
  const [checkedElementIdList, setCheckedElementIdList] = useState([])
  const [currentPagination, setCurrentPagination] = useState(1)
  const [isTableFilterVisible, setIsTableFilterVisible] = useState(false)
  const [confirmDialog, setConfirmDialog] = useState({ show: false, dialogInfos: null })
  const [currentProforma, setCurrentProforma] = useState()
  const [sendMailDialog, setSendMailDialog] = useState({})

  let searchRef = useRef(null);

  const [tableRowElementActions] = useState([
    { icon: "edit", title: "Modifier la proforma (crée un brouillon)", action: "editProforma", disableKeyValues:{'front_custom_etat' : ['Annulée', 'Facturée']} },
    { icon: "article", title: "Transformer en facture (crée un brouillon)", action: "facturerProforma", disableKeyValues:{'front_custom_etat' : ['Expirée', 'Facturée']} },
    { icon: "file_copy", title: "Dupliquer (crée un brouillon)", action: "duplicateProforma" },
    { icon: "task", title: "Générer la facture", action: "generateFacture", disableKeyValues:{'front_custom_etat' : ['Expirée', 'Facturée']} },
    { icon: "print", title: "Imprimer la proforma", action: "downloadPDF" },
    { icon: "email", title: "Envoyer par mail", action: "sendEmail" }
  ])

  const [proformaElementActions] = useState([
    { icon: "edit", title: "Modifier la proforma (crée un brouillon)", action: "editProforma", disableKeyValues:{'front_custom_etat' : ['Annulée', 'Facturée']} },
    { icon: "article", title: "Transformer en facture (crée un brouillon)", action: "facturerProforma", disableKeyValues:{'front_custom_etat' : ['Expirée', 'Facturée']} },
    { icon: "file_copy", title: "Dupliquer (crée un brouillon)", action: "duplicateProforma" },
    { icon: "task", title: "Générer la facture", action: "generateFacture", disableKeyValues:{'front_custom_etat' : ['Expirée', 'Facturée']} },
    { icon: "print", title: "Imprimer la proforma", action: "downloadPDF" },
    { icon: "email", title: "Envoyer par mail", action: "sendEmail" }
  ])

  useEffect(() => {
    if (grants) {
      !grants.proformas.valeur && setGlobalError({ statusText: `Proformas : vous n'avez pas les droit d'accès à cette ressource.` })
      granted(grants, "proformas.remove") && tableRowElementActions.push({ icon: "delete_forever", title: "Annuler", action: "removeProformaDialog", disableKeyValues:{'front_custom_etat' : ['Facturée']} })
      granted(grants, "proformas.remove") && proformaElementActions.push({ icon: "delete_forever", title: "Annuler", action: "removeProformaDialog", disableKeyValues:{'front_custom_etat' : ['Facturée']} })
    }
  }, [grants]);

  useEffect(() => {
    if (globalContextLoaded) {
      searchParams.get('proformaId') && getProforma(searchParams.get('proformaId'))
      setPreferences()
      getProformas()

      // Si un brouillon est en cours d'édition on redirige.
      if (!searchParams.get('factureId') && preferences.proformas.currentDraft) {
        navigate(`/${folderDetails?.dossier?._id}/proformas/brouillon?brouillonId=${preferences.proformas.currentDraft}&from=proformas`)
      }

    }
  }, [globalContextLoaded]);

  function setPreferences() {
    setTableHeaders(formatTableHeadersFromPrefs(preferences.proformas['tableHeaders'], parametres.proformas['fieldsLabel']))
    proformasParams.current['sort'] = preferences.proformas['tableSort']
    proformasParams.current['order'] = preferences.proformas['tableOrder']
    proformasParams.current['limit'] = preferences.proformas['tableCount'] || 5
    proformasParams.current['fields'] = Object.keys(preferences.proformas['tableHeaders']).join(',')
  }

  function getProforma(proformaId) {
    ProformaService.getProforma(folderDetails?.dossier?._id, proformaId).then(res => {
      if (!res.data?.data) {
        setGlobalError({ statusText: res.statusText })
      } else {
        changeCurrentProforma(res.data.data)
      }
    }).catch(error => {
      setGlobalError(error)
    });
  }

  function getProformas() {
    setCheckedElementIdList([])
    if (granted(grants, "proformas.consult")) {
      setIsGetProformasLoading(true)
      if (!proformasParams.current['filtre']) {
        ProformaService.getProformas(folderDetails?.dossier?._id, proformasParams.current).then(res => {
          res.data.data && addCustomEtatColumn(res.data.data)
          setProformas({
            proformas: res.data.data.proformas,
            count: res.data.data.resume.total_count,
            resume: res.data.data.resume
          })
          setIsGetProformasLoading(false)
        }).catch(error => {
          setGlobalError(error)
          setIsGetProformasLoading(false)
        });
      } else {
        ProformaService.getProformasSummary(folderDetails?.dossier?._id, proformasParams.current).then(res => {
          res.data.data[proformasParams.current['filtre']].length > 0 && addCustomEtatColumn({ proformas: res.data.data[proformasParams.current['filtre']] })
          setProformas({
            proformas: res.data.data[proformasParams.current['filtre']].length > 0 ? res.data.data[proformasParams.current['filtre']] : null,
            count: res.data.data.resume[proformasParams.current['filtre']].count,
            resume: res.data.data.resume
          })
          setIsGetProformasLoading(false)
        }).catch(error => {
          setGlobalError(error)
          setIsGetProformasLoading(false)
        });
      }
    }
  }

  function addCustomEtatColumn(data) {

    // const dureeValidite = parametres.proformas.duree_validite || 0

    data.proformas.map(proforma => {

      let value = ""
      if (proforma.validite_jours_calc <= 0) {
        value = "Expirée"
      }
      if (proforma.historiques.suivants.proforma) {
        value = "Annulée"
      }
      if (proforma.historiques.suivants.facture) {
        value = "Facturée"
      }
      if (proforma?.validite_jours_calc !== 0 && proforma.historiques.suivants.length == 0) {
        value = "En cours"
      }

      proforma['front_custom_etat'] = value
    })
  }

  function getProformasPDF(idList) {
    if (granted(grants, "proformas.consult")) {
      !idList && setIsPDFLoading(true)
      let params = {
        'start': 0,
        'search': proformasParams.current.search,
        'sort': proformasParams.current.sort,
        'order': proformasParams.current.order,
        'filters': proformasParams.current.filters
      }

      if(idList){
        params['proformas'] = idList
      }

      EditionService.getListe(folderDetails?.dossier?._id, params, "proformas", "pdf").then(res => {
        setIsPDFLoading(false)
        if (!res?.data) {
          setGlobalError({ statusText: res.statusText })
        } else {
          getFile(res.data, `Proformas - Liste`, "pdf")
        }
      }).catch(error => {
        setIsPDFLoading(false)
        setGlobalError(error)
      });
    }
  }

  function removeProformaList(proformaIdList) {
    ProformaService.removeProformas(folderDetails?.dossier?._id, proformaIdList).then(res => {
      setConfirmDialog({ show: false });
      getProformas()
      changeCurrentProforma()
      setSideWidth()
      uncheckElementList(proformaIdList)
    }).catch(err => {
      setConfirmDialog({
        ...confirmDialog,
        dialogInfos: { ...confirmDialog.dialogInfos, error: err }
      })
    });
  }

  function uncheckElementList(idList) {
    let checkedElementIdListCopy = [...checkedElementIdList];
    idList.map(id => {
      let index = checkedElementIdListCopy.indexOf(id)
      if (index >= 0) {
        checkedElementIdListCopy.splice(index, 1)
      }
    })
    setCheckedElementIdList(checkedElementIdListCopy)
  }

  function saveTableHeadersPrefs(e) {
    const newHeadersObject = {}
    e.forEach(object => {
      newHeadersObject[object['dataTarget']] = { position: object['position'], hidden: object['hidden'] }
    });
    updatePreference({ ["tableHeaders"]: newHeadersObject })
  }

  function updatePreference(prefs) {
    PreferenceService.updatePreferences(folderDetails?.dossier?._id, { prefs: { ['proformas']: prefs } }).then(res => {
      getPreferences()
    }).catch(error => {
      setGlobalError(error)
    });
  }

  function changeCurrentProforma(proforma) {
    proforma?._id ? setSearchParams({ proformaId: proforma._id }) : setSearchParams(searchParams.delete('proformaId'))
    setCurrentProforma(proforma)
  }

  function changeTableHeaders(e) {
    setTableHeaders(e)
    saveTableHeadersPrefs(e)
  }

  function changePagination(p) {
    setCurrentPagination(p)
    proformasParams.current['start'] = (proformasParams.current.limit * p) - proformasParams.current.limit
    getProformas()
  }

  function changeLimit(l) {
    proformasParams.current['limit'] = l
    updatePreference({ ["tableCount"]: l })
    changePagination(1)
  }

  let searchTimeOut;
  function changeSearchValue(value) {
    window.clearTimeout(searchTimeOut);
    searchTimeOut = window.setTimeout(function () {
      setCurrentPagination(1)
      proformasParams.current['start'] = 0
      proformasParams.current['search'] = value ? value : ""
      searchRef.current.value = value ? value : ""
      getProformas()
    }, 1000);
  }

  let filtersTimeOut;
  function changeFilters(f) {
    window.clearTimeout(filtersTimeOut);
    filtersTimeOut = window.setTimeout(function () {
      setCurrentPagination(1)
      proformasParams.current['start'] = 0
      proformasParams.current['filters'] = f
      getProformas()
    }, 1000);
  }

  function clearFilters() {
    setCurrentPagination(1)
    proformasParams.current['start'] = 0
    proformasParams.current['filters'] = null
    getProformas()
    setIsTableFilterVisible(false)
  }

  function changeSortOrder(s, o) {
    proformasParams.current['sort'] = s
    proformasParams.current['order'] = o
    updatePreference({ ["tableSort"]: s, ["tableOrder"]: o })
    getProformas()
  }

  function onProformaSaved(proformaId) {
    getProforma(proformaId)
    getProformas()
  }

  function closeSide() {
    setCurrentProforma()
  }

  function openBrouillon(brouillonId, documentType) {
    navigate(`/${folderDetails?.dossier?._id}/${documentType}/brouillon?brouillonId=${brouillonId}&from=proformas`)
  }

  function createNewProforma() {
    let newProforma = {
      "type": "proforma"
    }
    ProformaService.postProforma(folderDetails?.dossier?._id, { proformas: [newProforma] }).then(res => {
      for (var brouillonId in res.data.data.brouillons) {
        openBrouillon(brouillonId, "proformas")
      }
    }).catch(error => {
      setGlobalError(error)
    });

  }

  function removeProformaListDialog(proformaIdList) {
    setConfirmDialog({
      show: true,
      dialogInfos: {
        title: `Supprimer ${proformaIdList.length} proforma(s) ?`,
        description: 'Voulez vous supprimer les éléments sélectionnés ?',
        actionName: 'Supprimer la sélection',
        btnVariant: 'danger',
        element: proformaIdList,
        error: false,
        confirmAction: 'removeProformaList'
      }
    })
  }

  function removeProformaDialog(proforma) {
    setConfirmDialog({
      show: true,
      dialogInfos: {
        title: proforma.nom,
        description: 'Voulez vous supprimer cet élément ?',
        actionName: 'Supprimer',
        btnVariant: 'danger',
        element: [proforma._id],
        error: false,
        confirmAction: 'removeProformaList'
      }
    })
  }

  function downloadPDF(proforma) {
    EditionService.getProforma(folderDetails?.dossier?._id, proforma._id, "pdf").then(res => {
      if (!res.data) {
        setGlobalError({ statusText: res.statusText })
      } else {
        getFile(res.data, `Proforma ${proforma.code}`, "pdf")
      }
    }).catch(error => {
      setGlobalError(error)
    });
  }

  function sendEmail(proforma) {
    setSendMailDialog({
      "clientId": proforma.client_id,
      "folderId": folderDetails?.dossier?._id,
      "documentType": "proformas",
      "documentId": proforma._id,
      "show": true
    })
  }

  function downloadCheckedProformas() {
    EditionService.getProformas(folderDetails?.dossier?._id, { proformas: checkedElementIdList }).then(res => {
      if (!res.data) {
        setGlobalError({ statusText: res.statusText })
      } else {
        if (checkedElementIdList.length > 1) {
          getFile(res.data, `Proformas`, "zip")
        } else {
          getFile(res.data, `Proforma`, "pdf")
        }
      }
    }).catch(error => {
      setGlobalError(error)
    });
  }

  function editProforma(proforma) {
    ProformaService.updateProforma(folderDetails?.dossier?._id, proforma._id).then(res => {
      if (!res.data?.data) {
        setGlobalError({ statusText: res.statusText })
      } else {
        let brouillonId = Object.keys(res.data.data.brouillons)[0]
        openBrouillon(brouillonId, "proformas")
      }
    }).catch(error => {
      setGlobalError(error)
    });
  }

  function facturerProforma(proforma) {
    ProformaService.facturerProforma(folderDetails?.dossier?._id, proforma._id).then(res => {
      if (!res.data?.data) {
        setGlobalError({ statusText: res.statusText })
      } else {
        let brouillonId = Object.keys(res.data.data.brouillons)[0]
        openBrouillon(brouillonId, "factures")
      }
    }).catch(error => {
      setGlobalError(error)
    });
  }

  function duplicateProforma(proforma) {
    ProformaService.duplicateProforma(folderDetails?.dossier?._id, proforma._id).then(res => {
      if (!res.data?.data) {
        setGlobalError({ statusText: res.statusText })
      } else {
        let brouillonId = Object.keys(res.data.data.brouillons)[0]
        openBrouillon(brouillonId, "proformas")
      }
    }).catch(error => {
      setGlobalError(error)
    });
  }

  function generateFacture(proforma) {
    ProformaService.generateFacture(folderDetails?.dossier?._id, proforma._id, { 'do_not_simulate': true }).then(res => {
      if (!res.data?.data) {
        setGlobalError({ statusText: res.statusText })
      } else {

        if (res.data?.data?.failed) {
          setGlobalError({ data: { data: res.data?.data?.failed, status: { message: "La génération a échouée." } } })
        }

        if (res.data?.data?.factures) {
          navigate(`/${folderDetails?.dossier?._id}/factures?factureId=${res.data?.data?.factures[proforma._id]}`)
        }

      }
    }).catch(error => {
      setGlobalError(error)
    });
  }

  function changeParamFiltre(value) {
    proformasParams.current['filtre'] = value
    setCurrentPagination(1)
    proformasParams.current['start'] = 0
    getProformas()
  }

  return (
    <Container fluid id="Proformas" className="py-4">

      <div className="pb-2 d-flex align-items-center justify-content-between">
        <h4 className="">Proformas</h4>
        <div>
          {/* Brouillons en cours */}
          <Brouillons brouillonTypes={["proforma"]} openBrouillon={b => openBrouillon(b, "proformas")} grantedConsult={granted(grants, "brouillons.consult")} grantedEdit={granted(grants, "brouillons.edit")} grantedRemove={granted(grants, "brouillons.remove")} />

          {granted(grants, "proformas.edit") &&
            <Button variant="primary" onClick={() => createNewProforma()}>Créer une proforma</Button>
          }
        </div>
      </div>

      <ErrorManager error={globalError} fixed="true" />

      <SendMailDialog clientId={sendMailDialog.clientId} folderId={sendMailDialog.folderId} documentType={sendMailDialog.documentType} documentId={sendMailDialog.documentId} show={sendMailDialog.show} setCloseModal={() => setSendMailDialog({ show: false })} />

      <ConfirmDialog
        show={confirmDialog.show}
        dialogInfos={confirmDialog.dialogInfos}
        setCancel={() => setConfirmDialog({ show: false })}
        setConfirm={(a, e) => eval(a)(e)}
      />

      <Row>

        <Col xs="12">
          <Row className="mb-4">
            {granted(grants, "proformas.consult") &&
              <>
                <Col xs="12" xl="3" className="mb-sm-2 mb-xl-0">
                  <InputGroup>
                    <i className="material-icons input-icon text-gray-400">search</i>
                    <FormControl
                      className="input-icon-space rounded-start"
                      placeholder="Recherche globale"
                      type="text"
                      onChange={e => changeSearchValue(e.target.value)}
                      ref={searchRef}
                    />
                    {proformasParams.current['search'] &&
                      <Button onClick={() => changeSearchValue()} variant="gray-100" className="border border-start-0">
                        <i className="material-icons i-lg">clear</i>
                      </Button>
                    }
                  </InputGroup>
                </Col>
                <Col xs="12" xl="auto" className="d-flex align-items-center mb-sm-2 mb-xl-0">
                  {!proformasParams.current.filters &&
                    <OverlayTrigger placement="right" delay={{ show: 800 }} overlay={<Tooltip>Filtres de recherche</Tooltip>}>
                      <Button className="me-2" variant="secondary" onClick={() => setIsTableFilterVisible(!isTableFilterVisible)}>
                        <i className="material-icons i-lg">filter_alt</i>
                      </Button>
                    </OverlayTrigger>
                  }
                  {proformasParams.current.filters &&
                    <Button className="me-2" variant="warning" onClick={() => clearFilters()}>
                      Suppr. les filtres
                      <i className="material-icons i-lg ms-2 d-inline-flex">clear</i>
                    </Button>
                  }
                  <ColManager tableHeaders={tableHeaders} changeTableHeaders={e => changeTableHeaders(e)} />
                  <Button disabled={isPDFLoading} variant="secondary" className="me-2" onClick={() => getProformasPDF()}>
                    {!isPDFLoading && <i className="material-icons i-lg float-start me-2">print</i>}
                    {isPDFLoading && <Spinner animation="border" size="sm" className="me-2" />}
                    Imprimer la liste ({proformas?.count})
                  </Button>

                  {checkedElementIdList?.length > 0 &&
                    <Dropdown className="d-inline-block me-2">
                      <Dropdown.Toggle variant="dark" id="dropdown-selectionnes">
                        {checkedElementIdList.length} Sélectionné{checkedElementIdList?.length > 1 && <span>s</span>}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {/* <Dropdown.Item onClick={() => setCheckedElementIdList([])}>Tout désélectionner</Dropdown.Item> */}
                        <Dropdown.Item onClick={() => downloadCheckedProformas()}>Imprimer les proformas</Dropdown.Item>

                        {granted(grants, "proformas.remove") &&
                          <>
                            <Dropdown.Item onClick={() => getProformasPDF(checkedElementIdList)}>Imprimer la liste sélectionnée</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item className="text-danger" onClick={() => removeProformaListDialog(checkedElementIdList)}>Supprimer</Dropdown.Item>
                          </>
                        }
                      </Dropdown.Menu>
                    </Dropdown>
                  }
                </Col>
              </>
            }

          </Row>

          <Row className="mb-3">
            <Col>
              <Nav variant="pills" activeKey={proformasParams.current.filtre || "all"} >
                <Nav.Item>
                  <Nav.Link onClick={() => changeParamFiltre()} eventKey="all" className="border border-2 border-primary d-flex flex-column me-2 h-100">
                    <span className="fw-bold">Toutes ({proformas?.resume?.total_count})</span>
                    <span className="">
                      Total TTC : N/C
                    </span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link onClick={() => changeParamFiltre('encours')} eventKey="encours" className="border d-flex flex-column me-2 h-100">
                    <span className="fw-bold">En cours ({proformas?.resume?.encours?.count})</span>
                    {proformas?.resume?.encours?.total_ttc != null &&
                      <span className="">
                        Total TTC : {currencyFormat(proformas?.resume?.encours?.total_ttc)}
                      </span>
                    }
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link onClick={() => changeParamFiltre('facturees')} eventKey="facturees" className="border d-flex flex-column me-2 h-100">
                    <span className="fw-bold">Facturées ({proformas?.resume?.facturees?.count})</span>
                    {proformas?.resume?.facturees?.total_ttc != null &&
                      <span className="">
                        Total TTC : {currencyFormat(proformas?.resume?.facturees?.total_ttc)}
                      </span>
                    }
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link onClick={() => changeParamFiltre('expirees')} eventKey="expirees" className="border d-flex flex-column me-2 h-100">
                    <span className="fw-bold">Expirées ({proformas?.resume?.expirees?.count})</span>
                    {proformas?.resume?.expirees?.total_ttc != null &&
                      <span className="">
                        Total TTC : {currencyFormat(proformas?.resume?.expirees?.total_ttc)}
                      </span>
                    }
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link onClick={() => changeParamFiltre('annulees')} eventKey="annulees" className="border d-flex flex-column me-2 h-100">
                    <span className="fw-bold">Annulées ({proformas?.resume?.annulees?.count})</span>
                    {proformas?.resume?.annulees?.total_ttc != null &&
                      <span className="">
                        Total TTC : {currencyFormat(proformas?.resume?.annulees?.total_ttc)}
                      </span>
                    }
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>

          {granted(grants, "proformas.consult") &&
            <Row>
              <Col xs="12">
                <div className="p-3 bg-white rounded border overflow-auto">

                  <DynamicTable
                    isDataLoading={isGetProformasLoading}
                    elementActions={tableRowElementActions}
                    setElementAction={(a, e) => eval(a)(e)}
                    sideWidth={sideWidth}
                    tableHeaders={tableHeaders}
                    tableDataList={proformas?.proformas}
                    currentElement={currentProforma}
                    changeCurrentElement={e => changeCurrentProforma(e)}
                    checkedElementIdList={checkedElementIdList}
                    setCheckedElementIdList={e => setCheckedElementIdList(e)}
                    changeFilters={f => changeFilters(f)}
                    isTableFilterVisible={isTableFilterVisible}
                    changeSortOrder={(s, o) => changeSortOrder(s, o)}
                    params={proformasParams.current}
                  />
                  <Pagination className="mt-3" itemsLength={proformas?.count} elementsPerPage={proformasParams.current.limit} currentPagination={currentPagination} changePagination={p => changePagination(p)} showChangeLimit changeLimit={l => changeLimit(l)} />
                </div>
              </Col>
            </Row>
          }

          {currentProforma &&
            <SideDetails
              currentElement={{ id: currentProforma?._id, label: new Date(currentProforma?.date).toLocaleString(), title: currentProforma?.client_nom }}
              changeCurrentElement={e => changeCurrentProforma(e)}
              setSideClose={closeSide}
              setSideWidth={e => setSideWidth(e)}
              detailsComponent={
                <ProformaDetails folderId={folderDetails?.dossier?._id} proformaId={currentProforma._id} elementActions={proformaElementActions} setElementAction={(a, e) => eval(a)(e)} />
              }
            />
          }
        </Col>
      </Row>

    </Container>


  );
}

export default Proformas;