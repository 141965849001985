import React, { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from "react-router-dom";
import { Container, Button, Dropdown, DropdownButton, Alert, Col, Row, Spinner, OverlayTrigger, Tooltip, Toast, ToastContainer } from 'react-bootstrap';
import './ErrorManager.scss';

const ErrorManager = (props) => {

    const [errorList, setErrorList] = useState([]);
    const [show, setShow] = useState(false);

    useEffect(() => {

        setShow(false)

        let errList = []

        if (props.error?.data?.data) {
            getDeepKeys(props.error?.data?.data)
            function getDeepKeys(obj) {
                for (var key in obj) {
                    if (typeof obj[key] === 'string' && obj[key].substring(0, 9) == "Erreur : ") {
                        let splitString = obj[key].split("Erreur : ");
                        splitString.forEach(str => {
                            if (str != "") {
                                errList.push(str)
                            }
                        });
                    }
                    if (obj.length == 1 && typeof obj[key] === 'string' && obj[key].substring(0, 9) != "Erreur : ") {
                        errList.push(obj[key])
                    }
                    if (typeof obj[key] === "object") {
                        getDeepKeys(obj[key])
                    }
                }
                return;
            }
        }

        if (errList.length == 0 && props.error?.statusText) {
            errList.push(props.error.statusText)
        }

        if (errList.length == 0 && props.error) {
            errList.push('Une erreur est survenue')
        }

        setErrorList(errList)

        setTimeout(() => {
            setShow(true)
        }, 100);

    }, [props.error]);

    return (
        <div id="ErrorManager">
            {show && errorList.length > 0 &&
                <Alert variant="danger" onClose={() => setShow(false)} dismissible className={props.fixed ? "error-fixed zindex-900 shadow shadow-lg" : ""}>
                    {props?.error?.data?.status?.message &&
                        <h6>{props?.error?.data?.status?.message}</h6>
                    }
                    {!props?.error?.data?.status?.message &&
                        <h6>Erreur</h6>
                    }
                    {errorList.map((error, i) => (
                        <li className="small" key={i}>{error}</li>
                    ))}
                </Alert>
            }
        </div>
    )
}

export default ErrorManager;
