import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Container, Button, Dropdown, DropdownButton, Form, Col, Row, Tooltip, OverlayTrigger, InputGroup } from 'react-bootstrap';
import { FamilyService } from '../../services/FamilyService';
import { UserService } from '../../services/UserService';
import { PreferenceService } from '../../services/PreferenceService';
import { GlobalContext } from "../../services/GlobalProvider";

import TextHighlight from '../text-highlight/TextHighlight';
import ModalAddFamily from './modal-add-family/ModalAddFamily';
import ModalRemoveFamily from './modal-remove-family/ModalRemoveFamily';
import ModalEditFamily from './modal-edit-family/ModalEditFamily';
import { granted } from '../../helper/Helper'

import './Families.scss';

const Families = (props) => {

    const { grants } = React.useContext(GlobalContext);
    const { getPreferences, preferences, folderDetails, globalContextLoaded, parametres } = React.useContext(GlobalContext);

    const [treeFamilyList, setTreeFamilyList] = useState(null)
    const [treeFamilyListLoading, setTreeFamilyListLoading] = useState(false)
    const [familyList, setFamilyList] = useState(null)
    const [currentFamily, setCurrentFamily] = useState(props.currentFamily)
    const [showFamilyList, setShowFamilyList] = useState([])
    const [searchValue, setSearchValue] = useState(null)
    const [closedFamilies, setClosedFamilies] = useState([])
    const [showCode, setShowCode] = useState()
    const [showModalAddFamily, setShowModalAddFamily] = useState({ show: false, family: null })
    const [showModalRemoveFamily, setShowModalRemoveFamily] = useState({ show: false, family: null })
    const [showModalEditFamily, setShowModalEditFamily] = useState({ show: false, family: null })

    const searchInput = useRef("");

    useEffect(() => {
        filterFamily()
    }, [searchValue]);

    useEffect(() => {
        if (globalContextLoaded) {
            setShowCode(preferences.familles['showCode'])
            getFamilies()
        }
    }, [globalContextLoaded]);

    function changeFamily(f) {
        setCurrentFamily(f)
        props.changeFamily(f)
    }

    function getFamilies() {
        setTreeFamilyListLoading(true)
        FamilyService.getTreeFamilies(folderDetails?.dossier?._id).then(res => {
            setTreeFamilyListLoading(false)
            setTreeFamilyList(res.data.data.familles)
            createFamilyList(res.data.data.familles)
        }).catch(error => {
            setTreeFamilyListLoading(false)
        });
    }

    function createFamilyList(tree) {
        let famList = []
        recursive(tree, [])
        function recursive(famTree, famParent) {
            for (let fam of famTree) {
                fam.parents = famParent
                famList.push(fam)
                if (fam.children.length > 0) {
                    recursive(fam.children, [...famParent, fam])
                }
            }
        }
        setFamilyList(famList)
        setShowFamilyList(famList.map(f => f._id))
    }

    function openClose(familyId) {
        let closedFamiliesCopy = [...closedFamilies];
        const index = closedFamiliesCopy.indexOf(familyId);
        index > -1 ? closedFamiliesCopy.splice(index, 1) : closedFamiliesCopy.push(familyId)
        setClosedFamilies(closedFamiliesCopy)
    }

    function openCloseAll() {
        let closedFamiliesCopy = [...closedFamilies];
        let openAll = true
        for (let family of familyList) {
            const index = closedFamiliesCopy.indexOf(family._id);
            index == -1 && closedFamiliesCopy.push(family._id)
            index == -1 && (openAll = false)
        }
        openAll && (closedFamiliesCopy = [])
        setClosedFamilies(closedFamiliesCopy)
    }

    function filterFamily() {
        let showArr = []
        familyList?.map(f => {
            if (f.designation?.toLowerCase().includes(searchValue.toLowerCase()) || f?.code.toLowerCase().includes(searchValue.toLowerCase())) {
                showArr.push(f._id)
                f.parents.map(p => showArr.push(p._id))
            }
        })
        setShowFamilyList(showArr)
    }

    function closeModalRemove(o) {
        setShowModalRemoveFamily({ show: false, family: null })
        if (o.reload) {
            getFamilies()
        }
    }

    function closeModalEdit(o) {
        setShowModalEditFamily({ show: false, family: null })
        if (o.reload) {
            getFamilies()
        }
    }

    function closeModalAddFamily(o) {
        setShowModalAddFamily({ show: false, family: null })
        if (o.reload) {
            getFamilies()
        }
    }

    function changeShowCode() {
        let currentShow = showCode
        setShowCode(!currentShow)

        //familyParams.current['sort'] = !currentShow ? 'code' : 'designation'
        //getFamilies()
        updatePreference("showCode", !currentShow)
    }

    function updatePreference(key, value) {
        PreferenceService.updatePreferences(folderDetails?.dossier?._id, { prefs: { ['familles']: { [key]: value } } }).then(res => {
            getPreferences()
        })
    }

    function removeSearchValue() {
        searchInput.current.value = ""
        setSearchValue("")
    }

    const TreeNode = (treeProps) => {

        return (
            <>
                {treeProps.childList?.filter(family => showFamilyList.indexOf(family._id) > -1).map(family => (

                    <span id="FamilyTree" key={family._id}>

                        <span title={family.designation} className={"family cursor-pointer hover-bg-gray-200 " + (family._id == currentFamily ? 'bg-primary-300 hover-bg-primary-400 text-primary-800' : '')} >
                            {family.children.length > 0 &&
                                <i className={"caret cursor-pointer material-icons text-muted " + (closedFamilies.indexOf(family._id) > -1 ? 'caret-close' : '')} onClick={() => openClose(family._id)} >arrow_forward_ios</i>
                            }
                            <span onClick={() => changeFamily(family._id)}>
                                {showCode && <span className="me-2 fw-bold"><TextHighlight text={family.code} value={searchValue} /></span>}
                                <TextHighlight text={family.designation} value={searchValue} />
                            </span>

                            {(parametres?.general?.export_comptable?.comptabilite_analytique && family?.nature_analytique) &&
                                <OverlayTrigger
                                    placement="left"
                                    overlay={
                                        <Tooltip>
                                            <b>Nature : </b>{family.nature_analytique}
                                        </Tooltip>
                                    }
                                >
                                    <i className="material-icons d-inline-block ms-2 me-2 i-lg">
                                        style
                                    </i>
                                </OverlayTrigger>
                            }
                            {(granted(grants, "familles.edit") || granted(grants, "familles.remove")) &&
                                <Dropdown className="light-dropdown p-2">
                                    <Dropdown.Toggle>
                                        <i className="material-icons i-lg">more_horiz</i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {granted(grants, "familles.edit") &&
                                            <>
                                                <Dropdown.Item onClick={() => setShowModalAddFamily({ show: true, family: family })}>Ajouter une sous-famille</Dropdown.Item>
                                                <Dropdown.Item onClick={() => setShowModalEditFamily({ show: true, family: family })}>Modifier</Dropdown.Item>
                                                <Dropdown.Item onClick={() => props.createNewArticle(family)}>Ajouter un article</Dropdown.Item>
                                            </>
                                        }
                                        {granted(grants, "familles.remove") &&
                                            <Dropdown.Item onClick={() => setShowModalRemoveFamily({ show: true, family: family })} className="text-danger">Supprimer</Dropdown.Item>
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                            }

                        </span>


                        <span className={"subfamilies " + (closedFamilies.indexOf(family._id) > -1 ? 'close' : '')}>
                            <TreeNode childList={family.children} />
                        </span>

                    </span>
                ))}
            </>
        );
    };

    return (
        <>
            {showModalAddFamily.show && <ModalAddFamily object={showModalAddFamily} setClose={closeModalAddFamily} />}
            {showModalRemoveFamily.show && <ModalRemoveFamily object={showModalRemoveFamily} setClose={closeModalRemove} />}
            {showModalEditFamily.show && <ModalEditFamily object={showModalEditFamily} setClose={closeModalEdit} />}

            {
                treeFamilyList && (
                    <>
                        <span id="FamilyTree" className="ms-0 mb-2">

                            <span title='Toutes les familles' className={"family cursor-pointer hover-bg-gray-200 fw-bold " + (null == currentFamily ? 'bg-primary-300 hover-bg-primary-400 text-primary-800' : '')} >

                                <i className={"caret cursor-pointer material-icons text-muted " + (22222 > -1 ? 'caret-close' : '')} onClick={() => openCloseAll()} >arrow_forward_ios</i>
                                <span onClick={() => changeFamily(null)}>Familles (tout afficher)</span>

                                <Dropdown className="light-dropdown p-2">
                                    {/* <Dropdown.Toggle as={CustomDropdownIcon}></Dropdown.Toggle> */}

                                    <Dropdown.Toggle className="visible">
                                        <i className="material-icons i-lg">more_horiz</i>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <InputGroup className="mx-3 my-2 w-auto">
                                            <Form.Control ref={searchInput} type="text" onChange={e => setSearchValue(e.target.value)} placeholder="Recherche famille" />
                                            {searchInput.current.value &&
                                                <Button className="border border-start-0" variant="gray-100" onClick={() => removeSearchValue()}><i className="material-icons">close</i></Button>
                                            }
                                        </InputGroup>
                                        <span className="mx-3 mb-1 d-block">
                                            <Form.Check
                                                className="me-1"
                                                type="switch"
                                                inline
                                                checked={showCode}
                                                onChange={() => changeShowCode()}
                                            />
                                            Afficher le code
                                        </span>
                                        <Dropdown.Item onClick={() => props.setIsFamiliesVisible(false)}>Masquer les familles</Dropdown.Item>
                                        {granted(grants, "familles.edit") &&
                                            <Dropdown.Item onClick={() => setShowModalAddFamily({ show: true, family: null })}>Ajouter un département</Dropdown.Item>
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>

                            </span>
                        </span>
                        {treeFamilyList?.filter(family => showFamilyList.indexOf(family._id) > -1).length == 0 &&
                            <em>- Aucune famille trouvée -</em>
                        }
                        {!treeFamilyListLoading &&
                            <TreeNode childList={treeFamilyList} />
                        }
                        {
                            treeFamilyListLoading && (
                                <span className="hloader"></span>
                            )
                        }
                    </>
                )
            }

        </>
    );

};

export default Families;
