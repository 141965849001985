// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#WysiwygInput {
  display: flex;
  flex-direction: column;
}
#WysiwygInput.focus {
  border-color: #80d1ff;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(1, 163, 255, 0.25);
}
#WysiwygInput .disabled {
  background: hsla(236, 24%, 87%, 0.5);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 500;
}
#WysiwygInput a {
  color: inherit;
  text-decoration: none;
}
#WysiwygInput .rdw-option-wrapper {
  border: none;
}
#WysiwygInput .rdw-option-wrapper:hover {
  box-shadow: none;
  background-color: #eff1f9;
}
#WysiwygInput .rdw-dropdown-wrapper {
  border: none;
}
#WysiwygInput .rdw-dropdown-wrapper:hover {
  box-shadow: none;
  background-color: #eff1f9;
}
#WysiwygInput .rdw-dropdown-optionwrapper {
  overflow: hidden;
  box-shadow: none;
}
#WysiwygInput .rdw-editor-main {
  max-height: 300px;
}
#WysiwygInput .message-editor {
  min-height: 80px;
}`, "",{"version":3,"sources":["webpack://./src/components/form-inputs/wysiwyg-input/WysiwygInput.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;AACJ;AACI;EACI,qBAAA;EACA,UAAA;EACA,iDAAA;AACR;AAEI;EACI,oCAAA;EACA,kBAAA;EACA,MAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,YAAA;AAAR;AAGI;EACI,cAAA;EACA,qBAAA;AADR;AAGI;EACI,YAAA;AADR;AAEQ;EACI,gBAAA;EACA,yBAAA;AAAZ;AAGI;EACI,YAAA;AADR;AAEQ;EACI,gBAAA;EACA,yBAAA;AAAZ;AAII;EACI,gBAAA;EACA,gBAAA;AAFR;AAKI;EACI,iBAAA;AAHR;AAYI;EACI,gBAAA;AAVR","sourcesContent":["#WysiwygInput {\n    display: flex;\n    flex-direction: column;\n\n    &.focus{\n        border-color: #80d1ff;\n        outline: 0;\n        box-shadow: 0 0 0 0.25rem rgba(1, 163, 255, 0.25);\n    }\n\n    .disabled{\n        background: hsla(236, 24%, 87%, 0.5);\n        position: absolute;\n        top: 0;\n        bottom: 0;\n        left: 0;\n        right: 0;\n        z-index: 500;\n    }\n    \n    a{\n        color: inherit;\n        text-decoration: none;\n    }\n    .rdw-option-wrapper{\n        border: none;\n        &:hover{\n            box-shadow: none;\n            background-color: #eff1f9;\n        }\n    }\n    .rdw-dropdown-wrapper{\n        border: none;\n        &:hover{\n            box-shadow: none;\n            background-color: #eff1f9;\n        }\n    }\n\n    .rdw-dropdown-optionwrapper{\n        overflow: hidden;\n        box-shadow: none;\n    }\n\n    .rdw-editor-main{\n        max-height: 300px;\n    }\n    .editor-wrapper {\n    }\n    \n    .message-toolbar {\n        \n    }\n    \n    .message-editor {\n        min-height: 80px;\n    }\n\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
