// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#FactureDetails .time-dot::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: #ffffff;
  display: inline-block;
  margin-left: -21px;
  margin-right: 15px;
  border: 1px solid #9ea0b9;
}
#FactureDetails .table-container {
  width: max-content;
  min-width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/side-details/facture/facture-details/FactureDetails.scss"],"names":[],"mappings":"AAEQ;EACI,WAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,yBAAA;EACA,qBAAA;EACA,kBAAA;EACA,kBAAA;EACA,yBAAA;AADZ;AAII;EACI,kBAAA;EACA,eAAA;AAFR","sourcesContent":["#FactureDetails{\n    .time-dot{\n        &::before{\n            content: \"\";\n            width: 10px;\n            height: 10px;\n            border-radius: 10px;\n            background-color: #ffffff;\n            display: inline-block;\n            margin-left: -21px;\n            margin-right: 15px;\n            border: 1px solid #9ea0b9 ;\n        }\n    }\n    .table-container{\n        width: max-content;\n        min-width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
