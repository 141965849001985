import React, { useEffect, useState } from 'react';
import { ArticleService } from '../../../../services/ArticleService';
import { Spinner, Card, Tabs, Tab, Row, Col, Accordion, Button, Table, Alert, Form, Dropdown, InputGroup } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import ErrorManager from "../../../error-manager/ErrorManager";
import FamilyInput from "../../../form-inputs/family-input/FamilyInput";
import ContributionsInput from "../../../form-inputs/contributions-input/ContributionsInput";
import TextArrayInput from "../../../form-inputs/text-array-input/TextArrayInput";
import { getFormErrorMsg, getInvalidObject, granted, getChangedValues } from '../../../../helper/Helper'
import { GlobalContext } from "../../../../services/GlobalProvider";
import WysiwygInput from "../../../../components/form-inputs/wysiwyg-input/WysiwygInput";
import ArticleInput from "../../../../components/form-inputs/article-input/ArticleInput";


const ArticleEdition = (props) => {

    const [form, setForm] = useState({
        "_id": "",
        "code": "",
        "famille_id": "",
        "designation": "",
        "description": "",
        "observation": "",
        "conditionnement": "",
        "prix_revient_pondere": "",
        "prix_vente_ht": "",
        "prix_vente_ttc": "",
        "unite_vente": "",
        "taux_remise_maximum": "",
        "code_associe": "",
        "contributions": [],
        "codesbarres": [],
        "tags": []
    })

    const [prixVente, setPrixVente] = useState("ht")
    const [invalidData, setInvalidData] = useState()
    const [globalError, setGlobalError] = useState()
    const [getArticleLoading, setGetArticleLoading] = useState(false)
    const [articleInitial, setArticleInitial] = useState()
    const [inputChanged, setInputChanged] = useState(false)
    const [isCodeLoading, setIsCodeLoading] = useState(false)

    const { grants } = React.useContext(GlobalContext);

    useEffect(() => {
        props.articleEditingId && getArticle(props.articleEditingId)

        props.newArticleFamily && handleChangeFamille({
            target: {
                name: "famille_id",
                value: props.newArticleFamily._id,
                family: props.newArticleFamily
            }
        })

        /* if (props.articleEditingId) {
            getArticle(props.articleEditingId)
        } else {
            setGetArticleLoading(false)
        } */
    }, []);

    function handleChange(e) {
        setInputChanged(true)
        let value
        e.target.type == "checkbox" ? value = e.target.checked : value = e.target.value
        setForm({ ...form, [e.target.name]: value })
        setInvalidData({ ...invalidData, [e.target.name]: null })
    }

    function handleChangeCodeArticleAssocie(article) {
        console.log('----article')
        console.log(article)

        setInputChanged(true)
        
        setForm({
            ...form, ['code_associe']: article?._id ? article._id : null
        })

        setInvalidData({ ...invalidData, ['code_associe']: null })
    }

    function handleChangeFamille(e) {

        if (!props.articleEditingId) {
            setNextCode(e.target.family.code)
        }

        setInputChanged(true)

        let formCopy = form
        formCopy["famille_id"] = e.target.value

        setForm({ ...formCopy })
        setInvalidData({ ...invalidData, ["famille_id"]: null })
    }

    function handleChangePrixVente(e) {
        console.log('------------prix_vente_ht')
        setInputChanged(true)
        let formCopy = form
        if (e.target.name == "prix_vente_ht") {
            formCopy[e.target.name] = e.target.value
            formCopy["prix_vente_ttc"] = ""
        }
        if (e.target.name == "prix_vente_ttc") {
            formCopy[e.target.name] = e.target.value
            formCopy["prix_vente_ht"] = ""
        }
        setForm({ ...formCopy })
    }

    function setNextCode(prefix) {
        setIsCodeLoading(true)
        ArticleService.getNextCode(props.folderId, prefix).then(res => {
            setIsCodeLoading(false)
            setForm({ ...form, ['code']: res.data.data[prefix] })
        }).catch(error => {
            setIsCodeLoading(false)
            setGlobalError(error)
        });
    }

    function getArticle(articleId) {
        ArticleService.getArticle(props.folderId, articleId).then(res => {
            mapForm(res.data.data)
            setGetArticleLoading(false)
            setArticleInitial(res.data.data)
        }).catch(error => {
            setGlobalError(error)
            setGetArticleLoading(false)
        });
    }

    function mapForm(apiObject) {
        let formCopy = {}
        for (let key in form) {
            formCopy[key] = apiObject[key]
        }
        formCopy.contributions = apiObject?.contributions.map(c => c._id)
        setForm(formCopy)
    }

    function addArticle() {
        ArticleService.postArticle(props.folderId, { articles: [form] }).then(res => {
            props.onSetSaved(res.data.data.articles[0])
        }).catch(error => {
            setGlobalError(error)
            setInvalidData(getInvalidObject(error.data?.data))
        });
    }

    function updateArticle() {

        let maintainIfEqual = ["_id"]
        prixVente == "ht" ? maintainIfEqual.push("prix_vente_ht") : maintainIfEqual.push("prix_vente_ttc")

        ArticleService.updateArticle(props.folderId, { articles: [getChangedValues(form, articleInitial, null, maintainIfEqual, ["codesbarres", "contributions"])] }).then(res => {
            props.onSetSaved(res.data.data.articles[0])
        }).catch(error => {
            setGlobalError(error)
            setInvalidData(getInvalidObject(error.data?.data))
        });
    }

    return (
        <>

            <Form.Group className="mb-3 float-end me-2 d-flex flex-column" controlId="formBasicEmail">
                {!props.articleEditingId &&
                    <Button
                        disabled={!form.code || !form.designation || !form.famille_id}
                        variant="primary"
                        onClick={() => addArticle()}>
                        Enregistrer
                    </Button>
                }
                {props.articleEditingId &&
                    <Button
                        disabled={!inputChanged || !form.code || !form.designation}
                        variant="primary"
                        onClick={() => updateArticle()}>
                        Enregistrer l'article
                    </Button>
                }
            </Form.Group>

            {!props.articleEditingId &&
                <div className="d-flex  mb-4 ps-2">
                    <div className="pe-3 w-100">
                        <h6 className="text-secondary m-0">Créer un</h6>
                        <h6 className="fw-bolder m-0">Nouvel article</h6>
                    </div>
                </div>
            }

            {props.articleEditingId &&
                <div className="d-flex  mb-4 ps-2">
                    <div className="pe-3 w-100">
                        <h6 className="text-secondary m-0">Modification de l'article</h6>
                        <h6 className="fw-bolder m-0">{form?.designation}</h6>
                    </div>
                </div>
            }

            {getArticleLoading &&
                <span className="hloader mt-3"></span>
            }

            {!getArticleLoading &&
                <div className="light-v-scroll pe-3 ps-2 pb-5 w-100">

                    {globalError && (
                        <ErrorManager error={globalError} fixed="true" />
                    )}

                    <Form>

                        <Form.Group className="mb-3">
                            <Form.Label>Famille<span className="text-danger">*</span></Form.Label>
                            <FamilyInput folderId={props.folderId} familyId={form?.famille_id} setFamilyId={handleChangeFamille} name="famille_id" isInvalid={getFormErrorMsg(invalidData?.famille_id)} feedback={getFormErrorMsg(invalidData?.famille_id)} />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Code<span className="text-danger">*</span></Form.Label>
                            {isCodeLoading &&
                                <Spinner animation="border" size="sm" className="ms-2 text-gray-400" />
                            }
                            <Form.Control disabled={isCodeLoading} name="code" value={form?.code} isInvalid={getFormErrorMsg(invalidData?.code)} onChange={handleChange} type="text" />
                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.code)}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Désignation<span className="text-danger">*</span></Form.Label>
                            <Form.Control name="designation" value={form?.designation} isInvalid={getFormErrorMsg(invalidData?.designation)} onChange={handleChange} type="text" />
                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.designation)}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <Form.Control name="description" value={form?.description} isInvalid={getFormErrorMsg(invalidData?.description)} onChange={handleChange} type="text" />
                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.description)}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Codes barres</Form.Label>
                            <TextArrayInput value={form?.codesbarres} addLabel="Ajouter un code barre" width="50" setTextArrayList={handleChange} name="codesbarres" placeHolder="Code barre" isInvalid={getFormErrorMsg(invalidData?.codesbarres)} feedback={getFormErrorMsg(invalidData?.codesbarres)} />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Code article associé</Form.Label>
                            <ArticleInput name="code_associe" isInvalid={getFormErrorMsg(invalidData?.code_associe)} articleId={form?.code_associe} folderId={props.folderId} setArticle={a => handleChangeCodeArticleAssocie(a)} />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Observation</Form.Label>
                            <WysiwygInput value={form?.observation} placeHolder="Rédiger les informations relatives à la facture..." changeValue={handleChange} name="observation" isInvalid={getFormErrorMsg(invalidData?.observation)} feedback={getFormErrorMsg(invalidData?.observation)} />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Conditionnement</Form.Label>
                            <Form.Control name="conditionnement" value={form?.conditionnement} isInvalid={getFormErrorMsg(invalidData?.conditionnement)} onChange={handleChange} type="number" />
                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.conditionnement)}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Contributions</Form.Label>
                            <ContributionsInput folderId={props.folderId} value={form?.contributions} setContributionList={handleChange} name="contributions" isInvalid={getFormErrorMsg(invalidData?.contributions)} feedback={getFormErrorMsg(invalidData?.contributions)} />
                        </Form.Group>

                        {granted(grants, "articles.edit.prix") &&
                            <>
                                <Form.Group className="mb-3">
                                    <Form.Label>Prix de revient pondéré</Form.Label>
                                    <Form.Control name="prix_revient_pondere" value={form?.prix_revient_pondere} isInvalid={getFormErrorMsg(invalidData?.prix_revient_pondere)} onChange={handleChange} type="number" />
                                    <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.prix_revient_pondere)}</Form.Control.Feedback>
                                </Form.Group>

                                {granted(grants, "articles.edit.prix.prix_vente") &&

                                    <>

                                        <Form.Check
                                            className="mb-2"
                                            inline
                                            label="Prix de vente HT"
                                            name="prix_vente"
                                            type="radio"
                                            id={`prix_vente_ht`}
                                            onChange={() => setPrixVente("ht")}
                                            checked={prixVente == "ht"}
                                        />
                                        <Form.Check
                                            className="mb-2"
                                            inline
                                            label="Prix de vente TTC"
                                            name="prix_vente"
                                            type="radio"
                                            id={`prix_vente_ttc`}
                                            onChange={() => setPrixVente("ttc")}
                                            checked={prixVente == "ttc"}
                                        />

                                        {prixVente == "ht" &&
                                            <InputGroup className="mb-3">
                                                <Form.Control name="prix_vente_ht" value={form?.prix_vente_ht} isInvalid={getFormErrorMsg(invalidData?.prix_vente_ht)} onChange={handleChangePrixVente} type="number" />
                                                <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.prix_vente_ht)}</Form.Control.Feedback>
                                                <InputGroup.Text id="basic-addon2">HT</InputGroup.Text>
                                            </InputGroup>
                                        }

                                        {prixVente == "ttc" &&
                                            <InputGroup className="mb-3">
                                                <Form.Control name="prix_vente_ttc" value={form?.prix_vente_ttc} isInvalid={getFormErrorMsg(invalidData?.prix_vente_ttc)} onChange={handleChangePrixVente} type="number" />
                                                <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.prix_vente_ttc)}</Form.Control.Feedback>
                                                <InputGroup.Text id="basic-addon2">TTC</InputGroup.Text>
                                            </InputGroup>
                                        }

                                    </>
                                }
                            </>
                        }
                        {granted(grants, "articles.edit.taux_remise_maximum") &&
                            <Form.Group className="mb-3">
                                <Form.Label>Taux de remise maximum</Form.Label>
                                <Form.Control name="taux_remise_maximum" value={form?.taux_remise_maximum} isInvalid={getFormErrorMsg(invalidData?.taux_remise_maximum)} onChange={handleChange} type="number" />
                                <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.taux_remise_maximum)}</Form.Control.Feedback>
                            </Form.Group>
                        }

                        <Form.Group className="mb-3">
                            <Form.Label>Unité de vente</Form.Label>
                            <Form.Control name="unite_vente" value={form?.unite_vente} isInvalid={getFormErrorMsg(invalidData?.unite_vente)} onChange={handleChange} type="text" />
                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.unite_vente)}</Form.Control.Feedback>
                        </Form.Group>

                    </Form>
                </div>
            }
        </>

    )

}

export default ArticleEdition;