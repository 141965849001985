import React, { useEffect, useState } from 'react';
import { ArticleService } from '../../../../services/ArticleService';
import { FamilyService } from '../../../../services/FamilyService';
import { Spinner, Card, Tabs, Tab, Row, Col, Accordion, Button, OverlayTrigger, Alert, Tooltip, Dropdown, Badge } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { isDark, granted } from '../../../../helper/Helper'
import ErrorManager from "../../../error-manager/ErrorManager";
import Fournisseurs from "./fournisseurs/Fournisseurs";
import Tags from "./tags/Tags";
import Tarifs from "./tarifs/Tarifs";
import Factures from "./factures/Factures";
import Proformas from "./proformas/Proformas";
import Historique from "./historique/Historique";
import Signaletique from "./signaletique/Signaletique";
import { GlobalContext } from "../../../../services/GlobalProvider";

const ArticleDetails = (props) => {

    const { grants } = React.useContext(GlobalContext);

    let [openElement, setOpenElement] = useState("signaletique")
    let [article, setArticle] = useState()
    const [getArticleLoading, setGetArticleLoading] = useState()
    const [tags, setTags] = useState([])

    useEffect(() => {
        setArticle()
        getArticle()
        setOpenElement("signaletique")
    }, [props.articleId]);

    function handleTabSelect(key) {
        setOpenElement(key)
    }

    function getArticle() {
        setGetArticleLoading(true)
        ArticleService.getArticle(props.folderId, props.articleId).then(res => {
            setArticle(res.status == 200 ? res.data.data : { warning: res.statusText })
            setGetArticleLoading(false)
            setTags(res?.data?.data?.tags)
        }).catch(error => {
            setGetArticleLoading(false)
            setArticle({ error: error })
        });
    }

    function tabChange() {
        getArticle()
        props.onTabChange()
    }

    return (
        <div id="ArticleDetails">

            {(!article && getArticleLoading) &&
                <span className="hloader mt-3"></span>
            }

            {article?.warning &&
                <div className="d-inline-block w-100 mt-4">
                    <Alert variant="warning">
                        {article?.warning}
                    </Alert>
                </div>
            }

            {article?.error &&
                <div className="d-inline-block w-100 mt-4">
                    <ErrorManager error={article?.error} />
                </div>
            }

            {
                (article && !article.warning && !article.error) && (
                    <>
                        <div className="d-flex mb-4">
                            <div className="me-auto">
                                <h6 className="fw-bolder m-0">{article.designation}<span className="text-secondary ms-2">{article.code}</span></h6>
                                <div className="me-3">
                                    <Badge bg="light" text="dark" className="me-2">Famille : {article?.famille?.designation}</Badge>
                                    <Badge bg="light" text="dark" className="me-2">HT : {article.prix_vente_ht}</Badge>
                                    <Badge bg="light" text="dark" className="me-2">TTC : {article.prix_vente_ttc}</Badge>
                                </div>
                                {tags?.length > 0 &&
                                    <span className="bubble-tags-container border mt-1">
                                        Tags :
                                        {tags.map(tag => (
                                            <span key={tag._id} className="bubble" style={{ backgroundColor: tag.couleur }}>
                                                <span style={{ backgroundColor: tag.couleur }} className={"label " + (isDark(tag.couleur) ? "text-dark" : "text-white")}>{tag.tag}</span>
                                            </span>
                                        ))}
                                    </span>
                                }

                            </div>

                            {props.elementActions.length > 0 &&
                                <Dropdown className="float-end ms-2 me-3">
                                    <OverlayTrigger
                                        placement="left"
                                        delay={{ show: 800 }}
                                        overlay={
                                            <Tooltip>
                                                Plus d'actions
                                            </Tooltip>
                                        }
                                    >
                                        <Dropdown.Toggle variant="secondary" className='no-btn-caret'>
                                            <i className="material-icons i-lg">more_horiz</i>
                                        </Dropdown.Toggle>
                                    </OverlayTrigger>
                                    <Dropdown.Menu>
                                        {props.elementActions.map((element) => (
                                            <Dropdown.Item key={element.title} onClick={() => props.setElementAction(element.action, article)}>{element.title}</Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            }
                        </div>

                        <div className="">
                            <Tabs variant="pills" defaultActiveKey="signaletique" onSelect={handleTabSelect} className="mb-3">

                                <Tab eventKey="signaletique" title="Signalétique" className="light-v-scroll height-full-min-280 pe-2">
                                    {openElement == "signaletique" &&
                                        <Signaletique folderId={props.folderId} article={article} />
                                    }
                                </Tab>
                                {granted(grants, "tags") &&
                                    <Tab eventKey="tags" title="Tags" className="light-v-scroll height-full-min-250 pe-2">
                                        {openElement == "tags" &&
                                            <Tags folderId={props.folderId} article={article} setTags={(tags) => setTags(tags)} isArticleLoading={getArticleLoading} onTagChange={() => tabChange()} />
                                        }
                                    </Tab>
                                }

                                <Tab eventKey="fournisseurs" title="Fournisseurs" className="light-v-scroll height-full-min-280 pe-2">
                                    {openElement == "fournisseurs" &&
                                        <Fournisseurs folderId={props.folderId} article={article} onFournisseurChange={() => tabChange()} />
                                    }
                                </Tab>

                                <Tab eventKey="tarifs" title="Tarifs" className="light-v-scroll height-full-min-280 pe-2">
                                    {openElement == "tarifs" &&
                                        <Tarifs folderId={props.folderId} article={article} onTarifChange={() => tabChange()} />
                                    }
                                </Tab>

                                <Tab eventKey="factures" title="Factures" className="light-v-scroll height-full-min-280 pe-2">
                                    {openElement == "factures" &&
                                        <Factures folderId={props.folderId} article={article} />
                                    }
                                </Tab>

                                <Tab eventKey="proformas" title="Proformas" className="light-v-scroll height-full-min-280 pe-2">
                                    {openElement == "proformas" &&
                                        <Proformas folderId={props.folderId} article={article} />
                                    }
                                </Tab>

                                <Tab eventKey="historique" title="historique" className="light-v-scroll height-full-min-280 pe-2">
                                    {openElement == "historique" &&
                                        <Historique folderId={props.folderId} article={article} />
                                    }
                                </Tab>

                            </Tabs>
                        </div>
                    </>
                )
            }
        </div>

    )

}

export default ArticleDetails;