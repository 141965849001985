module.exports = global.config = {
  "_DEBUG_": false,

  // Url de l'api
  //"_API_URL_": "https://facture-api.xlprog.dev", 
  "_API_URL_": "https://api.stockxl.nc", 

  // Url du service
  //"_FRONT_URL_": "https://facture.xlprog.dev",
  "_FRONT_URL_": "https://stockxl.nc",

  // Url loginxl
  //"_PROFIL_API_URL_": "https://profil-api.xlprog.dev",
  "_PROFIL_API_URL_": "https://profil-api.xlprog.nc",

  // Url du serveur OAuth partie publique
  //"_OAUTH_PUBLIC_URL_": "https://auth.xlprog.dev", *
  "_OAUTH_PUBLIC_URL_": "https://oauth.xlprog.nc", 

  // Infos du client OAuth
  "_OAUTH_CLIENT_ID_": "3d1178bf-d945-42c0-86e9-c31ab1783697", 
  "_OAUTH_CLIENT_SECRET_": "secret"
};