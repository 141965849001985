import React, { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { Table, Badge, FormControl, Container, Button, Dropdown, DropdownButton, Form, Col, Row, Alert, OverlayTrigger, Tooltip, Accordion, Modal } from 'react-bootstrap';
import { getFormErrorMsg, formattedDateInput, currencyFormat, getInvalidObject } from '../../../helper/Helper'
import { BrouillonService } from '../../../services/BrouillonService';
import { ReglementService } from '../../../services/ReglementService';
import ErrorManager from "../../../components/error-manager/ErrorManager";
import { GlobalContext } from "../../../services/GlobalProvider";
import ConfirmDialog from '../../../components/confirm-dialog/ConfirmDialog';
import WysiwygInput from "../../../components/form-inputs/wysiwyg-input/WysiwygInput";


const Description = (props) => {

    const [value, setValue] = useState(props.brouillon.description)
    const [valueHasChanged, setValueHasChanged] = useState(false)

    function handleChange(e) {
        console.log(e.target.value)
        setValue(e.target.value)
        setValueHasChanged(true)
    }

    function cancel() {
        setValue(props.brouillon.description)
        setValueHasChanged(false)
    }

    function save() {
        props.changeValue({
            target: {
                name: "description",
                value: value
            }
        })
        setValueHasChanged(false)
    }

    return (
        <div>
            <div className="d-flex align-items-center mb-3">
                {valueHasChanged &&
                    <span className="">
                        <Button size="sm" variant="secondary" onClick={() => cancel()}>Annuler</Button>
                        <Button size="sm" className="ms-2" onClick={() => save()}>Enregistrer</Button>
                    </span>
                }
            </div>
            <Form.Group>
                <Form.Control className={(props.showChanges && props.modifiedValues?.description) ? "modified-border" : ""} as="textarea" name="description" placeholder="Description à destination du client" value={value} isInvalid={getFormErrorMsg(props.invalidData?.description)} onChange={handleChange} type="text" />
                <Form.Control.Feedback type="invalid">{getFormErrorMsg(props.invalidData?.description)}</Form.Control.Feedback>
            </Form.Group>
        </div>
    );
}

export default Description;
