import { Link } from "react-router-dom";
import React, { Component, useState, useEffect, useRef } from 'react';
import { Navbar, Container, Nav, Badge, Dropdown, Button } from 'react-bootstrap';
import { FolderService } from '../../services/FolderService'
import { UserService } from '../../services/UserService'
import { GlobalContext } from "../../services/GlobalProvider";
import { useNavigate, useParams, useSearchParams, useLocation } from "react-router-dom";
import ErrorManager from "../../components/error-manager/ErrorManager";
import { BrouillonService } from '../../services/BrouillonService';
import ConfirmDialog from '../../components/confirm-dialog/ConfirmDialog';
import Pagination from '../../components/pagination/Pagination';
import { getFormErrorMsg, currencyFormat, granted } from '../../helper/Helper'


const Brouillons = ({ brouillonTypes, openBrouillon }) => {

  const [brouillons, setBrouillons] = useState()
  const [totalBrouillons, setTotalBrouillons] = useState(0)
  const [globalError, setGlobalError] = useState()
  const [confirmDialog, setConfirmDialog] = useState({ show: false, dialogInfos: null })
  const [currentPagination, setCurrentPagination] = useState(1)

  const { globalContextLoaded, userDetails, parametres, folderDetails, grants, getPreferences } = React.useContext(GlobalContext);

  const navigate = useNavigate();

  let brouillonsParams = useRef({
    'start': 0,
    'limit': 5,
    'filters': {
      'filter[type]': [{ operator: "=", value: brouillonTypes }]
    }
  });

  let brouillonsUserParams = useRef({
    'filters': {
      'filter[type]': [{ operator: "=", value: brouillonTypes }]
    }
  });

  useEffect(() => {
    if (globalContextLoaded) {
      brouillonsUserParams.current['filters']['filter[user_id]'] = [{ operator: "=", value: userDetails._id }]
      brouillonsParams.current['filters']['filter[user_id]'] = [{ operator: "!=", value: userDetails._id }]
      getBrouillons()
    }
  }, [globalContextLoaded]);

  function getBrouillons() {
    BrouillonService.getBrouillons(folderDetails?.dossier?._id, brouillonsParams.current).then(res => {
      setBrouillons(res.data.data)
      

      let userBrouillons = granted(grants, "brouillons.consult.brouillons_tiers") ? res.data.data?.brouillons.filter(b => b.user.user_id != userDetails._id).length : 0
      let tiersBrouillons = res.data.data?.brouillons.filter(b => b.user.user_id == userDetails._id).length || 0
      setTotalBrouillons(userBrouillons + tiersBrouillons)

    }).catch(error => {
      setGlobalError(error)
    });
  }

  function removeBrouillon(brouillonId) {
    BrouillonService.removeBrouillon(folderDetails?.dossier?._id, brouillonId).then(res => {
      getBrouillons()
      setConfirmDialog({ show: false });
    }).catch(error => {
      setGlobalError(error)
    });
  }

  function removeBrouillonDialog(brouillon) {
    setConfirmDialog({
      show: true,
      dialogInfos: {
        title: `Supprimer le brouillon`,
        description: `Voulez vous supprimer le brouillon ${brouillon.client_nom} ?`,
        actionName: 'Supprimer',
        btnVariant: 'danger',
        element: brouillon._id,
        error: false,
        confirmAction: 'removeBrouillon'
      }
    })
  }

  function changePagination(p) {
    setCurrentPagination(p)
    brouillonsParams.current['start'] = (brouillonsParams.current.limit * p) - brouillonsParams.current.limit
    getBrouillons()
  }

  return (
    <>

      <ErrorManager error={globalError} fixed="true" />

      <ConfirmDialog
        show={confirmDialog.show}
        dialogInfos={confirmDialog.dialogInfos}
        setCancel={() => setConfirmDialog({ show: false })}
        setConfirm={(a, e) => eval(a)(e)}
      />


      <>
        {brouillons &&
          <Dropdown className="d-inline-block me-2" align="end">
            <Dropdown.Toggle variant="dark" id="dropdown-selectionnes">
              {totalBrouillons} brouillon{totalBrouillons > 1 && <span>s</span>} en cours
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ width: 'max-content' }}>

              {/* Brouillons de l'utilisateur connecté */}
              {(granted(grants, "brouillons.consult") && brouillons && (brouillons?.brouillons.filter(b => b.user.user_id == userDetails._id).length > 0)) &&
                <>
                  <h6 className="ps-3 pt-2">Mes brouillons</h6>
                  {brouillons?.brouillons.filter(b => b.user.user_id == userDetails._id).sort(function(a, b) {return new Date(b.date) - new Date(a.date)}).map((brouillon, i) => (
                    <Dropdown.ItemText key={brouillon._id} className="d-flex">
                      <Button size="sm" variant="secondary" disabled={!granted(grants, "brouillons.edit")} onClick={() => granted(grants, "brouillons.edit") && openBrouillon(brouillon._id)} className="w-100 text-start d-flex align-items-center">
                        <span className="fw-bold me-1">{new Date(brouillon.date).toLocaleString()} : </span>
                        <span className="me-4">{brouillon.client_nom || "-"}</span>
                        <Badge bg="gray-200" text="dark" className="ms-auto">{brouillon.type}</Badge>
                      </Button>
                      {granted(grants, "brouillons.remove") &&
                        <Button size="sm" variant="outline-danger" className="ms-2" onClick={() => removeBrouillonDialog(brouillon)}>Supprimer</Button>
                      }
                    </Dropdown.ItemText>
                  ))}
                </>
              }

              {/* Brouillons des autres utilisateurs */}
              {(granted(grants, "brouillons.consult.brouillons_tiers") && brouillons && (brouillons?.brouillons.filter(b => b.user.user_id != userDetails._id).length > 0)) &&
                <>
                  <h6 className="ps-3 pt-2">Brouillons des autres utilisateurs</h6>
                  {brouillons?.brouillons.filter(b => b.user.user_id != userDetails._id).sort(function(a, b) {return new Date(b.date) - new Date(a.date)}).map((brouillon, i) => (
                    <Dropdown.ItemText key={brouillon._id} className="d-flex align-items-start">
                      <Button size="sm" variant="secondary" disabled={!granted(grants, "brouillons.edit.brouillons_tiers")} onClick={() => granted(grants, "brouillons.edit.brouillons_tiers") && openBrouillon(brouillon._id)} className="w-100 d-flex flex-column">
                        <div className="d-flex w-100 align-items-center">
                          <span className="fw-bold me-1">{new Date(brouillon.date).toLocaleString()} : </span>
                          <span className="me-4">{brouillon.client_nom || "-"}</span>
                          <Badge bg="gray-200" text="dark" className="ms-auto">{brouillon.type}</Badge>
                        </div>
                        <small>Crée par {brouillon.user.user_login || "-"}</small>
                      </Button>
                      {granted(grants, "brouillons.remove.brouillons_tiers") &&
                        <Button size="sm" variant="outline-danger" className="ms-2" onClick={() => removeBrouillonDialog(brouillon)}>Supprimer</Button>
                      }
                    </Dropdown.ItemText>
                  ))}
                </>
              }
              <div className="ps-3">
                <Pagination className="mt-3" size="sm" itemsLength={brouillons?.total} elementsPerPage={brouillonsParams.current.limit} currentPagination={currentPagination} changePagination={p => changePagination(p)} />
              </div>

            </Dropdown.Menu>
          </Dropdown>
        }
      </>

    </>

  );

}

export default Brouillons;