import {axiosAPI} from "./axiosClient"
import { UserService } from './UserService';
import { useParams, useSearchParams } from "react-router-dom";
import qs from "qs";
import { getParams } from '../helper/Helper'


export const ReglementService = {

  getReglements(folderId, params) {
    return axiosAPI.get(`/${folderId}/reglements?lookup=true&calculated_totaux=true&${getParams(params)}`)
  },

  getReglementsSummary(folderId, params) {
    return axiosAPI.get(`/${folderId}/reglements/summary?lookup=true&${getParams(params)}`)
  },

  getReglementsPDF(folderId, params) {
    return axiosAPI.get(`/${folderId}/reglements?lookup=true&${getParams(params)}&format=pdf`, {responseType: 'blob'})
  },

  getReglement(folderId, reglementId){
    return axiosAPI.get(`/${folderId}/reglements/${reglementId}?lookup=true`)
  },

  updateReglement(folderId, dataPost){
    return axiosAPI.put(`/${folderId}/reglements`, qs.stringify(dataPost))
  },

  postReglement(folderId, dataPost){
    return axiosAPI.post(`/${folderId}/reglements`, qs.stringify(dataPost))
  },
  
  removeReglements(folderId, reglementIdList) {
    var params = '?reglements[]=' + reglementIdList.join('&reglements[]=');
    return axiosAPI.delete(`/${folderId}/reglements${params}`)
  },

  getReglementsModes(folderId, params) {
    return axiosAPI.get(`/${folderId}/modespaiements?${getParams(params)}`)
  },

  updateReglementMode(folderId, dataPost){
    return axiosAPI.put(`/${folderId}/modespaiements`, qs.stringify(dataPost))
  },

  postReglementMode(folderId, dataPost){
    return axiosAPI.post(`/${folderId}/modespaiements`, qs.stringify(dataPost))
  },

  removeReglementMode(folderId, modeId) {
    return axiosAPI.delete(`/${folderId}/modespaiements/${modeId}`)
  },

  /* downloadReceiptPDF(folderId, reglementId) {
    return axiosAPI.get(`/${folderId}/reglements/${reglementId}?lookup=true&format=pdf`, {responseType: 'blob'})
  }, */
  
}