import React, { useEffect, useState, useRef } from 'react';
import { ReglementService } from '../../../../../services/ReglementService';
import { FournisseurService } from '../../../../../services/FournisseurService';
import { Spinner, Card, Tabs, Tooltip, Row, Col, Accordion, Button, Badge, Alert, Form, Dropdown, OverlayTrigger, Popover, InputGroup, FormControl } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import ErrorManager from "../../../../error-manager/ErrorManager";
import ConfirmDialog from '../../../../confirm-dialog/ConfirmDialog';
import Pagination from '../../../../../components/pagination/Pagination';
import { getFormErrorMsg, currencyFormat, formattedDateInput } from '../../../../../helper/Helper'


// b9be01edae // b9be01ee4c


const Reglements = (props) => {

    const [reglementsData, setReglementsData] = useState()
    const [reglementsError, setReglementsError] = useState()
    const [isFilterVisible, setIsFilterVisible] = useState(false)
    const [currentPagination, setCurrentPagination] = useState(1)
    const [showForm, setShowForm] = useState(false)
    const [reglementsModes, setReglementsModes] = useState()
    const [dateDebut, setDateDebut] = useState()
    const [dateFin, setDateFin] = useState()
    const [currentMode, setCurrentMode] = useState()
    const [confirmDialog, setConfirmDialog] = useState({ show: false, dialogInfos: null })
    const [isGetReglementsLoading, setIsGetReglementsLoading] = useState(false)

    let reglementsParams = useRef({
        'start': 0,
        'limit': 10,
        'family': null,
        'search': null,
        'filters': {
            'filter[client_id]': [{ operator: "", value: props.facture.client_id }],
            'filter[facture_id]': [{ operator: "", value: props.facture._id }]
        },
        'sort': "date",
        'order': "desc",
        'fields': "_id,date,date_valeur,facture_id,client_id,client_nom,mode_paiement_id,mode_paiement_libelle,organisme,numero_piece,montant,observation"
    });

    let initialForm = {
        "date_valeur": formattedDateInput(new Date()),
        "facture_id": `${props.facture._id}`,
        "client_id": `${props.facture.client_id}`,
        "observation": "",
        "mode_paiement_id": "",
        "organisme": "",
        "numero_piece": "",
        "montant": ""
    }

    const formEdit = useRef();

    useEffect(() => {
        getReglements()
        getReglementsModes()
        formEdit.current = initialForm
    }, []);

    useEffect(() => {
        if (props.showReglementForm) {
            setNewPayment()
        }
    }, [props.showReglementForm]);

    useEffect(() => {
        let dateFilters = []
        dateDebut && dateFilters.push({ operator: ">=", value: dateDebut })
        dateFin && dateFilters.push({ operator: "<=", value: dateFin })
        if (dateFilters.length > 0) {
            reglementsParams.current['filters']['filter[date]'] = dateFilters
        } else {
            delete reglementsParams.current['filters']['filter[date]'];
        }
    }, [dateDebut, dateFin]);

    function handleChange(e) {

        formEdit.current[e.target.name] = e.target.value

        if (e.target.name == "mode_paiement_id") {
            let mode = reglementsModes.find(mode => mode._id === e.target.value)
            setCurrentMode(mode)
            if (!mode.piece_comptable) {
                formEdit.current['organisme'] = ""
                formEdit.current['numero_piece'] = ""
            }
        }


    }

    function setNewPayment() {
        formEdit.current = initialForm
        setShowForm(true)
    }

    function changePagination(p) {
        setCurrentPagination(p)
        reglementsParams.current['start'] = (reglementsParams.current.limit * p) - reglementsParams.current.limit
        getReglements()
    }

    function getReglementsModes() {
        ReglementService.getReglementsModes(props.folderId).then(res => {
            setReglementsModes(res.data.data.modespaiements)
        }).catch(error => {
            setReglementsError(error)
        });
    }

    function getReglements() {
        setIsGetReglementsLoading(true)
        setReglementsError()
        ReglementService.getReglements(props.folderId, reglementsParams.current).then(res => {
            setIsGetReglementsLoading(false)
            setReglementsData(res.data ? res.data.data : null)
        }).catch(error => {
            setIsGetReglementsLoading(false)
            setReglementsError(error)
        });
    }


    function addReglement() {
        ReglementService.postReglement(props.folderId, { reglements: [formEdit.current] }).then(res => {
            setShowForm(false)
            getReglements()
            formEdit.current = initialForm
            props.onTagChange()
        }).catch(error => {
            setShowForm(false)
            setReglementsError(error)
        });
    }

    let searchTimeOut;
    function changeSearchValue(value) {
        window.clearTimeout(searchTimeOut);
        searchTimeOut = window.setTimeout(function () {
            reglementsParams.current['search'] = value
            setCurrentPagination(1)
            reglementsParams.current['start'] = 0
            getReglements()
        }, 1000);
    }

    function Split({ title, value, border }) {
        return (
            <div className={"d-flex justify-content-between py-2 " + (border ? "border-bottom" : "")}>
                <span className="pe-4 text-gray-500">{title}</span>
                <span className="">{value}</span>
            </div>
        )
    }

    function clearDateFilters() {
        setDateDebut()
        setDateFin()
        delete reglementsParams.current['filters']['filter[date]'];
        setIsFilterVisible(false)
        setCurrentPagination(1)
        reglementsParams.current['start'] = 0
        getReglements()
    }

    function validFilters() {
        setCurrentPagination(1)
        reglementsParams.current['start'] = 0
        getReglements()
    }

    return (
        <>

            {props.isFactureLoading &&
                <span className="hloader mt-3"></span>
            }

            {reglementsError && <ErrorManager error={reglementsError} />}

            {!props.isFactureLoading &&
                <>
                    {!reglementsError &&
                        <span className='mb-3 d-block'>
                            <div className="d-flex">
                                <div className="flex-fill pe-3">
                                    <InputGroup>
                                        <i className="material-icons input-icon text-gray-400">search</i>
                                        <FormControl
                                            className="rounded-start input-icon-space"
                                            placeholder="Rechercher"
                                            type="search"
                                            onChange={e => changeSearchValue(e.target.value)}
                                        />
                                    </InputGroup>
                                </div>
                                <div className="">
                                    <OverlayTrigger
                                        placement="left"
                                        delay={{ show: 800 }}
                                        overlay={
                                            <Tooltip>
                                                Date de début et date de fin
                                            </Tooltip>
                                        }
                                    >
                                        <>
                                            <Button disabled={dateDebut || dateFin} className="" variant="secondary" onClick={() => setIsFilterVisible(!isFilterVisible)} >
                                                <i className="material-icons">date_range</i>
                                            </Button>
                                        </>
                                    </OverlayTrigger>
                                </div>
                            </div>

                            {isFilterVisible &&
                                <>
                                    <div className="pt-3 d-flex">
                                        <div className="w-50 pe-3">
                                            <InputGroup>
                                                <Form.Control size="sm" onChange={e => setDateDebut(e.target.value)} name="date_debut" placeholder="Date de début" type="date" />
                                            </InputGroup>
                                        </div>
                                        <div className="w-50 pe-3">
                                            <InputGroup >
                                                <Form.Control size="sm" onChange={e => setDateFin(e.target.value)} name="date_fin" placeholder="Date de fin" type="date" />
                                            </InputGroup>
                                        </div>
                                        <div className="d-flex">
                                            <Button size="sm" className="me-1" variant="secondary" onClick={() => clearDateFilters()}>Annuler</Button>
                                            <Button size="sm" onClick={() => validFilters()}>Appliquer</Button>
                                        </div>
                                    </div>
                                </>
                            }

                        </span>
                    }

                    <span className='mb-3 d-flex'>
                        <Button className="ms-auto" onClick={() => setNewPayment()}>Ajouter un règlement</Button>
                    </span>

                    {showForm && (
                        <div className="p-4 border rounded mt-2 mb-4">

                            <h6 className="mb-3">Nouveau règlement :</h6>

                            <Form.Group className="d-flex align-items-center">
                                <Form.Label column sm={3}>Date d'exécution</Form.Label>
                                <Col sm={9}>
                                    <Form.Control size="sm" name="date_valeur" defaultValue={formattedDateInput(formEdit.current?.date_valeur)} onChange={handleChange} type="date" />
                                </Col>
                            </Form.Group>
                            <Form.Group className="d-flex align-items-center">
                                <Form.Label column sm={3}>Observations</Form.Label>
                                <Col sm={9}>
                                    <Form.Control size="sm" autoComplete="off" name="observation" defaultValue={formEdit.current?.observation} onChange={handleChange} type="text" />
                                </Col>
                            </Form.Group>
                            <Form.Group className="d-flex align-items-center">
                                <Form.Label column sm={3}>Mode de paiement</Form.Label>
                                <Col sm={9}>
                                    <Form.Select autoFocus size="sm" name="mode_paiement_id" defaultValue={formEdit.current.mode_paiement_id} onChange={handleChange}>
                                        <option value="" disabled>- Mode de paiement -</option>
                                        {reglementsModes?.map(mode =>
                                            <option key={mode._id} value={mode._id}>{mode.libelle}</option>
                                        )}
                                    </Form.Select>
                                </Col>
                            </Form.Group>

                            {currentMode?.piece_comptable &&
                                <>
                                    <Form.Group className="d-flex align-items-center">
                                        <Form.Label column sm={3}>Organisme bancaire</Form.Label>
                                        <Col sm={9}>
                                            <Form.Control size="sm" name="organisme" defaultValue={formEdit.current?.organisme} onChange={handleChange} type="text" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="d-flex align-items-center">
                                        <Form.Label column sm={3}>Numéro de pièce</Form.Label>
                                        <Col sm={9}>
                                            <Form.Control size="sm" name="numero_piece" defaultValue={formEdit.current?.numero_piece} onChange={handleChange} type="text" />
                                        </Col>
                                    </Form.Group>
                                </>
                            }

                            <Form.Group className="d-flex align-items-center">
                                <Form.Label column sm={3}>Montant</Form.Label>
                                <Col sm={9}>
                                    <Form.Control size="sm" name="montant" defaultValue={formEdit.current?.montant} onChange={handleChange} type="number" />
                                </Col>
                            </Form.Group>

                            <div className="d-flex justify-content-end">
                                <Button variant="secondary" className="me-2" onClick={() => setShowForm(false)}>Annuler</Button>
                                <Button variant="primary" className="" onClick={() => addReglement()}>Enregistrer</Button>
                            </div>

                        </div>
                    )}

                    {isGetReglementsLoading &&
                        <span className="hloader mt-3"></span>
                    }

                    {(!isGetReglementsLoading && !reglementsData?.reglements && !reglementsError) && <em>- Aucun élément trouvé -</em>}

                    {(!isGetReglementsLoading && !reglementsError && reglementsData?.reglements.length > 0) &&
                        <>

                            <Accordion>

                                {reglementsData?.reglements.map(reglement => (
                                    <Accordion.Item key={reglement._id} eventKey={reglement._id} className="border-0 mb-3">

                                        <Accordion.Header>
                                            <span className="d-flex justify-content-between w-100 pe-4">
                                                <span className="">{reglement.client_nom} ({new Date(reglement.date).toLocaleString()})</span>
                                                <Badge bg="gray-600" text="white" className="">{currencyFormat(reglement.montant)} F</Badge>
                                            </span>
                                        </Accordion.Header>
                                        <Accordion.Body className="px-1">
                                            <Row>
                                                <Col>
                                                    <Split border title="Nom client" value={reglement.client_nom} />
                                                    <Split border title="Date" value={reglement.date} />
                                                    <Split border title="Date valeur (date d'exécution)" value={reglement.date_valeur} />
                                                    <Split border title="Mode de paiement" value={reglement.mode_paiement_libelle} />
                                                    <Split border title="Montant" value={currencyFormat(reglement.montant)} />
                                                    <Split border title="Numéro de pièce" value={reglement.numero_piece} />
                                                    <Split border title="Organisme" value={reglement.organisme} />
                                                    <Split border title="Observation" value={reglement.observation} />
                                                    {/* <Button variant="danger" size="sm" className="mt-2 float-end" onClick={() => removeReglementConfirm(reglement)}>Supprimer ce règlement</Button> */}
                                                </Col>
                                            </Row>


                                        </Accordion.Body>


                                    </Accordion.Item>
                                ))}
                            </Accordion>

                            <Pagination itemsLength={reglementsData?.total} elementsPerPage={reglementsParams.current.limit} currentPagination={currentPagination} changePagination={p => changePagination(p)} />
                        </>
                    }
                </>
            }


        </>
    );

}
export default Reglements;