import { axiosAPI } from "./axiosClient"
import { UserService } from './UserService';
import { useParams, useSearchParams } from "react-router-dom";
import qs from "qs";
import { getParams } from '../helper/Helper'

export const FactureService = {

  /* getClients(folderId, params) {
    return axiosAPI.get(`/${folderId}/clients?lookup=true&${getParams(params)}`)
  }, */

  getFactures(folderId, params) {
    return axiosAPI.get(`/${folderId}/factures?lookup=true&${getParams(params)}`)
  },

  getFacturesPDF(folderId, params) {
    return axiosAPI.get(`/${folderId}/factures?lookup=true&${getParams(params)}&format=pdf`, {responseType: 'blob'})
  },

  getFacture(folderId, factureId) {
    return axiosAPI.get(`/${folderId}/factures/${factureId}?lookup=true`)
  },

  updateFacture(folderId, factureId) {
    return axiosAPI.put(`/${folderId}/factures/${factureId}`)
  },
  
  duplicateFacture(folderId, factureId) {
    return axiosAPI.post(`/${folderId}/factures/${factureId}/dupliquer`)
  },
  
  convertToProforma(folderId, factureId) {
    return axiosAPI.post(`/${folderId}/factures/${factureId}/convert/proforma`)
  },
  
  createAvoir(folderId, factureId) {
    return axiosAPI.post(`/${folderId}/factures/${factureId}/convert/avoir`)
  },

  postFacture(folderId, dataPost) {
    return axiosAPI.post(`/${folderId}/factures`, qs.stringify(dataPost))
  },
  
  removeFactures(folderId, factureIdList) {
    var params = '?factures[]=' + factureIdList.join('&factures[]=');
    return axiosAPI.delete(`/${folderId}/factures${params}`)
  },

}