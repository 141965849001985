import React, { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from "react-router-dom";
import { Container, Button, Dropdown, DropdownButton, Alert, Col, Row, Spinner, Modal, Tooltip, InputGroup, Form } from 'react-bootstrap';
import { EditionService } from '../../services/EditionService';
import { ClientService } from '../../services/ClientService';
import ErrorManager from "../../components/error-manager/ErrorManager";

//import './SendMailDialog.scss';

const SendMailDialog = ({ clientId, folderId, documentType, documentId, show, setCloseModal }) => {

    const [showModal, setShowModal] = useState(false);
    const [globalError, setGlobalError] = useState();
    const [interlocuteurList, setInterlocuteurList] = useState([]);
    const [toSendMailList, setToSendMailList] = useState([]);
    const [toSendCustomMailList, setToSendCustomMailList] = useState([]);
    const [isSendLoading, setIsSendLoading] = useState(false);
    const [mailErrorList, setMailErrorList] = useState([]);
    const [isMailSuccess, setIsMailSuccess] = useState(false);


    let inputRef = useRef();

    useEffect(() => {
        setShowModal(show)
        if (show) {
            resetValues()
            getClient()
        }
    }, [show]);

    function resetValues() {
        setGlobalError()
        setInterlocuteurList([])
        setToSendMailList([])
        setToSendCustomMailList([])
        setIsSendLoading(false)
        setMailErrorList([])
        setIsMailSuccess(false)
    }

    function handleClose(e) {
        setCloseModal()
    }

    function onCheckChange(e) {
        if (e.target.checked) {
            setToSendMailList([...toSendMailList, e.target.value])
        } else {
            setToSendMailList(toSendMailList.filter(m => m !== e.target.value));
        }
    }

    function onCheckCustomChange(e) {
        if (e.target.checked) {
            setToSendCustomMailList([...toSendCustomMailList, e.target.value])
        } else {
            setToSendCustomMailList(toSendCustomMailList.filter(m => m !== e.target.value));
        }
    }

    function addCustomEmail() {
        console.log(inputRef.current.value)
        setToSendCustomMailList([...toSendCustomMailList, inputRef.current.value])
        inputRef.current.value = null
    }

    function addPrincipalMail(interlocuteurs) {
        {
            interlocuteurs?.map(interlocuteur => {
                if (interlocuteur.principal && interlocuteur?.mails?.length > 0) {
                    setToSendMailList(interlocuteur.mails)
                }
            })
        }

    }

    function getClient() {
        ClientService.getClient(folderId, clientId).then(res => {
            if (!res.data?.data) {
                setGlobalError({ statusText: res.statusText })
            } else {
                setInterlocuteurList(res.data.data.interlocuteurs)
                addPrincipalMail(res.data.data.interlocuteurs)
            }
        }).catch(error => {
            setGlobalError(error)
        });
    }

    function sendEmails() {
        setIsMailSuccess(false)
        setIsSendLoading(true)
        setMailErrorList([])
        let allMailList = toSendMailList.concat(toSendCustomMailList);
        EditionService.getSendDocumentMails(folderId, documentType, documentId, { emails: allMailList }).then(res => {
            setIsSendLoading(false)
            
            if (!res.data) {
                setGlobalError({ statusText: res.statusText })
            }else{
                setIsMailSuccess(true)
            }

        }).catch(error => {


            try {
                const fr = new FileReader();
                fr.addEventListener("load", e => {
                    console.log("---fr.result")
                    console.log(fr.result)
                    if(fr.result){
                        console.log("---RESULT !")
                        let response = JSON.parse(fr.result)
                        let errorList =[]
                        response?.data?.map(data => {
                            if (data.substring(0, 9) == "Erreur : ") {
                                errorList.push(data)
                            }
                        })
                        setMailErrorList(errorList)
                    }else{
                        setGlobalError(error)
                    }
                });
                fr.readAsText(error.data);
            } catch (err) {
                setGlobalError(error)
            }




            setIsSendLoading(false)
        });


    }

    return (
        <div id="SendMailDialog">
            <Modal show={showModal} onHide={() => handleClose()} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Envoyer le document par mail</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <ErrorManager error={globalError} />

                    {mailErrorList.length > 0 &&
                        <Alert variant="danger">Le document n'a pas été envoyé car certaines adresses email semblent invalides.</Alert>
                    }

                    {isMailSuccess &&
                        <Alert variant="success">Le document a bien été envoyé.</Alert>
                    }

                    {interlocuteurList?.map(interlocuteur => (
                        <div key={interlocuteur._id}>
                            {interlocuteur?.mails?.length > 0 &&
                                <>
                                    <p className="m-0 small mt-2 mb-1">{interlocuteur?.nom} {interlocuteur?.prenom} {interlocuteur?.principal && <>(principal)</>}</p>
                                    {interlocuteur.mails.map(mail => (
                                        <Form.Check key={mail}
                                            className="text-gray-600"
                                            type="checkbox"
                                            label={mail}
                                            value={mail}
                                            onChange={onCheckChange}
                                            checked={(toSendMailList.length > 0 && toSendMailList?.indexOf(mail) > -1)}
                                            feedback={mailErrorList.filter(m => (m.includes(mail))).map(m => (
                                                <span key={m}>{m}</span>
                                            ))}
                                            feedbackType="invalid"
                                            isInvalid={mailErrorList.find(m => m.includes(mail))}
                                        />
                                    ))}
                                </>
                            }
                        </div>
                    ))}

                    {toSendCustomMailList.length > 0 &&
                        <>
                            <p className="m-0 small mt-2 mb-1">Emails ajoutés :</p>
                            {toSendCustomMailList.map(mail => (
                                <Form.Check key={mail}
                                    className="text-gray-600"
                                    type="checkbox"
                                    label={mail}
                                    value={mail}
                                    onChange={onCheckCustomChange}
                                    defaultChecked={(toSendCustomMailList.length > 0 && toSendCustomMailList?.indexOf(mail) > -1)}
                                    feedback={mailErrorList.filter(m => (m.includes(mail))).map(m => (
                                        <span key={m}>{m}</span>
                                    ))}
                                    feedbackType="invalid"
                                    isInvalid={mailErrorList.find(m => m.includes(mail))}
                                />
                            ))}
                        </>
                    }

                    {(interlocuteurList?.filter(i => i.mails.length > 0).length == 0) &&
                        <em className="small text-gray-600">- Aucune adresse mail n'est rattachée à ce client -</em>
                    }

                    <InputGroup className="mt-3">
                        <Form.Control
                            placeholder="Ajouter une adresse email"
                            ref={inputRef}
                        />
                        <Button /* disabled={} */ variant="secondary" id="button-addon2" onClick={() => addCustomEmail()} >
                            Ajouter
                        </Button>
                    </InputGroup>
                    <Button disabled={isSendLoading} className="float-end mt-4" onClick={() => sendEmails()} >
                        {isSendLoading && <Spinner animation="border" size="sm" className="me-2" />}
                        Envoyer
                    </Button>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default SendMailDialog;
