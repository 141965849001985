import React, { useEffect, useState, useCallback, useRef } from "react";
import { InputGroup, FloatingLabel, Badge, FormControl, Table, Checkbox, Container, Button, Dropdown, DropdownButton, Form, Col, Row, Popover, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useOuterClick, currencyFormat, deepFind } from '../../../helper/Helper'
import ErrorManager from "../../../components/error-manager/ErrorManager";
import TypeRender from './type-render/TypeRender';
import './DynamicTable.scss';

const DynamicTable = (props) => {

    const [activeRowId, setActiveRowId] = useState(null)
    const [selectedRowId, setSelectedRowId] = useState(null)
    const [filters, setFilters] = useState(null)
    const [sort, setSort] = useState()
    const [order, setOrder] = useState()
    const [colSizeRatio, setColSizeRatio] = useState()
    const [globalError, setGlobalError] = useState()

    let tableNavigation = useRef(false)
    const changeRowTimerRef = useRef(null);

    const outerClick = useOuterClick(ev => {
        tableNavigation.current = false
        setSelectedRowId()
    });

    useEffect(() => {
        setSort(props.params.sort)
    }, [props.params.sort]);

    useEffect(() => {
        if (props.currentElement) {
            selectRow(props.currentElement)
        }
    }, [props.currentElement]);

    useEffect(() => {
        setOrder(props.params.order)
    }, [props.params.order]);

    useEffect(() => {
        setFilters(props.params.filters)
    }, [props.params.filters]);

    useEffect(() => {
        if (props.tableHeaders) {
            let totalSizeValue = 0
            props.tableHeaders.filter(h => h.hidden != true).map(h => { totalSizeValue = totalSizeValue + h.colSize })
            setColSizeRatio(100 / totalSizeValue)
        }
    }, [props.tableHeaders]);

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);


    function handleKeyDown(e) {
        if (activeRowId && tableNavigation.current) {
            const { code } = e;
            switch (code) {
                case "ArrowUp":
                    e.preventDefault();
                    prevRow()
                    break;
                case "ArrowDown":
                    e.preventDefault();
                    nextRow()
                    break;
                case "ArrowRight":
                    break;
                case "ArrowLeft":
                    break;
                case "Space":
                    e.preventDefault();
                    handleCheckboxChange(selectedRowId)
                    break;
                case "Escape":
                    //props.changeCurrentElement();
                    break;
                default:
                    break;
            }
        }
    }

    function changeFilters(f) {
        setFilters(f)
        props.changeFilters(f)
    }

    function setError(error) {
        setGlobalError(error)
    }

    function selectRow(tableBodyElement) {
        setSelectedRowId(tableBodyElement._id)
        setActiveRowId(tableBodyElement._id)
        tableNavigation.current = true
        props.changeCurrentElement(tableBodyElement);
    }


    function prevRow() {
        let index = selectedRowId ? props.tableDataList.findIndex(e => e._id === selectedRowId) : props.tableDataList.findIndex(e => e._id === activeRowId);
        if (index != 0) {
            // setActiveRowId(tableBody[index -1]._id)
            setSelectedRowId(props.tableDataList[index - 1]._id)
            changeCurrentElement(props.tableDataList[index - 1])
        }
    }

    function nextRow() {
        let index = selectedRowId ? props.tableDataList.findIndex(e => e._id === selectedRowId) : props.tableDataList.findIndex(e => e._id === activeRowId);
        if (index + 1 < props.tableDataList.length) {
            setSelectedRowId(props.tableDataList[index + 1]._id)
            changeCurrentElement(props.tableDataList[index + 1])
        }
    }

    let changeCurrentElement = (element) => {
        if (changeRowTimerRef.current) {
            clearTimeout(changeRowTimerRef.current);
        }
        changeRowTimerRef.current = setTimeout(() => {
            props.changeCurrentElement(element)
            setActiveRowId(element._id)
        }, 500);
    }

    const handleCheckboxChange = (value) => {
        let checkedElementIdList = [...props.checkedElementIdList];
        let index = checkedElementIdList.indexOf(value)
        if (index >= 0) {
            checkedElementIdList = checkedElementIdList.filter(id => id !== value)
        } else {
            checkedElementIdList.push(value)
        }
        props.setCheckedElementIdList(checkedElementIdList)
    }

    const handleHeaderCheckboxChange = (event) => {
        // si aucun élément n'est coché, ça coche tout, sinon, ça décoche tout
        let checkedElementIdListCopy = [...props.checkedElementIdList];
        const filteredTableBody = props.tableDataList.filter(i => props.checkedElementIdList.includes(i._id))
        if (!filteredTableBody.length) {
            props.tableDataList.map(e => checkedElementIdListCopy.push(e._id))
        } else {
            props.tableDataList.map(e => {
                let index = checkedElementIdListCopy.indexOf(e._id)
                if (index >= 0) {
                    checkedElementIdListCopy.splice(index, 1)
                }
            })
        }
        props.setCheckedElementIdList(checkedElementIdListCopy)
    }

    function setHeaderCheckbox(input) {

        const filteredTableBody = props.tableDataList.filter(i => props.checkedElementIdList?.includes(i._id))

        input.checked = false;
        input.indeterminate = false;

        if (filteredTableBody.length == props.tableDataList.length) {
            input.checked = true;
        } else if (filteredTableBody.length > 0) {
            input.indeterminate = true;
        }

    }

    function setItemFilter(operation, header, value) {

        let filtersCopy = { ...filters }

        //si le filtre n'existe pas, on le crée :
        if (!filtersCopy[`filter[${header.dataTarget}]`]) {
            filtersCopy[`filter[${header.dataTarget}]`] = []
        }

        // index de l'opérateur concerné
        let index = filtersCopy[`filter[${header.dataTarget}]`].findIndex(object => {
            return object.operation == operation;
        });

        // on supprime l'opérateur
        if (index > -1) {
            filtersCopy[`filter[${header.dataTarget}]`].splice(index, 1)
        }

        let obj

        switch (operation) {
            case 'contain_str':
                obj = (value ? { operation: operation, operator: "", value: `${value}` } : null)
                break;
            case 'no_contain_str':
                obj = (value ? { operation: operation, operator: "!", value: `${value}` } : null)
                break;
            case 'start_by_str':
                obj = (value ? { operation: operation, operator: "^", value: `${value}` } : null)
                break;
            case 'equal_to':
                obj = (value ? { operation: operation, operator: "=", value: `${value}` } : null)
                break;
            case 'different_to':
                obj = (value ? { operation: operation, operator: "!=", value: `${value}` } : null)
                break;
            case 'greater_than':
                obj = (value ? { operation: operation, operator: ">", value: `${value}` } : null)
                break;
            case 'lower_than':
                obj = (value ? { operation: operation, operator: "<", value: `${value}` } : null)
                break;

            default:
                break;
        }

        // ajouter l'objet
        obj && filtersCopy[`filter[${header.dataTarget}]`]?.push(obj)

        // supprimer le filtre si vide
        if (filtersCopy[`filter[${header.dataTarget}]`].length == 0) {
            delete filtersCopy[`filter[${header.dataTarget}]`]
        }

        return Object.keys(filtersCopy).length === 0 ? null : filtersCopy

    }

    function getInputValue(operation, header) {
        let element = filters && filters[`filter[${header.dataTarget}]`]?.filter(f => f.operation == operation)
        return (element && element[0]?.value) ? element[0]?.value : ''
    }

    function displayType(header, typeList) {
        return typeList.indexOf(header.type) > -1 ? true : false
    }

    function changeSortOrder(header) {
        let o = order == 'asc' ? 'desc' : 'asc'
        if (header.dataTarget != sort) {
            o = 'asc'
        }
        props.changeSortOrder(header.dataTarget, o)
    }

    return (
        <div id="DynamicTable">

            <ErrorManager error={globalError} fixed="true" />

            {props.tableHeaders &&
                <Table ref={outerClick} hover className="position-relative">

                    <thead className="">
                        <tr>
                            <th className="w-checkbox pe-4">
                                {props.tableDataList &&

                                    <input
                                        type="checkbox"
                                        ref={input => {
                                            if (input) {
                                                setHeaderCheckbox(input)
                                            }
                                        }}

                                        className="form-check-input"
                                        onChange={handleHeaderCheckboxChange} />
                                }
                            </th>
                            {props.tableHeaders.map((header, i) => {
                                return header.hidden ? null :
                                    <th key={header.dataTarget} style={{ width: colSizeRatio * header.colSize + '%' }} className="text-uppercase text-gray-600 small">

                                        {header.sortable && //header.dataTarget != sortOrder?.sort
                                            <span className={"cursor-pointer d-flex align-items-center " + (header.align == 'right' ? 'justify-content-end ' : '')} onClick={() => changeSortOrder(header)}>
                                                {header.label}
                                                {(order == "desc" && header.dataTarget == sort) &&
                                                    <i className="material-icons ms-2" >north</i>
                                                }
                                                {(order == "asc" && header.dataTarget == sort) &&
                                                    <i className="material-icons ms-2" >south</i>
                                                }
                                            </span>
                                        }
                                        {!header.sortable &&
                                            <span className="d-flex align-items-center">
                                                {header.label}
                                            </span>
                                        }

                                        {props?.isTableFilterVisible &&

                                            // Filtres de recherche
                                            <InputGroup size="sm" className={"no-btn-caret mt-2 " + (!header.filters ? "bg-gray-400 rounded" : "")}>

                                                {(
                                                    displayType(header, ['string']) ||
                                                    displayType(header, ['observation']) ||
                                                    displayType(header, ['html']) ||
                                                    displayType(header, ['reglements']) ||
                                                    displayType(header, ['adresses']) ||
                                                    displayType(header, ['interlocuteurs']) ||
                                                    displayType(header, ['contributions']) ||
                                                    displayType(header, ['fournisseurs']) ||
                                                    displayType(header, ['tags']) ||
                                                    displayType(header, ['genericList']) ||
                                                    displayType(header, ['codesbarres'])
                                                ) &&
                                                    <Form.Control
                                                        value={getInputValue('contain_str', header)}
                                                        onChange={e => changeFilters(setItemFilter('contain_str', header, e.target.value))}
                                                        size="sm"
                                                        type="search"
                                                        disabled={!header.filters}
                                                        placeholder={header.filters ? 'Contient' : 'Indisponible'}
                                                    />
                                                }
                                                {(
                                                    displayType(header, ['boolean']) ||
                                                    displayType(header, ['booleanTrueOnly']) ||
                                                    displayType(header, ['booleanFalseOnly']) ||
                                                    displayType(header, ['bloquer'])
                                                ) &&
                                                    <Form.Control
                                                        value="Filtrer Oui / Non"
                                                        readOnly
                                                        className="bg-white text-gray-600"
                                                        size="sm"
                                                        type="text"
                                                        disabled={!header.filters}
                                                        placeholder={header.filters ? 'Filtrer' : 'Indisponible'}
                                                    />
                                                }
                                                {(
                                                    displayType(header, ['date']) ||
                                                    displayType(header, ['dateList'])
                                                ) &&
                                                    <Form.Control
                                                        value={getInputValue('equal_to', header)}
                                                        onChange={e => changeFilters(setItemFilter('equal_to', header, e.target.value))}
                                                        size="sm"
                                                        type="date"
                                                        disabled={!header.filters}
                                                        placeholder={header.filters ? 'Égal à' : 'Indisponible'}
                                                    />
                                                }
                                                {(
                                                    displayType(header, ['number']) ||
                                                    displayType(header, ['gps']) ||
                                                    displayType(header, ['taux_remise']) ||
                                                    displayType(header, ['currency']) ||
                                                    displayType(header, ['currencyHighlightNegative'])
                                                ) &&
                                                    <Form.Control
                                                        value={getInputValue('equal_to', header)}
                                                        onChange={e => changeFilters(setItemFilter('equal_to', header, e.target.value))}
                                                        size="sm"
                                                        type="number"
                                                        disabled={!header.filters}
                                                        placeholder={header.filters ? 'Égal à' : 'Indisponible'}
                                                    />
                                                }

                                                <DropdownButton
                                                    variant={filters && filters[`filter[${header.dataTarget}]`] ? 'warning' : 'secondary'}
                                                    title={<i className="material-icons">filter_alt</i>}
                                                    align="end"
                                                    disabled={!header.filters}
                                                >
                                                    <span className="px-3 py-2 d-block table-header-dropdown">
                                                        {(
                                                            displayType(header, ['boolean']) ||
                                                            displayType(header, ['booleanTrueOnly']) ||
                                                            displayType(header, ['booleanFalseOnly']) ||
                                                            displayType(header, ['bloquer'])
                                                        ) &&
                                                            <>
                                                                <Form.Group controlId="">
                                                                    <Form.Check
                                                                        inline
                                                                        id={"boolean-1" + header.dataTarget}
                                                                        type="radio"
                                                                        onChange={e => changeFilters(setItemFilter('equal_to', header, 'true'))}
                                                                        label="Oui"
                                                                        name={`${header.dataTarget}-1`}
                                                                        checked={((filters && filters[`filter[${header.dataTarget}]`]) && (filters[`filter[${header.dataTarget}]`]['0'].value == 'true')) ? true : false}
                                                                    />
                                                                </Form.Group>
                                                                <Form.Group controlId="">
                                                                    <Form.Check
                                                                        inline
                                                                        id={"boolean-2" + header.dataTarget}
                                                                        type="radio"
                                                                        onChange={e => changeFilters(setItemFilter('equal_to', header, 'false'))}
                                                                        label="Non"
                                                                        name={`${header.dataTarget}-1`}
                                                                        checked={((filters && filters[`filter[${header.dataTarget}]`]) && (filters[`filter[${header.dataTarget}]`]['0'].value == 'false')) ? true : false}
                                                                    />
                                                                </Form.Group>
                                                                <Button variant="link" className="p-0" onClick={e => changeFilters(setItemFilter('equal_to', header, null))}>Effacer</Button>
                                                            </>
                                                        }
                                                        {(
                                                            displayType(header, ['string']) ||
                                                            displayType(header, ['codesbarres']) ||
                                                            displayType(header, ['tags']) ||
                                                            displayType(header, ['genericList'])
                                                        ) &&
                                                            <Form.Group controlId="">
                                                                <Form.Label className="m-0 small">Égal à</Form.Label>
                                                                <Form.Control value={getInputValue('equal_to', header)} onChange={e => changeFilters(setItemFilter('equal_to', header, e.target.value))} size="sm" type="search" />
                                                            </Form.Group>
                                                        }
                                                        {(
                                                            displayType(header, ['number']) ||
                                                            displayType(header, ['taux_remise']) ||
                                                            displayType(header, ['currency']) ||
                                                            displayType(header, ['currencyHighlightNegative'])
                                                        ) &&
                                                            <Form.Group controlId="">
                                                                <Form.Label className="m-0 small">Différent de</Form.Label>
                                                                <Form.Control value={getInputValue('different_to', header)} onChange={e => changeFilters(setItemFilter('different_to', header, e.target.value))} size="sm" type="number" />
                                                            </Form.Group>
                                                        }
                                                        {(
                                                            displayType(header, ['string']) ||
                                                            displayType(header, ['observation']) ||
                                                            displayType(header, ['codesbarres']) ||
                                                            displayType(header, ['tags']) ||
                                                            displayType(header, ['genericList'])
                                                        ) &&
                                                            <Form.Group controlId="">
                                                                <Form.Label className="m-0 small">Ne contient pas</Form.Label>
                                                                <Form.Control value={getInputValue('no_contain_str', header)} onChange={e => changeFilters(setItemFilter('no_contain_str', header, e.target.value))} size="sm" type="search" />
                                                            </Form.Group>
                                                        }
                                                        {(
                                                            displayType(header, ['string']) ||
                                                            displayType(header, ['codesbarres']) ||
                                                            displayType(header, ['tags']) ||
                                                            displayType(header, ['genericList'])
                                                        ) &&
                                                            <Form.Group controlId="">
                                                                <Form.Label className="m-0 small">Commence par</Form.Label>
                                                                <Form.Control value={getInputValue('start_by_str', header)} onChange={e => changeFilters(setItemFilter('start_by_str', header, e.target.value))} size="sm" type="search" />
                                                            </Form.Group>
                                                        }
                                                        {(
                                                            displayType(header, ['number']) ||
                                                            displayType(header, ['taux_remise']) ||
                                                            displayType(header, ['currency']) ||
                                                            displayType(header, ['currencyHighlightNegative'])
                                                        ) &&
                                                            <Form.Group controlId="">
                                                                <Form.Label className="m-0 small">Plus grand que</Form.Label>
                                                                <Form.Control value={getInputValue('greater_than', header)} onChange={e => changeFilters(setItemFilter('greater_than', header, e.target.value))} size="sm" type="number" />
                                                            </Form.Group>
                                                        }
                                                        {(
                                                            displayType(header, ['number']) ||
                                                            displayType(header, ['taux_remise']) ||
                                                            displayType(header, ['currency']) ||
                                                            displayType(header, ['currencyHighlightNegative'])
                                                        ) &&
                                                            <Form.Group controlId="">
                                                                <Form.Label className="m-0 small">Plus petit que</Form.Label>
                                                                <Form.Control value={getInputValue('lower_than', header)} onChange={e => changeFilters(setItemFilter('lower_than', header, e.target.value))} size="sm" type="number" />
                                                            </Form.Group>
                                                        }
                                                        {(
                                                            displayType(header, ['date']) ||
                                                            displayType(header, ['dateList'])
                                                        ) &&
                                                            <Form.Group controlId="">
                                                                <Form.Label className="m-0 small">Date de début</Form.Label>
                                                                <Form.Control value={getInputValue('greater_than', header)} onChange={e => changeFilters(setItemFilter('greater_than', header, e.target.value))} size="sm" type="date" />
                                                            </Form.Group>
                                                        }
                                                        {(
                                                            displayType(header, ['date']) ||
                                                            displayType(header, ['dateList'])
                                                        ) &&
                                                            <Form.Group controlId="">
                                                                <Form.Label className="m-0 small">Date de fin</Form.Label>
                                                                <Form.Control value={getInputValue('lower_than', header)} onChange={e => changeFilters(setItemFilter('lower_than', header, e.target.value))} size="sm" type="date" />
                                                            </Form.Group>
                                                        }
                                                    </span>

                                                </DropdownButton>
                                            </InputGroup>
                                        }

                                    </th>

                            })}
                        </tr>
                    </thead>

                    {(props.tableDataList && !props.isDataLoading) &&
                        <tbody>
                            {props.tableDataList.map((tableBodyElement, i) => (
                                <tr key={i} className={"cursor-pointer position-relative align-middle " + (tableBodyElement._id == activeRowId ? 'table-active zindex-30 ' : '') + (tableBodyElement._id == selectedRowId ? ' border-primary-100 selected zindex-1' : '')}>

                                    <td className="w-checkbox pe-2">
                                        <input type="checkbox" checked={props.checkedElementIdList?.includes(tableBodyElement._id)} value={tableBodyElement._id} className="form-check-input" onChange={e => handleCheckboxChange(e.target.value)} />
                                    </td>

                                    {props.tableHeaders.map((header, j) => {
                                        if (!header.hidden) {
                                            return <td className={header.align == 'right' ? 'text-end ' : ''} key={j} onClick={() => selectRow(tableBodyElement)}>
                                                <TypeRender header={header} tableBodyElement={tableBodyElement} setError={error => setError(error)} />
                                            </td>
                                        }
                                    })}

                                    {props.elementActions.length > 0 &&
                                        <td style={{ right: props.sideWidth != 0 && props.sideWidth, transition: 'right .4s' }} className={"actions p-0 zindex-20 " + ((props.sideWidth == 0 || !props.sideWidth) ? "" : "me-n5")}>
                                            <span>
                                                {props.elementActions.map((element, i) => (
                                                    <OverlayTrigger
                                                        key={element.title}
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip>
                                                                {element.title}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <i onClick={() => props.setElementAction(element.action, tableBodyElement)} className="material-icons hover-bg-gray-200 rounded text-dark">{element.icon}</i>
                                                    </OverlayTrigger>
                                                ))}
                                            </span>
                                        </td>
                                    }

                                </tr>

                            ))}

                        </tbody>
                    }

                    {props.isDataLoading &&
                        <tbody><tr><td><span className="hloader position-absolute start-0 end-0 mt-2"></span></td></tr></tbody>
                    }

                </Table>
            }

            {(!props.tableDataList && !props.isDataLoading) &&
                <em className="d-block not-found">- Aucun élément trouvé -</em>
            }
        </div>
    );
}

export default DynamicTable;