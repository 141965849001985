import React, { useState, useMemo, useEffect } from "react";
import { EditorState, convertToRaw, ContentState, convertFromHTML, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./WysiwygInput.scss";
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';

export default function WysiwygInput(props) {

    const [editorState, setEditorState] = useState(EditorState.createWithContent(convertFromRaw(markdownToDraft(props.value))));
    const [isFocus, setIsFocus] = useState(false);

    
    useEffect(() => {
        if(props.value != draftToMarkdown(convertToRaw(editorState.getCurrentContent()))){
            setEditorState(EditorState.createWithContent(convertFromRaw(markdownToDraft(props.value))))
        }
    }, [props.value]);

    const handleChange = (data) => {

        setEditorState(data);

        if(draftToMarkdown(convertToRaw(data.getCurrentContent())) != draftToMarkdown(convertToRaw(editorState.getCurrentContent()))){
            let value = {
                target:{
                    name: props.name,
                    value: draftToMarkdown(convertToRaw(data.getCurrentContent())),
                    id: props.id
                }
            }
            props.changeValue(value)
        }
        
    };

    // doc : https://jpuri.github.io/react-draft-wysiwyg/#/docs?_k=jjqinp

    const toolbarOptions = {
        options: ['inline', 'blockType', 'list', 'textAlign'],
        inline: {
            className: '',
            inDropdown: false,
            options: ['bold', 'italic', 'underline'],
        },
        blockType: {
            inDropdown: true,
            options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
        },
        list: { inDropdown: true },
        textAlign: { inDropdown: true },
    };

    function focus(){
        if(props.setFocus){
            props.setFocus()
        }
        setIsFocus(true)
    }

    return (
        <div id="WysiwygInput" className={"border border-gray-400 rounded p-2 position-relative bg-white " + (isFocus ? "focus " : "")}>
            {props.disabled && <span className="disabled"></span>}
            <Editor
                placeholder={props.placeHolder}
                editorState={editorState}
                onEditorStateChange={handleChange}
                wrapperClassName=""
                editorClassName="p-0"
                toolbarClassName="border-bottom border-top-0 border-start-0 border-end-0 pt-0"
                toolbar={toolbarOptions}
                onFocus={()=>focus()}
                onBlur={() => setIsFocus(false)}
            />
        </div>
    );
}