import {axiosAPI} from "./axiosClient"
import { UserService } from './UserService';
import { useParams, useSearchParams } from "react-router-dom";
import qs from "qs";
import { getParams } from '../helper/Helper'

export const FournisseurService = {

  getFournisseurs(folderId, params) {
    return axiosAPI.get(`/${folderId}/fournisseurs?${getParams(params)}`)
  },

  getFournisseursPDF(folderId, params) {
    return axiosAPI.get(`/${folderId}/fournisseurs?lookup=true&${getParams(params)}&format=pdf`, {responseType: 'blob'})
  },

  getFournisseur(folderId, fournisseurId){
    return axiosAPI.get(`/${folderId}/fournisseurs/${fournisseurId}?lookup=true`)
  },

  updateFournisseur(folderId, dataPost){
    return axiosAPI.put(`/${folderId}/fournisseurs`, qs.stringify(dataPost))
  },

  postFournisseur(folderId, dataPost){
    return axiosAPI.post(`/${folderId}/fournisseurs`, qs.stringify(dataPost))
  },
  
  removeFournisseurs(folderId, fournisseurIdList) {
    var params = '?fournisseurs[]=' + fournisseurIdList.join('&fournisseurs[]=');
    return axiosAPI.delete(`/${folderId}/fournisseurs${params}`)
  },
  
  updateAdresse(folderId, fournisseurId, dataPost){
    return axiosAPI.put(`/${folderId}/fournisseurs/${fournisseurId}/adresses`, qs.stringify(dataPost))
  },
  
  postAdresse(folderId, fournisseurId, dataPost){
    return axiosAPI.post(`/${folderId}/fournisseurs/${fournisseurId}/adresses`, qs.stringify(dataPost))
  },
  
  removeAdresse(folderId, fournisseurId, adresseId) {
    return axiosAPI.delete(`/${folderId}/fournisseurs/${fournisseurId}/adresses/${adresseId}`)
  },

  updateInterlocuteur(folderId, fournisseurId, dataPost){
    return axiosAPI.put(`/${folderId}/fournisseurs/${fournisseurId}/interlocuteurs`, qs.stringify(dataPost))
  },
  
  postInterlocuteur(folderId, fournisseurId, dataPost){
    return axiosAPI.post(`/${folderId}/fournisseurs/${fournisseurId}/interlocuteurs`, qs.stringify(dataPost))
  },
  
  removeInterlocuteur(folderId, fournisseurId, interlocuteurId) {
    return axiosAPI.delete(`/${folderId}/fournisseurs/${fournisseurId}/interlocuteurs/${interlocuteurId}`)
  },

  getNextCode(folderId) {
    return axiosAPI.get(`/${folderId}/fournisseurs/nextcode`)
  },

  getArticles(folderId, fournisseurId){
    return axiosAPI.get(`/${folderId}/fournisseurs/${fournisseurId}/articles`)
  },
  
}