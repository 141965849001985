import React, { useEffect, useState } from 'react';
import { Button, Badge, Dropdown, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useParams, useSearchParams } from "react-router-dom";
import './SideDetailsBrouillon.scss';

const SideDetailsBrouillon = (props) => {

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    function handleKeyDown(e) {
        if (!props.editMode) {
            const { code } = e;
            switch (code) {
                case "Escape":
                    props.setSideClose()
                    break;
                default:
                    break;
            }
        }
    }

    function cancelSideDetails() {
        props.setSideCancel();
    }

    return (
        <div id="SideDetailsBrouillon" className="border-start bg-white p-5 shadow-lg hideOutside">

            {props.editMode ?
                <Button className="float-end" variant="secondary" onClick={() => cancelSideDetails()}>Annuler</Button>
                :
                <Button className="float-end" variant="secondary" onClick={() => props.setSideClose()}>Fermer</Button>
            }

            {props.detailsComponent}

        </div>
    );
}

export default SideDetailsBrouillon;