import { Link } from "react-router-dom";
import React, { Component, useState, useEffect } from 'react';
import { Navbar, Container, Nav, NavDropdown, Dropdown, Button, ButtonGroup, SplitButton } from 'react-bootstrap';

import './TopNav.scss';
import logo from '../../assets/img/logo/logo-facturexl-no-bg.png'
import logoSmall from '../../assets/img/logo/logo-facturexl-small.png'
import { FolderService } from '../../services/FolderService'
import { UserService } from '../../services/UserService'
import { GlobalContext } from "../../services/GlobalProvider";
import { useNavigate, useParams, useSearchParams, useLocation } from "react-router-dom";
import { granted, getDomainFromUrl, setCookie, getCookie } from '../../helper/Helper'
import ShortCuts from './shortcuts/ShortCuts';
import ChangePasswordDialog from "./change-password-dialog/ChangePasswordDialog";


const TopNav = () => {

  const { userDetails, folderDetails, grants, folders, setFolderDetails, preferences, globalContextLoaded } = React.useContext(GlobalContext);

  const [currentPage, setCurrentPage] = useState()
  const [changePasswordDialog, setChangePasswordDialog] = useState({})

  let location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    let segment = location.pathname.split("/")[2];
    setCurrentPage(segment)
  }, [location.pathname]);

  function logout() {
    let idToken = getCookie('id_token')
    
    // remove cookies 
    sessionStorage.removeItem('access_token');
    setCookie("id_token", '', -10, getDomainFromUrl(global.config._FRONT_URL_))
    setCookie("refresh_token", '', -10, getDomainFromUrl(global.config._FRONT_URL_))

    let redirectUri = global.config._FRONT_URL_
    window.location.href = global.config._OAUTH_PUBLIC_URL_ + "/oauth2/sessions/logout?id_token_hint=" + idToken + "&post_logout_redirect_uri=" + redirectUri
  }

  function changeFolder(folder) {
    setFolderDetails(folder)
    navigate(`/${folder?.dossier?._id}/accueil`)
  }

  return (
    <>

      <ChangePasswordDialog show={changePasswordDialog.show} setCloseModal={() => setChangePasswordDialog({ show: false })} />

      <Navbar id="topNav" bg="primary-600" variant="dark" expand="md" fixed="top" className="py-3">
        <Container fluid>
          <Navbar.Brand className="d-xl-none" href={`/${folderDetails?.dossier?._id}/accueil`}><img className="me-2" src={logoSmall} alt="Logo XL Prog" /></Navbar.Brand>
          <Navbar.Brand className="d-none d-xl-block" href={`/${folderDetails?.dossier?._id}/accueil`}><img className="me-2" src={logo} alt="Logo XL Prog" /></Navbar.Brand>
          <Navbar.Toggle aria-controls="id-top-nav" /> {/* Utile au responsive */}

          <Navbar.Collapse id="id-top-nav">

            {folderDetails &&
              <>

                <Button disabled={!granted(grants, "clients")} className="me-2" variant={["clients"].indexOf(currentPage) > -1 ? "light" : "primary-700"} as={Link} to={`${folderDetails?.dossier?._id}/clients`}>Clients</Button>

                <Dropdown as={ButtonGroup} className="me-2">
                  <Button className={!granted(grants, "articles") ? "disabled" : ""} variant={["articles", "fournisseurs", "tarifs"].indexOf(currentPage) > -1 ? "light" : "primary-700"} as={Link} to={`${folderDetails?.dossier?._id}/articles`}>Articles</Button>
                  <Dropdown.Toggle split variant={["articles", "fournisseurs", "tarifs"].indexOf(currentPage) > -1 ? "light" : "primary-700"} />
                  <Dropdown.Menu>
                    <Dropdown.Item disabled={!granted(grants, "articles")} as={Link} to={`${folderDetails?.dossier?._id}/articles`}>Articles</Dropdown.Item>
                    <Dropdown.Item disabled={!granted(grants, "fournisseurs")} as={Link} to={`${folderDetails?.dossier?._id}/fournisseurs`}>Fournisseurs</Dropdown.Item>
                    <Dropdown.Item disabled={!granted(grants, "tarifs")} as={Link} to={`${folderDetails?.dossier?._id}/tarifs`}>Tarifs</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                <Dropdown as={ButtonGroup} className="me-2">
                  <Button className={"d-inline-flex " + (!granted(grants, "articles") ? "disabled" : "")} variant={["factures", "reglements", "proformas", "abonnements"].indexOf(currentPage) > -1 ? "light" : "primary-700"} as={Link} to={`${folderDetails?.dossier?._id}/factures`}>Facturation{preferences?.factures?.currentDraft && <i className="ps-3 ms-auto material-icons text-warning">fiber_manual_record</i>}</Button>
                  <Dropdown.Toggle split variant={["factures", "reglements", "proformas", "abonnements"].indexOf(currentPage) > -1 ? "light" : "primary-700"} />
                  <Dropdown.Menu>
                    <Dropdown.Item className="d-inline-flex" disabled={!granted(grants, "factures")} as={Link} to={`${folderDetails?.dossier?._id}/factures`}>Factures{preferences?.factures?.currentDraft && <i className="ps-3 ms-auto material-icons text-warning">fiber_manual_record</i>}</Dropdown.Item>
                    <Dropdown.Item className="d-inline-flex" disabled={!granted(grants, "reglements")} as={Link} to={`${folderDetails?.dossier?._id}/reglements`}>Reglements</Dropdown.Item>
                    <Dropdown.Item className="d-inline-flex" disabled={!granted(grants, "proformas")} as={Link} to={`${folderDetails?.dossier?._id}/proformas`}>Proformas{preferences?.proformas?.currentDraft && <i className="ps-3 ms-auto material-icons text-warning">fiber_manual_record</i>}</Dropdown.Item>
                    <Dropdown.Item className="d-inline-flex" disabled={!granted(grants, "abonnements")} as={Link} to={`${folderDetails?.dossier?._id}/abonnements`}>Abonnements{preferences?.abonnements?.currentDraft && <i className="ps-3 ms-auto material-icons text-warning">fiber_manual_record</i>}</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                <Button disabled={!granted(grants, "editions")} className="me-2" variant={["editions"].indexOf(currentPage) > -1 ? "light" : "primary-700"} as={Link} to={`${folderDetails?.dossier?._id}/editions`}>Editions</Button>
              </>
            }

            <Nav className="ms-auto d-flex align-items-center">

              {folderDetails &&
                <Button disabled={!granted(grants, "admin")} className="me-2" variant={["administration"].indexOf(currentPage) > -1 ? "light" : "primary-700"} as={Link} to={`${folderDetails?.dossier?._id}/administration`}><i className="material-icons me-2 float-start">settings</i>Paramètres</Button>
              }

              {folders?.length > 1 &&
                <Dropdown align="end" className="me-2">
                  <Dropdown.Toggle variant="primary-700">
                    <i className="material-icons me-2 float-start">folder</i>
                    {folderDetails?.dossier?.nom ? folderDetails?.dossier?.nom : "Dossiers"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {folders.map((folder, i) => (
                      <Dropdown.Item key={i} onClick={() => changeFolder(folder)}>{folder?.dossier?.nom}</Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              }

              {userDetails &&
                <>
                  <Dropdown align="end" className="me-2">
                    <Dropdown.Toggle variant="primary-700">
                      <i className="material-icons me-2 float-start">person</i>
                      {userDetails?.identifiant}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.ItemText>{userDetails?.email}</Dropdown.ItemText>
                      <Dropdown.Divider />
                      <Dropdown.Item onClick={() => setChangePasswordDialog({ "show": true })}>Changer le mot de passe</Dropdown.Item>
                      <Dropdown.Item onClick={() => logout()}>Déconnexion</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>


                  <ShortCuts />
                </>
              }

              {!userDetails &&
                <Nav.Link onClick={() => UserService.redirectToExternalLogin()}>Se connecter</Nav.Link>
              }

            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

    </>
  );

}

export default TopNav;