import { axiosAPI } from "./axiosClient"
import { UserService } from './UserService';
import { useParams, useSearchParams } from "react-router-dom";
import qs from "qs";
import { getParams } from '../helper/Helper'
import axios from 'axios';


export const PDFService = {

  postPDFPreview(folderId, dataPost, docType) {
    let params = {
      'params': dataPost,
      'format': 'pdf'
    }
    return axiosAPI.post(`/${folderId}/editions/pdfpreview/${docType}`, qs.stringify(params), { responseType: 'blob' })

  },

}