import React, { useEffect, useRef, useState } from 'react';
import { UsersProfilService } from '../../../services/UsersProfilService';
import { GlobalContext } from "../../../services/GlobalProvider";
import { Table, Badge, InputGroup, Alert, FormControl, Container, Button, Dropdown, DropdownButton, Form, Col, Row, Spinner, ListGroup, Tooltip, Nav, Tab } from 'react-bootstrap';
import { getFormErrorMsg, getInvalidObject, getChangedValues, updateObjectValue, deepFind } from '../../../helper/Helper'
import ErrorManager from "../../../components/error-manager/ErrorManager";
import { ContributionService } from '../../../services/ContributionService';



export default function Contributions({ setIsConfirmationNeeded }) {

    const { preferences, folderDetails } = React.useContext(GlobalContext);

    let [contributions, setContributions] = useState()
    const [globalError, setGlobalError] = useState()
    const [formEdit, setFormEdit] = useState()
    const [formNew, setFormNew] = useState()
    const [isSaveLoading, setIsSaveLoading] = useState(false)

    useEffect(() => {
        getContributions()
    }, []);

    useEffect(() => {
        if(formNew || formEdit){
            setIsConfirmationNeeded(true)
        }else{
            setIsConfirmationNeeded(false)
        }
    }, [formNew, formEdit]);


    function getContributions() {
        ContributionService.getContributions(folderDetails?.dossier?._id).then(res => {
            setContributions(res.data.data.contributions)
            setFormEdit()
            setIsSaveLoading(false)
        }).catch(error => {
            setGlobalError(error)
            setIsSaveLoading(false)
        });
    }

    function saveContribution() {
        setIsSaveLoading(true)
        ContributionService.postContribution(folderDetails?.dossier?._id, { contributions: [formNew] }).then(res => {
            getContributions()
            setFormNew()
        }).catch(error => {
            setGlobalError(error)
            setIsSaveLoading(false)
        });
    }

    function updateContribution() {
        setIsSaveLoading(true)
        ContributionService.updateContribution(folderDetails?.dossier?._id, { contributions: [formEdit] }).then(res => {
            getContributions()
        }).catch(error => {
            setGlobalError(error)
            setIsSaveLoading(false)
        });
    }

    function removeContribution(contrib) {
        ContributionService.removeContribution(folderDetails?.dossier?._id, contrib._id).then(res => {
            getContributions()
        }).catch(error => {
            setGlobalError(error)
        });
    }

    function handleChangeEdit(e) {
        let value
        e.target.type == "checkbox" ? value = e.target.checked : value = e.target.value
        setFormEdit({ ...formEdit, [e.target.name]: value })
    }

    function handleChangeNew(e) {
        let value
        e.target.type == "checkbox" ? value = e.target.checked : value = e.target.value
        setFormNew({ ...formNew, [e.target.name]: value })
    }

    function editMode(m) {
        setFormEdit({
            "_id": m._id,
            "libelle": m.libelle,
            "code": m.code,
            "valeur": m.valeur,
            "suffixe_comptable": m.suffixe_comptable
        })
    }

    function createNewContribution() {
        setFormNew({
            "_id": "",
            "libelle": "",
            "code": "",
            "valeur": "",
            "type": "",
            "suffixe_comptable": ""
        })
    }

    function cancelNewContribution() {
        setFormEdit()
        setFormNew()
    }

    return (

        <Row>


            <Col xl={8} xxl={6} className="bg-white border-start border-end position-relative">
                {globalError && (
                    <Col xs="12" className="mt-2">
                        <ErrorManager error={globalError} />
                    </Col>
                )}
                <div className="height-full-min-80 px-2 py-4 py-xxl-5 px-xxl-5 pb-0 ">
                    <div className="light-v-scroll">
                        <div className="d-flex align-items-center mb-5 mt-1">
                            <h6 className="m-0">Contributions</h6>
                            <Button className="ms-auto" onClick={() => createNewContribution()}>Créer</Button>
                        </div>
                        <div className="d-flex flex-column align-items-center mb-5 mt-1">

                            {formNew &&
                                <div className="border d-flex flex-column rounded mb-5 p-3 w-100">
                                    <div className="d-flex flex-row align-items-center">
                                        <h6>Nouvelle contribution</h6>
                                        <Button variant="secondary" className="ms-auto align-self-start" onClick={() => cancelNewContribution()}>Annuler</Button>
                                        <Button disabled={isSaveLoading || !formNew.libelle || !formNew.code || !formNew?.type} className="ms-2 align-self-start" onClick={() => saveContribution()}>{isSaveLoading && <Spinner animation="border" size="sm" className="me-2" />}Enregistrer</Button>
                                    </div>

                                    <div>
                                        <Form.Group className="mb-2">
                                            <Form.Label>Libelle<span className="text-danger">*</span> :</Form.Label>
                                            <Form.Control name="libelle" placeholder="Libelle" value={formNew.libelle} onChange={handleChangeNew} type="text" />
                                        </Form.Group>
                                        <Form.Group className="mb-2">
                                            <Form.Label>Code<span className="text-danger">*</span> :</Form.Label>
                                            <Form.Control name="code" placeholder="Code" value={formNew.code} onChange={handleChangeNew} type="text" />
                                        </Form.Group>
                                        <Form.Group className="mb-2">
                                            <Form.Label>Valeur :</Form.Label>
                                            <Form.Control name="valeur" placeholder="Valeur" value={formNew.valeur} onChange={handleChangeNew} type="valeur" />
                                        </Form.Group>

                                        <Form.Group className="mb-2">
                                            <Form.Label>Type<span className="text-danger">*</span> :</Form.Label>
                                            <Form.Select name="type" value={formNew?.type} onChange={handleChangeNew}>
                                                <option value="" disabled>- Choisir -</option>
                                                <option value="taxe_vente">TGC</option>
                                                <option value="contrib_eco">ECOPARTICIPATION</option>
                                            </Form.Select>
                                        </Form.Group>

                                        <Form.Group className="mb-2">
                                            <Form.Label>Suffixe comptable :</Form.Label>
                                            <Form.Control name="suffixe_comptable" placeholder="Suffixe comptable" value={formNew.suffixe_comptable} onChange={handleChangeNew} type="number" />
                                        </Form.Group>


                                    </div>

                                </div>
                            }

                            {contributions?.map((contribution, i) =>
                                <div key={i} className="border d-flex flex-column rounded mb-4 p-3 w-100">

                                    {formEdit?._id != contribution._id &&
                                        <>
                                            <div className="d-flex flex-row align-items-center">
                                                <h6>{contribution.libelle}</h6>
                                                <Dropdown className="ms-auto">
                                                    <Dropdown.Toggle disabled={formEdit?._id || formNew} variant="secondary" className='no-btn-caret'>
                                                        <i className="material-icons i-lg">more_horiz</i>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={() => editMode(contribution)}>Modifier</Dropdown.Item>
                                                        <Dropdown.Item onClick={() => removeContribution(contribution)} className="text-danger">Supprimer</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>

                                            <div>
                                                <p className="mb-0">
                                                    <span className="me-4"><Badge bg="secondary">{contribution.type}</Badge></span>
                                                    <span className="me-4">Code : {contribution.code}</span>
                                                    <span className="me-4">Valeur : {contribution.valeur}</span>
                                                    <span className="me-4">Suffixe comptable : {contribution.suffixe_comptable}</span>
                                                </p>

                                            </div>
                                        </>
                                    }

                                    {(formEdit?._id == contribution._id) &&
                                        <>
                                            <div className="d-flex flex-row align-items-center">
                                            <h6>{contribution.libelle}</h6>
                                                <Button variant="secondary" className="ms-auto align-self-start" onClick={() => setFormEdit()}>Annuler</Button>
                                                <Button disabled={isSaveLoading || !formEdit.libelle || !formEdit.code} className="ms-2 align-self-start" onClick={() => updateContribution()}>{isSaveLoading && <Spinner animation="border" size="sm" className="me-2" />}Enregistrer</Button>
                                            </div>

                                            <div>
                                                <Form.Group className="mb-2">
                                                    <Form.Label>Libelle<span className="text-danger">*</span> :</Form.Label>
                                                    <Form.Control name="libelle" placeholder="Libelle" value={formEdit.libelle} onChange={handleChangeEdit} type="text" />
                                                </Form.Group>
                                                <Form.Group className="mb-2">
                                                    <Form.Label>Code<span className="text-danger">*</span> :</Form.Label>
                                                    <Form.Control name="code" placeholder="Code" value={formEdit.code} onChange={handleChangeEdit} type="text" />
                                                </Form.Group>
                                                <Form.Group className="mb-2">
                                                    <Form.Label>Valeur :</Form.Label>
                                                    <Form.Control name="valeur" placeholder="Valeur" value={formEdit.valeur} onChange={handleChangeEdit} type="valeur" />
                                                </Form.Group>
                                                <Form.Group className="mb-2">
                                                    <Form.Label>Suffixe comptable :</Form.Label>
                                                    <Form.Control name="suffixe_comptable" placeholder="Suffixe comptable" value={formEdit.suffixe_comptable} onChange={handleChangeEdit} type="number" />
                                                </Form.Group>

                                            </div>

                                        </>
                                    }
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Col>

        </Row>

    );
}

