import { axiosAPI } from "./axiosClient"
import { UserService } from './UserService';
import { useParams, useSearchParams } from "react-router-dom";
import qs from "qs";
import { getParams } from '../helper/Helper'


export const PreferenceService = {


  getPreferencesListe(folderId, params) {
    return axiosAPI.get(`/${folderId}/preferences/liste?${getParams(params)}`)
  },


  // Dossier

  getPreferencesDossier(folderId, excludeReadOnly, exclusive, params) {
    return axiosAPI.get(`/${folderId}/preferences/dossier?excludeReadOnly=${excludeReadOnly}&exclusive=${exclusive}&${getParams(params)}`)
  },

  updatePreferencesDossier(folderId, dataPost) {
    return axiosAPI.put(`/${folderId}/preferences/dossier`, qs.stringify(dataPost))
  },

  removePreferencesDossier(folderId, pathList) {
    var prefs = '?prefs[]=' + pathList.join('&prefs[]=');
    return axiosAPI.delete(`/${folderId}/preferences/dossier${prefs}`)
  },


  // UsersProfil

  getPreferencesUsersProfil(folderId, excludeReadOnly, exclusive, params) {
    return axiosAPI.get(`/${folderId}/preferences/usersprofil?excludeReadOnly=${excludeReadOnly}&exclusive=${exclusive}&${getParams(params)}`)
  },

  updatePreferencesUsersProfil(folderId, dataPost) {
    return axiosAPI.put(`/${folderId}/preferences/usersprofil`, qs.stringify(dataPost))
  },

  removePreferencesUsersProfil(folderId, pathList) {
    var prefs = '?prefs[]=' + pathList.join('&prefs[]=');
    return axiosAPI.delete(`/${folderId}/preferences/usersprofil${prefs}`)
  },


  // UserProfil specifique

  getPreferencesUserProfil(profilId, folderId, excludeReadOnly, exclusive, params) {
    return axiosAPI.get(`/${folderId}/preferences/usersprofil/${profilId}?excludeReadOnly=${excludeReadOnly}&exclusive=${exclusive}&${getParams(params)}`)
  },

  updatePreferencesUserProfil(profilId, folderId, dataPost) {
    return axiosAPI.put(`/${folderId}/preferences/usersprofil/${profilId}`, qs.stringify(dataPost))
  },

  removePreferencesUserProfil(profilId, folderId, pathList) {
    var prefs = '?prefs[]=' + pathList.join('&prefs[]=');
    return axiosAPI.delete(`/${folderId}/preferences/usersprofil/${profilId}${prefs}`)
  },

  // User

  getPreferences(folderId, excludeReadOnly, exclusive, params) {
    return axiosAPI.get(`/${folderId}/preferences?excludeReadOnly=${excludeReadOnly}&exclusive=${exclusive}&${getParams(params)}`)
  },

  updatePreferences(folderId, dataPost) {
    return axiosAPI.put(`/${folderId}/preferences`, qs.stringify(dataPost))
  },

  removePreferences(folderId, pathList) {
    var prefs = '?prefs[]=' + pathList.join('&prefs[]=');
    return axiosAPI.delete(`/${folderId}/preferences${prefs}`)
  },

}