import React, { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { InputGroup, FormControl, Container, Button, Dropdown, DropdownButton, Badge, Col, Row, Form, OverlayTrigger, Tooltip, Toast, ToastContainer, Spinner } from 'react-bootstrap';

import { ArticleService } from '../../services/ArticleService';
import { AbonnementService } from '../../services/AbonnementService';
import { EditionService } from '../../services/EditionService';

import DynamicTable from '../../components/table/dynamic-table/DynamicTable';
import Pagination from '../../components/pagination/Pagination';
import SideDetails from '../../components/side-details/SideDetails';
import AbonnementDetails from '../../components/side-details/abonnement/abonnement-details/AbonnementDetails';
//import AbonnementEdition from '../../components/side-details/abonnement/abonnement-edition/AbonnementEdition';
import ColManager from '../../components/table/dynamic-table/col-manager/ColManager';
import Families from '../../components/families/Families';
import './Abonnements.scss';
import { PreferenceService } from '../../services/PreferenceService';
import ConfirmDialog from '../../components/confirm-dialog/ConfirmDialog';
import ErrorManager from "../../components/error-manager/ErrorManager";
import SendMailDialog from "../../components/send-mail-dialog/SendMailDialog";
import GenerateDialog from "./generate-dialog/GenerateDialog";
import { GlobalContext } from "../../services/GlobalProvider";
import { formatTableHeadersFromPrefs, granted, getFile } from '../../helper/Helper'
import Brouillons from "../../components/brouillons/Brouillons";
import YearMonthInput from "../../components/form-inputs/year-month-input/YearMonthInput";


const Abonnements = () => {

  const { globalContextLoaded, preferences, parametres, folderDetails, grants, getPreferences } = React.useContext(GlobalContext);

  let abonnementsParams = useRef({
    'start': 0,
    'limit': 50,
    'search': null,
    'filters': null,
    'sort': null,
    'order': null,
    'generated': null,
    'suspended': null,
    'scheduled': null,
    'mois_abonnement': null,
    'date_execution': null
  });

  const navigate = useNavigate();

  const [globalError, setGlobalError] = useState()
  const [searchParams, setSearchParams] = useSearchParams();
  const [abonnements, setAbonnements] = useState()
  const [isGetAbonnementsLoading, setIsGetAbonnementsLoading] = useState()
  const [isPDFLoading, setIsPDFLoading] = useState()
  const [sideWidth, setSideWidth] = useState()
  const [tableHeaders, setTableHeaders] = useState()
  const [checkedElementIdList, setCheckedElementIdList] = useState([])
  const [currentPagination, setCurrentPagination] = useState(1)
  const [isTableFilterVisible, setIsTableFilterVisible] = useState(false)
  const [confirmDialog, setConfirmDialog] = useState({ show: false, dialogInfos: null })
  const [currentAbonnement, setCurrentAbonnement] = useState()
  const [sendMailDialog, setSendMailDialog] = useState({})
  const [generateDialog, setGenerateDialog] = useState({})
  const [dateExecution, setDateExecution] = useState()
  const [isDoNotSimuateChecked, setIsDoNotSimuateChecked] = useState(false)
  const [isForceRegenerateChecked, setIsForceRegenerateChecked] = useState(false)


  const [tableRowElementActions] = useState([
    { icon: "edit", title: "Modifier l'abonnement", action: "editAbonnement" },
    { icon: "article", title: "Convertir en proforma", action: "convertToProforma" },
    { icon: "file_copy", title: "Dupliquer", action: "duplicateAbonnement" },
    { icon: "print", title: "Imprimer l'abonnement", action: "downloadPDF" },
    { icon: "email", title: "Envoyer par mail", action: "sendEmail" }
  ])

  const [abonnementElementActions] = useState([
    { icon: "edit", title: "Modifier l'abonnement", action: "editAbonnement" },
    { icon: "article", title: "Convertir en proforma", action: "convertToProforma" },
    { icon: "file_copy", title: "Dupliquer", action: "duplicateAbonnement" },
    { icon: "print", title: "Imprimer l'abonnement", action: "downloadPDF" },
    { icon: "email", title: "Envoyer par mail", action: "sendEmail" }
  ])

  /* 
GET /abonnements
GET /abonnements/nombre
GET /abonnements/{_id}
GET /abonnements/{_id}/suspensions

POST /abonnements
POST /abonnements/generate
POST /abonnements/{_id}/generate
POST /abonnements/dupliquer
POST /abonnements/{_id}/dupliquer
POST /abonnements/convert/proforma
POST /abonnements/{_id}/convert/proforma
POST /abonnements/{_id}/suspensions

PUT /abonnements/{_id}
PUT /abonnements/{_id}/suspensions

DEL /abonnements
DEL /abonnements/{_id}
DEL /abonnements/{_id}/suspensions[/{suspension_id}]
 */

  useEffect(() => {
    if (grants) {
      !grants.abonnements.valeur && setGlobalError({ statusText: `Abonnements : vous n'avez pas les droit d'accès à cette ressource.` })
      granted(grants, "abonnements.remove") && tableRowElementActions.push({ icon: "delete_forever", title: "Supprimer", action: "removeAbonnementDialog" })
      granted(grants, "abonnements.remove") && abonnementElementActions.push({ icon: "delete_forever", title: "Supprimer", action: "removeAbonnementDialog" })
    }
  }, [grants]);

  useEffect(() => {
    if (globalContextLoaded) {
      searchParams.get('abonnementId') && getAbonnement(searchParams.get('abonnementId'))
      setPreferences()
      getAbonnements()

      // Si un brouillon est en cours d'édition on redirige.
      if (!searchParams.get('factureId') && preferences.abonnements.currentDraft) {
        navigate(`/${folderDetails._id}/abonnements/brouillon?brouillonId=${preferences.abonnements.currentDraft}&from=abonnements`)
      }

    }
  }, [globalContextLoaded]);

  function setPreferences() {
    setTableHeaders(formatTableHeadersFromPrefs(preferences.abonnements['tableHeaders'], parametres.abonnements['fieldsLabel']))
    abonnementsParams.current['sort'] = preferences.abonnements['tableSort']
    abonnementsParams.current['order'] = preferences.abonnements['tableOrder']
    abonnementsParams.current['order'] = preferences.abonnements['tableOrder']
    abonnementsParams.current['fields'] = Object.keys(preferences.abonnements['tableHeaders']).join(',')
  }

  function getAbonnement(abonnementId) {
    AbonnementService.getAbonnement(folderDetails._id, abonnementId).then(res => {
      if (!res.data?.data) {
        setGlobalError({ statusText: res.statusText })
      } else {
        changeCurrentAbonnement(res.data.data)
      }
    }).catch(error => {
      setGlobalError(error)
    });
  }

  function getAbonnements() {
    setCheckedElementIdList([])
    if (granted(grants, "abonnements.consult")) {
      setIsGetAbonnementsLoading(true)
      AbonnementService.getAbonnements(folderDetails._id, abonnementsParams.current).then(res => {
        //res.data.data && addCustomEtatColumn(res.data.data)
        setAbonnements(res.data.data)
        setIsGetAbonnementsLoading(false)
      }).catch(error => {
        setIsGetAbonnementsLoading(false)
        setGlobalError(error)
      });
    }
  }

  /* function addCustomEtatColumn(data) {
    data.abonnements.map(abonnement => {

      let value = ""
      if (abonnement.validite_jours_calc < 0) {
        value = "Expirée"
      }
      if (abonnement.historiques.suivants.abonnement) {
        value = "Annulée"
      }
      if (abonnement.historiques.suivants.facture) {
        value = "Facturée"
      }
      abonnement['front_custom_etat'] = value
    })
  } */

  function getAbonnementsPDF() {
    if (granted(grants, "abonnements.consult")) {
      setIsPDFLoading(true)
      let params = {
        'start': 0,
        'search': abonnementsParams.current.search,
        'sort': abonnementsParams.current.sort,
        'order': abonnementsParams.current.order,
        'filters': abonnementsParams.current.filters
      }

      EditionService.getListe(folderDetails._id, params, "abonnements", "pdf").then(res => {
        setIsPDFLoading(false)
        if (!res?.data) {
          setGlobalError({ statusText: res.statusText })
        } else {
          getFile(res.data, `Abonnements - Liste`, "pdf")
        }
      }).catch(error => {
        setIsPDFLoading(false)
        setGlobalError(error)
      });
    }
  }

  function removeAbonnementList(abonnementIdList) {
    AbonnementService.removeAbonnements(folderDetails._id, abonnementIdList).then(res => {
      setConfirmDialog({ show: false });
      getAbonnements()
      changeCurrentAbonnement()
      setSideWidth()
      uncheckElementList(abonnementIdList)
    }).catch(err => {
      setConfirmDialog({
        ...confirmDialog,
        dialogInfos: { ...confirmDialog.dialogInfos, error: err }
      })
    });
  }

  function uncheckElementList(idList) {
    let checkedElementIdListCopy = [...checkedElementIdList];
    idList.map(id => {
      let index = checkedElementIdListCopy.indexOf(id)
      if (index >= 0) {
        checkedElementIdListCopy.splice(index, 1)
      }
    })
    setCheckedElementIdList(checkedElementIdListCopy)
  }

  function saveTableHeadersPrefs(e) {
    const newHeadersObject = {}
    e.forEach(object => {
      newHeadersObject[object['dataTarget']] = { position: object['position'], hidden: object['hidden'] }
    });
    updatePreference({ ["tableHeaders"]: newHeadersObject })
  }

  function updatePreference(prefs) {
    console.log('---prefs')
    console.log(prefs)
    PreferenceService.updatePreferences(folderDetails._id, { prefs: { ['abonnements']: prefs } }).then(res => {
      getPreferences()
    }).catch(error => {
      setGlobalError(error)
    });
  }

  function changeCurrentAbonnement(abonnement) {
    abonnement?._id ? setSearchParams({ abonnementId: abonnement._id }) : setSearchParams(searchParams.delete('abonnementId'))
    setCurrentAbonnement(abonnement)
  }

  function changeTableHeaders(e) {
    setTableHeaders(e)
    saveTableHeadersPrefs(e)
  }

  function changePagination(p) {
    setCurrentPagination(p)
    abonnementsParams.current['start'] = (abonnementsParams.current.limit * p) - abonnementsParams.current.limit
    getAbonnements()
  }

  function changeLimit(l) {
    abonnementsParams.current['limit'] = l
    changePagination(1)
  }

  let searchTimeOut;
  function changeSearchValue(value) {
    window.clearTimeout(searchTimeOut);
    searchTimeOut = window.setTimeout(function () {
      setCurrentPagination(1)
      abonnementsParams.current['start'] = 0
      abonnementsParams.current['search'] = value
      getAbonnements()
    }, 1000);
  }

  let filtersTimeOut;
  function changeFilters(f) {
    window.clearTimeout(filtersTimeOut);
    filtersTimeOut = window.setTimeout(function () {
      setCurrentPagination(1)
      abonnementsParams.current['start'] = 0
      abonnementsParams.current['filters'] = f
      getAbonnements()
    }, 1000);
  }

  function clearFilters() {
    setCurrentPagination(1)
    abonnementsParams.current['start'] = 0
    abonnementsParams.current['filters'] = null
    getAbonnements()
    setIsTableFilterVisible(false)
  }

  function changeSortOrder(s, o) {
    abonnementsParams.current['sort'] = s
    abonnementsParams.current['order'] = o
    updatePreference({ ["tableSort"]: s, ["tableOrder"]: o })
    getAbonnements()
  }

  function onAbonnementSaved(abonnementId) {
    getAbonnement(abonnementId)
    getAbonnements()
  }

  function onTabChange() {
    getAbonnements()
  }

  function closeSide() {
    setCurrentAbonnement()
  }

  function openBrouillon(brouillonId, documentType) {
    navigate(`/${folderDetails._id}/${documentType}/brouillon?brouillonId=${brouillonId}&from=abonnements`)
  }

  function createNewAbonnement() {
    let newAbonnement = {
      "type": "abonnement"
    }
    AbonnementService.postAbonnement(folderDetails._id, { abonnements: [newAbonnement] }).then(res => {
      for (var brouillonId in res.data.data.brouillons) {
        openBrouillon(brouillonId, "abonnements")
      }
    }).catch(error => {
      setGlobalError(error)
    });

  }

  function removeAbonnementListDialog(abonnementIdList) {
    setConfirmDialog({
      show: true,
      dialogInfos: {
        title: `Supprimer ${abonnementIdList.length} abonnement(s) ?`,
        description: 'Voulez vous supprimer les éléments sélectionnés ?',
        actionName: 'Supprimer la sélection',
        btnVariant: 'danger',
        element: abonnementIdList,
        error: false,
        confirmAction: 'removeAbonnementList'
      }
    })
  }

  function removeAbonnementDialog(abonnement) {
    setConfirmDialog({
      show: true,
      dialogInfos: {
        title: abonnement.nom,
        description: 'Voulez vous supprimer cet élément ?',
        actionName: 'Supprimer',
        btnVariant: 'danger',
        element: [abonnement._id],
        error: false,
        confirmAction: 'removeAbonnementList'
      }
    })
  }

  function downloadPDF(abonnement) {
    EditionService.getAbonnement(folderDetails._id, abonnement._id, "pdf").then(res => {
      if (!res.data) {
        setGlobalError({ statusText: res.statusText })
      } else {
        getFile(res.data, `Abonnement ${abonnement.code}`, "pdf")
      }
    }).catch(error => {
      setGlobalError(error)
    });
  }

  function sendEmail(abonnement) {
    setSendMailDialog({
      "clientId": abonnement.client_id,
      "folderId": folderDetails._id,
      "documentType": "abonnements",
      "documentId": abonnement._id,
      "show": true
    })
  }

  function generate(doNotSimulate) {

    let params = {
      mois_abonnement: abonnementsParams.current.mois_abonnement,
      abonnements: checkedElementIdList.length > 0 ? checkedElementIdList : [],
      date_execution: dateExecution,
      do_not_simulate: doNotSimulate,
      force_regenerate: isForceRegenerateChecked
    }

    setGenerateDialog({
      "folderId": folderDetails._id,
      "show": true,
      "params": params
    })

  }

  function downloadCheckedAbonnements() {
    EditionService.getAbonnements(folderDetails._id, { abonnements: checkedElementIdList }).then(res => {
      if (!res.data) {
        setGlobalError({ statusText: res.statusText })
      } else {
        if (checkedElementIdList.length > 1) {
          getFile(res.data, `Abonnements`, "zip")
        } else {
          getFile(res.data, `Abonnement`, "pdf")
        }
      }
    }).catch(error => {
      setGlobalError(error)
    });
  }

  function editAbonnement(abonnement) {
    AbonnementService.updateAbonnement(folderDetails._id, abonnement._id).then(res => {
      if (!res.data?.data) {
        setGlobalError({ statusText: res.statusText })
      } else {
        let brouillonId = Object.keys(res.data.data.brouillons)[0]
        openBrouillon(brouillonId, "abonnements")
      }
    }).catch(error => {
      setGlobalError(error)
    });
  }

  function convertToProforma(abonnement) {
    AbonnementService.convertToProforma(folderDetails._id, abonnement._id).then(res => {
      if (!res.data?.data) {
        setGlobalError({ statusText: res.statusText })
      } else {
        let brouillonId = Object.keys(res.data.data.brouillons)[0]
        openBrouillon(brouillonId, "proformas")
      }
    }).catch(error => {
      setGlobalError(error)
    });
  }

  function duplicateAbonnement(abonnement) {
    AbonnementService.duplicateAbonnement(folderDetails._id, abonnement._id).then(res => {
      if (!res.data?.data) {
        setGlobalError({ statusText: res.statusText })
      } else {
        let brouillonId = Object.keys(res.data.data.brouillons)[0]
        openBrouillon(brouillonId, "abonnements")
      }
    }).catch(error => {
      setGlobalError(error)
    });
  }

  let changeMoisAbonnementTimeOut;
  function changeMoisAbonnementParam(value) {
    window.clearTimeout(changeMoisAbonnementTimeOut);
    changeMoisAbonnementTimeOut = window.setTimeout(function () {
      abonnementsParams.current['mois_abonnement'] = value
      setCurrentPagination(1)
      abonnementsParams.current['start'] = 0
      getAbonnements()
    }, 1000);
  }

  function changeParamsCheckboxGroup(e) {

    if (e.target.value == 'true') { abonnementsParams.current[e.target.name] = true }
    if (e.target.value == 'false') { abonnementsParams.current[e.target.name] = false }
    if (e.target.value == '') { abonnementsParams.current[e.target.name] = null }

    setCurrentPagination(1)
    abonnementsParams.current['start'] = 0
    getAbonnements()

  }

  const ParamsCheckboxGroup = (props) => {

    return (
      <div className="mb-3 d-flex">
        <Form.Check
          disabled={isGetAbonnementsLoading}
          checked={abonnementsParams.current[props.paramName] == true}
          className="text-nowrap"
          inline
          label="Oui"
          name={props.paramName}
          type="radio"
          id={`${props.paramName}-radio-1`}
          value="true"
          onChange={e => changeParamsCheckboxGroup(e)}
        />
        <Form.Check
          disabled={isGetAbonnementsLoading}
          checked={abonnementsParams.current[props.paramName] == false}
          className="text-nowrap"
          inline
          label="Non"
          name={props.paramName}
          type="radio"
          id={`${props.paramName}-radio-2`}
          value="false"
          onChange={e => changeParamsCheckboxGroup(e)}
        />
        <Form.Check
          disabled={isGetAbonnementsLoading}
          checked={abonnementsParams.current[props.paramName] == null}
          className="text-nowrap"
          inline
          label="Non défini"
          name={props.paramName}
          type="radio"
          id={`${props.paramName}-radio-3`}
          value=""
          onChange={e => changeParamsCheckboxGroup(e)}
        />
      </div>
    )
  }

  return (
    <Container fluid id="Abonnements" className="py-4">

      <div className="pb-2 d-flex align-items-center justify-content-between">
        <h4 className="">Abonnements</h4>
        <div>
          {/* Brouillons en cours */}
          <Brouillons brouillonTypes={["abonnement"]} openBrouillon={b => openBrouillon(b, "abonnements")} grantedConsult={granted(grants, "brouillons.consult")} grantedEdit={granted(grants, "brouillons.edit")} grantedRemove={granted(grants, "brouillons.remove")} />

          {granted(grants, "abonnements.edit") &&
            <Button variant="primary" onClick={() => createNewAbonnement()}>Créer un abonnement</Button>
          }
        </div>
      </div>

      <ErrorManager error={globalError} fixed="true" />

      <SendMailDialog clientId={sendMailDialog.clientId} folderId={sendMailDialog.folderId} documentType={sendMailDialog.documentType} documentId={sendMailDialog.documentId} show={sendMailDialog.show} setCloseModal={() => setSendMailDialog({ show: false })} />

      <GenerateDialog folderId={generateDialog.folderId} show={generateDialog.show} params={generateDialog.params} setCloseModal={() => setGenerateDialog({ show: false })} />

      <ConfirmDialog
        show={confirmDialog.show}
        dialogInfos={confirmDialog.dialogInfos}
        setCancel={() => setConfirmDialog({ show: false })}
        setConfirm={(a, e) => eval(a)(e)}
      />

      <Row>

        <Col xs="12">
          <Row>
            {granted(grants, "abonnements.consult") &&
              <>
                <Col xs="12" xl="3" className="mb-sm-2 mb-xl-0">

                  <InputGroup>
                    <i className="material-icons input-icon text-gray-400">search</i>
                    <FormControl
                      className="input-icon-space rounded-start"
                      placeholder="Recherche globale"
                      type="search"
                      onChange={e => changeSearchValue(e.target.value)}
                    />

                    {!abonnementsParams.current.filters &&
                      <OverlayTrigger placement="right" delay={{ show: 800 }} overlay={<Tooltip>Filtres de recherche</Tooltip>}>
                        <Button variant={abonnementsParams.current.filters ? 'warning' : 'secondary'} onClick={() => setIsTableFilterVisible(!isTableFilterVisible)}>
                          <i className="material-icons i-lg">filter_alt</i>
                        </Button>
                      </OverlayTrigger>
                    }

                    {abonnementsParams.current.filters &&
                      <OverlayTrigger placement="right" delay={{ show: 800 }} overlay={<Tooltip>Supprimer les filtres</Tooltip>}>
                        <Button variant={abonnementsParams.current.filters ? 'warning' : 'secondary'} onClick={() => clearFilters()}>
                          <i className="material-icons i-lg">clear</i>
                        </Button>
                      </OverlayTrigger>
                    }

                  </InputGroup>
                </Col>
                <Col xs="12" xl="auto" className="d-flex align-items-top mb-sm-2 mb-xl-0">
                  <ColManager tableHeaders={tableHeaders} changeTableHeaders={e => changeTableHeaders(e)} />
                  {/* <Button disabled={isPDFLoading} variant="secondary" className="ms-2" onClick={() => getAbonnementsPDF()}>
                    {!isPDFLoading && <i className="material-icons i-lg float-start me-2">print</i>}
                    {isPDFLoading && <Spinner animation="border" size="sm" className="me-2" />}
                    Imprimer la liste ({abonnements?.total})
                  </Button> */}

                  {/* <Form.Group className="ms-2">
                    <Form.Control name="mois_abonnement" onChange={e => changeAnalyseFilter(groupId, analyseId, "date", ">=", e)} placeholder="2024-10" type="month" />
                  </Form.Group> */}




                  <YearMonthInput className="ms-3" isInvalid={false} disabled={isGetAbonnementsLoading} placeHolder="Mois d'abonnement" onChange={v => changeMoisAbonnementParam(v)} />


                  {/* <Dropdown className="ms-4">
                    <Dropdown.Toggle variant="secondary">
                      Mois d'abonnement
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="mois-abo-dropdown mt-1 p-2">
                      <Form.Group>
                        <Form.Label>Choisir un mois d'abonnement</Form.Label>
                        <Form.Control className="" name="mois_abonnement" onChange={e => changeMoisAbonnementParam(e)} placeholder="2024-10" type="month" />
                      </Form.Group>
                    </Dropdown.Menu>
                  </Dropdown> */}

                  <Dropdown className="ms-2">
                    <Dropdown.Toggle disabled={isGetAbonnementsLoading || !abonnementsParams.current['mois_abonnement']} variant="secondary">

                      {(() => {
                        let filtersNum = 0
                        if (abonnementsParams.current?.scheduled) { filtersNum++ }
                        if (abonnementsParams.current?.generated) { filtersNum++ }
                        if (abonnementsParams.current?.suspended) { filtersNum++ }
                        if (abonnementsParams.current['mois_abonnement'] && filtersNum > 0) {
                          return <Badge pill bg="warning" className="d-inline-flex me-2">{filtersNum}</Badge>
                        }
                        return
                      })()}
                      Filtrer la liste
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="mt-1 p-3">

                      <p className="mb-2">Programmés :</p>
                      <ParamsCheckboxGroup paramName="scheduled" />

                      <p className="mb-2">Suspendus :</p>
                      <ParamsCheckboxGroup paramName="suspended" />

                      <p className="mb-2">Générés :</p>
                      <ParamsCheckboxGroup paramName="generated" />

                    </Dropdown.Menu>
                  </Dropdown>

                  {/* <Dropdown className="ms-2">
                    <Dropdown.Toggle disabled={isGetAbonnementsLoading || !abonnementsParams.current['mois_abonnement']} variant="primary">
                      Générer les factures
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="generate-dropdown p-3 mt-1">

                      <Form.Group className="mb-2">
                        <Form.Label>Date d'exécution</Form.Label>
                        <Form.Control placeholder="Date d'exécution" onChange={e => setDateExecution(e.target.value)} type="date" />
                      </Form.Group>

                      <Form.Check
                        type="checkbox"
                        defaultChecked={isDoNotSimuateChecked}
                        onClick={() => setIsDoNotSimuateChecked(!isDoNotSimuateChecked)}
                        id={`id-generate`}
                        label="Ne pas simuler"
                      />

                      <Form.Check
                        type="checkbox"
                        defaultChecked={isForceRegenerateChecked}
                        onClick={() => setIsForceRegenerateChecked(!isForceRegenerateChecked)}
                        id={`id-force-regenerate`}
                        label="Forcer la re-génération"
                      />

                      {isDoNotSimuateChecked ?
                        <Button className="mt-2" onClick={() => generate(true)} variant="primary" disabled={isGetAbonnementsLoading || !abonnementsParams.current['mois_abonnement'] || !isDoNotSimuateChecked}>
                          <span>Générer {checkedElementIdList.length > 0 ? (checkedElementIdList.length + " abonnement(s)") : "tous les abonnements"}</span>
                        </Button>
                        :
                        <Button className="mt-2" onClick={() => generate(false)} variant="primary" disabled={isGetAbonnementsLoading || !abonnementsParams.current['mois_abonnement']}>
                          <span>Simuler {checkedElementIdList.length > 0 ? (checkedElementIdList.length + " abonnement(s)") : "tous les abonnements"}</span>
                        </Button>
                      }

                    </Dropdown.Menu>
                  </Dropdown> */}

                </Col>
              </>
            }
            <Col xs="12" xl="auto" className="mb-sm-2 mb-xl-0 ms-auto d-flex">
              {checkedElementIdList?.length > 0 &&
                <Dropdown className="d-inline-block me-2">
                  <Dropdown.Toggle variant="dark">
                    {checkedElementIdList.length} Sélectionné{checkedElementIdList?.length > 1 && <span>s</span>}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {/* <Dropdown.Item onClick={() => setCheckedElementIdList([])}>Tout désélectionner</Dropdown.Item> */}
                    {/* <Dropdown.Item onClick={() => downloadCheckedAbonnements()}>Imprimer les abonnements</Dropdown.Item> */}

                    {granted(grants, "abonnements.remove") &&
                      <>
                        <Dropdown.Divider />
                        <Dropdown.Item className="text-danger" onClick={() => removeAbonnementListDialog(checkedElementIdList)}>Supprimer</Dropdown.Item>
                      </>
                    }
                  </Dropdown.Menu>
                </Dropdown>
              }

              {/* Brouillons en cours */}
              {/* <Brouillons brouillonTypes={["abonnement"]} openBrouillon={b => openBrouillon(b, "abonnements")} grantedConsult={granted(grants, "brouillons.consult")} grantedEdit={granted(grants, "brouillons.edit")} grantedRemove={granted(grants, "brouillons.remove")} />

              {granted(grants, "abonnements.edit") &&
                <Button variant="primary" onClick={() => createNewAbonnement()}>Créer un abonnement</Button>
              } */}

            </Col>
          </Row>

          {granted(grants, "abonnements.consult") &&
            <Row className="mt-3">
              {abonnementsParams.current['mois_abonnement'] &&
                <Col xs={"2"}>
                  <div className="p-3 border rounded">

                    <h6>Génération des factures :</h6>

                    <Form.Group className="mb-2">
                      <Form.Label>Date d'exécution :</Form.Label>
                      <Form.Control placeholder="Date d'exécution" onChange={e => setDateExecution(e.target.value)} type="date" />
                    </Form.Group>

                    <Form.Check
                      type="checkbox"
                      defaultChecked={isDoNotSimuateChecked}
                      onClick={() => setIsDoNotSimuateChecked(!isDoNotSimuateChecked)}
                      id={`id-generate`}
                      label="Ne pas simuler"
                    />

                    <Form.Check
                      type="checkbox"
                      defaultChecked={isForceRegenerateChecked}
                      onClick={() => setIsForceRegenerateChecked(!isForceRegenerateChecked)}
                      id={`id-force-regenerate`}
                      label="Forcer la re-génération"
                    />

                    {isDoNotSimuateChecked ?
                      <Button className="mt-2" onClick={() => generate(true)} variant="primary" disabled={isGetAbonnementsLoading || !abonnementsParams.current['mois_abonnement'] || !isDoNotSimuateChecked}>
                        <span>Générer {checkedElementIdList.length > 0 ? (checkedElementIdList.length + " abonnement(s)") : "tous les abonnements"}</span>
                      </Button>
                      :
                      <Button className="mt-2" onClick={() => generate(false)} variant="primary" disabled={isGetAbonnementsLoading || !abonnementsParams.current['mois_abonnement']}>
                        <span>Simuler {checkedElementIdList.length > 0 ? (checkedElementIdList.length + " abonnement(s)") : "tous les abonnements"}</span>
                      </Button>
                    }
                  </div>
                </Col>
              }
              <Col xs={abonnementsParams.current['mois_abonnement'] ? "10" : "12"}>

                {abonnements?.total_ttc != null &&
                  <div className="rounded border px-3 py-2 bg-gray-900 text-white fw-bold me-2 d-inline-block mb-3">
                    <span className="small w-100 float-start text-gray-200">Total TTC</span>
                    <span>{abonnements?.total_ttc}</span>
                  </div>
                }

                <div className="p-3 bg-white rounded border overflow-auto">

                  <DynamicTable
                    isDataLoading={isGetAbonnementsLoading}
                    elementActions={tableRowElementActions}
                    setElementAction={(a, e) => eval(a)(e)}
                    sideWidth={sideWidth}
                    tableHeaders={tableHeaders}
                    tableDataList={abonnements?.abonnements}
                    currentElement={currentAbonnement}
                    changeCurrentElement={e => changeCurrentAbonnement(e)}
                    checkedElementIdList={checkedElementIdList}
                    setCheckedElementIdList={e => setCheckedElementIdList(e)}
                    changeFilters={f => changeFilters(f)}
                    isTableFilterVisible={isTableFilterVisible}
                    changeSortOrder={(s, o) => changeSortOrder(s, o)}
                    params={abonnementsParams.current}
                  />
                  <Pagination itemsLength={abonnements?.total} elementsPerPage={abonnementsParams.current.limit} currentPagination={currentPagination} changePagination={p => changePagination(p)} showChangeLimit changeLimit={l => changeLimit(l)} />
                </div>
              </Col>
            </Row>
          }

          {currentAbonnement &&
            <SideDetails
              currentElement={{ id: currentAbonnement?._id, label: new Date(currentAbonnement?.date).toLocaleString(), title: currentAbonnement?.client_nom }}
              changeCurrentElement={e => changeCurrentAbonnement(e)}
              setSideClose={closeSide}
              setSideWidth={e => setSideWidth(e)}
              detailsComponent={
                <AbonnementDetails folderId={folderDetails._id} abonnementId={currentAbonnement._id} elementActions={abonnementElementActions} setElementAction={(a, e) => eval(a)(e)} onTabChange={() => onTabChange()} />
              }
            />
          }
        </Col>
      </Row>

    </Container>


  );
}

export default Abonnements;