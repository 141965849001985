import { axiosAPI } from "./axiosClient"
import { UserService } from './UserService';
import { useParams, useSearchParams } from "react-router-dom";
import qs from "qs";
import { getParams } from '../helper/Helper'


export const UsersProfilService = {

    getUsersProfils(folderId, excludeReadOnly, exclusive) {
        return axiosAPI.get(`/${folderId}/usersprofils`)
    },

    getUsersProfilsFolderUsers(folderId) {
        return axiosAPI.get(`/${folderId}/usersprofils/folderusers`)
    },

    getUsersProfilsGrantsListe(folderId) {
        return axiosAPI.get(`/${folderId}/usersprofils/grantsliste`)
    },

    getUsersProfilsAvailableUsers(folderId) {
        return axiosAPI.get(`/${folderId}/usersprofils/availableusers`)
    },

    postUsersProfils(folderId, dataPost) {
        return axiosAPI.post(`/${folderId}/usersprofils`, qs.stringify(dataPost))
    },

    updateUsersProfils(folderId, dataPost) {
        return axiosAPI.put(`/${folderId}/usersprofils`, qs.stringify(dataPost))
    },

    postUserToProfil(folderId, profilId, userId) {
        return axiosAPI.post(`/${folderId}/usersprofils/${profilId}/users/${userId}`)
    },

    postUserListToProfil(folderId, profilId, dataPost) {
        return axiosAPI.post(`/${folderId}/usersprofils/${profilId}/users`, qs.stringify(dataPost))
    },

    putUserListToProfil(folderId, profilId, dataPost) {
        return axiosAPI.put(`/${folderId}/usersprofils/${profilId}/users`, qs.stringify(dataPost))
    },

    putUserToProfil(folderId, profilId, userId) {
        return axiosAPI.put(`/${folderId}/usersprofils/${profilId}/users/${userId}`)
    },

    removeUserToProfil(folderId, profilId, userId) {
        return axiosAPI.delete(`/${folderId}/usersprofils/${profilId}/users/${userId}`)
    },
    
    removeUsersProfils(folderId, userProfilId) {
        return axiosAPI.delete(`/${folderId}/usersprofils/${userProfilId}`)
    },

}