import React, { useEffect, useState } from "react";
import { Container, Button, Dropdown, DropdownButton, Form, Col, Row, Accordion, Label, Modal, Spinner, Alert } from 'react-bootstrap';
import ErrorManager from "../error-manager/ErrorManager";
import './ConfirmDialog.scss';

const ConfirmDialog = (props) => {

    /*
        dialogInfos: {
            title: // titre de la modal
            description: // texte de description
            btnVariant: // danger, info, secondary...
            element: // élément passé en paramètre et retourné au parent -> props.setConfirm(element, confirmAction)
            confirmAction: // nom de la méthode à exécuter dans le parent en cas de confirmation -> props.setConfirm(element, confirmAction)
            actionName: // titre du bouton d'action
            error: // erreur passée par le parent à afficher si besoin
        }
    */

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState()
    const [error, setError] = useState()

    useEffect(() => {
        setShow(props.show)
        setError()
        setLoading(false)
    }, [props.show]);

    useEffect(() => {
        if (props.dialogInfos?.error) {
            setLoading(false)
            setError(props.dialogInfos?.error)
        }
    }, [props.dialogInfos?.error]);

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    function confirm() {
        setLoading(true)
        props.setConfirm(props.dialogInfos?.confirmAction, props.dialogInfos?.element)
        //props.setConfirm(props.dialogInfos?.element, props.dialogInfos?.confirmAction)
    }


    function handleKeyDown(e) {
        if (show) {
            const { code } = e;
            switch (code) {
                case "Escape":
                    props.setCancel()
                    break;
                default:
                    break;
            }
        }
    }

    return (
        <>
            <Modal show={show} centered>

                <Modal.Header className="border-0 pb-0">
                    <h5>{props.dialogInfos?.title}</h5>
                </Modal.Header>

                <Modal.Body>
                    <Row>
                        <h6>{props.dialogInfos?.description}</h6>
                    </Row>
                    {error &&
                        <span className="error-container light-v-scroll">
                            <ErrorManager error={error} />
                        </span>
                    }
                </Modal.Body>

                <Modal.Footer className="border-0">
                    <Button variant="secondary" onClick={() => props.setCancel()}>
                        Annuler
                    </Button>
                    <Button autoFocus disabled={loading} variant={props.dialogInfos?.btnVariant} onClick={() => confirm()}>
                        {loading &&
                            <Spinner animation="border" size="sm" className="me-2" />
                        }
                        {props.dialogInfos?.actionName}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default ConfirmDialog;
