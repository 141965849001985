import React, { useEffect, useState } from "react";
import { Container, Button, Dropdown, DropdownButton, Form, Col, Row, Accordion, Label, Modal, Spinner, Alert } from 'react-bootstrap';
import { FamilyService } from '../../../services/FamilyService';
import { GlobalContext } from "../../../services/GlobalProvider";
import { useParams } from "react-router-dom";
import ErrorManager from "../../error-manager/ErrorManager";

const ModalAddFamily = (props) => {

    const [show, setShow] = useState(false);
    const [codeValue, setCodeValue] = useState(null);
    const [designationValue, setDesignationValue] = useState(null);
    const [natureAnalytiqueValue, setNatureAnalytiqueValue] = useState(null);
    const [addLoading, setAddLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isCodeLoading, setIsCodeLoading] = useState(false)

    const { folderDetails, parametres } = React.useContext(GlobalContext);

    useEffect(() => {
        setShow(props.object.show)
        setError(null)
        props.object.family?.code && setNextCode(props.object.family?.code)
    }, [props.object]);

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    function handleKeyDown(e) {
        if (show) {
            const { code } = e;
            switch (code) {
                case "Escape":
                    props.setClose({ reload: false })
                    break;
                default:
                    break;
            }
        }
    }

    function setNextCode(prefix) {
        setIsCodeLoading(true)
        FamilyService.getNextCode(folderDetails._id, prefix).then(res => {
            setIsCodeLoading(false)
            setCodeValue(res.data.data[prefix])
        }).catch(error => {
            setIsCodeLoading(false)
            setError(error)
        });
    }

    function addFamily() {

        const dataPost = {
            familles: [
                {
                    "code": codeValue,
                    "designation": designationValue,
                    "nature_analytique": natureAnalytiqueValue,
                    "parent": props.object.family?._id ? props.object.family?._id : null
                }
            ]
        }

        setAddLoading(true)
        setError(null)
        FamilyService.postFamily(folderDetails._id, dataPost).then((res) => {
            props.setClose({ reload: true })
            setAddLoading(false)
        }).catch(err => {
            setError(err)
            setAddLoading(false)
        });

    }

    return (
        <>
            <Modal show={show} centered>

                <Modal.Header className="border-0 pb-0">
                    <h5>
                        {props.object.family?.designation && props.object.family?.designation}
                        {!props.object.family?.designation && 'Famille Parent : -Racine-'}
                    </h5>
                </Modal.Header>

                <Modal.Body className="">
                    <Form>
                        <Row>
                            <span className="lead mb-4">Ajouter une sous famille :</span>
                        </Row>
                        <Row>
                            <Form.Group as={Col} xs="4">
                                <Form.Label>Code</Form.Label>
                                {isCodeLoading &&
                                    <Spinner animation="border" size="sm" className="ms-2 text-gray-400" />
                                }
                                <Form.Control defaultValue={codeValue} disabled={isCodeLoading} autoFocus type="text" placeholder="Code de la famille" onChange={e => setCodeValue(e.target.value)} />
                            </Form.Group>

                            <Form.Group as={Col} xs="8">
                                <Form.Label>Désignation</Form.Label>
                                <Form.Control type="text" placeholder="Désignation de la famille" onChange={e => setDesignationValue(e.target.value)} />
                            </Form.Group>
                        </Row>
                        {parametres?.general?.export_comptable?.comptabilite_analytique &&
                            <Row className="mt-3">
                                <Form.Group as={Col} xs="12">
                                    <Form.Label>Nature Analytique</Form.Label>
                                    <Form.Control type="text" placeholder="Nature analytique" onChange={e => setNatureAnalytiqueValue(e.target.value)} />
                                </Form.Group>
                            </Row>
                        }

                        <Row className="mt-3">
                            <Col xs="12">
                                <ErrorManager error={error} />
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>

                <Modal.Footer className="border-0">
                    <Button variant="secondary" onClick={() => props.setClose({ reload: false })}>
                        Annuler
                    </Button>
                    <Button disabled={!codeValue || !designationValue || addLoading} variant="primary" onClick={() => addFamily()}>
                        {addLoading &&
                            <Spinner animation="border" size="sm" className="me-2" />
                        }
                        Ajouter
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default ModalAddFamily;
