import axios from 'axios';
import '../config.inc';
import { UserService } from './UserService'

/// axiosAPI (_API_URL_) /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const axiosAPI = axios.create({
  baseURL: global.config._API_URL_,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
})

axiosAPI.interceptors.request.use(function (config) {
  const token = localStorage.getItem('access_token');
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

// interceptor to refresh token on 401
axiosAPI.interceptors.response.use(response => {
  return response;
}, error => {

  // si on intercepte une erreur 401
  if (error.response.status === 401) {
    refreshToken(error)
  } else {
    return Promise.reject(error.response);
  }

});


/// axiosOAuth (_OAUTH_PUBLIC_API_) /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const axiosOAuth = axios.create({
  baseURL: global.config._OAUTH_PUBLIC_API_,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  },
  withCredentials: false,
})

axiosOAuth.interceptors.request.use(function (config) {
  const token = localStorage.getItem('access_token');
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axiosOAuth.interceptors.response.use(response => {
  return response;
}, error => {

  if (error.response.status === 401) {
    refreshToken(error)
  } else {
    return Promise.reject(error.response);
  }

});

function refreshToken(error){
  const dataPost = {
    client_id: global.config._OAUTH_CLIENT_ID_,
    grant_type: 'refresh_token',
    refresh_token: localStorage.getItem('refresh_token'),
    client_secret: global.config._OAUTH_CLIENT_SECRET_
  }

  // On envoie le refresh_token
  return UserService.getToken(dataPost).then(res => {

    // On stocke les nouveaux tokens dans le localStorage
    UserService.setTokens(res.data)

    // On reexécute la requête demandée
    error.config.headers.Authorization = `Bearer ${res.data.access_token}`;
    return axios.request(error.config)

  }).catch(err => { // Le refresh_token retourne une erreur (token expiré par exemple)
    // Redirection vers la page de login
    UserService.goToExternalConnection()
  });
}
