import React, { useEffect, useState } from 'react';
import { ArticleService } from '../../../../../services/ArticleService';
import { FournisseurService } from '../../../../../services/FournisseurService';
import { Spinner, Card, Tabs, Tab, Row, Col, Accordion, Button, Table, Alert, Form, Dropdown, OverlayTrigger, Popover } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import ErrorManager from "../../../../error-manager/ErrorManager";
import ConfirmDialog from '../../../../confirm-dialog/ConfirmDialog';
import { GlobalContext } from "../../../../../services/GlobalProvider";
import { granted, currencyFormat } from '../../../../../helper/Helper'
import { Editor, convertFromHTML, EditorState, createWithContent, convertFromRaw, ContentState, convertToRaw } from "draft-js";
import { markdownToDraft } from 'markdown-draft-js';


const Signaletique = (props) => {

    const { grants } = React.useContext(GlobalContext);

    function Split({ title, value, border }) {
        return (
            <div className={"d-flex justify-content-between py-2 " + (border ? "border-bottom" : "")}>
                <span className="pe-4 text-gray-500">{title}</span>
                <span className="">{value}</span>
            </div>
        )
    }

    return (
        <Row>
            <Col>
                <Accordion defaultActiveKey={['famille', 'designation', 'observation', 'prix', 'codesbarres', 'contributions']} alwaysOpen>

                    <Accordion.Item eventKey="famille" className="border-0 mb-3">
                        <Accordion.Header>Famille</Accordion.Header>
                        <Accordion.Body className="px-1">
                            <Split title="Code" border value={props.article.famille.code} />
                            <Split title="Désignation" value={props.article.famille.designation} />
                            {/* <Split title="Observation" value={props.article.famille.observation} /> */}
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="designation" className="border-0 mb-3">
                        <Accordion.Header>Désignation</Accordion.Header>
                        <Accordion.Body className="px-1">
                            <Split title="Code" border value={props.article.code} />
                            <Split title="Désignation" border value={props.article.designation} />
                            <Split title="Description" border value={props.article.description} />
                            <Split title="Conditionnement" border value={props.article.conditionnement} />
                            <Split title="Code associé" border value={
                                props?.article?.article_associe?._id ?
                                    <a href={`/${props.folderId}/articles?articleId=${props?.article?.article_associe?._id}`} target="_blank">{props?.article?.article_associe?.code} - {props?.article?.article_associe?.designation}</a>
                                    :
                                    ""
                            } />
                            <Split title="Unité de vente" value={props.article.unite_vente} />
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="observation" className="border-0 mb-3">
                        <Accordion.Header>Observation</Accordion.Header>
                        <Accordion.Body className="px-1">
                            {props.article.observation &&
                                <Editor editorState={EditorState.createWithContent(convertFromRaw(markdownToDraft(props.article.observation)))} readOnly={true} />
                            }
                            {!props.article.observation &&
                                <em>- Aucune observation rédigée -</em>
                            }
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="prix" className="border-0 mb-3">
                        <Accordion.Header>Prix</Accordion.Header>
                        <Accordion.Body className="px-1">
                            {granted(grants, "articles.consult.prix_revient") &&
                                <Split title="Prix de revient pondéré" border value={currencyFormat(props.article.prix_revient_pondere)} />
                            }
                            <Split title="Prix de vente hors taxe" border value={currencyFormat(props.article.prix_vente_ht)} />
                            <Split title="Prix de vente TTC" border value={currencyFormat(props.article.prix_vente_ttc)} />
                            {granted(grants, "articles.consult.prix_revient") &&
                                <>
                                    <Split title="Taux marge" border value={props.article.taux_marge_calc} />
                                    <Split title="Taux marque" border value={props.article.taux_marque_calc} />
                                    <Split title="Taux remise maximum" border value={props.article.taux_remise_maximum} />
                                    <Split title="Coefficient" border value={props.article.coefficient_calc} />
                                </>
                            }
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="codesbarres" className="border-0 mb-3">
                        <Accordion.Header>Codes barres</Accordion.Header>
                        <Accordion.Body className="px-1">
                            <div className="d-block pb-2">
                                {props.article.codesbarres.length == 0 && <em>- Aucun élément trouvé -</em>}
                                {props.article.codesbarres?.map(code => (
                                    <span key={code} style={{ fontFamily: "var(--bs-font-monospace)" }} className="float-start fw-bold border rounded bg-gray-100 code px-2 py-1 me-2 mb-2">
                                        <small>{code}</small>
                                    </span>
                                ))}
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="contributions" className="border-0 mb-3">
                        <Accordion.Header>Contributions</Accordion.Header>
                        <Accordion.Body className="px-1">
                            {props.article.contributions.length == 0 && <em>- Aucun élément trouvé -</em>}
                            {props.article.contributions?.map((contribution, i, arr) => (
                                <div key={i} className={arr.length - 1 === i ? "" : "mb-3"}>
                                    <Split title="Nom" border value={contribution.libelle} />
                                    <Split title="Type" value={contribution.type} />
                                    {/* <Split title="Taux" border value={contribution.taux} /> */}
                                    {/* <Split title="Valeur" border value={contribution.valeur} /> */}
                                </div>
                            ))}
                        </Accordion.Body>
                    </Accordion.Item>



                </Accordion>
            </Col>

        </Row>
    );

}
export default Signaletique;