import { axiosAPI, axiosOAuth } from "./axiosClient"
import qs from "qs"
import React, { createContext } from 'react';
import axios from 'axios';

export const UserService = {

  getToken(dataPost) {
    return axiosOAuth.post(`/oauth2/token`, new URLSearchParams(dataPost))
  },

  setTokens(data) {
    localStorage.setItem('access_token', data.access_token)
    localStorage.setItem('refresh_token', data.refresh_token)
    localStorage.setItem('openid', data.id_token)
  },

  revoke(dataPost) {
    return axiosOAuth.post(`/oauth2/revoke`, new URLSearchParams(dataPost))
  },

  getUserInfos() {
    return axiosOAuth.get(`/userinfo`)
  },


  rapport(dataPost) {
    return axiosAPI.post(`/user/rapport`, qs.stringify(dataPost))
  },


  getUserGrants(folderId) {
    return axiosAPI.get(`${folderId}/user/grants`)
  },


  goToExternalConnection() {
    //console.log(global.config._OAUTH_PUBLIC_API_ + "/oauth2/auth?client_id=" + global.config._OAUTH_CLIENT_ID_ + "&response_type=code&state=1234568766&scope=openid+offline_access+stockweb+loginxl")
    window.location.href = global.config._OAUTH_PUBLIC_API_ + "/oauth2/auth?client_id=" + global.config._OAUTH_CLIENT_ID_ + "&response_type=code&state=1234568766&scope=openid+offline_access+stockweb+loginxl"
    //window.location.href = global.config._OAUTH_PUBLIC_API_ + "?client_id=" + global.config._OAUTH_CLIENT_ID_ + "&response_type=code&state=1234568766&scope=openid+offline_access+stockweb+loginxl"
  },

  //https://oauth.xlprog.nc/oauth2/auth?client_id=16e3939c-32c7-4597-a5c3-abe5c570b438&response_type=code&scope=openid+offline_access+stockweb&state=321654987654
  //https://oauth.xlprog.nc/oauth2/auth?client_id=16e3939c-32c7-4597-a5c3-abe5c570b438&response_type=code&state=321654987654&scope=openid+offline_access+stockweb+loginxl
}
