// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#DetailsTable tr:hover {
  position: relative;
}
#DetailsTable tr:hover .actions {
  display: block;
}
#DetailsTable tr td {
  max-width: 10em;
}
#DetailsTable .actions {
  display: none;
  position: absolute;
  border: 0;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 50%) !important;
  padding-left: 70px !important;
  top: 1px;
  bottom: 1px;
  right: 1px;
  box-shadow: 0px 0px 0px 0px #ffffff !important;
  box-sizing: content-box;
}
#DetailsTable .actions span {
  display: flex;
  align-items: center;
  height: 100%;
}
#DetailsTable .actions span i {
  font-size: 20px;
  padding: 1px 5px;
}
@media (max-width: 1399.98px) {
  #DetailsTable table {
    font-size: 0.9em;
  }
  #DetailsTable table tr td {
    padding: 4px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/brouillon/details-table/DetailsTable.scss"],"names":[],"mappings":"AAEQ;EACI,kBAAA;AADZ;AAGY;EACI,cAAA;AADhB;AAKQ;EACI,eAAA;AAHZ;AAOI;EACI,aAAA;EACA,kBAAA;EACA,SAAA;EACA,qFAAA;EACA,6BAAA;EACA,QAAA;EACA,WAAA;EACA,UAAA;EACA,8CAAA;EACA,uBAAA;AALR;AAOQ;EACI,aAAA;EACA,mBAAA;EACA,YAAA;AALZ;AAOY;EACI,eAAA;EACA,gBAAA;AALhB;AAYI;EACI;IACI,gBAAA;EAVV;EAWU;IACI,YAAA;EATd;AACF","sourcesContent":["#DetailsTable {\n    tr {\n        &:hover {\n            position: relative;\n\n            .actions {\n                display: block;\n            }\n        }\n\n        td {\n            max-width: 10em;\n        }\n    }\n\n    .actions {\n        display: none;\n        position: absolute;\n        border: 0;\n        background: linear-gradient(90deg, #ffffff00 0%, #ffffff 50%) !important;\n        padding-left: 70px !important;\n        top: 1px;\n        bottom: 1px;\n        right: 1px;\n        box-shadow: 0px 0px 0px 0px #ffffff !important;\n        box-sizing: content-box;\n\n        span {\n            display: flex;\n            align-items: center;\n            height: 100%;\n\n            i {\n                font-size: 20px;\n                padding: 1px 5px;\n            }\n        }\n    }\n\n\n    // `xxl` applies to x-large devices (large desktops, less than 1400px)\n    @media (max-width: 1399.98px) {\n        table {\n            font-size: .9em;\n            tr td {\n                padding: 4px;\n            }\n        }\n    }\n\n    // `xl` applies to large devices (desktops, less than 1200px)\n    @media (max-width: 1199.98px) {}\n\n    // `lg` applies to medium devices (tablets, less than 992px)\n    @media (max-width: 991.98px) {}\n\n    // `md` applies to small devices (landscape phones, less than 768px)\n    @media (max-width: 767.98px) {}\n\n    // `sm` applies to x-small devices (portrait phones, less than 576px)\n    @media (max-width: 575.98px) {}\n\n\n\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
