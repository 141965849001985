import React, { useEffect, useState, useRef } from 'react';
import { TarifService } from '../../../../../services/TarifService';
import { Form, Spinner, Card, Tabs, Tab, Row, Col, Accordion, Button, InputGroup, Alert, Tooltip, Dropdown, Badge } from 'react-bootstrap';
import ErrorManager from "../../../../error-manager/ErrorManager";
import { getFormErrorMsg, getInvalidObject, getChangedValues } from '../../../../../helper/Helper'
import FamilyInput from "../../../../form-inputs/family-input/FamilyInput";
import ConfirmDialog from '../../../../../components/confirm-dialog/ConfirmDialog';
//import ClientInput from "./client-input/ClientInput";
import ClientInput from "../../../../form-inputs/client-input/ClientInput";



const Clients = (props) => {

    const [getClientsLoading, setGetClientsLoading] = useState(false)
    const [postClientsLoading, setPostClientsLoading] = useState(false)
    const [removeClientIdLoading, setRemoveClientIdLoading] = useState()
    const [clients, setClients] = useState(false)
    const [globalError, setGlobalError] = useState()
    const [confirmDialog, setConfirmDialog] = useState({ show: false, dialogInfos: null })


    useEffect(() => {
        getClients()
    }, []);

    function getClients() {
        setGetClientsLoading(true)
        TarifService.getClients(props.folderId, props.tarif._id).then(res => {
            setGetClientsLoading(false)
            res?.data?.data?.clients ? setClients(res.data.data.clients) : setClients()
        }).catch(error => {
            setGetClientsLoading(false)
            setGlobalError(error)
        });
    }

    function addClient(client) {
        if (client.tarif_id) {
            setConfirmDialog({
                show: true,
                dialogInfos: {
                    title: `Remplacer le tarif existant ?`,
                    description: `Le tarif "${client?.tarif[0]?.libelle}" est déjà associé à ce client, souhaitez-vous le remplacer ?`,
                    actionName: 'Remplacer',
                    btnVariant: 'danger',
                    element: client,
                    error: false,
                    confirmAction: 'saveClient'
                }
            })
        } else {
            saveClient(client)
        }
    }

    function saveClient(client) {
        setGlobalError()
        setPostClientsLoading(true)
        setConfirmDialog({ show: false });
        TarifService.postClient(props.folderId, props.tarif._id, { clients: [client._id] }).then(res => {
            setPostClientsLoading(false)
            getClients()
        }).catch(error => {
            setGlobalError(error)
            setPostClientsLoading(false)
        });
    }

    function removeClient(client) {
        setGlobalError()
        setRemoveClientIdLoading(client._id)
        TarifService.removeClients(props.folderId, props.tarif._id, [client._id]).then(res => {
            setRemoveClientIdLoading()
            getClients()
        }).catch(error => {
            setRemoveClientIdLoading()
            setGlobalError(error)
        });
    }

    return (
        <>

            {globalError && (
                <ErrorManager error={globalError} />
            )}

            <ConfirmDialog
                show={confirmDialog.show}
                dialogInfos={confirmDialog.dialogInfos}
                setCancel={() => setConfirmDialog({ show: false })}
                setConfirm={(a, e) => eval(a)(e)}
            />

            <div className="mb-2 rounded p-3 bg-gray-100">
                <Form.Group>
                    <Form.Label>Ajouter un client :</Form.Label>
                    <ClientInput clientId="" folderId={props.folderId} setClient={addClient} />
                </Form.Group>
            </div>

            {(!clients && !getClientsLoading) &&
                <em>- Aucun élément trouvé -</em>
            }

            {(getClientsLoading || postClientsLoading) &&
                <span className="hloader mt-3"></span>
            }

            {(!getClientsLoading && clients) && clients.map(client => (
                <div key={client._id}>
                    <span className="py-1 px-2 bg-gray-100 rounded mb-2 d-flex align-items-center">
                        <span className="me-auto">
                            <span className="me-2 fw-bolder">{client.code}</span>
                            {client.nom}
                        </span>
                        <Button disabled={removeClientIdLoading == client._id} size="sm" onClick={() => removeClient(client)} variant="outline-danger" className="ms-3">
                            <i className="material-icons i-lg">delete</i>
                        </Button>
                    </span>
                </div>
            ))}

        </>
    );

}
export default Clients;