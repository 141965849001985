import React, { useEffect, useState } from 'react';
import { AbonnementService } from '../../../../../services/AbonnementService';
import { Form, Spinner, Card, Tabs, Tab, Row, Col, Accordion, Button, OverlayTrigger, Alert, Tooltip, Dropdown, Badge } from 'react-bootstrap';
import ErrorManager from "../../../../error-manager/ErrorManager";
import { getFormErrorMsg, getInvalidObject, getChangedValues, formattedDateInput } from '../../../../../helper/Helper'

const Suspensions = (props) => {

    const [currentSuspension, setCurrentSuspension] = useState()
    const [currentSuspensionChanges, setCurrentSuspensionChanges] = useState()
    const [invalidData, setInvalidData] = useState()
    const [globalError, setGlobalError] = useState()
    const [isLoading, setIsLoading] = useState(false)


    /*     useEffect(() => {
            let suspensionInitialIndex = props.abonnement?.suspensions?.findIndex(s => s._id == currentSuspension?._id)
            suspensionInitialIndex > -1 && setCurrentSuspensionChanges(getChangedValues(currentSuspension, props.abonnement?.suspensions[suspensionInitialIndex], [], ["_id"]))
        }, [currentSuspension]); */

    function postSuspension() {
        setIsLoading(true)
        AbonnementService.postSuspension(props.folderId, props.abonnement._id, { suspensions: [currentSuspension] }).then(res => {
            setIsLoading(false)
            setCurrentSuspension()
            setGlobalError()
            props.onSuspensionChange()
        }).catch(error => {
            setIsLoading(false)
            setGlobalError(error)
            setInvalidData(getInvalidObject(error.data?.data))
        });
    }

    function updateSuspension() {
        let currentSuspensionCopy = { ...currentSuspension }
        if (!currentSuspension.date_fin) { currentSuspensionCopy.date_fin = "0000-00-00" }

        setIsLoading(true)
        AbonnementService.updateSuspension(props.folderId, props.abonnement._id, { suspensions: [currentSuspensionCopy] }).then(res => {
            setIsLoading(false)
            setCurrentSuspension()
            setGlobalError()
            props.onSuspensionChange()
        }).catch(error => {
            setIsLoading(false)
            setGlobalError(error)
            setInvalidData(getInvalidObject(error.data?.data))
        });
    }

    function removeSuspension() {
        setIsLoading(true)
        AbonnementService.removeSuspension(props.folderId, props.abonnement._id, currentSuspension._id).then(res => {
            setIsLoading(false)
            setCurrentSuspension()
            setGlobalError()
            props.onSuspensionChange()
        }).catch(error => {
            setIsLoading(false)
            setGlobalError(error)
        });
    }

    function editNewSuspension() {
        setCurrentSuspension({
            date_debut: '',
            date_fin: ''
        })
    }

    function handleChange(e) {
        let value
        e.target.type == "checkbox" ? value = e.target.checked : value = e.target.value
        setCurrentSuspension({ ...currentSuspension, [e.target.name]: value })
        setInvalidData({ ...invalidData, [e.target.name]: null })
    }

    return (
        <>

            {props.isAbonnementLoading &&
                <span className="hloader mt-3"></span>
            }

            {!props.isAbonnementLoading &&
                <>
                    <div className="d-flex align-items-center">
                        {(props.abonnement?.suspensions?.length == 0 && !currentSuspension) &&
                            <em>- Aucun élément trouvé -</em>
                        }
                        <Button disabled={currentSuspension} className="ms-auto" onClick={() => editNewSuspension()}>Ajouter une suspension</Button>
                    </div>

                    {globalError && (
                        <div className="mt-2">
                            <ErrorManager error={globalError} />
                        </div>
                    )}

                    {(currentSuspension && !currentSuspension._id) &&
                        <div className="p-4 border rounded mt-2 mb-4">

                            <Form.Group className="d-flex align-items-center">
                                <Form.Label column sm={3}>Date de début<span className="text-danger">*</span></Form.Label>
                                <Col sm={9}>
                                    <Form.Control size="sm" name="date_debut" value={currentSuspension.date_debut} isInvalid={getFormErrorMsg(invalidData?.date_debut)} onChange={handleChange} type="date" />
                                    <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.date_debut)}</Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group className="d-flex align-items-center">
                                <Form.Label column sm={3}>Date de fin</Form.Label>
                                <Col sm={9}>
                                    <Form.Control size="sm" name="date_fin" value={currentSuspension.date_fin} isInvalid={getFormErrorMsg(invalidData?.date_fin)} onChange={handleChange} type="date" />
                                    <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.date_fin)}</Form.Control.Feedback>
                                </Col>
                            </Form.Group>

                            <Row>
                                <Col className="d-flex justify-content-end mt-2">
                                    <Button variant="secondary" className="me-2" onClick={() => setCurrentSuspension()}>Annuler</Button>
                                    <Button disabled={isLoading || !currentSuspension.date_debut} onClick={postSuspension}>Enregistrer la suspension</Button>
                                </Col>
                            </Row>
                        </div>
                    }

                    {props.abonnement?.suspensions?.map((suspension, i, arr) => (
                        <div key={suspension._id} >
                            {(suspension._id != currentSuspension?._id) &&
                                <div className={"px-4 py-3 rounded bg-gray-100 mt-2 " + (arr.length - 1 === i ? "" : "mb-2")}>
                                    <Row>
                                        <Col>
                                            <span className="d-flex align-items-center">
                                                <i className="me-2 fs-4 material-icons">event</i>
                                                Du {new Date(suspension.date_debut).toLocaleDateString()} au {suspension.date_fin ? new Date(suspension.date_fin).toLocaleDateString() : "(Indéfini)"}
                                            </span>
                                        </Col>
                                        <Col className="col-auto">
                                            <Button disabled={isLoading} variant="secondary" size="sm" onClick={() => setCurrentSuspension(suspension)}>Modifier</Button>
                                        </Col>
                                    </Row>
                                </div>
                            }
                            {(suspension._id == currentSuspension?._id) &&
                                <div className="p-4 border rounded mt-2">
                                    <Form.Group className="d-flex align-items-center">
                                        <Form.Label column sm={3}>Date de début<span className="text-danger">*</span></Form.Label>
                                        <Col sm={9}>
                                            <Form.Control size="sm" name="date_debut" value={formattedDateInput(currentSuspension.date_debut)} isInvalid={getFormErrorMsg(invalidData?.date_debut)} onChange={handleChange} type="date" />
                                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.date_debut)}</Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="d-flex align-items-center">
                                        <Form.Label column sm={3}>Date de fin</Form.Label>
                                        <Col sm={9}>
                                            <Form.Control size="sm" name="date_fin" value={formattedDateInput(currentSuspension.date_fin)} isInvalid={getFormErrorMsg(invalidData?.date_fin)} onChange={handleChange} type="date" />
                                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.date_fin)}</Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                    <Row>
                                        <Col className="d-flex justify-content-end mt-2">
                                            <Button disabled={isLoading} variant="danger" className="me-auto" onClick={removeSuspension}>Supprimer</Button>
                                            <Button variant="secondary" className="me-2" onClick={() => setCurrentSuspension()}>Annuler</Button>
                                            <Button disabled={isLoading || !currentSuspension.date_debut} onClick={updateSuspension}>Enregistrer les modifications</Button>
                                        </Col>
                                    </Row>
                                </div>
                            }
                        </div>
                    ))}
                </>
            }


        </>
    );

}
export default Suspensions;