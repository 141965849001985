import React, { useEffect, useRef, useState } from 'react';
import { UsersProfilService } from '../../../services/UsersProfilService';
import { GlobalContext } from "../../../services/GlobalProvider";
import { Table, Badge, InputGroup, Alert, FormControl, Container, Button, Dropdown, DropdownButton, Form, Col, Row, Spinner, ListGroup, Tooltip, Nav, Tab } from 'react-bootstrap';
import { getFormErrorMsg, getInvalidObject, getChangedValues, updateObjectValue, deepFind } from '../../../helper/Helper'
import ErrorManager from "../../../components/error-manager/ErrorManager";
import ConfirmDialog from '../../../components/confirm-dialog/ConfirmDialog';



import './UsersProfils.scss';


export default function UsersProfils() {

    const { preferences, folderDetails, getGrants } = React.useContext(GlobalContext);
    const [confirmDialog, setConfirmDialog] = useState({ show: false, dialogInfos: null })
    const [globalError, setGlobalError] = useState()


    let [profils, setProfils] = useState()
    let [profilsGrantsListe, setProfilsGrantsListe] = useState()
    let [profilsAvailableUsers, setProfilsAvailableUsers] = useState()
    let [profilEditing, setProfilEditing] = useState()
    let [profilUpdating, setProfilUpdating] = useState(false)
    let [userSelectedList, setUserSelectedList] = useState({})

    let profilToUpdate = useRef({});

    useEffect(() => {
        if (folderDetails) {
            getAvailableUsers()
            getGrantsListe()
            getUsersProfils()
        }
    }, [folderDetails]);


    function getUsersProfils() {
        UsersProfilService.getUsersProfils(folderDetails._id, false, false).then(res => {
            setProfils(res.data.data.profils)
        }).catch(error => {
            setGlobalError(error)
        });
    }

    function getAvailableUsers() {
        UsersProfilService.getUsersProfilsAvailableUsers(folderDetails._id).then(res => {
            setProfilsAvailableUsers(res.data.data.utilisateurs)
        }).catch(error => {
            setGlobalError(error)
        });
    }

    function getGrantsListe() {
        UsersProfilService.getUsersProfilsGrantsListe(folderDetails._id).then(res => {
            setProfilsGrantsListe(res.data.data)
        }).catch(error => {
            setGlobalError(error)
        });
    }

    function handleGrantChange(e, path) {
        let grants = profilEditing.droits
        updateObjectValue(grants, path, (e.target.checked ? 1 : 0))
        profilToUpdate.current['droits'] = grants
        setProfilEditing({ ...profilEditing, droits: grants })
    }

    function handleProfilChange(e) {
        profilToUpdate.current[e.target.name] = e.target.value
    }

    function changeUserSelectedList(e, userId, profilId) {

        let profilIdArray = userSelectedList[profilId] ? userSelectedList[profilId] : []

        if (profilIdArray.indexOf(userId) > -1) {
            profilIdArray = profilIdArray.filter(id => id !== userId)
        } else {
            profilIdArray.push(userId)
        }

        if (profilIdArray.length == 0) {
            setUserSelectedList(current => {
                const copy = { ...userSelectedList };
                delete copy[profilId];
                return copy;
            });
        } else {
            setUserSelectedList({ ...userSelectedList, [profilId]: profilIdArray })
        }

    }

    function editProfil(profil) {
        setGlobalError()
        profilToUpdate.current = {}

        if (profil) {
            setProfilEditing(profil)
        } else {
            setProfilEditing({
                libelle: '',
                description: '',
                droits: getGrantsFromListe()
            })
        }
    }

    function getGrantsFromListe() {
        let grants = {}
        {
            Object.entries(profilsGrantsListe).map(([key, value], i) => (
                grants[key] = recursive(key, value)
            ))
        }
        function recursive(key, value) {
            let obj = {}
            if (typeof value == "object") {
                {
                    Object.entries(value).map(([key, value], i) => {
                        if (key == 'valeur') {
                            obj[key] = value.default
                        } else {
                            obj[key] = recursive(key, value);
                        }
                    })
                }
            }
            return obj
        }
        return grants
    }

    function cancelProfilEditing() {
        setProfilEditing()
        getUsersProfils()
        getGrantsListe()
    }

    function updateProfil() {
        setProfilUpdating(true)
        profilToUpdate.current._id = profilEditing._id
        UsersProfilService.updateUsersProfils(folderDetails._id, { profils: [profilToUpdate.current] }).then(res => {
            setProfilUpdating(false)
            getUsersProfils()
            getGrants()
        }).catch(error => {
            setProfilUpdating(false)
            setGlobalError(error)
        });
    }

    function postUserToProfil(e, userId) {
        let profilId = e.target.elements['profil'].value
        e.preventDefault();
        UsersProfilService.postUserToProfil(folderDetails._id, profilId, userId).then(res => {
            getUsersProfils()
            getGrants()
        }).catch(error => {
            setGlobalError(error)
        });
    }

    function postUserListToProfil(e, profilSource, userList) {
        let profilId = e.target.elements['profil'].value
        e.preventDefault();
        UsersProfilService.postUserListToProfil(folderDetails._id, profilId, { users: userList }).then(res => {
            getUsersProfils()
            getGrants()
            setUserSelectedList(current => {
                const copy = { ...userSelectedList };
                delete copy[profilSource];
                return copy;
            });
        }).catch(error => {
            setGlobalError(error)
        });
    }

    function putUserListToProfil(e, profilSource, userList) {
        let profilId = e.target.elements['profil'].value
        e.preventDefault();
        UsersProfilService.putUserListToProfil(folderDetails._id, profilId, { users: userList }).then(res => {
            getUsersProfils()
            getGrants()
            setUserSelectedList(current => {
                const copy = { ...userSelectedList };
                delete copy[profilSource];
                return copy;
            });
        }).catch(error => {
            setGlobalError(error)
        });
    }


    function putUserToProfil(e, userId) {
        let profilId = e.target.elements['profil'].value
        e.preventDefault();
        UsersProfilService.putUserToProfil(folderDetails._id, profilId, userId).then(res => {
            getUsersProfils()
            getGrants()
        }).catch(error => {
            setGlobalError(error)
        });
    }

    function removeUserToProfil(profilId, userId) {
        UsersProfilService.removeUserToProfil(folderDetails._id, profilId, userId).then(res => {
            getUsersProfils()
            getGrants()
            setUserSelectedList(current => {
                const copy = { ...userSelectedList };
                const index = copy[profilId]?.indexOf(userId);
                if (index > -1) {
                    copy[profilId].splice(index, 1);
                }
                return copy;
            });
        }).catch(error => {
            setGlobalError(error)
        });
    }

    function saveProfil() {
        setProfilUpdating(true)
        UsersProfilService.postUsersProfils(folderDetails._id, { profils: [profilToUpdate.current] }).then(res => {
            setProfilEditing()
            getUsersProfils()
            setProfilUpdating(false)
        }).catch(error => {
            setProfilUpdating(false)
            setGlobalError(error)
        });

    }

    function removeProfil(profil) {
        UsersProfilService.removeUsersProfils(folderDetails._id, profil._id).then(res => {
            getUsersProfils()
            setConfirmDialog({ show: false })
        }).catch(error => {
            setGlobalError(error)
            setConfirmDialog({ show: false })
        });

    }

    function removeProfilConfirm(p) {

        setConfirmDialog({
            show: true,
            dialogInfos: {
                title: 'Supprimer',
                description: `Voulez vous supprimer le profil "${p.libelle}" ?`,
                actionName: 'Supprimer',
                btnVariant: 'danger',
                element: p,
                error: false,
                confirmAction: 'removeProfil'
            }
        })

    }

    function isUserInProfile(userId) {
        for (let profil of profils) {
            for (let membre of profil.membres) {
                if (membre.user_id == userId) { return true }
            }
        }
        return false
    }

    const getUserTpl = (userId) => {

        for (let user of profilsAvailableUsers) {
            if (userId == user._id) {
                return (
                    <div className="aaa">
                        <h6 className="m-0 p-0"><i className="material-icons me-2 float-start">person</i>{user.identifiant}</h6>
                        <p className="m-0 p-0">{user.email}</p>
                        <p className="m-0 p-0 text-gray-500">{user.observation}</p>
                    </div>

                )
            }
        }


    }

    const getUserTrTpl = (userId, profilId) => {

        for (let user of profilsAvailableUsers) {
            if (userId == user._id) {
                return (
                    <>
                        <td>
                            <Form.Check
                                type="checkbox"
                                id={`default-${userId}`}
                                onChange={e => changeUserSelectedList(e, userId, profilId)}
                            />
                        </td>
                        <td>{user.identifiant}</td>
                        <td>{user.email}</td>
                        <td>{user.observation}</td>
                        <td className="text-end">

                            <Dropdown>

                                <Dropdown.Toggle size="sm" className="no-btn-caret" variant="light" id="dropdown-basic">
                                    <i className="material-icons i-lg">move_down</i>
                                </Dropdown.Toggle>



                                {profilId == "inconnu" &&
                                    <Dropdown.Menu>
                                        <Dropdown.ItemText>
                                            <Form onSubmit={e => postUserToProfil(e, userId)}>
                                                <h6>Déplacer "{user.identifiant}" vers :</h6>
                                                <div className="d-flex">
                                                    <Form.Select className="w-auto" name="profil" defaultValue={""}>
                                                        <option value="" disabled>- Choisir un profil -</option>
                                                        {profils.map(profil =>
                                                            <option key={profil._id} value={profil._id}>{profil.libelle}</option>
                                                        )}
                                                    </Form.Select>
                                                    <Button size="sm" type="submit" className="ms-2">Enregistrer</Button>
                                                </div>
                                            </Form>
                                        </Dropdown.ItemText>
                                    </Dropdown.Menu>
                                }

                                {profilId != "inconnu" &&
                                    <Dropdown.Menu>
                                        <Dropdown.ItemText>
                                            <Form onSubmit={e => putUserToProfil(e, userId)}>
                                                <h6>Déplacer "{user.identifiant}" vers :</h6>
                                                <div className="d-flex">
                                                    <Form.Select className="w-auto" name="profil" defaultValue={""}>
                                                        <option value="" disabled>- Choisir un profil -</option>
                                                        {profils.map(profil =>
                                                            <option key={profil._id} value={profil._id}>{profil.libelle}</option>
                                                        )}
                                                    </Form.Select>
                                                    <Button size="sm" type="submit" className="ms-2">Enregistrer</Button>
                                                </div>
                                            </Form>
                                        </Dropdown.ItemText>
                                        <Dropdown.Item className="text-danger mt-2" onClick={() => removeUserToProfil(profilId, userId)}>Supprimer de ce profil</Dropdown.Item>
                                    </Dropdown.Menu>
                                }


                            </Dropdown>
                        </td>
                    </>
                )
            }
        }


    }

    const EditProfil = () => {

        return (
            <div className="m-1 pb-5">

                {profilEditing._id &&
                    <div className="d-flex align-items-center mb-4">
                        <h5>Edition des droits du profil "{profilEditing.libelle}"</h5>
                        <Button className="ms-auto" disabled={profilUpdating} onClick={() => updateProfil()}>Enregistrer</Button>
                        <Button variant="secondary" className="ms-2" disabled={profilUpdating} onClick={() => cancelProfilEditing()}>Annuler</Button>
                    </div>
                }

                {!profilEditing._id &&
                    <div className="d-flex align-items-center mb-4">
                        <h5>Créer un nouveau profil utilisateur</h5>
                        <Button className="ms-auto" disabled={profilUpdating} onClick={() => saveProfil()}>Enregistrer</Button>
                        <Button variant="secondary" className="ms-2" disabled={profilUpdating} onClick={() => cancelProfilEditing()}>Annuler</Button>
                    </div>
                }

                {!profilUpdating &&
                    <>
                        <Row className="mb-4">
                            <h6>Informations du profil</h6>
                            <Col sm={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Libelle<span className="text-danger">*</span></Form.Label>
                                    <Form.Control autoComplete="off" defaultValue={profilToUpdate.current.libelle ? profilToUpdate.current.libelle : profilEditing.libelle} value={profilToUpdate.libelle} placeholder="Libelle du profil utilisateur" name="libelle" onChange={handleProfilChange} type="text" />
                                </Form.Group>
                            </Col>
                            <Col sm={8}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control autoComplete="off" defaultValue={profilToUpdate.current.description ? profilToUpdate.current.description : profilEditing.description} value={profilToUpdate.description} placeholder="Courte description du profil" name="description" onChange={handleProfilChange} type="text" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <h6>Gestion des droits</h6>
                        {Object.entries(profilEditing.droits).map(([key, value], i) => (
                            <div key={i} className="mt-4 bg-gray-100 p-4 rounded">
                                <GrantsTree mainObject={key} value={value} path={key} firstLevel={true} />
                            </div>
                        ))}
                    </>
                }

                {profilUpdating &&
                    <span className="hloader"></span>
                }

            </div>
        )
    }

    const GrantsTree = ({ mainObject, value, disabled, path, firstLevel }) => {

        let objListe = {}
        objListe[mainObject] = profilsGrantsListe[mainObject]

        let disableNext = (value.valeur == 0 || disabled == true) ? true : false

        return (
            <>

                <Form.Check
                    className={firstLevel ? "fw-bolder" : ""}
                    label={deepFind(objListe, path)?.headingSubject}
                    type="switch"
                    defaultChecked={value.valeur}
                    onChange={e => handleGrantChange(e, `${path}.valeur`)}
                    disabled={disabled}
                />
                {/* <small className="text-gray-500 mb-3">{path}</small> */}
                <div className="ms-4">
                    {Object.entries(value).filter(([key, val]) => (key !== 'valeur' && key !== 'headingSubject')).map(([key, val], i) => (
                        <GrantsTree key={i} mainObject={mainObject} value={val} disabled={disableNext} path={`${path}.${key}`} />
                    ))}
                </div>
            </>
        )
    }

    return (

        <>
            <ConfirmDialog
                show={confirmDialog.show}
                dialogInfos={confirmDialog.dialogInfos}
                setCancel={() => setConfirmDialog({ show: false })}
                setConfirm={(a, e) => eval(a)(e)}
            />

            {globalError && (
                <ErrorManager error={globalError} fixed="true" />
            )}

            <Row>
                <Col sm={5} xxl={6} className="bg-white border-start border-end position-relative">
                    <div className="height-full-min-80 px-2 py-4 py-xxl-5 px-xxl-5 pb-0 ">
                        <div className="light-v-scroll pe-3">

                            <div className="d-flex align-items-center mb-5 mt-1">
                                <h6 className="m-0">Profils utilisateur</h6>
                                <Button className="ms-auto" onClick={() => editProfil()}>Créer un profil</Button>
                            </div>

                            {(profilsAvailableUsers && profils) &&
                                <>
                                    <div className="mb-5">

                                        <span className="d-flex align-items-center mb-2">
                                            <i className="text-warning me-2 fs-4 material-icons">help_outline</i>
                                            <h6 className="m-0 me-auto">Utilisateurs</h6>
                                            {(userSelectedList["inconnu"]?.length > 0) &&
                                                <Dropdown>

                                                    <Dropdown.Toggle size="sm" className="no-btn-caret" variant="dark" id="dropdown-basic">
                                                        <i className="material-icons i-lg float-start me-2">move_down</i>
                                                        Déplacer la sélection
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.ItemText>
                                                            <Form onSubmit={e => postUserListToProfil(e, "inconnu", userSelectedList["inconnu"])}>
                                                                <h6>Déplacer les utilisateurs vers le profil :</h6>
                                                                <div className="d-flex">
                                                                    <Form.Select className="w-auto" name="profil" defaultValue={""}>
                                                                        <option value="" disabled>- Choisir un profil -</option>
                                                                        {profils.map(profil =>
                                                                            <option key={profil._id} value={profil._id}>{profil.libelle}</option>
                                                                        )}
                                                                    </Form.Select>
                                                                    <Button size="sm" type="submit" className="ms-2">Enregistrer</Button>
                                                                </div>
                                                            </Form>
                                                        </Dropdown.ItemText>
                                                    </Dropdown.Menu>
                                                </Dropdown>

                                            }
                                        </span>

                                        <p style={{ marginLeft: 24 }} className="px-2  text-gray-500">Utilisateurs sans profil attribué</p>

                                        <div style={{ marginLeft: 24 }} className="d-flex flex-column">
                                            {profilsAvailableUsers?.filter(u => !isUserInProfile(u._id)).length > 0 &&
                                                <Table className="align-middle">
                                                    <thead>
                                                        <tr>
                                                            <th className="text-uppercase text-gray-600 small"></th>
                                                            <th className="text-uppercase text-gray-600 small">Identifiant</th>
                                                            <th className="text-uppercase text-gray-600 small">Email</th>
                                                            <th className="text-uppercase text-gray-600 small">Observation</th>
                                                            <th className="text-uppercase text-gray-600 small text-end">Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {profilsAvailableUsers?.filter(u => !isUserInProfile(u._id)).map(availableUser =>
                                                            <tr key={availableUser._id}>{getUserTrTpl(availableUser._id, "inconnu")}</tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            }

                                            {profilsAvailableUsers?.filter(u => !isUserInProfile(u._id)).length == 0 &&
                                                <em>- Aucun utilisateur dans ce profil -</em>
                                            }

                                        </div>
                                    </div>


                                    {profils.map((profil, i) =>
                                        <div key={profil._id} className="mb-3">

                                            <span className="d-flex align-items-center mb-2">

                                                <i className="text-primary me-2 fs-4 material-icons">people_outline</i>
                                                <h6 className="m-0 me-auto cursor-pointer text-primary" onClick={() => editProfil(profil)}>{profil.libelle} {profil.description && <span className="text-gray-500 ms-2">({profil.description})</span>}</h6>

                                                {(userSelectedList[profil._id]?.length > 0) &&
                                                    <Dropdown className="me-2">

                                                        <Dropdown.Toggle size="sm" className="no-btn-caret" variant="dark" id="dropdown-basic">
                                                            <i className="material-icons i-lg float-start me-2">move_down</i>
                                                            Déplacer la sélection
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.ItemText>
                                                                <Form onSubmit={e => putUserListToProfil(e, profil._id, userSelectedList[profil._id])}>
                                                                    <h6>Déplacer les utilisateurs vers le profil :</h6>
                                                                    <div className="d-flex">
                                                                        <Form.Select className="w-auto" name="profil" defaultValue={""}>
                                                                            <option value="" disabled>- Choisir un profil -</option>
                                                                            {profils.map(profil =>
                                                                                <option key={profil._id} value={profil._id}>{profil.libelle}</option>
                                                                            )}
                                                                        </Form.Select>
                                                                        <Button size="sm" type="submit" className="ms-2">Enregistrer</Button>
                                                                    </div>
                                                                </Form>
                                                            </Dropdown.ItemText>
                                                        </Dropdown.Menu>
                                                    </Dropdown>

                                                }
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="secondary" className='no-btn-caret'>
                                                        <i className="material-icons i-lg">more_horiz</i>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {/* <Dropdown.Item onClick={() => setNewUserCreating(i)}>Nouvel utilisateur</Dropdown.Item> */}
                                                        <Dropdown.Item onClick={() => editProfil(profil)}>Modifier le profil & droits</Dropdown.Item>
                                                        <Dropdown.Item className="text-danger" onClick={() => removeProfilConfirm(profil)}>Supprimer</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>

                                            </span>

                                            <div style={{ marginLeft: 24 }} className="d-flex flex-column">

                                                {profil.membres.length > 0 &&
                                                    <Table className="align-middle">
                                                        <thead>
                                                            <tr>
                                                                <th className="text-uppercase text-gray-600 small"></th>
                                                                <th className="text-uppercase text-gray-600 small">Identifiant</th>
                                                                <th className="text-uppercase text-gray-600 small">Email</th>
                                                                <th className="text-uppercase text-gray-600 small">Observation</th>
                                                                <th className="text-uppercase text-gray-600 small text-end">Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {profil.membres.map(membre =>
                                                                <tr key={membre.user_id}>{getUserTrTpl(membre.user_id, profil._id)}</tr>
                                                            )}
                                                        </tbody>
                                                    </Table>
                                                }
                                                {profil.membres.length == 0 &&
                                                    <em>- Aucun utilisateur dans ce profil -</em>
                                                }

                                            </div>


                                        </div>
                                    )}
                                </>
                            }

                        </div>
                    </div>
                </Col>

                {profilEditing &&
                    <Col sm={7} xxl={6} className="bg-white border-start border-end position-relative">
                        <div className="height-full-min-80 px-2 py-4 py-xxl-5 px-xxl-5 pb-0 ">
                            <div className="light-v-scroll">
                                <EditProfil />
                            </div>
                        </div>
                    </Col>
                }
            </Row>
        </>

    );
}