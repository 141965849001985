import {axiosAPI} from "./axiosClient"
import { UserService } from './UserService';
import { useParams, useSearchParams } from "react-router-dom";
import qs from "qs";
import { getParams } from '../helper/Helper'

export const ProformaService = {

  getProformas(folderId, params) {
    return axiosAPI.get(`/${folderId}/proformas?lookup=true&${getParams(params)}`)
  },

  getProformasPDF(folderId, params) {
    return axiosAPI.get(`/${folderId}/proformas?lookup=true&${getParams(params)}&format=pdf`, {responseType: 'blob'})
  },

  getProforma(folderId, proformaId){
    return axiosAPI.get(`/${folderId}/proformas/${proformaId}?lookup=true`)
  },

  updateProforma(folderId, proformaId){
    return axiosAPI.put(`/${folderId}/proformas/${proformaId}`)
  },

  duplicateProforma(folderId, proformaId){
    return axiosAPI.post(`/${folderId}/proformas/${proformaId}/dupliquer`)
  },

  generateFacture(folderId, proformaId){
    return axiosAPI.post(`/${folderId}/proformas/${proformaId}/generate`)
  },

  facturerProforma(folderId, proformaId){
    return axiosAPI.post(`/${folderId}/proformas/${proformaId}/facturer`)
  },

  postProforma(folderId, dataPost){
    return axiosAPI.post(`/${folderId}/proformas`, qs.stringify(dataPost))
  },
  
  removeProformas(folderId, proformaIdList) {
    var params = '?proformas[]=' + proformaIdList.join('&proformas[]=');
    return axiosAPI.delete(`/${folderId}/proformas${params}`)
  },

  
}