import React, { useEffect, useRef, useState, lazy, Suspense } from 'react';
import { UsersProfilService } from '../../../services/UsersProfilService';
import { GlobalContext } from "../../../services/GlobalProvider";
import { Table, Badge, InputGroup, Alert, FormControl, Container, Button, Dropdown, DropdownButton, Form, Col, Row, Spinner, ListGroup, Tooltip, ButtonGroup, Tab } from 'react-bootstrap';
import { getFormErrorMsg, getInvalidObject, getChangedValues, updateObjectValue, deepFind } from '../../../helper/Helper'
import ErrorManager from "../../../components/error-manager/ErrorManager";
import { PDFService } from '../../../services/PDFService';
import { ParametreService } from '../../../services/ParametreService';

export default function Editions({ paramsPath, tplTitle, tplType }) {

    const { folderDetails } = React.useContext(GlobalContext);

    let [PDF, setPDF] = useState()
    let [params, setParams] = useState()
    let [partParams, setPartParams] = useState()
    let [partParamsList, setPartParamsList] = useState()
    let [paramsLoading, setParamsLoading] = useState(false)
    let [paramsListLoading, setParamsListLoading] = useState(false)
    let [previewLoading, setPreviewLoading] = useState(false)

    const [globalError, setGlobalError] = useState()

    useEffect(() => {
        getParametres()
    }, []);

    let tplPath = paramsPath.split(".").slice(1).join("/")
    const Template = lazy(() => import(`./templates/${tplPath}/Template`));

    function getParametres() {
        setParamsLoading(true)
        setParamsListLoading(true)

        ParametreService.getParametres(folderDetails._id, false, false, { 'params': 'editions' }).then(res => {
            setParamsLoading(false)
            setParams(res.data.data.parametres)
            setPartParams(deepFind(res.data.data.parametres, paramsPath))
            getPDFPreview(res.data.data.parametres)
        }).catch(error => {
            setParamsLoading(false)
            setGlobalError(error)
        });

        ParametreService.getParametresListe(folderDetails._id, { 'params': 'editions' }).then(res => {
            setParamsListLoading(false)
            setPartParamsList(deepFind(res.data.data, paramsPath))
        }).catch(error => {
            setParamsListLoading(false)
            setGlobalError(error)
        });
    }

    function updateParametresDossier() {
        setParamsLoading(true)
        ParametreService.updateParametresDossier(folderDetails._id, { params: params }).then(res => {
            setParamsLoading(false)
        }).catch(error => {
            setParamsLoading(false)
            setGlobalError(error)
        });
    }


    function getPDFPreview(params) {
        setPreviewLoading(true)
        PDFService.postPDFPreview(folderDetails._id, params, tplType).then(res => {
            const file = new Blob([res.data], { type: 'application/pdf' });
            setPDF(URL.createObjectURL(file))
            setPreviewLoading(false)
        }).catch(error => {
            setPreviewLoading(false)
            setGlobalError(error)
        });
    }

    function onNextTemplate(tplPart) {
        let current = partParams[tplPart].template_name
        let tplList = Object.keys(partParamsList[tplPart].templates);
        let index = tplList.indexOf(current)
        let nextIndex = tplList.length - 1 == index ? 0 : index + 1
        let path = paramsPath + `.${tplPart}.` + `template_name`
        updateObjectValue(params, path, tplList[nextIndex])
        setPartParams(prevState => ({
            ...prevState,
            [tplPart]: {
                ...prevState[tplPart],
                template_name: tplList[nextIndex]
            }
        }))
    }

    function onPrevTemplate(tplPart) {
        let current = partParams[tplPart].template_name
        let tplList = Object.keys(partParamsList[tplPart].templates);
        let index = tplList.indexOf(current)
        let prevIndex = index == 0 ? tplList.length - 1 : index - 1
        let path = paramsPath + `.${tplPart}.` + `template_name`
        updateObjectValue(params, path, tplList[prevIndex])
        setPartParams(prevState => ({
            ...prevState,
            [tplPart]: {
                ...prevState[tplPart],
                template_name: tplList[prevIndex]
            }
        }))
    }

    function onHandleChange(e, currentTpl) {
        let value
        e.target.type == "checkbox" ? value = e.target.checked : value = e.target.value
        currentTpl[e.target.name] = value

        updateObjectValue(params, paramsPath + `.${e.target.name}`, value)
        //setPartParams({...partParams, [e.target.name]: value});


    }

    return (
        <Row id="Editions" className="height-full-min-80">
            <Col sm={5} xxl={6} className="p-5 light-v-scroll">
                {globalError && (<ErrorManager error={globalError} />)}
                {(paramsLoading || paramsListLoading) && <span className="w-100"><span className="hloader"></span></span>}
                {((!paramsLoading && !paramsListLoading) && partParams) &&
                    <>
                        <div className="d-flex align-items-center mb-5 mt-1">
                            <h6 className="m-0">{tplTitle}</h6>
                            <Button className="ms-auto me-2" variant="secondary">Annuler</Button>
                            <Button className="" onClick={() => updateParametresDossier()}>Enregistrer</Button>
                        </div>
                        <Suspense fallback={<span className="hloader"></span>}>
                            <Template partParams={partParams} partParamsList={partParamsList} onNextTemplate={onNextTemplate} onPrevTemplate={onPrevTemplate} onHandleChange={onHandleChange} />
                        </Suspense>
                    </>
                }
            </Col>

            <Col sm={5} xxl={6} className="bg-gray-600 position-relative p-0 m-0 overflow-hidden d-flex flex-column align-items-center">
                <Button variant="warning" className="my-3 d-flex" onClick={() => getPDFPreview(params)}><i className="material-icons me-2 i-lg">refresh</i>Prévisualiser</Button>
                {previewLoading && <span className="px-5 w-100"><span className="hloader"></span></span>}
                {(!previewLoading && PDF) && <embed src={PDF} width="100%" height="100%" />}
            </Col>
        </Row>
    );
}