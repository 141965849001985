import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { UserService } from '../../services/UserService'

export default function Gettoken() {

    const navigate = useNavigate();

    // Récupération du auth code
    const [searchParams] = useSearchParams()
    const codeParam = searchParams.get("code")

    // fetch Ory (oAuth) avec le code
    const dataPost = {
        grant_type: "authorization_code",
        code: codeParam,
        client_secret: global.config._OAUTH_CLIENT_SECRET_,
        client_id: global.config._OAUTH_CLIENT_ID_
    }

    // appel de la méthode getToken (Axios)
    UserService.getToken(dataPost).then(res => {
        // copie les tokens dans le localstorage
        UserService.setTokens(res.data)
        // redirection
        navigate("/dossiers")
    }).catch(error => {
        console.log('Erreur de récupération du token')
    });

    return null;
}