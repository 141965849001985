import React, { useEffect, useState, useRef } from 'react';
import { ReglementService } from '../../../../../services/ReglementService';
import { FournisseurService } from '../../../../../services/FournisseurService';
import { EditionService } from '../../../../../services/EditionService';
import { Spinner, Card, Tabs, Tooltip, Row, Col, Accordion, Button, Badge, Alert, Form, Dropdown, OverlayTrigger, Popover, InputGroup, FormControl } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import ErrorManager from "../../../../error-manager/ErrorManager";
import ConfirmDialog from '../../../../confirm-dialog/ConfirmDialog';
import Pagination from '../../../../../components/pagination/Pagination';
import { getFormErrorMsg, currencyFormat, formattedDateInput, getFile } from '../../../../../helper/Helper'
import SendMailDialog from "../../../../../components/send-mail-dialog/SendMailDialog";


const Reglements = (props) => {

    const [reglementsData, setReglementsData] = useState()
    const [reglementsError, setReglementsError] = useState()
    const [isFilterVisible, setIsFilterVisible] = useState(false)
    const [currentPagination, setCurrentPagination] = useState(1)
    const [showForm, setShowForm] = useState(false)
    const [reglementsModes, setReglementsModes] = useState()
    const [dateDebut, setDateDebut] = useState()
    const [dateFin, setDateFin] = useState()
    const [currentMode, setCurrentMode] = useState()
    const [confirmDialog, setConfirmDialog] = useState({ show: false, dialogInfos: null })
    const [isGetReglementsLoading, setIsGetReglementsLoading] = useState(false)
    const [globalError, setGlobalError] = useState();
    const [pdfLoadingId, setPdfLoadingId] = useState();
    const [sendMailDialog, setSendMailDialog] = useState({})
    const [formEdit, setFormEdit] = useState()
    const [isSaveLoading, setIsSaveLoading] = useState(false)

    const searchInput = useRef("");

    let reglementsParams = useRef({
        'start': 0,
        'limit': 10,
        'family': null,
        'search': null,
        'filters': {
            'filter[client_id]': [{ operator: "", value: props.facture.client_id }],
            'filter[facture_id]': [{ operator: "", value: props.facture._id }]
        },
        'sort': "date",
        'order': "desc",
        'fields': "_id,date,date_valeur,facture_id,client_id,client_nom,mode_paiement_id,mode_paiement_libelle,organisme,numero_piece,montant,observation"
    });

    useEffect(() => {
        getReglements()
        getReglementsModes()
    }, []);

    useEffect(() => {
        if (props.showReglementForm) {
            setNewPayment()
        }
    }, [props.showReglementForm]);

    useEffect(() => {
        let dateFilters = []
        dateDebut && dateFilters.push({ operator: ">=", value: dateDebut })
        dateFin && dateFilters.push({ operator: "<=", value: dateFin })
        if (dateFilters.length > 0) {
            reglementsParams.current['filters']['filter[date]'] = dateFilters
        } else {
            delete reglementsParams.current['filters']['filter[date]'];
        }
    }, [dateDebut, dateFin]);

    function handleChange(e) {

        setFormEdit((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));

        if (e.target.name == "mode_paiement_id") {
            let mode = reglementsModes.find(mode => mode._id === e.target.value)
            setCurrentMode(mode)
            if (!mode.piece_comptable) {
                setFormEdit((prevState) => ({
                    ...prevState,
                    'organisme': "",
                    'numero_piece': ""
                }));
            }
        }

    }

    function setNewPayment() {
        setFormEdit({
            "date_valeur": formattedDateInput(new Date()),
            "facture_id": `${props.facture._id}`,
            "client_id": `${props.facture.client_id}`,
            "observation": "",
            "mode_paiement_id": "",
            "organisme": "",
            "numero_piece": "",
            "montant": ""
        })
        setShowForm(true)
    }

    function changePagination(p) {
        setCurrentPagination(p)
        reglementsParams.current['start'] = (reglementsParams.current.limit * p) - reglementsParams.current.limit
        getReglements()
    }

    function getReglementsModes() {
        ReglementService.getReglementsModes(props.folderId).then(res => {
            setReglementsModes(res.data.data.modespaiements)
        }).catch(error => {
            setReglementsError(error)
        });
    }

    function getReglements() {
        setIsGetReglementsLoading(true)
        setReglementsError()
        ReglementService.getReglements(props.folderId, reglementsParams.current).then(res => {
            setIsGetReglementsLoading(false)
            setReglementsData(res.data ? res.data.data : null)
        }).catch(error => {
            setIsGetReglementsLoading(false)
            setReglementsError(error)
        });
    }

    function addReglement() {
        setIsSaveLoading(true)
        ReglementService.postReglement(props.folderId, { reglements: [formEdit] }).then(res => {
            reglementsParams.current['search'] = null
            getReglements()
            setNewPayment()
            setShowForm(false)
            props.onReglementChange()
            setIsSaveLoading(false)
        }).catch(error => {
            setShowForm(false)
            setReglementsError(error)
            setIsSaveLoading(false)
        });
    }

    let searchTimeOut;
    function changeSearchValue(value) {
        window.clearTimeout(searchTimeOut);
        searchTimeOut = window.setTimeout(function () {
            reglementsParams.current['search'] = value
            setCurrentPagination(1)
            reglementsParams.current['start'] = 0
            getReglements()
        }, 1000);
    }

    function removeSearchValue() {
        searchInput.current.value = ""
        changeSearchValue()
    }

    function Split({ title, value, border }) {
        return (
            <div className={"d-flex justify-content-between py-1 "}>
                <span className="pe-4 text-gray-600">{title}</span>
                <span className="">{value}</span>
            </div>
        )
    }

    function clearDateFilters() {
        setDateDebut()
        setDateFin()
        delete reglementsParams.current['filters']['filter[date]'];
        setIsFilterVisible(false)
        setCurrentPagination(1)
        reglementsParams.current['start'] = 0
        getReglements()
    }

    function validFilters() {
        setCurrentPagination(1)
        reglementsParams.current['start'] = 0
        getReglements()
    }

    function downloadPDF(reglement) {
        setPdfLoadingId(reglement._id)
        EditionService.getReglement(props.folderId, reglement._id, "pdf").then(res => {
            if (!res.data) {
                setGlobalError({ statusText: res.statusText })
            } else {
                getFile(res.data, `Reçu`, "pdf")
            }
            setPdfLoadingId()
        }).catch(error => {
            setGlobalError(error)
            setPdfLoadingId()
        });
    }

    function sendEmail(reglement) {
        setSendMailDialog({
            "clientId": reglement.client_id,
            "folderId": props.folderId,
            "documentType": "reglements",
            "documentId": reglement._id,
            "show": true
        })
    }

    return (
        <>

            {props.isFactureLoading &&
                <span className="hloader mt-3"></span>
            }

            {reglementsError && <ErrorManager error={reglementsError} />}

            <SendMailDialog clientId={sendMailDialog.clientId} folderId={sendMailDialog.folderId} documentType={sendMailDialog.documentType} documentId={sendMailDialog.documentId} show={sendMailDialog.show} setCloseModal={() => setSendMailDialog({ show: false })} />

            {!props.isFactureLoading &&
                <>
                    {!reglementsError &&
                        <span className='mb-3 d-block'>
                            <div className="d-flex">
                                <div className="flex-fill pe-3">
                                    <InputGroup>
                                        <i className="material-icons input-icon text-gray-400">search</i>
                                        <FormControl
                                            ref={searchInput}
                                            className="rounded-start input-icon-space"
                                            placeholder="Rechercher"
                                            type="text"
                                            onChange={e => changeSearchValue(e.target.value)}
                                        />
                                        {searchInput?.current?.value &&
                                            <Button className="border border-start-0" variant="gray-100" onClick={() => removeSearchValue()}><i className="material-icons">close</i></Button>
                                        }
                                    </InputGroup>
                                </div>
                                <div className="">
                                    <OverlayTrigger
                                        placement="left"
                                        delay={{ show: 800 }}
                                        overlay={
                                            <Tooltip>
                                                Date de début et date de fin
                                            </Tooltip>
                                        }
                                    >
                                        <>
                                            <Button disabled={dateDebut || dateFin} className="" variant="secondary" onClick={() => setIsFilterVisible(!isFilterVisible)} >
                                                <i className="material-icons">date_range</i>
                                            </Button>
                                        </>
                                    </OverlayTrigger>
                                </div>
                            </div>

                            {isFilterVisible &&
                                <>
                                    <div className="pt-3 d-flex">
                                        <div className="w-50 pe-3">
                                            <InputGroup>
                                                <Form.Control size="sm" onChange={e => setDateDebut(e.target.value)} name="date_debut" placeholder="Date de début" type="date" />
                                            </InputGroup>
                                        </div>
                                        <div className="w-50 pe-3">
                                            <InputGroup >
                                                <Form.Control size="sm" onChange={e => setDateFin(e.target.value)} name="date_fin" placeholder="Date de fin" type="date" />
                                            </InputGroup>
                                        </div>
                                        <div className="d-flex">
                                            <Button size="sm" className="me-1" variant="secondary" onClick={() => clearDateFilters()}>Annuler</Button>
                                            <Button size="sm" onClick={() => validFilters()}>Appliquer</Button>
                                        </div>
                                    </div>
                                </>
                            }

                        </span>
                    }

                    <span className='mb-3 d-flex'>
                        <Button className="ms-auto" onClick={() => setNewPayment()}>Ajouter un règlement</Button>
                    </span>

                    {showForm && (
                        <div className="p-4 border rounded mt-2 mb-4">

                            <h6 className="mb-3">Nouveau règlement :</h6>

                            <Form.Group className="d-flex align-items-center">
                                <Form.Label column sm={3}>Date d'exécution</Form.Label>
                                <Col sm={9}>
                                    <Form.Control size="sm" name="date_valeur" defaultValue={formattedDateInput(formEdit.date_valeur)} onChange={handleChange} type="date" />
                                </Col>
                            </Form.Group>
                            <Form.Group className="d-flex align-items-center">
                                <Form.Label column sm={3}>Observations</Form.Label>
                                <Col sm={9}>
                                    <Form.Control size="sm" autoComplete="off" name="observation" defaultValue={formEdit.observation} onChange={handleChange} type="text" />
                                </Col>
                            </Form.Group>
                            <Form.Group className="d-flex align-items-center">
                                <Form.Label column sm={3}>Mode de paiement<span className="text-danger">*</span></Form.Label>
                                <Col sm={9}>
                                    <Form.Select autoFocus size="sm" name="mode_paiement_id" defaultValue={formEdit.mode_paiement_id} onChange={handleChange}>
                                        <option value="" disabled>- Mode de paiement -</option>
                                        {reglementsModes?.map(mode =>
                                            <option key={mode._id} value={mode._id}>{mode.libelle}</option>
                                        )}
                                    </Form.Select>
                                </Col>
                            </Form.Group>

                            {currentMode?.piece_comptable &&
                                <>
                                    <Form.Group className="d-flex align-items-center">
                                        <Form.Label column sm={3}>Organisme bancaire</Form.Label>
                                        <Col sm={9}>
                                            <Form.Control size="sm" name="organisme" defaultValue={formEdit.organisme} onChange={handleChange} type="text" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="d-flex align-items-center">
                                        <Form.Label column sm={3}>Numéro de pièce</Form.Label>
                                        <Col sm={9}>
                                            <Form.Control size="sm" name="numero_piece" defaultValue={formEdit.numero_piece} onChange={handleChange} type="text" />
                                        </Col>
                                    </Form.Group>
                                </>
                            }

                            <Form.Group className="d-flex align-items-center">
                                <Form.Label column sm={3}>Montant<span className="text-danger">*</span></Form.Label>
                                <Col sm={9}>
                                    <Form.Control size="sm" name="montant" defaultValue={formEdit.montant} onChange={handleChange} type="number" />
                                </Col>
                            </Form.Group>

                            <div className="d-flex justify-content-end mt-2">
                                <Button variant="secondary" className="me-2" onClick={() => setShowForm(false)}>Annuler</Button>
                                <Button
                                    disabled={isSaveLoading || !formEdit.mode_paiement_id || !formEdit.montant}
                                    variant="primary"
                                    className=""
                                    onClick={() => addReglement()}
                                >
                                    {isSaveLoading &&
                                        <Spinner animation="border" size="sm" className="me-2" />
                                    }
                                    Enregistrer
                                </Button>
                            </div>

                        </div>
                    )}

                    {isGetReglementsLoading &&
                        <span className="hloader mt-3"></span>
                    }

                    <ErrorManager error={globalError} />

                    {(!isGetReglementsLoading && !reglementsData?.reglements && !reglementsError) && <em>- Aucun élément trouvé -</em>}

                    {(!isGetReglementsLoading && !reglementsError && reglementsData?.reglements.length > 0) &&
                        <>
                            {reglementsData?.reglements.map(reglement => (
                                <div key={reglement._id} className="p-3 rounded bg-gray-100 mb-3">
                                    <span className="d-flex align-items-center mb-2">
                                        <h6 className="m-0">Le {new Date(reglement.date).toLocaleString()}</h6>
                                        <Dropdown className="ms-auto">
                                            <Dropdown.Toggle disabled={pdfLoadingId == reglement._id} variant="secondary" className='no-btn-caret'>
                                                {pdfLoadingId == reglement._id ?
                                                    <>
                                                        <Spinner animation="border" size="sm" className="me-2" />
                                                        Téléchargement
                                                    </>
                                                    :
                                                    <i className="material-icons i-lg">more_horiz</i>
                                                }
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => downloadPDF(reglement)}>Imprimer le reçu</Dropdown.Item>
                                                <Dropdown.Item onClick={() => sendEmail(reglement)}>Envoyer par mail</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </span>
                                    <Split border title="Nom client" value={reglement.client_nom} />
                                    <Split border title="Montant" value={currencyFormat(reglement.montant)} />
                                    <Split border title="Date valeur (date d'exécution)" value={new Date(reglement.date_valeur).toLocaleString()} />
                                    <Split border title="Mode de paiement" value={reglement.mode_paiement_libelle} />
                                    {reglement.numero_piece &&
                                        <Split border title="Numéro de pièce" value={reglement.numero_piece} />
                                    }
                                    {reglement.organisme &&
                                        <Split border title="Organisme" value={reglement.organisme} />
                                    }
                                    {reglement.observation &&
                                        <Split title="Observation" value={reglement.observation} />
                                    }
                                </div>
                            ))}
                            <Pagination className="mt-3" itemsLength={reglementsData?.total} elementsPerPage={reglementsParams.current.limit} currentPagination={currentPagination} changePagination={p => changePagination(p)} />
                        </>
                    }
                </>
            }


        </>
    );

}
export default Reglements;