import React, { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { InputGroup, FormControl, Container, Button, Dropdown, DropdownButton, Form, Col, Row, Spinner, OverlayTrigger, Tooltip, Toast, ToastContainer } from 'react-bootstrap';

import { ArticleService } from '../../services/ArticleService';
import { EditionService } from '../../services/EditionService';
import DynamicTable from '../../components/table/dynamic-table/DynamicTable';
import Pagination from '../../components/pagination/Pagination';
import SideDetails from '../../components/side-details/SideDetails';
import ArticleDetails from '../../components/side-details/article/article-details/ArticleDetails';
import ArticleEdition from '../../components/side-details/article/article-edition/ArticleEdition';
import ColManager from '../../components/table/dynamic-table/col-manager/ColManager';
import Families from '../../components/families/Families';
import './Articles.scss';
import { PreferenceService } from '../../services/PreferenceService';
import ConfirmDialog from '../../components/confirm-dialog/ConfirmDialog';
import ErrorManager from "../../components/error-manager/ErrorManager";
import TagsFilter from "../../components/tags-filter/TagsFilter";
import { GlobalContext } from "../../services/GlobalProvider";
import { formatTableHeadersFromPrefs, granted, getFile } from '../../helper/Helper'
import TagsDialog from "../../components/tags-dialog/TagsDialog";


const Articles = () => {

  const { globalContextLoaded, preferences, parametres, folderDetails, grants, getPreferences } = React.useContext(GlobalContext);

  let articlesParams = useRef({
    'subfamily': true,
    'start': 0,
    'limit': null,
    'search': null,
    'sort': null,
    'order': null,
    'filters': null,
    'fields': null
  });

  const [globalError, setGlobalError] = useState()
  const [searchParams, setSearchParams] = useSearchParams();
  const [confirmDialog, setConfirmDialog] = useState({ show: false, dialogInfos: null })
  const [articles, setArticles] = useState()
  const [currentArticle, setCurrentArticle] = useState()
  const [articleEditingId, setArticleEditingId] = useState()
  const [isArticleEditing, setIsArticleEditing] = useState(false)
  const [tableHeaders, setTableHeaders] = useState()
  const [currentPagination, setCurrentPagination] = useState(1)
  const [checkedElementIdList, setCheckedElementIdList] = useState([])
  const [isTableFilterVisible, setIsTableFilterVisible] = useState(false)
  const [sideWidth, setSideWidth] = useState()
  const [isGetArticlesLoading, setIsGetArticlesLoading] = useState()
  const [isPDFLoading, setIsPDFLoading] = useState()
  const [isFamiliesVisible, setIsFamiliesVisible] = useState()
  const [newArticleFamily, setNewArticleFamily] = useState()
  const [tagsDialog, setTagsDialog] = useState({})

  let searchRef = useRef(null);

  let currentFamily = useRef();

  const [tableRowElementActions] = useState([
    { icon: "download", title: "Télécharger (indisponible)", action: "XXXXX" },
    { icon: "label", title: "Tags (indisponible)", action: "XXXXX" }
  ])

  const [articleElementActions, setArticleElementActions] = useState([
    { icon: "download", title: "Télécharger (indisponible)", action: "XXXXX" }
  ])


  useEffect(() => {
    if (grants) {

      !grants.articles.valeur && setGlobalError({ statusText: `Articles : vous n'avez pas les droit d'accès à cette ressource.` })

      granted(grants, "articles.edit") && tableRowElementActions.push({ icon: "edit", title: "Modifier l'article", action: "editArticle" })
      granted(grants, "articles.remove") && tableRowElementActions.push({ icon: "delete_forever", title: "Supprimer", action: "removeArticleDialog" })

      granted(grants, "articles.edit") && articleElementActions.push({ icon: "edit", title: "Modifier l'article", action: "editArticle" })
      granted(grants, "articles.remove") && articleElementActions.push({ icon: "delete_forever", title: "Supprimer", action: "removeArticleDialog" })
    }
  }, [grants]);

  useEffect(() => {
    if (globalContextLoaded) {
      searchParams.get('articleId') && getArticle(searchParams.get('articleId'))
      setPreferences()
      getArticles()
    }
  }, [globalContextLoaded]);

  function setPreferences() {
    setTableHeaders(formatTableHeadersFromPrefs(preferences.articles['tableHeaders'], parametres.articles['fieldsLabel']))
    setIsFamiliesVisible(preferences.articles['showFamilies'])
    articlesParams.current['sort'] = preferences.articles['tableSort']
    articlesParams.current['order'] = preferences.articles['tableOrder']
    articlesParams.current['limit'] = preferences.articles['tableCount']
    articlesParams.current['fields'] = Object.keys(preferences.articles['tableHeaders']).join(',')
  }

  function saveTableHeadersPrefs(e) {
    const newHeadersObject = {}
    e.forEach(object => {
      newHeadersObject[object['dataTarget']] = { position: object['position'], hidden: object['hidden'] }
    });
    updatePreference({ ["tableHeaders"]: newHeadersObject })
  }

  function changeTableHeaders(e) {
    setTableHeaders(e)
    saveTableHeadersPrefs(e)
  }

  function updatePreference(prefs) {
    PreferenceService.updatePreferences(folderDetails._id, { prefs: { ['articles']: prefs } }).then(res => {
      getPreferences()
    }).catch(error => {
      setGlobalError(error)
    });
  }

  function getArticle(articleId) {
    ArticleService.getArticle(folderDetails._id, articleId).then(res => {
      if (!res.data?.data) {
        setGlobalError({ statusText: res.statusText })
      } else {
        changeCurrentArticle(res.data.data)
        setIsArticleEditing(false)
      }
    }).catch(error => {
      setGlobalError(error)
    });
  }

  function getArticles() {
    setCheckedElementIdList([])
    if (granted(grants, "articles.consult")) {
      setIsGetArticlesLoading(true)
      ArticleService.getArticles(folderDetails._id, articlesParams.current, currentFamily.current).then(res => {
        setArticles(res.data.data)
        setIsGetArticlesLoading(false)
      }).catch(error => {
        setIsGetArticlesLoading(false)
        setGlobalError(error)
      });
    }
  }

  function getArticlesPDF(idList) {
    if (granted(grants, "articles.consult")) {
      !idList && setIsPDFLoading(true)
      let params = {
        'subfamily': articlesParams.current.subfamily,
        'start': 0,
        'search': articlesParams.current.search,
        'sort': articlesParams.current.sort,
        'order': articlesParams.current.order,
        'filters': articlesParams.current.filters
      }

      if(idList){
        params['articles'] = idList
      }

      currentFamily.current ? (params['filter[famille_id]'] = currentFamily.current) : (params['filter[famille_id]'] = null)

      EditionService.getListe(folderDetails._id, params, "articles", "pdf").then(res => {
        setIsPDFLoading(false)
        if (!res?.data) {
          setGlobalError({ statusText: res.statusText })
        } else {
          getFile(res.data, `Articles - Liste`, "pdf")
        }
      }).catch(error => {
        setIsPDFLoading(false)
        setGlobalError(error)
      });
    }
  }

  function removeArticleDialog(article) {
    setConfirmDialog({
      show: true,
      dialogInfos: {
        title: article.designation,
        description: 'Voulez vous supprimer cet élément ?',
        actionName: 'Supprimer',
        btnVariant: 'danger',
        element: [article._id],
        error: false,
        confirmAction: 'removeArticleList'
      }
    })
  }

  function removeArticleListDialog(articleIdList) {
    setConfirmDialog({
      show: true,
      dialogInfos: {
        title: `Supprimer ${articleIdList.length} article(s) ?`,
        description: 'Voulez vous supprimer les éléments sélectionnés ?',
        actionName: 'Supprimer la sélection',
        btnVariant: 'danger',
        element: articleIdList,
        error: false,
        confirmAction: 'removeArticleList'
      }
    })
  }

  function changeCurrentArticle(article) {
    article?._id ? setSearchParams({ articleId: article._id }) : setSearchParams(searchParams.delete('articleId'))
    setCurrentArticle(article)
  }

  function removeArticleList(articleIdList) {
    ArticleService.removeArticles(folderDetails._id, articleIdList).then(res => {
      setConfirmDialog({ show: false });
      getArticles()
      changeCurrentArticle()
      setSideWidth()
      uncheckElementList(articleIdList)
    }).catch(err => {
      setConfirmDialog({
        ...confirmDialog,
        dialogInfos: { ...confirmDialog.dialogInfos, error: err }
      })
    });
  }

  function uncheckElementList(idList) {
    let checkedElementIdListCopy = [...checkedElementIdList];
    idList.map(id => {
      let index = checkedElementIdListCopy.indexOf(id)
      if (index >= 0) {
        checkedElementIdListCopy.splice(index, 1)
      }
    })
    setCheckedElementIdList(checkedElementIdListCopy)
  }

  function changePagination(p) {
    setCurrentPagination(p)
    articlesParams.current['start'] = (articlesParams.current.limit * p) - articlesParams.current.limit
    getArticles()
  }

  function changeLimit(l) {
    articlesParams.current['limit'] = l
    updatePreference({ ["tableCount"]: l })
    changePagination(1)
  }

  function changeFamily(f) {
    setCurrentPagination(1)
    articlesParams.current['start'] = 0
    currentFamily.current = f
    getArticles()
  }

  let searchTimeOut;
  function changeSearchValue(value) {
    window.clearTimeout(searchTimeOut);
    searchTimeOut = window.setTimeout(function () {
      setCurrentPagination(1)
      articlesParams.current['start'] = 0
      articlesParams.current['search'] = value ? value : ""
      searchRef.current.value = value ? value : ""
      getArticles()
    }, 1000);
  }

  let filtersTimeOut;
  function changeFilters(f) {
    window.clearTimeout(filtersTimeOut);
    filtersTimeOut = window.setTimeout(function () {
      setCurrentPagination(1)
      articlesParams.current['start'] = 0
      articlesParams.current['filters'] = f
      getArticles()
    }, 1000);
  }

  function changeTagIdList(tagIdList) {
    let filtersCopy = { ...articlesParams?.current['filters'] }
    if (tagIdList.length > 0) {

      let tagArray = []
      tagIdList.map(tagId => {
        tagArray.push(tagId)
      })

      if (!filtersCopy[`filter[tags]`]) {
        filtersCopy[`filter[tags]`] = []
      }

      filtersCopy[`filter[tags]`] = [
        {
          "operation": "equal_to",
          "operator": "=",
          "value": tagArray.toString()
        }
      ]
      changeFilters(filtersCopy)
      setIsTableFilterVisible(true)
    } else {
      if (filtersCopy && filtersCopy[`filter[tags]`]) {
        delete filtersCopy[`filter[tags]`];
        changeFilters(filtersCopy)
      }
    }
  }

  function clearFilters() {
    setCurrentPagination(1)
    articlesParams.current['start'] = 0
    articlesParams.current['filters'] = null
    getArticles()
    setIsTableFilterVisible(false)
  }

  function changeSortOrder(s, o) {
    articlesParams.current['sort'] = s
    articlesParams.current['order'] = o
    updatePreference({ ["tableSort"]: s, ["tableOrder"]: o })
    getArticles()
  }

  function onArticleSaved(articleId) {
    getArticle(articleId)
    getArticles()
  }

  function onTabChange() {
    getArticles()
  }

  function closeSide() {
    setIsArticleEditing(false)
    setCurrentArticle()
  }

  function cancelEdition() {
    setIsArticleEditing(false)
    !currentArticle && setSideWidth()
  }

  function createNewArticle(family) {
    family ? setNewArticleFamily(family) : setNewArticleFamily()
    setIsArticleEditing(true)
    setArticleEditingId()
  }

  function setTags() {
    setTagsDialog({
      "show": true,
      "folderId": folderDetails._id,
      "documentIdList": checkedElementIdList
    })
  }

  function setCloseTagsDialog() {
    setTagsDialog({ show: false })
    getArticles()
  }

  function editArticle(article) {
    setIsArticleEditing(true)
    setArticleEditingId(article._id)
  }

  function changeIsFamiliesVisible(value) {
    setIsFamiliesVisible(value)
    updatePreference({ ["showFamilies"]: value })
  }


  return (
    <Container fluid id="Articles" className="py-4">

      <div className="pb-2 d-flex align-items-center justify-content-between">
        <h4 className="">Articles</h4>
        {granted(grants, "articles.edit") &&
          <Button variant="primary" className="ms-2" onClick={() => createNewArticle()}>Créer un article</Button>
        }
      </div>

      <ErrorManager error={globalError} fixed="true" />

      <ConfirmDialog
        show={confirmDialog.show}
        dialogInfos={confirmDialog.dialogInfos}
        setCancel={() => setConfirmDialog({ show: false })}
        setConfirm={(a, e) => eval(a)(e)}
      />

      <TagsDialog documentIdList={tagsDialog.documentIdList} documentType={"articles"} folderId={tagsDialog.folderId} show={tagsDialog.show} setCloseModal={setCloseTagsDialog} />


      <Row>
        {(isFamiliesVisible && granted(grants, "familles.consult")) &&
          <Col xs="12" lg="3" xl="2" className="ps-3">
            <Families currentFamily={currentFamily.current} changeFamily={f => changeFamily(f)} createNewArticle={f => createNewArticle(f)} setIsFamiliesVisible={e => changeIsFamiliesVisible(e)} />
          </Col>
        }

        <Col xs="12" lg={isFamiliesVisible ? "9" : 12} xl={isFamiliesVisible ? "10" : 12}>
          <Row>
            {granted(grants, "articles.consult") &&
              <>
                <Col xs="3" className="d-flex">
                  {!isFamiliesVisible &&
                    <OverlayTrigger placement="right" delay={{ show: 800 }} overlay={<Tooltip>Afficher les familles</Tooltip>}>
                      <Button className="d-flex me-2" variant="secondary" onClick={() => changeIsFamiliesVisible(true)} >
                        <i className="material-icons me-2">west</i>
                        Familles
                      </Button>
                    </OverlayTrigger>
                  }
                  <InputGroup>
                    <i className="material-icons input-icon text-gray-400">search</i>
                    <FormControl
                      className="input-icon-space rounded-start"
                      placeholder="Recherche globale"
                      type="text"
                      onChange={e => changeSearchValue(e.target.value)}
                      ref={searchRef}
                    />
                    {articlesParams.current['search'] &&
                      <Button onClick={() => changeSearchValue()} variant="gray-100" className="border border-start-0">
                        <i className="material-icons i-lg">clear</i>
                      </Button>
                    }
                  </InputGroup>
                </Col>
                <Col className="d-flex">
                  <InputGroup className="w-auto">

                    <TagsFilter documentType="clients" changeTagIdList={tagIdList => changeTagIdList(tagIdList)} parentFilters={articlesParams?.current['filters']} />

                    {!articlesParams.current.filters &&
                      <OverlayTrigger placement="right" delay={{ show: 800 }} overlay={<Tooltip>Filtres de recherche</Tooltip>}>
                        <Button className="me-2" variant="secondary" onClick={() => setIsTableFilterVisible(!isTableFilterVisible)}>
                          <i className="material-icons i-lg">filter_alt</i>
                        </Button>
                      </OverlayTrigger>
                    }

                    {articlesParams.current.filters &&
                      <Button className="me-2" variant="warning" onClick={() => clearFilters()}>
                        Suppr. les filtres
                        <i className="material-icons i-lg ms-2 d-inline-flex">clear</i>
                      </Button>
                    }
                  </InputGroup>
                  <ColManager tableHeaders={tableHeaders} changeTableHeaders={e => changeTableHeaders(e)} />
                  <Button disabled={isPDFLoading} variant="secondary" className="me-2" onClick={() => getArticlesPDF()}>
                    {!isPDFLoading && <i className="material-icons i-lg float-start me-2">print</i>}
                    {isPDFLoading && <Spinner animation="border" size="sm" className="me-2" />}
                    Imprimer la liste ({articles?.total})
                  </Button>

                  {checkedElementIdList?.length > 0 &&
                    <Dropdown className="d-inline-block">
                      <Dropdown.Toggle variant="dark" id="dropdown-selectionnes">
                        {checkedElementIdList.length} Sélectionné{checkedElementIdList?.length > 1 && <span>s</span>}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {/* <Dropdown.Item onClick={() => setCheckedElementIdList([])}>Tout désélectionner</Dropdown.Item> */}
                        {granted(grants, "tags.edit") &&
                          <Dropdown.Item onClick={() => setTags()}>Tags</Dropdown.Item>
                        }
                        {/* <Dropdown.Item>Changer la famille (indisponible)</Dropdown.Item> */}
                        {granted(grants, "articles.remove") &&
                          <>
                            <Dropdown.Item onClick={() => getArticlesPDF(checkedElementIdList)}>Imprimer la liste sélectionnée</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item className="text-danger" onClick={() => removeArticleListDialog(checkedElementIdList)}>Supprimer</Dropdown.Item>
                          </>
                        }
                      </Dropdown.Menu>
                    </Dropdown>
                  }
                </Col>
              </>
            }

          </Row>

          {granted(grants, "articles.consult") &&
            <Row className="mt-3">
              <Col xs="12">
                <div className="p-3 bg-white rounded border overflow-auto">
                  <DynamicTable
                    isDataLoading={isGetArticlesLoading}
                    elementActions={tableRowElementActions}
                    setElementAction={(a, e) => eval(a)(e)}
                    sideWidth={sideWidth}
                    tableHeaders={tableHeaders}
                    tableDataList={articles?.articles}
                    currentElement={currentArticle}
                    changeCurrentElement={e => changeCurrentArticle(e)}
                    checkedElementIdList={checkedElementIdList}
                    setCheckedElementIdList={e => setCheckedElementIdList(e)}
                    changeFilters={f => changeFilters(f)}
                    isTableFilterVisible={isTableFilterVisible}
                    changeSortOrder={(s, o) => changeSortOrder(s, o)}
                    params={articlesParams.current}
                  />
                  <Pagination itemsLength={articles?.total} elementsPerPage={articlesParams.current.limit} currentPagination={currentPagination} changePagination={p => changePagination(p)} showChangeLimit changeLimit={l => changeLimit(l)} />
                </div>
              </Col>
            </Row>
          }

          {(currentArticle || isArticleEditing) &&
            <SideDetails
              editMode={isArticleEditing}
              currentElement={isArticleEditing ? null : { id: currentArticle?._id, label: currentArticle?.code, title: currentArticle?.designation }}
              changeCurrentElement={e => changeCurrentArticle(e)}
              setSideClose={closeSide}
              setSideCancel={cancelEdition}
              setSideWidth={e => setSideWidth(e)}
              detailsComponent={
                isArticleEditing ?
                  <ArticleEdition folderId={folderDetails._id} articleEditingId={articleEditingId} newArticleFamily={newArticleFamily} onSetSaved={a => onArticleSaved(a)} />
                  :
                  <ArticleDetails folderId={folderDetails._id} articleId={currentArticle._id} elementActions={articleElementActions} setElementAction={(a, e) => eval(a)(e)} onTabChange={() => onTabChange()} />
              }
            />
          }

        </Col>
      </Row>

    </Container>
  );
}

export default Articles;
