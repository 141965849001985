import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Button, Form } from 'react-bootstrap';

const TextHighlight = (props) => {

    const [parts, setParts] = useState(null)

    useEffect(() => {
        const parts = props.text.split(new RegExp(`(${props.value})`, 'gi'));
        setParts(parts)
    }, [props.value]);

    return (
        <>
            { parts && parts.map((part, i) => 
                <span key={i} className={part.toLowerCase() === props.value?.toLowerCase() ? 'highlight' : '' }>
                    { part }
                </span>)
            }
        </>
    )
}

export default TextHighlight;
