import React, { useEffect, useState } from "react";
import { Container, Button, Dropdown, DropdownButton, Form, Col, Row, Alert, Label, Modal, Spinner, InputGroup } from 'react-bootstrap';
import { GlobalContext } from "../../../services/GlobalProvider";
import ErrorManager from "../../../components/error-manager/ErrorManager";
import { FactureService } from '../../../services/FactureService';
import { EditionService } from '../../../services/EditionService';
import { formatTableHeadersFromPrefs, granted, getFile } from '../../../helper/Helper'
import SendMailDialog from "../../../components/send-mail-dialog/SendMailDialog";


const ModalValidation = (props) => {

    const { folderDetails } = React.useContext(GlobalContext);
    const [globalError, setGlobalError] = useState()
    const [sendMailDialog, setSendMailDialog] = useState({})

    const [generatedIdDocument, setGeneratedIdDocument] = useState()
    const [generatedInfo, setGeneratedInfo] = useState()
    const [validationStatus, setValidationStatus] = useState()

    useEffect(() => {
        const responseIdDocument = Object.keys(props.postValidateResponse.data.data[props.currentBrouillon.type])[0]
        const responseStatus = props.postValidateResponse.data.data[props.currentBrouillon.type][responseIdDocument].status //  no_change   update

        setValidationStatus(props.postValidateResponse.data.data[props.currentBrouillon.type][responseIdDocument].status)
        if (responseStatus == "no_change") {
            setGeneratedInfo(props.postValidateResponse.data.data[props.currentBrouillon.type][responseIdDocument][props.currentBrouillon.type][0])
        }
        if (responseStatus == "update" || responseStatus == "create") {
            setGeneratedIdDocument(props.postValidateResponse.data.data[props.currentBrouillon.type][responseIdDocument][props.currentBrouillon.type][0])
        }
    }, []);

    function sendEmail() {
        setSendMailDialog({
            "clientId": props.currentBrouillon?.client_id,
            "folderId": folderDetails._id,
            "documentType": props.currentBrouillon?.type + "s",
            "documentId": generatedIdDocument,
            "show": true
        })
    }

    function downloadFacturePDF() {
        EditionService.getFacture(folderDetails._id, generatedIdDocument, "pdf").then(res => {
            if (!res.data) {
                setGlobalError({ statusText: res.statusText })
            } else {
                getFile(res.data, `Facture`, "pdf")
                //getFile(res.data, `Facture ${facture.code} - ${facture.client_nom}`)
            }
        }).catch(error => {
            setGlobalError(error)
        });
    }

    function downloadProformaPDF() {
        EditionService.getProforma(folderDetails._id, generatedIdDocument, "pdf").then(res => {
            if (!res.data) {
                setGlobalError({ statusText: res.statusText })
            } else {
                getFile(res.data, `Proforma`, "pdf")
                //getFile(res.data, `Facture ${facture.code} - ${facture.client_nom}`)
            }
        }).catch(error => {
            setGlobalError(error)
        });
    }

    return (
        <>

            <SendMailDialog clientId={sendMailDialog.clientId} folderId={sendMailDialog.folderId} documentType={sendMailDialog.documentType} documentId={sendMailDialog.documentId} show={sendMailDialog.show} setCloseModal={() => setSendMailDialog({ show: false })} />

            <Modal show centered>

                <Modal.Header className="border-0 pb-0">
                    {props.currentBrouillon?.type == "facture" &&
                        <>La facture a bien été validée.</>
                    }
                    {props.currentBrouillon?.type == "proforma" &&
                        <>La proforma a bien été validée.</>
                    }
                    {props.currentBrouillon?.type == "avoir" &&
                        <>L'avoir a bien été validé.</>
                    }
                    {props.currentBrouillon?.type == "abonnement" &&
                        <>L'abonnement a bien été validé.</>
                    }
                </Modal.Header>

                <Modal.Body className="">
                    <ErrorManager error={globalError} />
                    {generatedInfo &&
                        <Alert variant="info">
                            {generatedInfo}
                        </Alert>
                    }

                    {generatedIdDocument &&
                        <div className="">
                            {props.currentBrouillon?.type == "facture" &&
                                <Button className="w-100 mb-2" variant="primary" onClick={() => downloadFacturePDF()}>Imprimer la facture</Button>
                            }
                            {props.currentBrouillon?.type == "proforma" &&
                                <Button className="w-100 mb-2" variant="primary" onClick={() => downloadProformaPDF()}>Imprimer la proforma</Button>
                            }
                            {(props.currentBrouillon?.type == "proforma" || props.currentBrouillon?.type == "facture") &&
                                <Button className="w-100 mb-2" variant="secondary" onClick={() => sendEmail()}>Envoyer par mail</Button>
                            }
                        </div>
                    }
                </Modal.Body>

                <Modal.Footer className="border-0">
                    <Button variant="secondary" onClick={() => props.setClose(generatedIdDocument)} >
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal >
        </>
    )
}
export default ModalValidation;
