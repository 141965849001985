import React, { useEffect, useRef, useState } from 'react';
import { UsersProfilService } from '../../../services/UsersProfilService';
import { GlobalContext } from "../../../services/GlobalProvider";
import { Table, Badge, InputGroup, Alert, FormControl, Container, Button, Dropdown, DropdownButton, Form, Col, Row, Spinner, ListGroup, Tooltip, Nav, Tab } from 'react-bootstrap';
import { getFormErrorMsg, getInvalidObject, getChangedValues, updateObjectValue, deepFind } from '../../../helper/Helper'
import ErrorManager from "../../../components/error-manager/ErrorManager";
import { ReglementService } from '../../../services/ReglementService';



export default function ModesReglements() {

    const { preferences, folderDetails } = React.useContext(GlobalContext);

    let [reglementsModes, setReglementsModes] = useState()
    const [globalError, setGlobalError] = useState()
    const [formEdit, setFormEdit] = useState()
    const [formEditInitial, setFormEditInitial] = useState()
    const [formNew, setFormNew] = useState()

    useEffect(() => {
        getReglementsModes()
    }, []);


    function getReglementsModes() {
        ReglementService.getReglementsModes(folderDetails._id).then(res => {
            setReglementsModes(res.data.data.modespaiements)
            setFormEdit()
            setFormEditInitial()
        }).catch(error => {
            setGlobalError(error)
        });
    }

    function updateMode() {
        ReglementService.updateReglementMode(folderDetails._id, { modespaiements: [getChangedValues(formEdit, formEditInitial, [], ["_id"], [])] }).then(res => {
            getReglementsModes()
        }).catch(error => {
            setGlobalError(error)
        });
    }

    function removeMode(mode) {
        ReglementService.removeReglementMode(folderDetails._id, mode._id).then(res => {
            getReglementsModes()
        }).catch(error => {
            setGlobalError(error)
        });
    }

    function saveMode() {
        ReglementService.postReglementMode(folderDetails._id, { modespaiements: [formNew] }).then(res => {
            getReglementsModes()
            setFormNew()
        }).catch(error => {
            setGlobalError(error)
        });
    }

    function handleChangeEdit(e) {
        let value
        e.target.type == "checkbox" ? value = e.target.checked : value = e.target.value
        setFormEdit({ ...formEdit, [e.target.name]: value })
    }

    function handleChangeNew(e) {
        let value
        e.target.type == "checkbox" ? value = e.target.checked : value = e.target.value
        setFormNew({ ...formNew, [e.target.name]: value })
    }

    function editMode(m) {
        const form = {
            "_id": m._id,
            "libelle": m.libelle,
            "piece_comptable": m.piece_comptable,
            "compte_comptable": m.compte_comptable
        }
        setFormEdit(form)
        setFormEditInitial(form)
    }

    function createNewMode() {
        setFormNew({
            "libelle": "",
            "piece_comptable": "",
            "compte_comptable": ""
        })
    }

    function cancelNewMode() {
        setFormEdit()
        setFormNew()
    }

    return (

        <Row>
            <Col xl={8} xxl={6} className="bg-white border-start border-end position-relative">
                {globalError && (
                    <Col xs="12" className="mt-2">
                        <ErrorManager fixed error={globalError} />
                    </Col>
                )}
                <div className="height-full-min-80 px-2 py-4 py-xxl-5 px-xxl-5 pb-0 ">
                    <div className="light-v-scroll">
                        <div className="d-flex align-items-center mb-5 mt-1">
                            <h6 className="m-0">Modes de règlements</h6>
                            <Button className="ms-auto" onClick={() => createNewMode()}>Créer</Button>
                        </div>
                        <div className="d-flex flex-column align-items-center mb-5 mt-1">

                            {formNew &&
                                <div className="border d-flex flex-column rounded mb-5 p-3 w-100">
                                    <div className="d-flex flex-row align-items-center">
                                        <h6>Nouveau mode de règlement</h6>
                                        <Button variant="secondary" className="ms-auto align-self-start" onClick={() => cancelNewMode()}>Annuler</Button>
                                        <Button className="ms-2 align-self-start" onClick={() => saveMode()}>Enregistrer</Button>
                                    </div>

                                    <div>
                                        <Form.Group className="mb-2">
                                            <Form.Label>Libellé :</Form.Label>
                                            <Form.Control name="libelle" placeholder="Libellé" value={formNew.libelle} onChange={handleChangeNew} type="text" />
                                        </Form.Group>
                                        <Form.Group className="mb-2">
                                            <Form.Label>Compte comptable :</Form.Label>
                                            <Form.Control name="compte_comptable" placeholder="Compte comptable" value={formNew.compte_comptable} onChange={handleChangeNew} type="text" />
                                        </Form.Group>
                                        <Form.Check type="checkbox">
                                            <Form.Check.Input name="piece_comptable" value={formNew.piece_comptable} checked={formNew.piece_comptable} onChange={handleChangeNew} type="checkbox" />
                                            <Form.Check.Label>Demander les pièces comptables (organisme bancaire et numéro de pièce)</Form.Check.Label>
                                        </Form.Check>
                                    </div>

                                </div>
                            }

                            {reglementsModes?.map((mode, i) =>
                                <div key={i} className="border d-flex flex-column rounded mb-4 p-3 w-100">

                                    {/* {
    "_id": "4f4c2318c8",
    "created_at": "2024-02-26 07:33:04",
    "modified_at": "2024-02-26 07:33:04",
    "libelle": "Cheque",
    "piece_comptable": true,
    "compte_comptable": 0
} */}
                                    {formEdit?._id != mode._id &&
                                        <>
                                            <div className="d-flex flex-row align-items-center">
                                                <h6>{mode.libelle}</h6>
                                                <Dropdown className="ms-auto">
                                                    <Dropdown.Toggle disabled={formEdit?._id || formNew} variant="secondary" className='no-btn-caret'>
                                                        <i className="material-icons i-lg">more_horiz</i>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={() => editMode(mode)}>Modifier</Dropdown.Item>
                                                        <Dropdown.Item onClick={() => removeMode(mode)} className="text-danger">Supprimer</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>

                                            <div>
                                                <p className="mb-0">Pièce comptable : {mode.piece_comptable ? <Badge>Oui</Badge> : <Badge bg="secondary">Non</Badge>}</p>
                                                <p className="mb-0">Compte comptable : {mode.compte_comptable}</p>
                                            </div>
                                        </>
                                    }

                                    {(formEdit?._id == mode._id) &&
                                        <>
                                            <div className="d-flex flex-row align-items-center">
                                                <h6>{mode.libelle}</h6>
                                                <Button variant="secondary" className="ms-auto align-self-start" onClick={() => setFormEdit()}>Annuler</Button>
                                                <Button className="ms-2 align-self-start" onClick={() => updateMode()}>Enregistrer</Button>
                                            </div>

                                            <div>
                                                <Form.Group className="mb-2">
                                                    <Form.Label>Libellé :</Form.Label>
                                                    <Form.Control name="libelle" placeholder="Libellé" value={formEdit.libelle} onChange={handleChangeEdit} type="text" />
                                                </Form.Group>
                                                <Form.Group className="mb-2">
                                                    <Form.Label>Compte comptable :</Form.Label>
                                                    <Form.Control name="compte_comptable" placeholder="Compte comptable" value={formEdit.compte_comptable} onChange={handleChangeEdit} type="text" />
                                                </Form.Group>
                                                <Form.Check type="checkbox">
                                                    <Form.Check.Input name="piece_comptable" value={formEdit.piece_comptable} checked={formEdit.piece_comptable} onChange={handleChangeEdit} type="checkbox" />
                                                    <Form.Check.Label>Demander les pièces comptables (organisme bancaire et numéro de pièce)</Form.Check.Label>
                                                </Form.Check>
                                            </div>

                                        </>
                                    }
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Col>

        </Row>

    );
}

