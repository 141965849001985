import React, { useEffect, useState } from 'react';
import { ArticleService } from '../../../../../services/ArticleService';
import { FournisseurService } from '../../../../../services/FournisseurService';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import ErrorManager from "../../../../error-manager/ErrorManager";
import ConfirmDialog from '../../../../confirm-dialog/ConfirmDialog';
import { getFormErrorMsg, getInvalidObject, formattedDateInput, getChangedValues } from '../../../../../helper/Helper'
import { Form, Spinner, Card, Tabs, Tab, Row, Col, Accordion, Button, OverlayTrigger, Alert, FormControl, Dropdown, InputGroup, Table, Popover } from 'react-bootstrap';


const Fournisseurs = (props) => {

    const [globalError, setGlobalError] = useState()

    const [getArticleFournisseursLoading, setGetArticleFournisseursLoading] = useState(false)
    const [articleFournisseursList, setArticleFournisseursList] = useState()
    const [fournisseursList, setFournisseursList] = useState()
    const [confirmDialog, setConfirmDialog] = useState({ show: false, dialogInfos: null })
    const [isGetFournisseursLoading, setIsGetFournisseursLoading] = useState(true)
    const [invalidData, setInvalidData] = useState()
    const [currentFournisseur, setCurrentFournisseur] = useState()
    const [addingFournisseur, setAddingFournisseur] = useState(false)
    const [currentFournisseurChanges, setCurrentFournisseurChanges] = useState()

    useEffect(() => {
        let fournisseurInitialIndex = props.article?.fournisseurs?.findIndex(f => f.fournisseur_id == currentFournisseur?.fournisseur_id)
        fournisseurInitialIndex > -1 && setCurrentFournisseurChanges(getChangedValues(currentFournisseur, props.article?.fournisseurs[fournisseurInitialIndex], [], ["fournisseur_id"]))
    }, [currentFournisseur]);

    useEffect(() => {
        getArticleFournisseurs()
        getFournisseurs()
    }, []);

    function getArticleFournisseurs() {
        setGetArticleFournisseursLoading(true)
        setGlobalError()
        ArticleService.getFournisseurs(props.folderId, props.article._id).then(res => {
            setGetArticleFournisseursLoading(false)
            setArticleFournisseursList(res?.data?.data?.fournisseurs ? res.data.data.fournisseurs : null)
        }).catch(error => {
            setGetArticleFournisseursLoading(false)
            setGlobalError(error)
        });

    }

    function getFournisseurs() {
        setIsGetFournisseursLoading(true)
        setGlobalError()
        FournisseurService.getFournisseurs(props.folderId).then(res => {
            setFournisseursList(res?.data?.data?.fournisseurs)
            setIsGetFournisseursLoading(false)
        }).catch(error => {
            setIsGetFournisseursLoading(false)
            setGlobalError(error)
        });
    }

    function postFournisseur() {
        ArticleService.postFournisseur(props.folderId, props.article._id, { fournisseurs: [currentFournisseur] }).then(res => {
            setCurrentFournisseur()
            setGlobalError()
            props.onFournisseurChange()
            getArticleFournisseurs()
            setAddingFournisseur(false)
        }).catch(error => {
            setGlobalError(error)
            setInvalidData(getInvalidObject(error.data?.data))
        });
    }

    function updateFournisseur() {
        ArticleService.updateFournisseur(props.folderId, props.article._id, { fournisseurs: [currentFournisseurChanges] }).then(res => {
            setCurrentFournisseur()
            setGlobalError()
            props.onFournisseurChange()
            getArticleFournisseurs()
        }).catch(error => {
            setGlobalError(error)
            setInvalidData(getInvalidObject(error.data?.data))
        });
    }

    function removeFournisseurConfirm(f) {
        setConfirmDialog({
            show: true,
            dialogInfos: {
                title: 'Supprimer',
                description: `Voulez vous supprimer ${f.fournisseur.nom} ?`,
                actionName: 'Supprimer',
                btnVariant: 'danger',
                element: f,
                error: false,
                confirmAction: 'removeFournisseur'
            }
        })
    }

    function removeFournisseur(f) {
        ArticleService.removeFournisseur(props.folderId, props.article._id, f.fournisseur_id).then(res => {
            getArticleFournisseurs()
            setConfirmDialog({ show: false })
            setCurrentFournisseur()
        }).catch(error => {
            setGlobalError(error)
            setConfirmDialog({ show: false })
            setCurrentFournisseur()
        });
    }

    function editNewFournisseur() {
        setAddingFournisseur(true)
        setCurrentFournisseur({
            fournisseur_id: '',
            reference_fournisseur: '',
            type_transport: '',
            cout_transport: '',
            prix_achat: '',
            devise: '',
            type_transport: '',
            valeur_devise: '',
            prix_revient: '',
            date_achat: ''
        })
    }

    function popAdresse(adresse) {
        return (
            <Popover id="popover-basic">
                <Popover.Header as="h3">{adresse.adresse}</Popover.Header>
                <Popover.Body>
                    <span className="me-2 d-block"><b>Libelle : </b>{adresse.libelle}</span>
                    <span className="me-2 d-block"><b>CP : </b>{adresse.code_postal}</span>
                    <span className="me-2 d-block"><b>Ville : </b>{adresse.ville}</span>
                    <span className="me-2 d-block"><b>Pays : </b>{adresse.pays}</span>
                    <span className="me-2 d-block"><b>Adresse principale : </b>{adresse.principal ? 'oui' : 'non'}</span>
                    <span className="me-2 d-block"><b>Observation : </b>{adresse.observation}</span>
                </Popover.Body>
            </Popover>

        )
    }

    function handleChange(e) {
        let value
        e.target.type == "checkbox" ? value = e.target.checked : value = e.target.value
        setCurrentFournisseur({ ...currentFournisseur, [e.target.name]: value })
        setInvalidData({ ...invalidData, [e.target.name]: null })
    }

    function cancelChanges() {
        setAddingFournisseur()
        setCurrentFournisseur()
    }

    return (
        <>

            <ConfirmDialog
                show={confirmDialog.show}
                dialogInfos={confirmDialog.dialogInfos}
                setCancel={() => setConfirmDialog({ show: false })}
                setConfirm={(a, e) => eval(a)(e)}
            />

            {globalError && <ErrorManager error={globalError} />}

            {!getArticleFournisseursLoading &&
                <div className="d-flex justify-content-between mb-3 ">
                    <Button disabled={currentFournisseur} className="ms-auto" onClick={() => editNewFournisseur()}>Ajouter un fournisseur</Button>
                </div>
            }

            {(!isGetFournisseursLoading && !fournisseursList) &&
                <Alert variant="warning">Aucun fournisseur existant, veuillez <a href={`/${props.folderId}/fournisseurs`}>créer un fournisseur</a> pour l'ajouter.</Alert>
            }

            {getArticleFournisseursLoading && <span className="hloader"></span>}

            {(!getArticleFournisseursLoading && !articleFournisseursList && !globalError) && <em>- Aucun élément trouvé -</em>}

            {(currentFournisseur && addingFournisseur) &&
                <div className="p-4 border rounded mt-2 mb-4">
                    <Form.Group className="d-flex align-items-center">
                        <Form.Label column sm={4}>Nom</Form.Label>
                        <Col sm={8}>
                            <Form.Select size="sm" /* disabled={f.fournisseur} */ name="fournisseur_id" value={currentFournisseur.fournisseur_id} isInvalid={getFormErrorMsg(invalidData?.fournisseur_id)} onChange={handleChange}>
                                <option>- Sélectionner -</option>
                                {fournisseursList?.map(f => (
                                    <option disabled={articleFournisseursList && articleFournisseursList.findIndex(af => af.fournisseur_id == f._id) != -1} key={f._id} value={f._id}>{f.nom}</option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.fournisseur_id)}</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group className="d-flex align-items-center">
                        <Form.Label column sm={4}>Réf. Fournisseur</Form.Label>
                        <Col sm={8}>
                            <Form.Control size="sm" name="reference_fournisseur" value={currentFournisseur.reference_fournisseur} isInvalid={getFormErrorMsg(invalidData?.reference_fournisseur)} onChange={handleChange} type="text" />
                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.reference_fournisseur)}</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group className="d-flex align-items-center">
                        <Form.Label column sm={4}>Prix d'achat</Form.Label>
                        <Col sm={8}>
                            <Form.Control size="sm" name="prix_achat" value={currentFournisseur.prix_achat} isInvalid={getFormErrorMsg(invalidData?.prix_achat)} onChange={handleChange} type="number" />
                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.prix_achat)}</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group className="d-flex align-items-center">
                        <Form.Label column sm={4}>Devise</Form.Label>
                        <Col sm={8}>
                            <Form.Control size="sm" name="devise" value={currentFournisseur.devise} isInvalid={getFormErrorMsg(invalidData?.devise)} onChange={handleChange} type="text" />
                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.devise)}</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group className="d-flex align-items-center">
                        <Form.Label column sm={4}>Type de transport</Form.Label>
                        <Col sm={8}>
                            <Form.Control size="sm" name="type_transport" value={currentFournisseur.type_transport} isInvalid={getFormErrorMsg(invalidData?.type_transport)} onChange={handleChange} type="text" />
                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.type_transport)}</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group className="d-flex align-items-center">
                        <Form.Label column sm={4}>Date d'achat</Form.Label>
                        <Col sm={8}>
                            <Form.Control size="sm" name="date_achat" value={currentFournisseur.date_achat} isInvalid={getFormErrorMsg(invalidData?.date_achat)} onChange={handleChange} type="date" />
                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.date_achat)}</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    {/* <Form.Group className="d-flex align-items-center">
                        <Form.Label column sm={4}>cout_transport</Form.Label>
                        <Col sm={8}>
                            <Form.Control size="sm" name="cout_transport" value={currentFournisseur.cout_transport} isInvalid={getFormErrorMsg(invalidData?.cout_transport)} onChange={handleChange} type="text" />
                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.cout_transport)}</Form.Control.Feedback>
                        </Col>
                    </Form.Group> */}
                    {/* <Form.Group className="d-flex align-items-center">
                        <Form.Label column sm={4}>valeur_devise</Form.Label>
                        <Col sm={8}>
                            <Form.Control size="sm" name="valeur_devise" value={currentFournisseur.valeur_devise} isInvalid={getFormErrorMsg(invalidData?.valeur_devise)} onChange={handleChange} type="text" />
                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.valeur_devise)}</Form.Control.Feedback>
                        </Col>
                    </Form.Group> */}
                    {/* <Form.Group className="d-flex align-items-center">
                        <Form.Label column sm={4}>prix_revient</Form.Label>
                        <Col sm={8}>
                            <Form.Control size="sm" name="prix_revient" value={currentFournisseur.prix_revient} isInvalid={getFormErrorMsg(invalidData?.prix_revient)} onChange={handleChange} type="number" />
                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.prix_revient)}</Form.Control.Feedback>
                        </Col>
                    </Form.Group> */}
                    <Row>
                        <Col className="d-flex justify-content-end mt-2">
                            <Button variant="secondary" className="me-2" onClick={() => cancelChanges()}>Annuler</Button>
                            <Button onClick={postFournisseur}>Enregistrer le fournisseur</Button>
                        </Col>
                    </Row>
                </div>
            }

            {articleFournisseursList && articleFournisseursList.map((fournisseur, i, arr) => (
                <div key={fournisseur.fournisseur_id} >
                    {(fournisseur.fournisseur_id != currentFournisseur?.fournisseur_id) &&
                        <div className={"p-4 rounded bg-gray-100 mt-2 " + (arr.length - 1 === i ? "" : "mb-2")}>
                            <Row>
                                <Col>
                                    <p className="m-0">
                                        <b>
                                            {fournisseur?.fournisseur?.code &&
                                                <>{fournisseur?.fournisseur?.code} - </>
                                            }
                                            {fournisseur?.fournisseur?.nom &&
                                                <>{fournisseur?.fournisseur?.nom}</>
                                            }
                                        </b>
                                        {fournisseur?.reference_fournisseur &&
                                            <> ({fournisseur.reference_fournisseur})</>
                                        }
                                        
                                    </p>
                                </Col>
                                <Col className="col-auto">
                                    <Button disabled={currentFournisseur} variant="secondary" size="sm" onClick={() => setCurrentFournisseur(fournisseur)}>Modifier</Button>
                                </Col>
                            </Row>

                            <Row>
                                <Col className="12">
                                    {/* <p className="m-0">nom : {fournisseur?.fournisseur?.nom}</p> */}
                                    <p className="m-0">Prix d'achat : {fournisseur.prix_achat}</p>
                                    <p className="m-0">Devise : {fournisseur.devise}</p>
                                    <p className="m-0">Type de transport : {fournisseur.type_transport}</p>
                                    <p className="m-0">Date d'achat : {new Date(fournisseur.date_achat).toLocaleString()}</p>

                                    {fournisseur.fournisseur?.adresses?.map(adresse => (
                                        <OverlayTrigger key={adresse._id} trigger="click" rootClose placement="top" overlay={popAdresse(adresse)}>
                                            <Button variant="secondary" size="sm" className="me-1 mt-2">
                                                <i className="material-icons cursor-pointer">home</i>
                                            </Button>
                                        </OverlayTrigger>

                                    ))}
                                    {/* <p className="m-0">Prix de revient : {fournisseur.prix_revient}</p> */}
                                    {/* <p className="m-0">Valeur devise : {fournisseur.valeur_devise}</p> */}
                                </Col>
                            </Row>

                        </div>
                    }
                    {(fournisseur.fournisseur_id == currentFournisseur?.fournisseur_id) &&
                        <div className="p-4 border rounded mt-2">

                            <Form.Group className="d-flex align-items-center">
                                <Form.Label column sm={4}>Nom</Form.Label>
                                <Col sm={8}>
                                    <Form.Select size="sm" disabled name="fournisseur_id" value={currentFournisseur.fournisseur_id} isInvalid={getFormErrorMsg(invalidData?.fournisseur_id)} onChange={handleChange}>
                                        <option>- Sélectionner -</option>
                                        {fournisseursList?.map(f => (
                                            <option /* disabled={articleFournisseursList && articleFournisseursList.findIndex(af => af.fournisseur_id == f._id) != -1} */ key={f._id} value={f._id}>{f.nom}</option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.fournisseur_id)}</Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group className="d-flex align-items-center">
                                <Form.Label column sm={4}>Réf. Fournisseur</Form.Label>
                                <Col sm={8}>
                                    <Form.Control size="sm" name="reference_fournisseur" value={currentFournisseur.reference_fournisseur} isInvalid={getFormErrorMsg(invalidData?.reference_fournisseur)} onChange={handleChange} type="text" />
                                    <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.reference_fournisseur)}</Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group className="d-flex align-items-center">
                                <Form.Label column sm={4}>Prix d'achat</Form.Label>
                                <Col sm={8}>
                                    <Form.Control size="sm" name="prix_achat" value={currentFournisseur.prix_achat} isInvalid={getFormErrorMsg(invalidData?.prix_achat)} onChange={handleChange} type="number" />
                                    <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.prix_achat)}</Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group className="d-flex align-items-center">
                                <Form.Label column sm={4}>Devise</Form.Label>
                                <Col sm={8}>
                                    <Form.Control size="sm" name="devise" value={currentFournisseur.devise} isInvalid={getFormErrorMsg(invalidData?.devise)} onChange={handleChange} type="text" />
                                    <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.devise)}</Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group className="d-flex align-items-center">
                                <Form.Label column sm={4}>Type de transport</Form.Label>
                                <Col sm={8}>
                                    <Form.Control size="sm" name="type_transport" value={currentFournisseur.type_transport} isInvalid={getFormErrorMsg(invalidData?.type_transport)} onChange={handleChange} type="text" />
                                    <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.type_transport)}</Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group className="d-flex align-items-center">
                                <Form.Label column sm={4}>Date d'achat</Form.Label>
                                <Col sm={8}>
                                    <Form.Control size="sm" name="date_achat" value={formattedDateInput(currentFournisseur.date_achat)} isInvalid={getFormErrorMsg(invalidData?.date_achat)} onChange={handleChange} type="date" />
                                    <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.date_achat)}</Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            {/* <Form.Group className="d-flex align-items-center">
                                <Form.Label column sm={4}>cout_transport</Form.Label>
                                <Col sm={8}>
                                    <Form.Control size="sm" name="cout_transport" value={currentFournisseur.cout_transport} isInvalid={getFormErrorMsg(invalidData?.cout_transport)} onChange={handleChange} type="text" />
                                    <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.cout_transport)}</Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group className="d-flex align-items-center">
                                <Form.Label column sm={4}>valeur_devise</Form.Label>
                                <Col sm={8}>
                                    <Form.Control size="sm" name="valeur_devise" value={currentFournisseur.valeur_devise} isInvalid={getFormErrorMsg(invalidData?.valeur_devise)} onChange={handleChange} type="text" />
                                    <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.valeur_devise)}</Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group className="d-flex align-items-center">
                                <Form.Label column sm={4}>prix_revient</Form.Label>
                                <Col sm={8}>
                                    <Form.Control size="sm" name="prix_revient" value={currentFournisseur.prix_revient} isInvalid={getFormErrorMsg(invalidData?.prix_revient)} onChange={handleChange} type="number" />
                                    <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.prix_revient)}</Form.Control.Feedback>
                                </Col>
                            </Form.Group> */}

                            <Row>
                                <Col className="d-flex justify-content-end mt-2">
                                    <Button variant="danger" className="me-auto" onClick={() => removeFournisseurConfirm(currentFournisseur)}>Supprimer</Button>
                                    <Button variant="secondary" className="me-2" onClick={() => cancelChanges()}>Annuler</Button>
                                    <Button onClick={updateFournisseur}>Enregistrer les modifications</Button>
                                </Col>
                            </Row>
                        </div>
                    }
                </div>
            ))}

        </>
    );
}

export default Fournisseurs;