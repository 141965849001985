import React, { useEffect, useState } from 'react';
import { FournisseurService } from '../../../../services/FournisseurService';
import { Form, Spinner, Card, Tabs, Table, Tab, Row, Col, Accordion, Button, OverlayTrigger, Alert, Tooltip, Dropdown, Badge } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import ErrorManager from "../../../error-manager/ErrorManager";
import { getFormErrorMsg } from '../../../../helper/Helper'
//import './FournisseurDetails.scss';
import { FactureService } from '../../../../services/FactureService';
import { reorder, sortByNumber, currencyFormat } from '../../../../helper/Helper'
import './FactureDetails.scss';
import { Editor, convertFromHTML, EditorState, createWithContent, convertFromRaw, ContentState, convertToRaw } from "draft-js";
import { markdownToDraft } from 'markdown-draft-js';
import Reglements from './reglements/Reglements';
import { GlobalContext } from "../../../../services/GlobalProvider";

const FactureDetails = (props) => {

    const [facture, setFacture] = useState()
    const [getFactureLoading, setGetFactureLoading] = useState()
    let [openElement, setOpenElement] = useState()
    let [showReglementForm, setShowReglementForm] = useState(false)

    const { globalContextLoaded, preferences, parametres, folderDetails, grants, getPreferences } = React.useContext(GlobalContext);


    useEffect(() => {
        setFacture()
        getFacture()
        setOpenElement(props.openTab ? props.openTab : "infos")

        if (props.openTab == "reglements") {
            setShowReglementForm(true)
        } else {
            setShowReglementForm(false)
        }

    }, [props.factureId]);

    function handleTabSelect(key) {
        setOpenElement(key)
    }

    function getFacture() {
        setGetFactureLoading(true)

        FactureService.getFacture(props.folderId, props.factureId).then(res => {

            sortByNumber(res.data.data.details, "numero_ligne")

            setFacture(res.data.data)
            setGetFactureLoading(false)

        }).catch(error => {
            setGetFactureLoading(false)

            // setIsGetFacturesLoading(false)
            // setGlobalError(error)
        });
    }

    function getReglementStatus() {

        if (facture?.type != "facture") { return null }

        let totalReglements = 0
        facture?.reglements?.map(r => totalReglements += r.montant)

        if (facture?.details.length > 0) {
            if (facture.total_ttc <= totalReglements) {
                return <Badge bg="success" text="white" className="me-2">Réglée</Badge>
            }
            if (totalReglements == 0) {
                return <Badge bg="danger" text="white" className="me-2">Non réglée</Badge>
            }
            if (totalReglements != 0) {
                return <Badge bg="warning" text="dark" className="me-2">Partiellement réglée</Badge>
            }
        } else {
            return null
        }
    }

    function Split({ title, value, border }) {
        return (
            <div className={"d-flex justify-content-between py-2 " + (border ? "border-bottom" : "")}>
                <span className="pe-4 text-gray-500">{title}</span>
                <span className="">{value}</span>
            </div>
        )
    }

    function tabChange() {
        getFacture()
        props.onTabChange()
    }

    return (

        <div id="FactureDetails">

            {(!facture && getFactureLoading) &&
                <span className="hloader mt-3"></span>
            }

            {(facture) &&
                <>
                    <div className="d-flex mb-4">
                        <div className="me-auto">
                            <div className="me-3">
                                <h6 className="fw-bolder m-0">{facture.client_nom}</h6>
                            </div>
                            <div className="me-3">
                                <Badge bg="light" text="dark" className="me-2">Type : {facture.type}</Badge>
                                <Badge bg="light" text="dark" className="me-2">Date : {new Date(facture.date).toLocaleString()}</Badge>
                                {getReglementStatus()}
                            </div>
                        </div>
                        {props.elementActions.length > 0 &&
                            <Dropdown className="float-end ms-2 me-3">
                                <OverlayTrigger
                                    placement="left"
                                    delay={{ show: 800 }}
                                    overlay={
                                        <Tooltip>
                                            Plus d'actions
                                        </Tooltip>
                                    }
                                >
                                    <Dropdown.Toggle variant="secondary" className='no-btn-caret'>
                                        <i className="material-icons i-lg">more_horiz</i>
                                    </Dropdown.Toggle>
                                </OverlayTrigger>
                                <Dropdown.Menu>
                                    {props.elementActions.map((element) => (
                                        <Dropdown.Item key={element.title} onClick={() => props.setElementAction(element.action, facture)}>{element.title}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        }
                    </div>

                    <div className="">
                        <Tabs variant="pills" defaultActiveKey={openElement} onSelect={handleTabSelect} className="mb-3">

                            <Tab eventKey="infos" title="Infos" className="light-v-scroll height-full-min-250 pe-2">
                                {openElement == "infos" &&



                                    <Accordion defaultActiveKey={['description', 'facture', 'details', 'observations', 'client']} alwaysOpen>

                                        <Accordion.Item eventKey="description" className="border-0 mb-3">
                                            <Accordion.Header>Description</Accordion.Header>
                                            <Accordion.Body className="px-1">
                                                {facture.description &&
                                                    <p>{facture.description}</p>
                                                }
                                                {!facture.description &&
                                                    <em>- Aucune description rédigée -</em>
                                                }
                                            </Accordion.Body>
                                        </Accordion.Item>
                                       
                                        <Accordion.Item eventKey="client" className="border-0 mb-3">
                                            <Accordion.Header>Client</Accordion.Header>
                                            <Accordion.Body className="px-1">
                                                {facture.client._id && <Split title="Client" border value={<a href={`/${props.folderId}/clients?clientId=${facture.client._id}`} target="_blank">Afficher le client</a>} />}
                                                <Split border title="Nom" value={facture.client.nom} />
                                                <Split border title="Code" value={facture.client.code} />
                                                <Split border title="Profession" value={facture.client.profession} />
                                                <Split title="Bloquer" value={facture.client.bloquer ? "Oui" : "Non"} />
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="facture" className="border-0 mb-3">
                                            {facture.type == "facture" &&
                                                <Accordion.Header>Facture</Accordion.Header>
                                            }
                                            {facture.type == "avoir" &&
                                                <Accordion.Header>Avoir</Accordion.Header>
                                            }
                                            <Accordion.Body className="px-1">
                                                <Split border title="Code" value={facture.code} />
                                                <Split border title="Utilisateur" value={facture.user.user_login} />
                                                <Split border title="Vendeur" value={facture.vendeur.user_login} />
                                                <Split border title="Mois de génération" value={facture.generated} />
                                                {parametres?.general?.export_comptable.comptabilite_analytique &&
                                                    <Split border title="Secteur analytique" value={facture.secteur_analytique} />
                                                }
                                                <Split border title="Total prix revient" value={currencyFormat(facture.total_prix_revient)} />
                                                <Split border title="Total brut hors taxes" value={currencyFormat(facture.total_brut_ht)} />
                                                <Split border title="Total net hors taxes" value={currencyFormat(facture.total_net_ht)} />
                                                <Split border title="Total remise" value={currencyFormat(facture.total_remise)} />
                                                <Split border title="Total TTC" value={currencyFormat(facture.total_ttc)} />
                                                <Split border title="Statut" value={facture.statut} />
                                                <Split title="Total montant taxes" value={currencyFormat(facture.total_taxes?.montant_total)} />
                                                <div className="">
                                                    {facture.total_taxes?.montants_details.map(montant => (
                                                        <Badge key={montant.contribution_id} bg="gray-200" text="dark" className="me-2">{montant.libelle}</Badge>
                                                    ))}
                                                </div>

                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="details" className="border-0 mb-3">
                                            <Accordion.Header>Details</Accordion.Header>
                                            <Accordion.Body className="px-1 overflow-auto bg-gray-100">

                                                {(facture.details.length > 0) &&
                                                    <div className="table-container mt-2">
                                                        <Table borderless className="small w-100">
                                                            <thead>
                                                                <tr className='text-gray-600 text-uppercase'>
                                                                    <th>Code</th>
                                                                    <th style={{ minWidth: 220 }}>Désignation</th>
                                                                    <th>Qté</th>
                                                                    <th>Prix H.T</th>
                                                                    <th>Montant H.T</th>
                                                                    <th>Taux remise</th>
                                                                    <th>Montant remise</th>
                                                                    <th>Taux contrib.</th>
                                                                    <th>Montant contri.</th>
                                                                    <th>Px. de revient</th>
                                                                    <th>Px. de vente TTC</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="align-middle">
                                                                {facture.details.map(d =>
                                                                    <tr key={d._id}>
                                                                        <td>{d.code}</td>
                                                                        <td>
                                                                            {d.article_id && <a href={`/${props.folderId}/articles/?articleId=${d.article_id}`}>{d.designation}</a>}
                                                                            {!d.article_id && <span>{d.designation}</span>}
                                                                        </td>
                                                                        <td>{d.quantite}</td>
                                                                        <td>{currencyFormat(d.prix_vente_ht)}</td>
                                                                        <td>{currencyFormat(d.montant_net_ht)}</td>
                                                                        <td>{d.remise?.taux}</td>
                                                                        <td>{d.remise?.montant}</td>
                                                                        <td>
                                                                            {d.contributions?.map(c => (
                                                                                <span key={c.contribution_id} className="rounded bg-gray-300 px-1 me-1">
                                                                                    {c.valeur}
                                                                                </span>
                                                                            ))}
                                                                        </td>
                                                                        <td>
                                                                            {d.contributions?.map(c => (
                                                                                <span key={c.contribution_id} className="rounded bg-gray-300 px-1 me-1">
                                                                                    {currencyFormat(c.montant)}
                                                                                </span>
                                                                            ))}
                                                                        </td>
                                                                        <td>{currencyFormat(d.prix_revient)}</td>
                                                                        <td>{currencyFormat(d.montant_ttc)}</td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                }
                                                {(facture.details.length == 0) &&
                                                    <em>- Aucune ligne de détail n'a été saisie -</em>
                                                }
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="observations" className="border-0 mb-3">
                                            <Accordion.Header>Observations</Accordion.Header>
                                            <Accordion.Body className="px-1">
                                                {facture.observation &&
                                                    <Editor editorState={EditorState.createWithContent(convertFromRaw(markdownToDraft(facture.observation)))} readOnly={true} />
                                                }
                                                {!facture.observation &&
                                                    <em>- Aucune observation rédigée -</em>
                                                }
                                            </Accordion.Body>
                                        </Accordion.Item>

                                    </Accordion>
                                }
                            </Tab>

                            <Tab eventKey="reglements" title="Règlements" className="light-v-scroll height-full-min-250 pe-2">
                                {openElement == "reglements" &&
                                    <Reglements showReglementForm={showReglementForm} folderId={props.folderId} facture={facture} isFactureLoading={getFactureLoading} onTagChange={() => tabChange()} />
                                }
                            </Tab>

                            <Tab eventKey="historique" title="Documents liés" className="light-v-scroll height-full-min-250 pe-2">
                                <span className="d-flex flex-column border-start border-gray-400 ps-3">

                                    {facture.historiques.precedents?.facture &&
                                        <a className="time-dot my-2" href={`/${props.folderId}/factures/?factureId=${facture.historiques.precedents?.facture}`}>Facture {facture.historiques.precedents?.facture}</a>
                                    }
                                    {facture.historiques.precedents?.proforma &&
                                        <a className="time-dot my-2" href={`/${props.folderId}/factures/?factureId=${facture.historiques.precedents?.proforma}`}>Proforma {facture.historiques.precedents?.proforma}</a>
                                    }
                                    {facture.historiques.precedents?.avoir &&
                                        <a className="time-dot my-2" href={`/${props.folderId}/factures/?factureId=${facture.historiques.precedents?.avoir}`}>Avoir {facture.historiques.precedents?.avoir}</a>
                                    }
                                    {facture.historiques.precedents?.abonnement &&
                                        <a className="time-dot my-2" href={`/${props.folderId}/abonnements/?abonnementId=${facture.historiques.precedents?.abonnement}`}>Abonnement {facture.historiques.precedents?.abonnement}</a>
                                    }

                                    {(
                                        facture.historiques.precedents?.avoir ||
                                        facture.historiques.precedents?.proforma ||
                                        facture.historiques.precedents?.facture ||
                                        facture.historiques.precedents?.abonnement ||
                                        facture.historiques.suivants?.avoir ||
                                        facture.historiques.suivants?.proforma ||
                                        facture.historiques.suivants?.facture ||
                                        facture.historiques.suivants?.abonnement
                                    ) &&
                                        <p className="time-dot my-2">Document actuel ({facture.type} {facture._id})</p>
                                    }

                                    {facture.historiques.suivants?.avoir &&
                                        <a className="time-dot my-2" href={`/${props.folderId}/factures/?factureId=${facture.historiques.suivants?.avoir}`}>Avoir {facture.historiques.suivants?.avoir}</a>
                                    }
                                    {facture.historiques.suivants?.proforma &&
                                        <a className="time-dot my-2" href={`/${props.folderId}/factures/?factureId=${facture.historiques.suivants?.proforma}`}>Proforma {facture.historiques.suivants?.proforma}</a>
                                    }
                                    {facture.historiques.suivants?.facture &&
                                        <a className="time-dot my-2" href={`/${props.folderId}/factures/?factureId=${facture.historiques.suivants?.facture}`}>Facture {facture.historiques.suivants?.facture}</a>
                                    }
                                    {facture.historiques.suivants?.abonnement &&
                                        <a className="time-dot my-2" href={`/${props.folderId}/abonnements/?abonnementId=${facture.historiques.suivants?.abonnement}`}>Abonnement {facture.historiques.suivants?.abonnement}</a>
                                    }
                                </span>

                                {(
                                    !facture.historiques.precedents?.avoir &&
                                    !facture.historiques.precedents?.facture &&
                                    !facture.historiques.precedents?.proforma &&
                                    !facture.historiques.precedents?.abonnement &&
                                    !facture.historiques.suivants?.avoir &&
                                    !facture.historiques.suivants?.facture &&
                                    !facture.historiques.suivants?.proforma &&
                                    !facture.historiques.suivants?.abonnement
                                ) &&
                                    <em>- Aucun document lié -</em>
                                }
                            </Tab>


                        </Tabs>
                    </div>



                </>
            }
        </div>
    )

}

export default FactureDetails;

