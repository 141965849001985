import {axiosAPI} from "./axiosClient"
import { UserService } from './UserService';
import { useParams, useSearchParams } from "react-router-dom";
import qs from "qs";
import { getParams } from '../helper/Helper'

export const AbonnementService = {

  getAbonnements(folderId, params) {
    return axiosAPI.get(`/${folderId}/abonnements?lookup=true&${getParams(params)}`)
  },

  /* getAbonnementsPDF(folderId, params) {
    return axiosAPI.get(`/${folderId}/abonnements?lookup=true&${getParams(params)}&format=pdf`, {responseType: 'blob'})
  }, */

  getAbonnement(folderId, abonnementId){
    return axiosAPI.get(`/${folderId}/abonnements/${abonnementId}?lookup=true`)
  },

  updateAbonnement(folderId, abonnementId){
    return axiosAPI.put(`/${folderId}/abonnements/${abonnementId}`)
  },

  duplicateAbonnement(folderId, abonnementId){
    return axiosAPI.post(`/${folderId}/abonnements/${abonnementId}/dupliquer`)
  },

  postAbonnement(folderId, dataPost){
    return axiosAPI.post(`/${folderId}/abonnements`, qs.stringify(dataPost))
  },
  
  removeAbonnements(folderId, abonnementIdList) {
    var params = '?abonnements[]=' + abonnementIdList.join('&abonnements[]=');
    return axiosAPI.delete(`/${folderId}/abonnements${params}`)
  },

  convertToProforma(folderId, abonnementId) {
    return axiosAPI.post(`/${folderId}/abonnements/${abonnementId}/convert/proforma`)
  },


  
  postGenerate(folderId, dataPost){
    return axiosAPI.post(`/${folderId}/abonnements/generate`, qs.stringify(dataPost))
  },


  updateSuspension(folderId, abonnementId, dataPost){
    return axiosAPI.put(`/${folderId}/abonnements/${abonnementId}/suspensions`, qs.stringify(dataPost))
  },
  
  postSuspension(folderId, abonnementId, dataPost){
    return axiosAPI.post(`/${folderId}/abonnements/${abonnementId}/suspensions`, qs.stringify(dataPost))
  },
  
  removeSuspension(folderId, abonnementId, adresseId) {
    return axiosAPI.delete(`/${folderId}/abonnements/${abonnementId}/suspensions/${adresseId}`)
  },

}


/* 
GET /abonnements
GET /abonnements/nombre
GET /abonnements/{_id}
GET /abonnements/{_id}/suspensions

POST /abonnements
POST /abonnements/generate
POST /abonnements/{_id}/generate
POST /abonnements/dupliquer
POST /abonnements/{_id}/dupliquer
POST /abonnements/convert/proforma
POST /abonnements/{_id}/convert/proforma
POST /abonnements/{_id}/suspensions

PUT /abonnements/{_id}
PUT /abonnements/{_id}/suspensions

DEL /abonnements
DEL /abonnements/{_id}
DEL /abonnements/{_id}/suspensions[/{suspension_id}]
 */