import React, { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { InputGroup, FormControl, Container, Button, Dropdown, DropdownButton, Form, Col, Row, Spinner, OverlayTrigger, Tooltip, Toast, ToastContainer } from 'react-bootstrap';

import { EditionService } from '../../services/EditionService';
import { FournisseurService } from '../../services/FournisseurService';

import DynamicTable from '../../components/table/dynamic-table/DynamicTable';
import Pagination from '../../components/pagination/Pagination';
import SideDetails from '../../components/side-details/SideDetails';
import FournisseurDetails from '../../components/side-details/fournisseur/fournisseur-details/FournisseurDetails';
import FournisseurEdition from '../../components/side-details/fournisseur/fournisseur-edition/FournisseurEdition';
import ColManager from '../../components/table/dynamic-table/col-manager/ColManager';
import Families from '../../components/families/Families';
import './Fournisseurs.scss';
import { PreferenceService } from '../../services/PreferenceService';
import ConfirmDialog from '../../components/confirm-dialog/ConfirmDialog';
import ErrorManager from "../../components/error-manager/ErrorManager";
import { GlobalContext } from "../../services/GlobalProvider";
import { formatTableHeadersFromPrefs, granted, getFile } from '../../helper/Helper'


const Fournisseurs = () => {

  const { globalContextLoaded, preferences, parametres, folderDetails, grants, getPreferences } = React.useContext(GlobalContext);

  let fournisseursParams = useRef({
    'start': 0,
    'limit': 50,
    'search': null,
    'filters': null,
    'sort': null,
    'order': null
  });

  const [globalError, setGlobalError] = useState()
  const [searchParams, setSearchParams] = useSearchParams();
  const [fournisseurs, setFournisseurs] = useState()
  const [isGetFournisseursLoading, setIsGetFournisseursLoading] = useState()
  const [isPDFLoading, setIsPDFLoading] = useState()
  const [sideWidth, setSideWidth] = useState()
  const [tableHeaders, setTableHeaders] = useState()
  const [checkedElementIdList, setCheckedElementIdList] = useState([])
  const [currentPagination, setCurrentPagination] = useState(1)
  const [isTableFilterVisible, setIsTableFilterVisible] = useState(false)
  const [confirmDialog, setConfirmDialog] = useState({ show: false, dialogInfos: null })
  const [currentFournisseur, setCurrentFournisseur] = useState()
  const [isFournisseurEditing, setIsFournisseurEditing] = useState(false)
  const [fournisseurEditingId, setFournisseurEditingId] = useState()

  const [tableRowElementActions] = useState([
    { icon: "edit", title: "Modifier le fournisseur", action: "editFournisseur" }
  ])

  const [fournisseurElementActions] = useState([
    { icon: "edit", title: "Modifier le fournisseur", action: "editFournisseur" }
  ])

  useEffect(() => {
    if (grants) {
      !grants.fournisseurs.valeur && setGlobalError({ statusText: `Fournisseurs : vous n'avez pas les droit d'accès à cette ressource.` })
      granted(grants, "fournisseurs.remove") && tableRowElementActions.push({ icon: "delete_forever", title: "Supprimer", action: "removeFournisseurDialog" })
      granted(grants, "fournisseurs.remove") && fournisseurElementActions.push({ icon: "delete_forever", title: "Supprimer", action: "removeFournisseurDialog" })
    }
  }, [grants]);

  useEffect(() => {
    if (globalContextLoaded) {
      searchParams.get('fournisseurId') && getFournisseur(searchParams.get('fournisseurId'))
      setPreferences()
      getFournisseurs()
    }
  }, [globalContextLoaded]);

  function setPreferences() {
    setTableHeaders(formatTableHeadersFromPrefs(preferences.fournisseurs['tableHeaders'], parametres.fournisseurs['fieldsLabel']))
    fournisseursParams.current['sort'] = preferences.fournisseurs['tableSort']
    fournisseursParams.current['order'] = preferences.fournisseurs['tableOrder']
    fournisseursParams.current['fields'] = Object.keys(preferences.fournisseurs['tableHeaders']).join(',')
  }

  function getFournisseur(fournisseurId) {
    FournisseurService.getFournisseur(folderDetails._id, fournisseurId).then(res => {
      if (!res.data?.data) {
        setGlobalError({ statusText: res.statusText })
      } else {
        changeCurrentFournisseur(res.data.data)
        setIsFournisseurEditing(false)
      }
    }).catch(error => {
      setGlobalError(error)
    });
  }

  function getFournisseurs() {
    setCheckedElementIdList([])
    if (granted(grants, "fournisseurs.consult")) {
      setIsGetFournisseursLoading(true)
      FournisseurService.getFournisseurs(folderDetails._id, fournisseursParams.current).then(res => {
        setFournisseurs(res.data.data)
        setIsGetFournisseursLoading(false)
      }).catch(error => {
        setIsGetFournisseursLoading(false)
        setGlobalError(error)
      });
    }
  }

  function getFournisseursPDF() {
    if (granted(grants, "fournisseurs.consult")) {
      setIsPDFLoading(true)
      let params = {
        'start': 0,
        'search': fournisseursParams.current.search,
        'sort': fournisseursParams.current.sort,
        'order': fournisseursParams.current.order,
        'filters': fournisseursParams.current.filters
      }

      EditionService.getListe(folderDetails._id, params, "fournisseurs", "pdf").then(res => {
        setIsPDFLoading(false)
        if (!res?.data) {
          setGlobalError({ statusText: res.statusText })
        } else {
          getFile(res.data, `Proformas - Liste`, "pdf")
        }
      }).catch(error => {
        setIsPDFLoading(false)
        setGlobalError(error)
      });
    }
  }

  function removeFournisseurList(fournisseurIdList) {
    FournisseurService.removeFournisseurs(folderDetails._id, fournisseurIdList).then(res => {
      setConfirmDialog({ show: false });
      getFournisseurs()
      changeCurrentFournisseur()
      setSideWidth()
      uncheckElementList(fournisseurIdList)
    }).catch(err => {
      setConfirmDialog({
        ...confirmDialog,
        dialogInfos: { ...confirmDialog.dialogInfos, error: err }
      })
    });
  }

  function uncheckElementList(idList) {
    let checkedElementIdListCopy = [...checkedElementIdList];
    idList.map(id => {
      let index = checkedElementIdListCopy.indexOf(id)
      if (index >= 0) {
        checkedElementIdListCopy.splice(index, 1)
      }
    })
    setCheckedElementIdList(checkedElementIdListCopy)
  }



  function saveTableHeadersPrefs(e) {
    const newHeadersObject = {}
    e.forEach(object => {
      newHeadersObject[object['dataTarget']] = { position: object['position'], hidden: object['hidden'] }
    });
    updatePreference({ ["tableHeaders"]: newHeadersObject })
  }

  function updatePreference(prefs) {
    PreferenceService.updatePreferences(folderDetails._id, { prefs: { ['fournisseurs']: prefs } }).then(res => {
      getPreferences()
    }).catch(error => {
      setGlobalError(error)
    });
  }

  function changeCurrentFournisseur(fournisseur) {
    fournisseur?._id ? setSearchParams({ fournisseurId: fournisseur._id }) : setSearchParams(searchParams.delete('fournisseurId'))
    setCurrentFournisseur(fournisseur)
  }

  function changeTableHeaders(e) {
    setTableHeaders(e)
    saveTableHeadersPrefs(e)
  }

  function changePagination(p) {
    setCurrentPagination(p)
    fournisseursParams.current['start'] = (fournisseursParams.current.limit * p) - fournisseursParams.current.limit
    getFournisseurs()
  }

  function changeLimit(l) {
    fournisseursParams.current['limit'] = l
    changePagination(1)
  }

  let searchTimeOut;
  function changeSearchValue(value) {
    window.clearTimeout(searchTimeOut);
    searchTimeOut = window.setTimeout(function () {
      setCurrentPagination(1)
      fournisseursParams.current['start'] = 0
      fournisseursParams.current['search'] = value
      getFournisseurs()
    }, 1000);
  }

  let filtersTimeOut;
  function changeFilters(f) {
    window.clearTimeout(filtersTimeOut);
    filtersTimeOut = window.setTimeout(function () {
      setCurrentPagination(1)
      fournisseursParams.current['start'] = 0
      fournisseursParams.current['filters'] = f
      getFournisseurs()
    }, 1000);
  }

  function clearFilters() {
    setCurrentPagination(1)
    fournisseursParams.current['start'] = 0
    fournisseursParams.current['filters'] = null
    getFournisseurs()
    setIsTableFilterVisible(false)
  }

  function changeSortOrder(s, o) {
    fournisseursParams.current['sort'] = s
    fournisseursParams.current['order'] = o
    updatePreference({ ["tableSort"]: s, ["tableOrder"]: o })
    getFournisseurs()
  }

  function onFournisseurSaved(articleId) {
    getFournisseur(articleId)
    getFournisseurs()
  }

  function onTabChange() {
    getFournisseurs()
  }

  function closeSide() {
    setIsFournisseurEditing(false)
    setCurrentFournisseur()
  }

  function cancelEdition() {
    setIsFournisseurEditing(false)
    !currentFournisseur && setSideWidth()
  }

  function createNewFournisseur() {
    setIsFournisseurEditing(true)
    setFournisseurEditingId()
  }

  function removeFournisseurListDialog(fournisseurIdList) {
    setConfirmDialog({
      show: true,
      dialogInfos: {
        title: `Supprimer ${fournisseurIdList.length} fournisseur(s) ?`,
        description: 'Voulez vous supprimer les éléments sélectionnés ?',
        actionName: 'Supprimer la sélection',
        btnVariant: 'danger',
        element: fournisseurIdList,
        error: false,
        confirmAction: 'removeFournisseurList'
      }
    })
  }

  function removeFournisseurDialog(fournisseur) {
    setConfirmDialog({
      show: true,
      dialogInfos: {
        title: fournisseur.nom,
        description: 'Voulez vous supprimer cet élément ?',
        actionName: 'Supprimer',
        btnVariant: 'danger',
        element: [fournisseur._id],
        error: false,
        confirmAction: 'removeFournisseurList'
      }
    })
  }

  function editFournisseur(fournisseur) {
    setIsFournisseurEditing(true)
    setFournisseurEditingId(fournisseur._id)
  }

  return (
    <Container fluid id="Fournisseurs" className="py-4">

      <div className="pb-2 d-flex align-items-center justify-content-between">
        <h4 className="">Fournisseurs</h4>
        {granted(grants, "fournisseurs.edit") &&
          <Button variant="primary" className="ms-2" onClick={() => createNewFournisseur()}>Créer un fournisseur</Button>
        }
      </div>

      <ErrorManager error={globalError} fixed="true" />

      <ConfirmDialog
        show={confirmDialog.show}
        dialogInfos={confirmDialog.dialogInfos}
        setCancel={() => setConfirmDialog({ show: false })}
        setConfirm={(a, e) => eval(a)(e)}
      />

      <Row>

        <Col xs="12">
          <Row>
            {granted(grants, "fournisseurs.consult") &&
              <>
                <Col xs="5" xl="3">
                  <InputGroup>
                    <i className="material-icons input-icon text-gray-400">search</i>
                    <FormControl
                      className="input-icon-space rounded-start"
                      placeholder="Recherche globale"
                      type="search"
                      onChange={e => changeSearchValue(e.target.value)}
                    />

                    {!fournisseursParams.current.filters &&
                      <OverlayTrigger placement="right" delay={{ show: 800 }} overlay={<Tooltip>Filtres de recherche</Tooltip>}>
                        <Button variant={fournisseursParams.current.filters ? 'warning' : 'secondary'} onClick={() => setIsTableFilterVisible(!isTableFilterVisible)}>
                          <i className="material-icons i-lg">filter_alt</i>
                        </Button>
                      </OverlayTrigger>
                    }

                    {fournisseursParams.current.filters &&
                      <OverlayTrigger placement="right" delay={{ show: 800 }} overlay={<Tooltip>Supprimer les filtres</Tooltip>}>
                        <Button variant={fournisseursParams.current.filters ? 'warning' : 'secondary'} onClick={() => clearFilters()}>
                          <i className="material-icons i-lg">clear</i>
                        </Button>
                      </OverlayTrigger>
                    }

                  </InputGroup>
                </Col>
                <Col>
                  <ColManager tableHeaders={tableHeaders} changeTableHeaders={e => changeTableHeaders(e)} />
                  <Button disabled={isPDFLoading} variant="secondary" className="ms-2" onClick={() => getFournisseursPDF()}>
                    {!isPDFLoading && <i className="material-icons i-lg float-start me-2">print</i>}
                    {isPDFLoading && <Spinner animation="border" size="sm" className="me-2" />}
                    Imprimer la liste ({fournisseurs?.total})
                  </Button>
                </Col>
              </>
            }
            <Col md="auto">
              {checkedElementIdList?.length > 0 &&
                <Dropdown className="d-inline-block">
                  <Dropdown.Toggle variant="dark" id="dropdown-selectionnes">
                    {checkedElementIdList.length} Sélectionné{checkedElementIdList?.length > 1 && <span>s</span>}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {/* <Dropdown.Item onClick={() => setCheckedElementIdList([])}>Tout désélectionner</Dropdown.Item> */}
                    {granted(grants, "fournisseurs.remove") &&
                      <>
                        <Dropdown.Divider />
                        <Dropdown.Item className="text-danger" onClick={() => removeFournisseurListDialog(checkedElementIdList)}>Supprimer</Dropdown.Item>
                      </>
                    }
                  </Dropdown.Menu>
                </Dropdown>
              }
              {/* {granted(grants, "fournisseurs.edit") &&
                <Button variant="primary" className="ms-2" onClick={() => createNewFournisseur()}>Créer un fournisseur</Button>
              } */}
            </Col>
          </Row>

          {granted(grants, "fournisseurs.consult") &&
            <Row className="mt-3">
              <Col xs="12">
                <div className="p-3 bg-white rounded border overflow-auto">
                  <DynamicTable
                    isDataLoading={isGetFournisseursLoading}
                    elementActions={tableRowElementActions}
                    setElementAction={(a, e) => eval(a)(e)}
                    sideWidth={sideWidth}
                    tableHeaders={tableHeaders}
                    tableDataList={fournisseurs?.fournisseurs}
                    currentElement={currentFournisseur}
                    changeCurrentElement={e => changeCurrentFournisseur(e)}
                    checkedElementIdList={checkedElementIdList}
                    setCheckedElementIdList={e => setCheckedElementIdList(e)}
                    changeFilters={f => changeFilters(f)}
                    isTableFilterVisible={isTableFilterVisible}
                    changeSortOrder={(s, o) => changeSortOrder(s, o)}
                    params={fournisseursParams.current}
                  />
                  <Pagination itemsLength={fournisseurs?.total} elementsPerPage={fournisseursParams.current.limit} currentPagination={currentPagination} changePagination={p => changePagination(p)} showChangeLimit changeLimit={l => changeLimit(l)} />
                </div>
              </Col>
            </Row>
          }

          {(currentFournisseur || isFournisseurEditing) &&
            <SideDetails
              editMode={isFournisseurEditing}
              currentElement={isFournisseurEditing ? null : { id: currentFournisseur?._id, label: currentFournisseur?.code, title: currentFournisseur?.nom }}
              changeCurrentElement={e => changeCurrentFournisseur(e)}
              setSideClose={closeSide}
              setSideCancel={cancelEdition}
              setSideWidth={e => setSideWidth(e)}
              detailsComponent={
                isFournisseurEditing ?
                  <FournisseurEdition folderId={folderDetails._id} fournisseurEditingId={fournisseurEditingId} onSetSaved={f => onFournisseurSaved(f)} />
                  :
                  <FournisseurDetails folderId={folderDetails._id} fournisseurId={currentFournisseur._id} elementActions={fournisseurElementActions} setElementAction={(a, e) => eval(a)(e)} onTabChange={() => onTabChange()} />
              }
            />
          }

        </Col>
      </Row>

    </Container>

  );
}

export default Fournisseurs;