import React, { useEffect, useState, useRef } from "react";
import { Container, Button, Dropdown, DropdownButton, Form, Col, Row, Accordion, Label, Modal, Alert, Spinner } from 'react-bootstrap';
import { FamilyService } from '../../../services/FamilyService';
import { ArticleService } from '../../../services/ArticleService';
import { GlobalContext } from "../../../services/GlobalProvider";
import { useParams } from "react-router-dom";
import ErrorManager from "../../error-manager/ErrorManager";

const ModalRemoveFamily = (props) => {  

    const [show, setShow] = useState(false);
    const [hasChildren, setHasChildren] = useState();
    const [hasArticles, setHasArticles] = useState();
    const [articlesLoading, setArticlesLoading] = useState(true);
    const [removeLoading, setRemoveLoading] = useState(false);
    const [error, setError] = useState(null);

    const {folderDetails} = React.useContext(GlobalContext);

    let articlesParams = useRef({});

    useEffect(() => {
        setShow(props.object.show)
        setError(null)
    }, [props.object]); 

    useEffect(() => {

        if(props.object.show == true){
            
            props.object.family.children.length>0 ? setHasChildren(true) : setHasChildren(false)

            setArticlesLoading(true)
            ArticleService.getArticles(folderDetails?.dossier?._id, articlesParams.current, props.object.family?._id).then(res => {
                setArticlesLoading(false)
                res.status == 204 ? setHasArticles(false) : setHasArticles(true)
            }).catch(error => {
                  setArticlesLoading(false)
            });

        }

    }, [props.object.show]); 

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    function handleKeyDown(e) {
        if (show) {
            const { code } = e;
            switch (code) {
                case "Escape":
                    props.setClose({ reload: false })
                    break;
                default:
                    break;
            }
        }
    }

    function removeFamily(){
        setRemoveLoading(true)
        setError(null)
        FamilyService.removeFamily(folderDetails?.dossier?._id, props.object.family?._id).then(res => {
            props.setClose({reload:true})
            setRemoveLoading(false)
        }).catch(err => {
            setError(err)
            setRemoveLoading(false)
        });
    }

    return (
        <>
            <Modal show={show} centered>

                <Modal.Header className="border-0 pb-0">
                    <h5>{props.object.family?.designation}</h5>
                </Modal.Header>

                <Modal.Body className="">
                        

                        <Row>
                            <Col xs="12">
                                {
                                    articlesLoading && (
                                        <Spinner animation="border" size="sm" />
                                    )
                                }

                                {
                                    !articlesLoading && (
                                        <>
                                            {(hasArticles || hasChildren) && 
                                            <h6> Vous ne pouvez pas supprimer cette famille : </h6>
                                        }
            
                                            {hasChildren && 
                                                <li>Veuillez d'abord supprimer ou déplacer les sous-familles</li>
                                            }
                                            
                                            {hasArticles && 
                                                <li>Veuillez d'abord supprimer ou déplacer les articles.</li>
                                            }
                                            
                                            {(!hasArticles && !hasChildren) && 
                                                <h6> Voulez vous supprimer cette famille ? </h6>
                                            }
                                        </>
                                    )
                                }


                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs="12">
                                <ErrorManager error={error}/>
                            </Col>
                        </Row>
                </Modal.Body>

                <Modal.Footer className="border-0">
                    <Button variant="secondary" onClick={() => props.setClose({reload:false})}>
                        Annuler
                    </Button>
                    <Button disabled={hasArticles || hasChildren || articlesLoading || removeLoading} variant="danger" onClick={() => removeFamily()}>
                        {removeLoading && 
                            <Spinner animation="border" size="sm" className="me-2" />
                        }
                        Supprimer
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default ModalRemoveFamily;
