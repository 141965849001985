import React, { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from "react-router-dom";
import { Container, Button, Dropdown, DropdownButton, Alert, Col, Row, Spinner, FormControl, Tooltip, InputGroup, Form } from 'react-bootstrap';
import { GlobalContext } from "../../services/GlobalProvider";
import { TagService } from '../../services/TagService';
import { ClientService } from '../../services/ClientService';
import ErrorManager from "../../components/error-manager/ErrorManager";
import { isDark, granted, useOuterClick } from '../../helper/Helper'
import Pagination from '../../components/pagination/Pagination';

import './TagsFilter.scss';


const TagsFilter = ({ documentType, changeTagIdList, parentFilters }) => {

    const { globalContextLoaded, preferences, parametres, folderDetails, grants, getPreferences } = React.useContext(GlobalContext);

    const [globalError, setGlobalError] = useState();
    const [tagListData, setTagListData] = useState();
    const [showTagList, setShowTagList] = useState(false);
    const [searchValue, setSearchValue] = useState(null);
    const [inputLibelle, setInputLibelle] = useState();
    const [inputColor, setInputColor] = useState("#e5e7e9");
    const [openColorPalet, setOpenColorPalet] = useState(false);
    const [addRemoveTagLoading, setAddRemoveTagLoading] = useState(false);
    const [totalElementsTagsList, setTotalElementsTagsList] = useState();
    const [partialElementsTagsList, setPartialElementsTagsList] = useState();
    const [isTagListLoading, setIsTagListLoading] = useState(false);
    const [filterTagList, setFilterTagList] = useState([]);
    const [currentPagination, setCurrentPagination] = useState(1)


    const tagListOuterClick = useOuterClick(ev => {
        showTagList && setShowTagList(false)
    });

    let tagsParams = useRef({
        'start': 0,
        'limit': 50,
        'search': null,
        'filters': {
            'filter[type]': [{ operator: "=", value: documentType }],
            'filter[tag]': []
        },
        'sort': null,
        'order': null
    });

    useEffect(() => {
        let tagIdList = []
        filterTagList.map(tag => {
            tagIdList.push(tag._id)
        })
        changeTagIdList(tagIdList)
    }, [filterTagList]);

    useEffect(() => {
        if (!parentFilters) {
            setFilterTagList([])
        }
    }, [parentFilters]);

    function changePagination(p) {
        setCurrentPagination(p)
        tagsParams.current['start'] = (tagsParams.current.limit * p) - tagsParams.current.limit
        getTagList()
    }

    let searchTimeOut;
    function changeSearchValue(value) {
        window.clearTimeout(searchTimeOut);
        searchTimeOut = window.setTimeout(function () {
            if (value) {
                tagsParams.current['filters']['filter[tag]'] = [{ operator: "", value: value }]
            } else {
                tagsParams.current['filters']['filter[tag]'] = []
            }
            setCurrentPagination(1)
            tagsParams.current['start'] = 0
            getTagList()
        }, 800);
    }

    function getTagList() {
        setGlobalError()
        setIsTagListLoading(true)
        TagService.getTags(folderDetails._id, tagsParams.current).then(res => {
            res.status == 204 ? setTagListData() : setTagListData(res.data.data)
            setIsTagListLoading(false)
        }).catch(error => {
            setGlobalError(error)
            setIsTagListLoading(false)
        });
    }

    function addTag(tag) {
        setFilterTagList(filterTagList => [...filterTagList, tag]);
    }

    function removeTag(tag) {
        setFilterTagList(filterTagList.filter(tagFound => tagFound._id !== tag._id))
    }

    function showDropdownTagList() {
        if (!showTagList) {
            getTagList()
            setSearchValue(null)
        }
        setShowTagList(!showTagList)
    }

    return (
        <div ref={tagListOuterClick}>

            <Button id="TagsFilterButton" className="d-flex align-items-center rounded-0" variant="secondary" onClick={() => showDropdownTagList()}>
                Filtrer par tags
                {filterTagList?.length > 0 &&
                    <span className="bubble-tags-container">
                        {filterTagList.map(tag => (
                            <span key={tag._id} className="bubble" style={{ backgroundColor: tag.couleur }}>
                                <span style={{ backgroundColor: tag.couleur }} className={"label " + (isDark(tag.couleur) ? "text-dark" : "text-white")}>{tag.tag}</span>
                            </span>
                        ))}
                    </span>
                }
            </Button>

            {showTagList &&
                <div id="TagsFilter" className="position-absolute bg-white p-3 rounded border zindex-90 mt-1 end-0 top-100">
                    {filterTagList.length > 0 &&
                        <>
                            <h6>Les résultats contiennent l'un de ces tags :</h6>
                            {filterTagList.map(tag => (
                                <span key={tag._id} /* onClick={addTag(tag)} */ className={"cursor-pointer me-2 rounded-5 px-2 py-1 d-inline-flex mb-2 small fw-bold " + (isDark(tag.couleur) ? "text-dark " : "text-white ")} style={{ backgroundColor: tag.couleur }}>
                                    {tag.tag}
                                    <i className="material-icons ms-1 i-lg cursor-pointer" onClick={() => removeTag(tag)}>close</i>
                                </span>
                            ))}
                        </>
                    }
                    <FormControl
                        autoFocus
                        className="mb-3 mt-3"
                        placeholder="Rechercher un tag"
                        type="search"
                        onChange={e => changeSearchValue(e.target.value)}
                    />
                    {!isTagListLoading &&
                        <>

                            {tagListData?.tags?.length > 0 && (
                                <div>
                                    {tagListData.tags.map(tag => (
                                        <span key={tag._id} onClick={() => addTag(tag)}  className={"cursor-pointer me-1 rounded-5 px-2 d-inline-flex mb-2 small fw-bold " + (isDark(tag.couleur) ? "text-dark " : "text-white ")} style={{ backgroundColor: tag.couleur}}>
                                            {tag.tag}
                                        </span>
                                    ))}
                                </div>
                            )}

                            {!tagListData && (
                                <em>- Aucun tag trouvé -</em>
                            )}

                            <Pagination itemsLength={tagListData?.total} elementsPerPage={tagsParams.current.limit} currentPagination={currentPagination} changePagination={p => changePagination(p)} />

                        </>
                    }


                    {isTagListLoading &&
                        <span className="hloader mt-3"></span>
                    }
                </div>
            }
        </div>
    )
}

export default TagsFilter;
