import React, { useEffect, useState, useRef } from "react";
import { Container, Button, Dropdown, DropdownButton, Form, InputGroup, Row, Badge, Label, Col, Tooltip, Spinner, OverlayTrigger, FormControl } from 'react-bootstrap';
import { ContributionService } from '../../../services/ContributionService';
import { ClientService } from '../../../services/ClientService';
import Pagination from '../../../components/pagination/Pagination';
import { GlobalContext } from "../../../services/GlobalProvider";
import { useOuterClick, currencyFormat, granted, formattedDateInput, getFormErrorMsg } from '../../../helper/Helper'
import ErrorManager from "../../../components/error-manager/ErrorManager";
import './YearMonthInput.scss';


const YearMonthInput = (props) => {

    const [isDropdownOpen, setIsDropdownOpen] = useState()
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear())
    const [inputDate, setInputDate] = useState('')
    const [invalidFormat, setInvalidFormat] = useState(false)

    const { parametres, folderDetails, userDetails, grants } = React.useContext(GlobalContext);

    const inputRef = useRef();

    useEffect(() => {
        if(props.value){
            setInputDate(props.value)
        }
    }, [props.value]);

    const outerClick = useOuterClick(ev => {
        setIsDropdownOpen(false)
    });

    const scheduledMonthMap = [
        {
            libelle: 'Janv.',
            value: '01'
        },
        {
            libelle: 'Févr.',
            value: '02'
        },
        {
            libelle: 'Mars',
            value: '03'
        },
        {
            libelle: 'Avr.',
            value: '04'
        },
        {
            libelle: 'Mai',
            value: '05'
        },
        {
            libelle: 'Juin',
            value: '06'
        },
        {
            libelle: 'Juill.',
            value: '07'
        },
        {
            libelle: 'Août',
            value: '08'
        },
        {
            libelle: 'Sept.',
            value: '09'
        },
        {
            libelle: 'Oct.',
            value: '10'
        },
        {
            libelle: 'Nov.',
            value: '11'
        },
        {
            libelle: 'Déc.',
            value: '12'
        }
    ]

    function addYearCount() {
        setCurrentYear(currentYear => currentYear - 1)
        // if (document.getSelection().type === 'Range') return;
    }
    function removeYearCount() {
        setCurrentYear(currentYear => currentYear + 1)
        // if (document.getSelection().type === 'Range') return;
    }

    function setMonth(value) {
        setInvalidFormat(false)
        setInputDate(`${value}/${currentYear}`)
        props.onChange(`${value}/${currentYear}`)
        setIsDropdownOpen(false)
    }

    function goMonthBefore() {
        let month
        let year
        if (!inputDate) {
            year = new Date().getFullYear()
            month = (new Date().getMonth()).toLocaleString('fr-FR', { minimumIntegerDigits: 2, useGrouping: false })
        } else {
            let newMonth = parseInt(inputDate.split('/')[0]) - 1 == 0 ? 12 : (parseInt(inputDate.split('/')[0]) - 1)
            month = newMonth.toLocaleString('fr-FR', { minimumIntegerDigits: 2, useGrouping: false })
            year = parseInt(inputDate.split('/')[1])
        }
        setInputDate(`${month}/${year}`)
        props.onChange(`${month}/${year}`)
    }

    function goMonthNext() {
        let month
        let year
        if (!inputDate) {
            year = new Date().getFullYear()
            month = (new Date().getMonth() + 2).toLocaleString('fr-FR', { minimumIntegerDigits: 2, useGrouping: false })
        } else {
            let newMonth = parseInt(inputDate.split('/')[0]) + 1 == 13 ? 1 : (parseInt(inputDate.split('/')[0]) + 1)
            month = newMonth.toLocaleString('fr-FR', { minimumIntegerDigits: 2, useGrouping: false })
            year = parseInt(inputDate.split('/')[1])
        }
        setInputDate(`${month}/${year}`)
        props.onChange(`${month}/${year}`)
    }

    function goYearBefore() {
        let month
        let year
        if (!inputDate) {
            year = new Date().getFullYear() - 1
            month = (new Date().getMonth() + 1).toLocaleString('fr-FR', { minimumIntegerDigits: 2, useGrouping: false })
        } else {
            month = parseInt(inputDate.split('/')[0]).toLocaleString('fr-FR', { minimumIntegerDigits: 2, useGrouping: false })
            year = parseInt(inputDate.split('/')[1]) - 1
        }
        setInputDate(`${month}/${year}`)
        props.onChange(`${month}/${year}`)
    }

    function goYearNext() {
        let month
        let year
        if (!inputDate) {
            year = new Date().getFullYear() + 1
            month = (new Date().getMonth() + 1).toLocaleString('fr-FR', { minimumIntegerDigits: 2, useGrouping: false })
        } else {
            month = parseInt(inputDate.split('/')[0]).toLocaleString('fr-FR', { minimumIntegerDigits: 2, useGrouping: false })
            year = parseInt(inputDate.split('/')[1]) + 1
        }
        setInputDate(`${month}/${year}`)
        props.onChange(`${month}/${year}`)
    }

    function openDropdown() {
        setCurrentYear(new Date().getFullYear())
        setIsDropdownOpen(true)
    }

    function changeDate(value) {
        setInputDate(value)
        if (isValidDate(value) || !value) {
            props.onChange(value)
            setInvalidFormat(false)
        } else {
            setInvalidFormat(true)
        }
    }

    function removeDate() {
        setInputDate('')
        props.onChange('')
        setInvalidFormat(false)
    }

    function isValidDate(dateString) {
        var regEx = /^\d{2}\/\d{4}$/;
        return dateString.match(regEx) != null;
    }

    return (
        <div id="YearMonthInput" className="position-relative me-2">

            <InputGroup hasValidation>
                <Button disabled={invalidFormat || props.disabled} variant="secondary" onClick={() => goYearBefore()}>
                    <i className="material-icons i-lg">keyboard_double_arrow_left</i>
                </Button>
                <Button disabled={invalidFormat || props.disabled} variant="secondary" onClick={() => goMonthBefore()}>
                    <i className="material-icons i-lg">chevron_left</i>
                </Button>
                <Form.Control
                    isInvalid={invalidFormat}
                    disabled={props.disabled}
                    type="text"
                    onFocus={() => openDropdown()}
                    //onBlur={() => setIsDropdownOpen(false)}
                    onChange={e => changeDate(e.target.value)}
                    value={inputDate}
                    placeholder={props.placeHolder}
                />
                {inputDate &&
                    <Button className="border-top border-bottom" variant="gray-100" onClick={() => removeDate()}>
                        <i className="material-icons i-lg">close</i>
                    </Button>
                }
                <Button disabled={invalidFormat || props.disabled} variant="secondary" onClick={() => goMonthNext()}>
                    <i className="material-icons i-lg">chevron_right</i>
                </Button>
                <Button disabled={invalidFormat || props.disabled} variant="secondary" onClick={() => goYearNext()}>
                    <i className="material-icons i-lg">keyboard_double_arrow_right</i>
                </Button>
                <Form.Control.Feedback type="invalid">Format invalide</Form.Control.Feedback>
            </InputGroup>

            {isDropdownOpen &&
                <span ref={outerClick} className="dropdown position-absolute zindex-100 bg-white border rounded mt-1 p-3">
                    <span className="d-flex justify-content-between mb-2">
                        <i style={{ userSelect: "none" }} className="material-icons i-lg cursor-pointer" onClick={() => addYearCount()}>chevron_left</i>
                        <b>{currentYear}</b>
                        <i style={{ userSelect: "none" }} className="material-icons i-lg cursor-pointer" onClick={() => removeYearCount()}>chevron_right</i>
                    </span>
                    <Row className="">
                        {scheduledMonthMap.map((month, index) => (
                            <Col xs={3} key={index} className="p-0">
                                <span onClick={() => setMonth(month.value)} className="small p-2 hover-bg-gray-100 d-flex cursor-pointer ">
                                    {month.libelle}
                                </span>
                            </Col>
                        ))}
                    </Row>
                </span>
            }
        </div>

    )
}
export default YearMonthInput;
