import {axiosAPI} from "./axiosClient"
import qs from "qs"

export const ContributionService = {

  getContributions(folderId) {
    return axiosAPI.get(`/${folderId}/contributions`)
  },

  updateContribution(folderId, dataPost){
    return axiosAPI.put(`/${folderId}/contributions`, qs.stringify(dataPost))
  },
  
  postContribution(folderId, dataPost){
    return axiosAPI.post(`/${folderId}/contributions`, qs.stringify(dataPost))
  },
  
  removeContribution(folderId, contributionId) {
    return axiosAPI.delete(`/${folderId}/contributions/${contributionId}`)
  },

}