import React, { useEffect, useState } from 'react';
import { ClientService } from '../../../../../services/ClientService';
import { Form, Spinner, Card, Tabs, Tab, Row, Col, Accordion, Button, OverlayTrigger, Alert, Tooltip, Dropdown, Badge } from 'react-bootstrap';
import ErrorManager from "../../../../error-manager/ErrorManager";
import { getFormErrorMsg, getInvalidObject, getChangedValues } from '../../../../../helper/Helper'

const Adresses = (props) => {

    const [currentAdresse, setCurrentAdresse] = useState()
    const [currentAdresseChanges, setCurrentAdresseChanges] = useState()
    const [invalidData, setInvalidData] = useState()
    const [globalError, setGlobalError] = useState()

    useEffect(() => {
        let adresseInitialIndex = props.client?.adresses?.findIndex(a => a._id == currentAdresse?._id)
        adresseInitialIndex > -1 && setCurrentAdresseChanges(getChangedValues(currentAdresse, props.client?.adresses[adresseInitialIndex], [], ["_id"]))
    }, [currentAdresse]);

    function postAdresse() {
        ClientService.postAdresse(props.folderId, props.client._id, { adresses: [currentAdresse] }).then(res => {
            setCurrentAdresse()
            setGlobalError()
            props.onAdresseChange()
        }).catch(error => {
            setGlobalError(error)
            setInvalidData(getInvalidObject(error.data?.data))
        });
    }

    function updateAdresse() {
        ClientService.updateAdresse(props.folderId, props.client._id, { adresses: [currentAdresseChanges] }).then(res => {
            setCurrentAdresse()
            setGlobalError()
            props.onAdresseChange()
        }).catch(error => {
            setGlobalError(error)
            setInvalidData(getInvalidObject(error.data?.data))
        });
    }

    function removeAdresse() {
        ClientService.removeAdresse(props.folderId, props.client._id, currentAdresse._id).then(res => {
            setCurrentAdresse()
            setGlobalError()
            props.onAdresseChange()
        }).catch(error => {
            setGlobalError(error)
        });
    }

    function editNewAdresse() {
        setCurrentAdresse({
            libelle: '',
            adresse: '',
            code_postal: '',
            observation: '',
            pays: '',
            principal: props.client?.adresses?.length == 0 ? true : false,
            ville: ''
        })
    }

    function handleChange(e) {
        let value
        e.target.type == "checkbox" ? value = e.target.checked : value = e.target.value
        setCurrentAdresse({ ...currentAdresse, [e.target.name]: value })
        setInvalidData({ ...invalidData, [e.target.name]: null })
    }

    return (
        <>

            {props.isClientLoading &&
                <span className="hloader mt-3"></span>
            }

            {!props.isClientLoading &&
                <>
                    <div className="d-flex align-items-center">
                        {(props.client?.adresses?.length == 0 && !currentAdresse) &&
                            <em>- Aucun élément trouvé -</em>
                        }
                        <Button disabled={currentAdresse} className="ms-auto" onClick={() => editNewAdresse()}>Ajouter une adresse</Button>
                    </div>

                    {globalError && (
                        <ErrorManager error={globalError} />
                    )}

                    {(currentAdresse && !currentAdresse._id) &&
                        <div className="p-4 border rounded mt-2 mb-4">

                            <Form.Group className="d-flex align-items-center">
                                <Form.Label column sm={3}>Libelle</Form.Label>
                                <Col sm={9}>
                                    <Form.Control size="sm" name="libelle" value={currentAdresse.libelle} isInvalid={getFormErrorMsg(invalidData?.libelle)} onChange={handleChange} type="text" />
                                    <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.libelle)}</Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group className="d-flex align-items-center">
                                <Form.Label column sm={3}>Adresse</Form.Label>
                                <Col sm={9}>
                                    <Form.Control size="sm" name="adresse" value={currentAdresse.adresse} isInvalid={getFormErrorMsg(invalidData?.adresse)} onChange={handleChange} type="text" />
                                    <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.adresse)}</Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group className="d-flex align-items-center">
                                <Form.Label column sm={3}>Code postal</Form.Label>
                                <Col sm={9}>
                                    <Form.Control size="sm" name="code_postal" value={currentAdresse.code_postal} isInvalid={getFormErrorMsg(invalidData?.code_postal)} onChange={handleChange} type="number" />
                                    <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.code_postal)}</Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group className="d-flex align-items-center">
                                <Form.Label column sm={3}>Observations</Form.Label>
                                <Col sm={9}>
                                    <Form.Control size="sm" name="observation" value={currentAdresse.observation} isInvalid={getFormErrorMsg(invalidData?.observation)} onChange={handleChange} type="text" />
                                    <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.observation)}</Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group className="d-flex align-items-center">
                                <Form.Label column sm={3}>Ville</Form.Label>
                                <Col sm={9}>
                                    <Form.Control size="sm" name="ville" value={currentAdresse.ville} isInvalid={getFormErrorMsg(invalidData?.ville)} onChange={handleChange} type="text" />
                                    <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.ville)}</Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group className="d-flex align-items-center">
                                <Form.Label column sm={3}>Pays</Form.Label>
                                <Col sm={9}>
                                    <Form.Control size="sm" name="pays" value={currentAdresse.pays} isInvalid={getFormErrorMsg(invalidData?.pays)} onChange={handleChange} type="text" />
                                    <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.pays)}</Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group className="mt-2">
                                <Col sm={12}>
                                    <Form.Check type="checkbox" id="adresse-principal">
                                        <Form.Check.Input disabled={props.client?.adresses?.length == 0} name="principal" value={currentAdresse.principal} checked={currentAdresse.principal ? true : false} type="checkbox" onChange={handleChange} isInvalid={getFormErrorMsg(invalidData?.principal)} />
                                        <Form.Check.Label>Adresse principale</Form.Check.Label>
                                        <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.principal)}</Form.Control.Feedback>
                                    </Form.Check>
                                </Col>
                            </Form.Group>
                            <Row>
                                <Col className="d-flex justify-content-end mt-2">
                                    <Button variant="secondary" className="me-2" onClick={() => setCurrentAdresse()}>Annuler</Button>
                                    <Button onClick={postAdresse}>Enregistrer l'adresse</Button>
                                </Col>
                            </Row>
                        </div>
                    }

                    {props.client?.adresses?.map((adresse, i, arr) => (
                        <div key={adresse._id} >
                            {(adresse._id != currentAdresse?._id) &&
                                <div className={"p-4 rounded bg-gray-100 mt-2 " + (arr.length - 1 === i ? "" : "mb-2")}>
                                    <Row>
                                        <Col>
                                            <p className="m-0">{adresse.libelle ? adresse.libelle : adresse.adresse}. {adresse.principal && (<span>(Adresse principale)</span>)}</p>
                                        </Col>
                                        <Col className="col-auto">
                                            <Button variant="secondary" size="sm" onClick={() => setCurrentAdresse(adresse)}>Modifier</Button>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col className="12">
                                            <p className="m-0">Adresse : {adresse.adresse}</p>
                                            <p className="m-0">Libelle : {adresse.libelle}</p>
                                            <p className="m-0">Code postal : {adresse.code_postal}</p>
                                            <p className="m-0">Pays : {adresse.pays}</p>
                                            <p className="m-0">Ville : {adresse.ville}</p>
                                            <p className="m-0 mt-2">{adresse.observation}</p>
                                        </Col>
                                    </Row>


                                </div>
                            }
                            {(adresse._id == currentAdresse?._id) &&
                                <div className="p-4 border rounded mt-2">
                                    <Form.Group className="d-flex align-items-center">
                                        <Form.Label column sm={3}>libelle</Form.Label>
                                        <Col sm={9}>
                                            <Form.Control size="sm" name="libelle" value={currentAdresse.libelle} isInvalid={getFormErrorMsg(invalidData?.libelle)} onChange={handleChange} type="text" />
                                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.libelle)}</Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="d-flex align-items-center">
                                        <Form.Label column sm={3}>adresse</Form.Label>
                                        <Col sm={9}>
                                            <Form.Control size="sm" name="adresse" value={currentAdresse.adresse} isInvalid={getFormErrorMsg(invalidData?.adresse)} onChange={handleChange} type="text" />
                                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.adresse)}</Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="d-flex align-items-center">
                                        <Form.Label column sm={3}>code_postal</Form.Label>
                                        <Col sm={9}>
                                            <Form.Control size="sm" name="code_postal" value={currentAdresse.code_postal} isInvalid={getFormErrorMsg(invalidData?.code_postal)} onChange={handleChange} type="number" />
                                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.code_postal)}</Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="d-flex align-items-center">
                                        <Form.Label column sm={3}>observation</Form.Label>
                                        <Col sm={9}>
                                            <Form.Control size="sm" name="observation" value={currentAdresse.observation} isInvalid={getFormErrorMsg(invalidData?.observation)} onChange={handleChange} type="text" />
                                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.observation)}</Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="d-flex align-items-center">
                                        <Form.Label column sm={3}>pays</Form.Label>
                                        <Col sm={9}>
                                            <Form.Control size="sm" name="pays" value={currentAdresse.pays} isInvalid={getFormErrorMsg(invalidData?.pays)} onChange={handleChange} type="text" />
                                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.pays)}</Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="d-flex align-items-center">
                                        <Form.Label column sm={3}>ville</Form.Label>
                                        <Col sm={9}>
                                            <Form.Control size="sm" name="ville" value={currentAdresse.ville} isInvalid={getFormErrorMsg(invalidData?.ville)} onChange={handleChange} type="text" />
                                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.ville)}</Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="mt-2">
                                        <Col sm={12}>
                                            <Form.Check type="checkbox" id="adresse-principal">
                                                <Form.Check.Input disabled={props.client?.adresses?.length == 1} name="principal" value={currentAdresse.principal} checked={currentAdresse.principal ? true : false} type="checkbox" onChange={handleChange} isInvalid={getFormErrorMsg(invalidData?.principal)} />
                                                <Form.Check.Label>Adresse principale</Form.Check.Label>
                                                <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.principal)}</Form.Control.Feedback>
                                            </Form.Check>
                                        </Col>
                                    </Form.Group>

                                    <Row>
                                        <Col className="d-flex justify-content-end mt-2">
                                            <Button variant="danger" className="me-auto" onClick={removeAdresse}>Supprimer</Button>
                                            <Button variant="secondary" className="me-2" onClick={() => setCurrentAdresse()}>Annuler</Button>
                                            <Button onClick={updateAdresse}>Enregistrer les modifications</Button>
                                        </Col>
                                    </Row>
                                </div>
                            }
                        </div>
                    ))}
                </>
            }


        </>
    );

}
export default Adresses;