import {axiosAPI} from "./axiosClient"
import { UserService } from './UserService';
import { useParams, useSearchParams } from "react-router-dom";
import qs from "qs";
import { getParams } from '../helper/Helper'


export const ParametreService = {

  getParametresListe(folderId, params) {
    return axiosAPI.get(`/${folderId}/parametres/liste?${getParams(params)}`)
  },
  
  getParametres(folderId, excludeReadOnly, exclusive, params) {
    return axiosAPI.get(`/${folderId}/parametres?excludeReadOnly=${excludeReadOnly}&exclusive=${exclusive}&${getParams(params)}`)
  },


  // Dossier

  getParametresDossier(folderId, excludeReadOnly, exclusive, params) {
    return axiosAPI.get(`/${folderId}/parametres/dossier?excludeReadOnly=${excludeReadOnly}&exclusive=${exclusive}&${getParams(params)}`)
  },

  updateParametresDossier(folderId, dataPost){
    return axiosAPI.put(`/${folderId}/parametres/dossier`, qs.stringify(dataPost))
  },

  removeParametresDossier(folderId, pathList) {
    var params = '?params[]=' + pathList.join('&params[]=');
    return axiosAPI.delete(`/${folderId}/parametres/dossier${params}`)
  },

  
  // UsersProfil

  getParametresUsersProfil(folderId, excludeReadOnly, exclusive, params) {
    return axiosAPI.get(`/${folderId}/parametres/usersprofil?excludeReadOnly=${excludeReadOnly}&exclusive=${exclusive}&${getParams(params)}`)
  },

  
  updateParametresUsersProfil(folderId, dataPost){
    return axiosAPI.put(`/${folderId}/parametres/usersprofil`, qs.stringify(dataPost))
  },
  
  removeParametresUsersProfil(folderId, pathList) {
    var params = '?params[]=' + pathList.join('&params[]=');
    return axiosAPI.delete(`/${folderId}/parametres/usersprofil${params}`)
  },


  // UserProfil specifique

  getParametresUserProfil(profilId, folderId, excludeReadOnly, exclusive, params) {
    return axiosAPI.get(`/${folderId}/parametres/usersprofil/${profilId}?excludeReadOnly=${excludeReadOnly}&exclusive=${exclusive}&${getParams(params)}`)
  },

  updateParametresUserProfil(profilId, folderId, dataPost){
    return axiosAPI.put(`/${folderId}/parametres/usersprofil/${profilId}`, qs.stringify(dataPost))
  },

  removeParametresUserProfil(profilId, folderId, pathList) {
    var params = '?params[]=' + pathList.join('&params[]=');
    return axiosAPI.delete(`/${folderId}/parametres/usersprofil/${profilId}${params}`)
  },


}