// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#RecursiveConfig {
  /* .accordion-button {
      background-color: #fff !important;
  } */
  /*     .accordion-button:focus {
          box-shadow: none;
      }

      .accordion-button:not(.collapsed) {
          color: #212529;
      } */
}`, "",{"version":3,"sources":["webpack://./src/pages/administration/recursive-config/RecursiveConfig.scss"],"names":[],"mappings":"AAAA;EACI;;KAAA;EAIJ;;;;;;SAAA;AAMA","sourcesContent":["#RecursiveConfig{\n    /* .accordion-button {\n        background-color: #fff !important;\n    } */\n    \n/*     .accordion-button:focus {\n        box-shadow: none;\n    }\n    \n    .accordion-button:not(.collapsed) {\n        color: #212529;\n    } */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
