import { axiosAPI } from "./axiosClient"
import { UserService } from './UserService';
import { useParams, useSearchParams } from "react-router-dom";
import qs from "qs";
import queryString from 'query-string'
import { getParams } from '../helper/Helper'

export const ArticleService = {

  getArticles(folderId, params, familyId) {
    familyId ? (params['filter[famille_id]'] = familyId) : (params['filter[famille_id]'] = null)
    return axiosAPI.get(`/${folderId}/articles?lookup=true&${getParams(params)}`)
  },

  postArticle(folderId, dataPost) {
    return axiosAPI.post(`/${folderId}/articles`, qs.stringify(dataPost))
  },

  updateArticle(folderId, dataPost) {
    return axiosAPI.put(`/${folderId}/articles`, qs.stringify(dataPost))
  },

  getArticle(folderId, articleId) {
    return axiosAPI.get(`/${folderId}/articles/${articleId}?lookup=true`)
  },

  getTarifs(folderId, articleId) {
    return axiosAPI.get(`/${folderId}/articles/${articleId}/tarifs?lookup=true`)
  },

  postTarif(folderId, articleId, dataPost) {
    return axiosAPI.post(`/${folderId}/articles/${articleId}/tarifs`, qs.stringify(dataPost))
  },

  updateTarif(folderId, articleId, dataPost) {
    return axiosAPI.put(`/${folderId}/articles/${articleId}/tarifs`, qs.stringify(dataPost))
  },

  removeTarif(folderId, articleId, tarifId) {
    return axiosAPI.delete(`/${folderId}/articles/${articleId}/tarifs/${tarifId}`)
  },

  getFournisseurs(folderId, articleId) {
    return axiosAPI.get(`/${folderId}/articles/${articleId}/fournisseurs`)
  },

  postFournisseur(folderId, articleId, dataPost) {
    return axiosAPI.post(`/${folderId}/articles/${articleId}/fournisseurs`, qs.stringify(dataPost))
  },

  updateFournisseur(folderId, articleId, dataPost) {
    return axiosAPI.put(`/${folderId}/articles/${articleId}/fournisseurs`, qs.stringify(dataPost))
  },

  removeFournisseur(folderId, articleId, fournisseurId) {
    return axiosAPI.delete(`/${folderId}/articles/${articleId}/fournisseurs/${fournisseurId}`)
  },

  getFactures(folderId, articleId, params) {
    return axiosAPI.get(`/${folderId}/articles/${articleId}/factures?lookup=true&${getParams(params)}`)
  },

  getProformas(folderId, articleId, params) {
    return axiosAPI.get(`/${folderId}/articles/${articleId}/proformas?${getParams(params)}`)
  },

  removeArticles(folderId, articleIdList) {
    var params = '?articles[]=' + articleIdList.join('&articles[]=');
    return axiosAPI.delete(`/${folderId}/articles${params}`)
  },

  getHistorique(folderId, articleId, params) {
    return axiosAPI.get(`/${folderId}/blockchains/articles/history/${articleId}`)
  },

  getNextCode(folderId, prefix) {
    return axiosAPI.get(`/${folderId}/articles/nextcode/${prefix}`)
  },

  postTag(folderId, articleId, tagId) {
    return axiosAPI.post(`/${folderId}/articles/${articleId}/tags/${tagId}`)
  },

  removeTag(folderId, articleId, tagId) {
    return axiosAPI.delete(`/${folderId}/articles/${articleId}/tags/${tagId}`)
  },

}