// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#FamilyInput .dropdown-menu {
  max-height: 350px;
  overflow: auto;
}
#FamilyInput .treenode {
  margin-left: 16px;
  display: flex;
  flex-direction: column;
}
#FamilyInput .family {
  display: flex;
  align-items: center;
  border-radius: 20px;
  position: relative;
}
#FamilyInput .family.disabled {
  cursor: not-allowed;
  pointer-events: none;
}
#FamilyInput .family > span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 0.85rem;
  width: 100%;
  padding: 4px 0px 2px 6px;
}
#FamilyInput .family .caret {
  width: 12px;
  margin-left: -22px;
  position: absolute;
  transform: rotate(90deg);
  font-size: 12px;
}
#FamilyInput .family .caret.caret-close {
  transform: rotate(0deg);
}
#FamilyInput .subfamilies {
  display: block;
}
#FamilyInput .subfamilies.close {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/form-inputs/family-input/FamilyInput.scss"],"names":[],"mappings":"AACI;EACI,iBAAA;EACA,cAAA;AAAR;AAEI;EAII,iBAFS;EAGT,aAAA;EACA,sBAAA;AAHR;AAKI;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,kBAAA;AAHR;AAKQ;EACI,mBAAA;EACA,oBAAA;AAHZ;AAKQ;EACI,uBAAA;EACA,mBAAA;EACA,gBAAA;EACA,kBAAA;EACA,WAAA;EACA,wBAAA;AAHZ;AAKQ;EACI,WAAA;EACA,kBAAA;EACA,kBAAA;EACA,wBAAA;EACA,eAAA;AAHZ;AAKY;EACI,uBAAA;AAHhB;AAQI;EACI,cAAA;AANR;AAOQ;EACI,aAAA;AALZ","sourcesContent":["#FamilyInput{\n    .dropdown-menu{\n        max-height: 350px;\n        overflow: auto;\n    }\n    .treenode{\n    \n        $spacer: 16px;\n    \n        margin-left: $spacer;\n        display: flex;\n        flex-direction: column;\n    }\n    .family{\n        display: flex;\n        align-items: center;\n        border-radius: 20px;\n        position: relative;\n\n        &.disabled{\n            cursor: not-allowed;\n            pointer-events: none;\n        }\n        &>span{\n            text-overflow: ellipsis;\n            white-space: nowrap;\n            overflow: hidden;\n            font-size: 0.85rem;\n            width: 100%;\n            padding: 4px 0px 2px 6px;\n        }\n        .caret{\n            width: 12px;\n            margin-left: -22px;\n            position: absolute;\n            transform: rotate(90deg);\n            font-size: 12px;\n    \n            &.caret-close{\n                transform: rotate(0deg);\n            }\n        }\n        \n    }\n    .subfamilies{\n        display: block;\n        &.close{\n            display: none;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
