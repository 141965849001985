import React, { useState, useEffect } from 'react';

import { UserService } from '../../services/UserService';
import { FolderService } from '../../services/FolderService';
import ColManager from '../../components/table/dynamic-table/col-manager/ColManager';
import { GlobalContext, GlobalDispatchContext } from "../../services/GlobalProvider";
import { useNavigate } from "react-router-dom";
import { Navbar, Container, Nav, NavDropdown, Dropdown, Button, Row, Col, Form, Table, InputGroup, FormControl, OverlayTrigger, Tooltip, DropdownButton } from 'react-bootstrap';
import { Link } from "react-router-dom";
import axios from 'axios';
import '../../config.inc';
import './Accueil.scss';


export default function Accueil() {

  /*   evtSource.onmessage = (event) => {
  
      console.log('-----event')
      console.log(event)
  
      setMessage(event.data)
    } */

  /* 
  ---> APPEL OK : 
  evtSource.addEventListener("time", (event) => {
      console.log(event);
      setMessage(event.data)
    }); */

  //content-type=text/event-stream;charset=UTF-8


  /*   const eventSource = new EventSource(resoureUrl, {
      headers: {
          'Authorization': 'Bearer ' + authorizationToken
      }
    }); */


  /*   const axiosAPI = axios.create({
      baseURL: global.config._API_URL_,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }) */


  /*   useEffect(() => {
  
      axios.get(`https://api.xlprog.nc/admin/testsse/`)
      .then(res => {
        console.log(res)
      })
    
      
    }, []); */


  /* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */

  /*   const token = localStorage.getItem('access_token');
  
    const evtSource = new EventSource(`${global.config._API_URL_}/admin/sse?key=${token}`, {
      //withCredentials: true,
      //authorizationHeader: "Bearer " + token,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'text/event-stream',
        'charset': 'charset=UTF-8'
      }
    } ); */

  const [message, setMessage] = useState("XX")
  return (
    <Container fluid id="Accueil" className="py-4">
      <Row>
        <Col xs={8}>
          <h6>Page d'Accueil test</h6>
          <p>En développement</p>

        </Col>

        {/* <Col xs={4} className="border rounded p-3">

          <h6 className="mb-4">Facture rapide</h6>

          <div className="d-flex mb-3">
            <div className="flex-fill pe-2">
              <InputGroup>
                <i className="material-icons input-icon text-gray-400">search</i>
                <FormControl
                  className="rounded-start input-icon-space"
                  placeholder="Client"
                  type="search"
                />
              </InputGroup>
            </div>
            <div>
              <OverlayTrigger
                placement="left"
                delay={{ show: 800 }}
                overlay={
                  <Tooltip>
                    Créer un client
                  </Tooltip>
                }
              >
                <Button variant="secondary" >
                  <i className="material-icons i-lg">person_add</i>
                </Button>
              </OverlayTrigger>
            </div>
          </div>

          <div className="p-3 bg-gray-100 rounded mb-3">


            <Table responsive size="sm" borderless className="text-nowrap align-middle mb-2">
              <thead className="text-uppercase text-gray-600 small">
                <tr>
                  <th>#</th>
                  <th>CODE</th>
                  <th>DESIGNATION</th>
                  <th className="text-end">QUANTITE</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>126565</td>
                  <td className="text-truncate">LOGICIEL FACTUREXL</td>
                  <td className="text-end">1</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>763809</td>
                  <td className="text-truncate">LICENCE PAR POSTE SUPPLEMENTAIRE (IMMOXL)</td>
                  <td className="text-end">3</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>728394</td>
                  <td className="text-truncate">LOGICIEL POINT DE VENTE</td>
                  <td className="text-end">1</td>
                </tr>
              </tbody>
            </Table>

            <InputGroup>
              <i className="material-icons input-icon text-gray-400">search</i>
              <FormControl
                className="rounded-start input-icon-space"
                placeholder="Articles"
                type="search"
              />
            </InputGroup>
          </div>

          <InputGroup className="mb-3">
            <i className="material-icons input-icon text-gray-400">credit_card</i>
            <FormControl
              className="rounded-start input-icon-space w-auto"
              placeholder="Montant du règlement"
              type="search"
            />
            <Form.Select className="">
              <option disabled>- Type -</option>
              <option>Carte bleue</option>
              <option>Chèque</option>
              <option>Espèces</option>
            </Form.Select>
          </InputGroup>

          <Button className="float-end ms-2">Valider la facture</Button>
          <Button variant="link" className="float-end">Modifier en version complète</Button>




        </Col> */}

      </Row>

    </Container>
  );
}