import React, { useEffect, useState, useRef } from 'react';
import { TagService } from '../../../../../services/TagService';
import { ClientService } from '../../../../../services/ClientService';
import { Form, Spinner, Card, Tabs, Tab, Row, Col, Accordion, Button, OverlayTrigger, InputGroup, Tooltip, Dropdown, Badge } from 'react-bootstrap';
import ErrorManager from "../../../../error-manager/ErrorManager";
import { isDark, granted, getChangedValues } from '../../../../../helper/Helper'
import ConfirmDialog from '../../../../../components/confirm-dialog/ConfirmDialog';
import { GlobalContext } from "../../../../../services/GlobalProvider";
import Pagination from '../../../../../components/pagination/Pagination';

import './Tags.scss';

const Tags = (props) => {

    const [globalError, setGlobalError] = useState();
    const [tagListData, setTagListData] = useState();
    const [inputColor, setInputColor] = useState("#e5e7e9");
    const [openColorPalet, setOpenColorPalet] = useState(false);
    const [isTagListLoading, setIsTagListLoading] = useState(false);
    const [isAddTagLoading, setIsAddTagLoading] = useState(false);
    const [isCreateTagLoading, setIsCreateTagLoading] = useState(false);
    const [confirmDialog, setConfirmDialog] = useState({ show: false, dialogInfos: null })
    const [currentPagination, setCurrentPagination] = useState(1)

    const { grants } = React.useContext(GlobalContext);

    const searchInput = useRef("");

    let tagsParams = useRef({
        'start': 0,
        'limit': 50,
        'search': null,
        'filters': {
            'filter[type]': [{ operator: "=", value: "clients" }],
            'filter[tag]': []
        },
        'sort': null,
        'order': null
    });

    useEffect(() => {
        getTagList()
    }, []);

    function changeInputColor(color) {
        setInputColor(color)
        setOpenColorPalet(false)
    }

    function changePagination(p) {
        setCurrentPagination(p)
        tagsParams.current['start'] = (tagsParams.current.limit * p) - tagsParams.current.limit
        getTagList()
    }

    let searchTimeOut;
    function changeSearchValue(value) {
        window.clearTimeout(searchTimeOut);
        searchTimeOut = window.setTimeout(function () {
            if (value) {
                tagsParams.current['filters']['filter[tag]'] = [{ operator: "", value: value }]
            } else {
                tagsParams.current['filters']['filter[tag]'] = []
            }
            setCurrentPagination(1)
            tagsParams.current['start'] = 0
            getTagList()
        }, 800);
    }

    function getTagList() {
        setGlobalError()
        setIsTagListLoading(true)
        TagService.getTags(props.folderId, tagsParams.current).then(res => {
            res.status == 204 ? setTagListData() : setTagListData(res.data.data)
            setIsTagListLoading(false)
        }).catch(error => {
            setGlobalError(error)
            setIsTagListLoading(false)
        });
    }

    function createTag() {
        setGlobalError()
        setIsCreateTagLoading(true)
        let tag = {
            type: "clients",
            tag: searchInput.current.value,
            couleur: inputColor
        }
        let clients = [props.client._id]
        TagService.postClientListTag(props.folderId, { tag: tag, clients: clients }).then(res => {
            searchInput.current.value = ""
            changeSearchValue()
            props.onTagChange()
            setIsCreateTagLoading(false)
        }).catch(error => {
            setGlobalError(error)
            setIsCreateTagLoading(false)
        });
    }

    function addTag(tag) {
        setGlobalError()
        setIsAddTagLoading(true)
        ClientService.postTag(props.folderId, props.client._id, tag._id).then(res => {
            props.onTagChange()
            setIsAddTagLoading(false)
        }).catch(error => {
            setGlobalError(error)
            setIsAddTagLoading(false)
        });

    }

    function removeTagDialog(tag) {
        setConfirmDialog({
            show: true,
            dialogInfos: {
                title: `Supprimer le tag du client`,
                description: `Voulez vous supprimer le tag "${tag.tag}" ?`,
                actionName: 'Supprimer',
                btnVariant: 'danger',
                element: tag,
                error: false,
                confirmAction: 'removeTag'
            }
        })
    }

    function removeTag(tag) {
        setGlobalError()
        setConfirmDialog({ show: false })
        TagService.removeClientListTag(props.folderId, tag._id, [props.client._id]).then(res => {
            tagsParams.current['start'] = 0
            setCurrentPagination(1)
            props.onTagChange()
            getTagList()
        }).catch(error => {
            setGlobalError(error)
        });
    }

    return (
        <div id="Tags">

            {globalError && (
                <ErrorManager error={globalError} />
            )}

            <ConfirmDialog
                show={confirmDialog.show}
                dialogInfos={confirmDialog.dialogInfos}
                setCancel={() => setConfirmDialog({ show: false })}
                setConfirm={(a, e) => eval(a)(e)}
            />

            <h6>Tags appliqués au client :</h6>

            {(props.client?.tags?.length == 0) &&
                <em>- Aucun tag n'est appliqué au client -</em>
            }

            {(props.client?.tags?.length > 0) && props.client?.tags.map(tag => (
                <span key={tag._id} className={"me-2 rounded-5 px-2 py-1 d-inline-flex mb-2 small fw-bold " + (isDark(tag.couleur) ? "text-dark" : "text-white")} style={{ backgroundColor: tag.couleur }}>
                    {tag.tag}
                    {granted(grants, "tags.edit") &&
                        <i className="material-icons ms-1 i-lg cursor-pointer" onClick={() => removeTagDialog(tag)}>close</i>
                    }
                </span>
            ))}

            {(isCreateTagLoading || isAddTagLoading) &&
                <Spinner animation="border" size="sm" className="mt-1" />
            }

            {granted(grants, "tags.edit") &&
                <>
                    <h6 className="mt-4">Appliquer les tags :</h6>
                    <InputGroup className="mb-3">
                        <Form.Control
                            ref={searchInput}
                            placeholder="Rechercher un tag"
                            onChange={e => changeSearchValue(e.target.value)}
                        />
                        {searchInput.current.value &&
                            <>
                                <Button disabled={isTagListLoading} className="align-items-center d-flex" variant="secondary" onClick={() => setOpenColorPalet(!openColorPalet)}><span style={{ backgroundColor: inputColor }} className="color-dot border me-1"></span> Couleur</Button>
                                <Button disabled={isTagListLoading || isCreateTagLoading} onClick={() => createTag()} className="d-flex">
                                    <i className="material-icons me-2">
                                        west
                                    </i>
                                    Créer ce tag
                                </Button>
                            </>
                        }
                    </InputGroup>
                    {openColorPalet &&
                        <div className="position-relative">
                            <div className="color-container d-flex position-absolute zindex-9000 rounded border bg-white shadow overflow-auto mt-1 p-1">
                                <div className="color-group">
                                    <span className="color-cell" onClick={() => changeInputColor("#f9ebea")} style={{ backgroundColor: '#f9ebea' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#f2d7d5")} style={{ backgroundColor: '#f2d7d5' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#e6b0aa")} style={{ backgroundColor: '#e6b0aa' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#d98880")} style={{ backgroundColor: '#d98880' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#cd6155")} style={{ backgroundColor: '#cd6155' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#c0392b")} style={{ backgroundColor: '#c0392b' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#a93226")} style={{ backgroundColor: '#a93226' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#922b21")} style={{ backgroundColor: '#922b21' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#7b241c")} style={{ backgroundColor: '#7b241c' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#641e16")} style={{ backgroundColor: '#641e16' }}></span>
                                </div>
                                <div className="color-group">
                                    <span className="color-cell" onClick={() => changeInputColor("#fdedec")} style={{ backgroundColor: '#fdedec' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#fadbd8")} style={{ backgroundColor: '#fadbd8' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#f5b7b1")} style={{ backgroundColor: '#f5b7b1' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#f1948a")} style={{ backgroundColor: '#f1948a' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#ec7063")} style={{ backgroundColor: '#ec7063' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#e74c3c")} style={{ backgroundColor: '#e74c3c' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#cb4335")} style={{ backgroundColor: '#cb4335' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#b03a2e")} style={{ backgroundColor: '#b03a2e' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#943126")} style={{ backgroundColor: '#943126' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#78281f")} style={{ backgroundColor: '#78281f' }}></span>
                                </div>
                                <div className="color-group">
                                    <span className="color-cell" onClick={() => changeInputColor("#f5eef8")} style={{ backgroundColor: '#f5eef8' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#ebdef0")} style={{ backgroundColor: '#ebdef0' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#d7bde2")} style={{ backgroundColor: '#d7bde2' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#c39bd3")} style={{ backgroundColor: '#c39bd3' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#af7ac5")} style={{ backgroundColor: '#af7ac5' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#9b59b6")} style={{ backgroundColor: '#9b59b6' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#884ea0")} style={{ backgroundColor: '#884ea0' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#76448a")} style={{ backgroundColor: '#76448a' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#633974")} style={{ backgroundColor: '#633974' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#512e5f")} style={{ backgroundColor: '#512e5f' }}></span>
                                </div>
                                <div className="color-group">
                                    <span className="color-cell" onClick={() => changeInputColor("#f4ecf7")} style={{ backgroundColor: '#f4ecf7' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#e8daef")} style={{ backgroundColor: '#e8daef' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#d2b4de")} style={{ backgroundColor: '#d2b4de' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#bb8fce")} style={{ backgroundColor: '#bb8fce' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#a569bd")} style={{ backgroundColor: '#a569bd' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#8e44ad")} style={{ backgroundColor: '#8e44ad' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#7d3c98")} style={{ backgroundColor: '#7d3c98' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#6c3483")} style={{ backgroundColor: '#6c3483' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#5b2c6f")} style={{ backgroundColor: '#5b2c6f' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#4a235a")} style={{ backgroundColor: '#4a235a' }}></span>
                                </div>
                                <div className="color-group">
                                    <span className="color-cell" onClick={() => changeInputColor("#eaf2f8")} style={{ backgroundColor: '#eaf2f8' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#d4e6f1")} style={{ backgroundColor: '#d4e6f1' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#a9cce3")} style={{ backgroundColor: '#a9cce3' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#7fb3d5")} style={{ backgroundColor: '#7fb3d5' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#5499c7")} style={{ backgroundColor: '#5499c7' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#2980b9")} style={{ backgroundColor: '#2980b9' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#2471a3")} style={{ backgroundColor: '#2471a3' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#1f618d")} style={{ backgroundColor: '#1f618d' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#1a5276")} style={{ backgroundColor: '#1a5276' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#154360")} style={{ backgroundColor: '#154360' }}></span>
                                </div>
                                <div className="color-group">
                                    <span className="color-cell" onClick={() => changeInputColor("#ebf5fb")} style={{ backgroundColor: '#ebf5fb' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#d6eaf8")} style={{ backgroundColor: '#d6eaf8' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#aed6f1")} style={{ backgroundColor: '#aed6f1' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#85c1e9")} style={{ backgroundColor: '#85c1e9' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#5dade2")} style={{ backgroundColor: '#5dade2' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#3498db")} style={{ backgroundColor: '#3498db' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#2e86c1")} style={{ backgroundColor: '#2e86c1' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#2874a6")} style={{ backgroundColor: '#2874a6' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#21618c")} style={{ backgroundColor: '#21618c' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#1b4f72")} style={{ backgroundColor: '#1b4f72' }}></span>
                                </div>
                                <div className="color-group">
                                    <span className="color-cell" onClick={() => changeInputColor("#e8f8f5")} style={{ backgroundColor: '#e8f8f5' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#d1f2eb")} style={{ backgroundColor: '#d1f2eb' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#a3e4d7")} style={{ backgroundColor: '#a3e4d7' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#76d7c4")} style={{ backgroundColor: '#76d7c4' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#48c9b0")} style={{ backgroundColor: '#48c9b0' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#1abc9c")} style={{ backgroundColor: '#1abc9c' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#17a589")} style={{ backgroundColor: '#17a589' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#148f77")} style={{ backgroundColor: '#148f77' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#117864")} style={{ backgroundColor: '#117864' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#0e6251")} style={{ backgroundColor: '#0e6251' }}></span>
                                </div>
                                <div className="color-group">
                                    <span className="color-cell" onClick={() => changeInputColor("#e8f6f3")} style={{ backgroundColor: '#e8f6f3' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#d0ece7")} style={{ backgroundColor: '#d0ece7' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#a2d9ce")} style={{ backgroundColor: '#a2d9ce' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#73c6b6")} style={{ backgroundColor: '#73c6b6' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#45b39d")} style={{ backgroundColor: '#45b39d' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#16a085")} style={{ backgroundColor: '#16a085' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#138d75")} style={{ backgroundColor: '#138d75' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#117a65")} style={{ backgroundColor: '#117a65' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#0e6655")} style={{ backgroundColor: '#0e6655' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#0b5345")} style={{ backgroundColor: '#0b5345' }}></span>
                                </div>
                                <div className="color-group">
                                    <span className="color-cell" onClick={() => changeInputColor("#e9f7ef")} style={{ backgroundColor: '#e9f7ef' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#d4efdf")} style={{ backgroundColor: '#d4efdf' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#a9dfbf")} style={{ backgroundColor: '#a9dfbf' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#7dcea0")} style={{ backgroundColor: '#7dcea0' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#52be80")} style={{ backgroundColor: '#52be80' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#27ae60")} style={{ backgroundColor: '#27ae60' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#229954")} style={{ backgroundColor: '#229954' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#1e8449")} style={{ backgroundColor: '#1e8449' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#196f3d")} style={{ backgroundColor: '#196f3d' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#145a32")} style={{ backgroundColor: '#145a32' }}></span>
                                </div>
                                <div className="color-group">
                                    <span className="color-cell" onClick={() => changeInputColor("#eafaf1")} style={{ backgroundColor: '#eafaf1' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#d5f5e3")} style={{ backgroundColor: '#d5f5e3' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#abebc6")} style={{ backgroundColor: '#abebc6' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#82e0aa")} style={{ backgroundColor: '#82e0aa' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#58d68d")} style={{ backgroundColor: '#58d68d' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#2ecc71")} style={{ backgroundColor: '#2ecc71' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#28b463")} style={{ backgroundColor: '#28b463' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#239b56")} style={{ backgroundColor: '#239b56' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#1d8348")} style={{ backgroundColor: '#1d8348' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#186a3b")} style={{ backgroundColor: '#186a3b' }}></span>
                                </div>
                                <div className="color-group">
                                    <span className="color-cell" onClick={() => changeInputColor("#fef9e7")} style={{ backgroundColor: '#fef9e7' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#fcf3cf")} style={{ backgroundColor: '#fcf3cf' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#f9e79f")} style={{ backgroundColor: '#f9e79f' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#f7dc6f")} style={{ backgroundColor: '#f7dc6f' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#f4d03f")} style={{ backgroundColor: '#f4d03f' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#f1c40f")} style={{ backgroundColor: '#f1c40f' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#d4ac0d")} style={{ backgroundColor: '#d4ac0d' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#b7950b")} style={{ backgroundColor: '#b7950b' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#9a7d0a")} style={{ backgroundColor: '#9a7d0a' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#7d6608")} style={{ backgroundColor: '#7d6608' }}></span>
                                </div>
                                <div className="color-group">
                                    <span className="color-cell" onClick={() => changeInputColor("#fef5e7")} style={{ backgroundColor: '#fef5e7' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#fdebd0")} style={{ backgroundColor: '#fdebd0' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#fad7a0")} style={{ backgroundColor: '#fad7a0' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#f8c471")} style={{ backgroundColor: '#f8c471' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#f5b041")} style={{ backgroundColor: '#f5b041' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#f39c12")} style={{ backgroundColor: '#f39c12' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#d68910")} style={{ backgroundColor: '#d68910' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#b9770e")} style={{ backgroundColor: '#b9770e' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#9c640c")} style={{ backgroundColor: '#9c640c' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#7e5109")} style={{ backgroundColor: '#7e5109' }}></span>
                                </div>
                                <div className="color-group">
                                    <span className="color-cell" onClick={() => changeInputColor("#fdf2e9")} style={{ backgroundColor: '#fdf2e9' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#fae5d3")} style={{ backgroundColor: '#fae5d3' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#f5cba7")} style={{ backgroundColor: '#f5cba7' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#f0b27a")} style={{ backgroundColor: '#f0b27a' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#eb984e")} style={{ backgroundColor: '#eb984e' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#e67e22")} style={{ backgroundColor: '#e67e22' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#ca6f1e")} style={{ backgroundColor: '#ca6f1e' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#af601a")} style={{ backgroundColor: '#af601a' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#935116")} style={{ backgroundColor: '#935116' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#784212")} style={{ backgroundColor: '#784212' }}></span>
                                </div>
                                <div className="color-group">
                                    <span className="color-cell" onClick={() => changeInputColor("#fbeee6")} style={{ backgroundColor: '#fbeee6' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#f6ddcc")} style={{ backgroundColor: '#f6ddcc' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#edbb99")} style={{ backgroundColor: '#edbb99' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#e59866")} style={{ backgroundColor: '#e59866' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#dc7633")} style={{ backgroundColor: '#dc7633' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#d35400")} style={{ backgroundColor: '#d35400' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#ba4a00")} style={{ backgroundColor: '#ba4a00' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#a04000")} style={{ backgroundColor: '#a04000' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#873600")} style={{ backgroundColor: '#873600' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#6e2c00")} style={{ backgroundColor: '#6e2c00' }}></span>
                                </div>
                                <div className="color-group">
                                    <span className="color-cell" onClick={() => changeInputColor("#fdfefe")} style={{ backgroundColor: '#fdfefe' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#fbfcfc")} style={{ backgroundColor: '#fbfcfc' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#f7f9f9")} style={{ backgroundColor: '#f7f9f9' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#f4f6f7")} style={{ backgroundColor: '#f4f6f7' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#f0f3f4")} style={{ backgroundColor: '#f0f3f4' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#ecf0f1")} style={{ backgroundColor: '#ecf0f1' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#d0d3d4")} style={{ backgroundColor: '#d0d3d4' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#b3b6b7")} style={{ backgroundColor: '#b3b6b7' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#979a9a")} style={{ backgroundColor: '#979a9a' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#7b7d7d")} style={{ backgroundColor: '#7b7d7d' }}></span>
                                </div>
                                <div className="color-group">
                                    <span className="color-cell" onClick={() => changeInputColor("#f8f9f9")} style={{ backgroundColor: '#f8f9f9' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#f2f3f4")} style={{ backgroundColor: '#f2f3f4' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#e5e7e9")} style={{ backgroundColor: '#e5e7e9' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#d7dbdd")} style={{ backgroundColor: '#d7dbdd' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#cacfd2")} style={{ backgroundColor: '#cacfd2' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#bdc3c7")} style={{ backgroundColor: '#bdc3c7' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#a6acaf")} style={{ backgroundColor: '#a6acaf' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#909497")} style={{ backgroundColor: '#909497' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#797d7f")} style={{ backgroundColor: '#797d7f' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#626567")} style={{ backgroundColor: '#626567' }}></span>
                                </div>
                                <div className="color-group">
                                    <span className="color-cell" onClick={() => changeInputColor("#f2f4f4")} style={{ backgroundColor: '#f2f4f4' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#e5e8e8")} style={{ backgroundColor: '#e5e8e8' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#ccd1d1")} style={{ backgroundColor: '#ccd1d1' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#b2babb")} style={{ backgroundColor: '#b2babb' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#99a3a4")} style={{ backgroundColor: '#99a3a4' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#7f8c8d")} style={{ backgroundColor: '#7f8c8d' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#707b7c")} style={{ backgroundColor: '#707b7c' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#616a6b")} style={{ backgroundColor: '#616a6b' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#515a5a")} style={{ backgroundColor: '#515a5a' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#424949")} style={{ backgroundColor: '#424949' }}></span>
                                </div>
                                <div className="color-group">
                                    <span className="color-cell" onClick={() => changeInputColor("#eaecee")} style={{ backgroundColor: '#eaecee' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#d5d8dc")} style={{ backgroundColor: '#d5d8dc' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#abb2b9")} style={{ backgroundColor: '#abb2b9' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#808b96")} style={{ backgroundColor: '#808b96' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#566573")} style={{ backgroundColor: '#566573' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#2c3e50")} style={{ backgroundColor: '#2c3e50' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#273746")} style={{ backgroundColor: '#273746' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#212f3d")} style={{ backgroundColor: '#212f3d' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#1c2833")} style={{ backgroundColor: '#1c2833' }}></span>
                                    <span className="color-cell" onClick={() => changeInputColor("#17202a")} style={{ backgroundColor: '#17202a' }}></span>
                                </div>
                            </div>
                        </div>
                    }

                    {tagListData?.tags?.length > 0 && (
                        <div>
                            {tagListData.tags.map(tag => (
                                <span key={tag._id} onClick={() => (!isTagListLoading && !isAddTagLoading) && (addTag(tag))} className={"cursor-pointer me-1 rounded-5 px-2 d-inline-flex mb-2 small fw-bold " + (isDark(tag.couleur) ? "text-dark " : "text-white ")} style={{ backgroundColor: tag.couleur, opacity: (isTagListLoading || isAddTagLoading) ? .5 : 1 }}>
                                    {tag.tag}
                                </span>
                            ))}
                        </div>
                    )}

                    {!tagListData && (
                        <em>- Aucun tag trouvé -</em>
                    )}

                    <Pagination itemsLength={tagListData?.total} elementsPerPage={tagsParams.current.limit} currentPagination={currentPagination} changePagination={p => changePagination(p)} />

                </>
            }

        </div>
    );

}
export default Tags;