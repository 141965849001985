import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { UserService } from '../../services/UserService'
import { setCookie, getCookie, getDomainFromUrl } from '../../helper/Helper';

export default function Logout() {

    // remove cookies 
    sessionStorage.removeItem('access_token');
    setCookie("id_token", '', -10, getDomainFromUrl(global.config._FRONT_URL_))
    setCookie("refresh_token", '', -10, getDomainFromUrl(global.config._FRONT_URL_))

    // broadcast : on informe les autres pages / onglets qu'une déconnexion a eu lieu
    const bc = new BroadcastChannel('authentication');
    bc.postMessage('logout');

    return null

}