// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#TarifArticleInput {
  overflow: visible;
  position: relative;
}
#TarifArticleInput .drop-container-bottom {
  top: 100%;
  margin-top: 10px;
  width: 100%;
}
#TarifArticleInput table tr.selected {
  box-shadow: 0px 0px 0px 2px #01a3ff;
}
#TarifArticleInput table tr td {
  white-space: nowrap;
  max-width: none !important;
}
#TarifArticleInput .search-prefix {
  position: absolute;
  z-index: 15;
  top: 5px;
  bottom: 5px;
  left: 5px;
  width: 26px;
  font-family: "Plus Jakarta Sans", sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/components/side-details/tarif/tarif-details/articles/article-input/ArticleInput.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,kBAAA;AACJ;AACI;EACI,SAAA;EACA,gBAAA;EACA,WAAA;AACR;AAKY;EACI,mCAAA;AAHhB;AAMY;EACI,mBAAA;EACA,0BAAA;AAJhB;AASI;EACI,kBAAA;EACA,WAAA;EACA,QAAA;EACA,WAAA;EACA,SAAA;EACA,WAAA;EACA,4CAAA;AAPR","sourcesContent":["#TarifArticleInput {\n    overflow: visible;\n    position: relative;\n\n    .drop-container-bottom {\n        top: 100%;\n        margin-top: 10px;\n        width: 100%;\n    }\n\n    table {\n        //table-layout: fixed;\n        tr {\n            &.selected {\n                box-shadow: 0px 0px 0px 2px #01a3ff;\n            }\n\n            td {\n                white-space: nowrap;\n                max-width: none !important;\n            }\n        }\n    }\n\n    .search-prefix {\n        position: absolute;\n        z-index: 15;\n        top: 5px;\n        bottom: 5px;\n        left: 5px;\n        width: 26px;\n        font-family: 'Plus Jakarta Sans', sans-serif;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
