import React, { useEffect, useRef, useState } from 'react';
import { Table, Badge, InputGroup, Alert, FormControl, Card, Button, Dropdown, DropdownButton, Form, Col, Row, Spinner, ListGroup, Tooltip, Accordion, Tab } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { GlobalContext } from "../../../services/GlobalProvider";
import { getFormErrorMsg, getInvalidObject, getChangedValues, updateObjectValue, deepFind } from '../../../helper/Helper'
import ErrorManager from "../../../components/error-manager/ErrorManager";
import TableHeaders from "./table-headers/TableHeaders";
import FieldsLabel from "./fields-label/FieldsLabel";
import { UsersProfilService } from '../../../services/UsersProfilService';
import WysiwygInput from "../../../components/form-inputs/wysiwyg-input/WysiwygInput";


const PrefsParams = (props) => {

    const { preferences, folderDetails, getParametres, getPreferences } = React.useContext(GlobalContext);

    const [configListe, setConfigListe] = useState({});
    const [configToUpload, setConfigToUpload] = useState({});
    const [configInitial, setConfigInitial] = useState({});
    const [configDefined, setConfigDefined] = useState({});
    const [configFolderDefined, setConfigFolderDefined] = useState({});
    const [configUsersProfilDefined, setConfigUsersProfilDefined] = useState({});
    const [globalError, setGlobalError] = useState()
    const [focusId, setFocusId] = useState()
    const [disableLevels, setDisableLevels] = useState([])
    const [usersProfils, setUsersProfils] = useState()
    const [hasValueChanged, setHasValueChanged] = useState(false)
    const [isSaveLoading, setIsSaveLoading] = useState(false)

    let params = { params: props?.fieldList }

    useEffect(() => {
        if (focusId) {
            props.setIsConfirmationNeeded(true)
        } else {
            props.setIsConfirmationNeeded(false)
        }
    }, [focusId]);

    useEffect(() => {
        if (folderDetails) {
            if (props.source == "userprofil") {
                getProfils()
            } else {
                getConfigs()
            }
        }
    }, [folderDetails]);

    let currentProfilId = useRef();

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    function handleKeyDown(e) {
        if (focusId) {
            const { code } = e;
            switch (code) {
                case "ArrowUp":
                    break;
                case "ArrowDown":
                    break;
                case "Enter":
                    if (e.ctrlKey) {
                        updateConfig()
                        return;
                    }
                    break;
                case "Escape":
                    cancelChange()
                    break;
                default:
                    break;
            }
        }
    }

    function getProfils() {
        UsersProfilService.getUsersProfils(folderDetails?.dossier?._id, false, false).then(res => {
            setUsersProfils(res.data.data.profils)
            currentProfilId.current = res.data.data.profils[0]._id
            getConfigs()
        }).catch(error => {
            setGlobalError(error)
        });
    }

    function handleChangeProfil(e) {
        currentProfilId.current = e.target.value
        setConfigListe({})
        setConfigInitial({})
        setConfigToUpload({})
        getConfigs()
    }

    function getConfigs() {

        setConfigDefined({})

        props.getListeService(folderDetails?.dossier?._id, params).then(res => {
            setConfigListe(res.data.data)
        }).catch(error => {
            setGlobalError(error)
        });

        if (props.source == "dossier") {

            props.getFolderService(folderDetails?.dossier?._id, true, false, params).then(res => {
                setConfigInitial(res.data.data[props.apiObject])
            }).catch(error => {
                setGlobalError(error)
            });

            props.getFolderService(folderDetails?.dossier?._id, true, true, params).then(res => {
                res.status == 200 && setConfigDefined(res.data.data[props.apiObject])
            }).catch(error => {
                setGlobalError(error)
            });

        }

        if (props.source == "userprofil") {

            setDisableLevels(["dossier"])

            props.getUserProfilService(currentProfilId.current, folderDetails?.dossier?._id, true, false, params).then(res => {
                setConfigInitial(res.data.data[props.apiObject])
            }).catch(error => {
                setGlobalError(error)
            });

            props.getFolderService(folderDetails?.dossier?._id, true, true, params).then(res => {
                res.status == 200 && setConfigFolderDefined(res.data.data[props.apiObject])
            }).catch(error => {
                setGlobalError(error)
            });

            props.getUserProfilService(currentProfilId.current, folderDetails?.dossier?._id, true, true, params).then(res => {
                res.status == 200 && setConfigDefined(res.data.data[props.apiObject])
            }).catch(error => {
                setGlobalError(error)
            });

        }

        if (props.source == "user") {

            setDisableLevels(["dossier", "userprofil"])

            props.getService(folderDetails?.dossier?._id, true, false, params).then(res => {
                setConfigInitial(res.data.data[props.apiObject])
            }).catch(error => {
                setGlobalError(error)
            });

            props.getFolderService(folderDetails?.dossier?._id, true, true, params).then(res => {
                res.status == 200 && setConfigFolderDefined(res.data.data[props.apiObject])
            }).catch(error => {
                setGlobalError(error)
            });

            props.getUsersProfilService(folderDetails?.dossier?._id, true, true, params).then(res => {
                res.status == 200 && setConfigUsersProfilDefined(res.data.data[props.apiObject])
            }).catch(error => {
                setGlobalError(error)
            });

            props.getService(folderDetails?.dossier?._id, true, true, params).then(res => {
                res.status == 200 && setConfigDefined(res.data.data[props.apiObject])
            }).catch(error => {
                setGlobalError(error)
            });

        }

    }

    function updateConfig(path) {

        setIsSaveLoading(true)
        setGlobalError()

        function onUpdateDone() {
            setIsSaveLoading(false)
            setConfigToUpload({})
            setFocusId()
            getConfigs()
            props.apiObject == "parametres" && getParametres()
            props.apiObject == "preferences" && getPreferences()
            setHasValueChanged(false)
        }

        let dataObject = {}
        if (Object.keys(configToUpload).length === 0) {
            let configToUploadCopy = configToUpload
            updateObjectValue(configToUploadCopy, path, deepFind(configInitial, path))

            props.apiObject == "parametres" && (dataObject = { params: configToUploadCopy })
            props.apiObject == "preferences" && (dataObject = { prefs: configToUploadCopy })
        } else {
            props.apiObject == "parametres" && (dataObject = { params: configToUpload })
            props.apiObject == "preferences" && (dataObject = { prefs: configToUpload })
        }

        if (props.source == "userprofil") {
            props.updateService(currentProfilId.current, folderDetails?.dossier?._id, dataObject).then(res => {
                onUpdateDone()
            }).catch(error => {
                setGlobalError(error)
            });
        } else {
            props.updateService(folderDetails?.dossier?._id, dataObject).then(res => {
                onUpdateDone()
            }).catch(error => {
                setGlobalError(error)
            });
        }
    }

    function resetValues() {

        function onDeleteDone() {
            setFocusId()
            getConfigs()
            props.apiObject == "preferences" && getPreferences()
            props.apiObject == "parametres" && getParametres()
        }

        if (props.source == "userprofil") {
            props.removeService(currentProfilId.current, folderDetails?.dossier?._id, ["*"]).then(res => {
                onDeleteDone()
            }).catch(error => {
                setGlobalError(error)
            });
        } else {
            props.removeService(folderDetails?.dossier?._id, ["*"]).then(res => {
                onDeleteDone()
            }).catch(error => {
                setGlobalError(error)
            });
        }
    }

    function removeConfig(path) {

        function onDeleteDone() {
            setFocusId()
            getConfigs()
            props.apiObject == "preferences" && getPreferences()
            props.apiObject == "parametres" && getParametres()
        }

        deleteProp(configToUpload, path)

        if (props.source == "userprofil") {
            props.removeService(currentProfilId.current, folderDetails?.dossier?._id, [path]).then(res => {
                onDeleteDone()
            }).catch(error => {
                setGlobalError(error)
            });
        } else {
            props.removeService(folderDetails?.dossier?._id, [path]).then(res => {
                onDeleteDone()
            }).catch(error => {
                setGlobalError(error)
            });
        }

    }

    function deleteProp(obj, path) {
        let paths = path.split('.')
        let last = paths.pop()
        delete paths.reduce((o, k) => o[k] || {}, obj)[last]
    }

    function handleChange(e) {

        let value = e.target.value
        if (e.target.type == "checkbox") { value = e.target.checked }
        if (e.target.type == "number") { value = parseInt(e.target.value) }

        updateObjectValue(configToUpload, e.target.id, value)
        if (value == deepFind(configInitial, e.target.id)) {
            setHasValueChanged(false)
        } else {
            setHasValueChanged(true)
        }

    }

    function cancelChange(e) {
        setFocusId()
        setConfigToUpload({})
        setHasValueChanged(false)
    }

    function CustomTableHeader({ children, eventKey }) {
        const decoratedOnClick = useAccordionButton(eventKey);
        return (
            <div
                className={"w-100 py-2 px-3 rounded cursor-pointer d-flex align-items-center " + (focusId && (focusId != eventKey) ? "bg-gray-200" : "bg-white")}
                onClick={decoratedOnClick}
            >
                <i className="material-icons me-2">table_chart</i>{children}
                <i className="material-icons ms-auto i-lg">
                    expand_more
                </i>
            </div>
        );
    }

    const InheritedLabel = ({ source, path }) => {

        let sourceValue = "defaut"
        deepFind(configFolderDefined, path) && (sourceValue = "folder")
        deepFind(configUsersProfilDefined, path) && (sourceValue = "usersProfil")
        deepFind(configDefined, path) && (sourceValue = "defined")

        return (
            <span className="d-flex">
                {sourceValue == "defaut" &&
                    <small className="ms-2 bg-gray-100 text-gray-500 py-0 px-2">Valeur par défaut</small>
                }
                {sourceValue == "folder" &&
                    <small className="ms-2 bg-gray-300 text-gray-800 py-0 px-2">Valeur héritée du dossier</small>
                }
                {sourceValue == "usersProfil" &&
                    <small className="ms-2 bg-gray-300 text-gray-800 py-0 px-2">Valeur héritée du profil utilisateur</small>
                }
                {sourceValue == "defined" &&
                    <small className="ms-2 d-inline-flex align-items-center bg-primary text-primary-100">
                        <span className="py-0 px-2">Valeur définie</span>
                        <i className="material-icons cursor-pointer fw-bold bg-primary-600 hover-bg-primary-700 py-0 px-2" onClick={() => removeConfig(path)}>close</i>
                    </small>
                }
            </span>
        )
    }

    const SaveCancelBtn = ({ path }) => {
        return (
            <>
                {focusId == path &&
                    <span className="d-flex">
                        <Button disabled={!hasValueChanged || isSaveLoading} size="sm" className="d-inline-flex align-items-center" onClick={() => updateConfig(path)}>{isSaveLoading && <Spinner animation="border" size="sm" className="me-2" />} Enregistrer</Button>
                        <Button size="sm" variant="secondary" className="ms-1" onClick={() => cancelChange()}>Annuler</Button>
                    </span>
                }
            </>
        )
    }

    const GetInputByType = ({ path, description, type }) => {

        let value = (deepFind(configToUpload, path) != null) ? deepFind(configToUpload, path) : deepFind(configInitial, path)

        return (
            <>
                {type == "number" &&
                    <>
                        <p className="mb-1">{description}</p>
                        <div className="d-inline-flex w-100">
                            <FormControl
                                //htmlSize="2"
                                className="me-2"
                                autoComplete="off"
                                id={path}
                                defaultValue={value}
                                onChange={e => handleChange(e)}
                                type="number"
                                onFocus={() => setFocusId(path)}
                                disabled={focusId && (focusId != path)}
                                autoFocus={focusId == path}
                            />
                            <SaveCancelBtn path={path} />

                        </div>
                        <InheritedLabel path={path} />
                    </>
                }

                {type == "string" &&
                    <>
                        <p className="mb-1">{description}</p>

                        <div className="d-inline-flex w-100">
                            <FormControl
                                //htmlSize="100"
                                className="me-2"
                                autoComplete="off"
                                id={path}
                                defaultValue={value}
                                onChange={e => handleChange(e)}
                                type="text"
                                onFocus={() => setFocusId(path)}
                                disabled={focusId && (focusId != path)}
                                autoFocus={focusId == path}
                            />
                            <SaveCancelBtn path={path} />
                        </div>
                        <InheritedLabel path={path} />
                    </>
                }

                {type == "bool" &&
                    <>
                        <div className="d-inline-flex align-items-center w-100">
                            <Form.Check
                                className="border-bottom py-2 m-0 me-2"
                                onChange={e => handleChange(e)}
                                id={path}
                                label={description}
                                value={value}
                                defaultChecked={value}
                                type="switch"
                                disabled={focusId && (focusId != path)}
                                onClick={() => setFocusId(path)}
                            />
                            <SaveCancelBtn path={path} />
                        </div>
                        <span className="d-flex"><InheritedLabel path={path} /></span>
                    </>

                }

            </>
        )
    }

    const TreeNode = ({ childList, path }) => {
        return (
            <div className="mb-5">
                {childList && Object.entries(childList).map(([key, value], i) =>
                    <div key={i}>
                        {(() => {
                            switch (key) {
                                case 'tableHeaders':
                                    if (!disableLevels.includes(deepFind(configListe, `${path}.${key}`)[0]?.hidden.levelMax)) {
                                        return (
                                            <div className="mb-3 me-2">

                                                <Accordion defaultActiveKey={focusId}>
                                                    <Card className="border-gray-400">
                                                        <Card.Header className="p-0 border-0">
                                                            <CustomTableHeader eventKey={path}>{deepFind(configListe, `${path}.${key}`)?.headingSubject}</CustomTableHeader>
                                                        </Card.Header>
                                                        <Accordion.Collapse eventKey={path}>
                                                            <Card.Body>
                                                                <SaveCancelBtn path={path} />
                                                                <TableHeaders disabled={focusId && (focusId != path)} path={`${path}.${key}`} descParam={deepFind(configListe, `${path}.${key}`)?.headingSubject} configInitial={configInitial} configToUpload={configToUpload} onChange={e => handleChange(e)} setFocusId={() => setFocusId(path)} />
                                                                <SaveCancelBtn path={path} />
                                                            </Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>
                                                </Accordion>

                                                <InheritedLabel path={`${path}.${key}`} />

                                            </div>
                                        );
                                    }
                                case 'email_body':
                                    if (!disableLevels.includes(deepFind(configListe, `${path}.${key}`)?.levelMax)) {
                                        return (
                                            <Row className="mb-3 align-items-center">
                                                <Col xs={12}>
                                                    <p>{deepFind(configListe, `${path}.${key}`)?.headingSubject}</p>
                                                    <div className="p-3 rounded border">
                                                        <p className="m-0 p-0">Utilisez les mots clés ci dessous entre crochés pour afficher dynamiquement du texte.</p>
                                                        <p className="text-gray-600 m-0 p-0">Ex : Visitez notre magasin à l'adresse [adresse] et découvrez une large gamme de produits !</p>
                                                        <p>Mots clés : [raison_sociale] [enseigne] [identification] [adresse] [tel] [mails] [web] [informations_bancaires] [conditions_paiement]</p>
                                                        <WysiwygInput disabled={focusId && (focusId != `${path}.${key}`)} value={(deepFind(configToUpload, `${path}.${key}`) != null) ? deepFind(configToUpload, `${path}.${key}`) : deepFind(configInitial, `${path}.${key}`)} placeHolder="" changeValue={e => handleChange(e)} id={`${path}.${key}`} setFocus={() => setFocusId(`${path}.${key}`)} />
                                                        <div className="d-flex justify-content-end mt-2">
                                                            <SaveCancelBtn path={`${path}.${key}`} />
                                                        </div>
                                                    </div>
                                                    <InheritedLabel path={`${path}.${key}`} />
                                                </Col>

                                            </Row>
                                        );
                                    }
                                case 'email_footer':
                                    if (!disableLevels.includes(deepFind(configListe, `${path}.${key}`)?.levelMax)) {
                                        return (
                                            <Row className="mb-3 align-items-center">
                                                <Col xs={12}>
                                                    <p>{deepFind(configListe, `${path}.${key}`)?.headingSubject}</p>
                                                    <div className="p-3 rounded border">
                                                        <p className="m-0 p-0">Utilisez les mots clés ci dessous entre crochés pour afficher dynamiquement du texte.</p>
                                                        <p className="text-gray-600 m-0 p-0">Ex : Visitez notre magasin à l'adresse [adresse] et découvrez une large gamme de produits !</p>
                                                        <p>Mots clés : [raison_sociale] [enseigne] [identification] [adresse] [tel] [mails] [web] [informations_bancaires] [conditions_paiement]</p>
                                                        <WysiwygInput disabled={focusId && (focusId != `${path}.${key}`)} value={(deepFind(configToUpload, `${path}.${key}`) != null) ? deepFind(configToUpload, `${path}.${key}`) : deepFind(configInitial, `${path}.${key}`)} placeHolder="" changeValue={e => handleChange(e)} id={`${path}.${key}`} setFocus={() => setFocusId(`${path}.${key}`)} />
                                                        <div className="d-flex justify-content-end mt-2">
                                                            <SaveCancelBtn path={`${path}.${key}`} />
                                                        </div>
                                                    </div>
                                                    <InheritedLabel path={`${path}.${key}`} />
                                                </Col>

                                            </Row>
                                        );
                                    }
                                case 'fieldsLabel':
                                    if (!disableLevels.includes(deepFind(configListe, `${path}.${key}`)?.levelMax)) {

                                        return (
                                            <Row className="mb-3 align-items-center">
                                                <Col xs={8}>
                                                    <div className="ms-3">
                                                        <FieldsLabel fieldList={value} focusId={focusId} disabled={focusId && (focusId != path)} path={`${path}.${key}`} descParam={deepFind(configListe, `${path}.${key}`)?.headingSubject} configInitial={configInitial} configToUpload={configToUpload} onChange={e => handleChange(e)} setFocusId={() => setFocusId(path)} />
                                                        <span className="d-flex">
                                                            <InheritedLabel path={`${path}.${key}`} />
                                                        </span>
                                                    </div>
                                                </Col>
                                                <Col xs={4}>
                                                    <SaveCancelBtn path={`${path}.${key}`} />
                                                </Col>
                                            </Row>
                                        );
                                    }
                                default:
                                    if (!disableLevels.includes(deepFind(configListe, `${path}.${key}`)?.levelMax)) {
                                        return (
                                            <div className="mb-3">
                                                <GetInputByType path={`${path}.${key}`} description={deepFind(configListe, `${path}.${key}`)?.headingSubject} type={deepFind(configListe, `${path}.${key}`)?.type} />
                                                {typeof value === 'object' &&
                                                    <div className="ms-3">
                                                        <>
                                                            <h6 className={"mb-2 " + (i != 0 ? "mt-5" : "")}>{deepFind(configListe, `${path}.${key}`)?.headingSubject}</h6>
                                                            <TreeNode childList={value} path={`${path}.${key}`} />
                                                        </>
                                                    </div>
                                                }
                                            </div>
                                        )
                                    }
                                    return null;
                            }
                        })()}

                    </div>
                )}
            </div>
        )
    };

    return (
        <div id="PrefsParams" className="bg-white border-start border-end position-relative">

            {usersProfils &&
                <div className="px-2 py-3 px-xxl-5 py-xxl-4 bg-gray-100 border-bottom d-flex align-items-center">
                    <h6 className="me-3 m-0">Profils utilisateurs :</h6>
                    <Form.Select className="w-auto" onChange={handleChangeProfil}>
                        {usersProfils.map(userProfil => (
                            <option key={userProfil._id} value={userProfil._id}>{userProfil.libelle}</option>
                        ))}
                    </Form.Select>
                </div>
            }

            {globalError && (
                <div className="mt-4">
                    <ErrorManager className="mt-4 mx-5" error={globalError} />
                </div>
            )}


            {!globalError && (Object.keys(configListe).length > 0 && Object.keys(configInitial).length > 0) &&
                <div className={"px-2 py-3 pb-0 p-xxl-5 pb-xxl-0  " + (usersProfils ? "height-full-min-240" : "height-full-min-80")}>
                    <div className="light-v-scroll p-1 pe-3">
                        <div className="w-100 d-flex mb-3">
                            <Button variant="secondary" className="ms-auto" onClick={() => resetValues()}>Tout réinitialiser</Button>
                        </div>
                        {Object.entries(configInitial).map(([key, value], i) =>
                        ((!Array.isArray(value) || value.length > 0) &&
                            <div key={i}>

                                {(!disableLevels.includes(deepFind(configListe, key)?.levelMax)) &&
                                    <Row>
                                        <Col xl={4}>
                                            <h6 className="mb-3 mt-xl-0 mb-xl-0">{deepFind(configListe, key)?.headingSubject}</h6>
                                        </Col>
                                        <Col xl={8}>
                                            <TreeNode childList={deepFind(configInitial, key)} path={key} />
                                        </Col>
                                    </Row>
                                }
                            </div>
                        )
                        )}
                    </div>
                </div>
            }
        </div>
    )
}

export default PrefsParams;