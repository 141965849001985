import React, { useEffect, useState } from 'react';
import { FournisseurService } from '../../../../../services/FournisseurService';
import { Form, Spinner, Card, Tabs, Tab, Row, Col, Accordion, Button, OverlayTrigger, Alert, Tooltip, Dropdown, Badge } from 'react-bootstrap';
import ErrorManager from "../../../../error-manager/ErrorManager";
import { getFormErrorMsg, getInvalidObject, getChangedValues } from '../../../../../helper/Helper'

const Articles = (props) => {

    const [articles, setArticles] = useState()
    const [articlesLoading, setArticlesLoading] = useState(false)

    const [globalError, setGlobalError] = useState()

    useEffect(() => {
        getArticles()
    }, []);

    function getArticles() {
        setArticlesLoading(true)
        setGlobalError()
        FournisseurService.getArticles(props.folderId, props.fournisseur._id).then(res => {
            setArticles(res?.data?.data?.articles)
            setArticlesLoading(false)
        }).catch(error => {
            setArticlesLoading(false)
            setGlobalError(error)
        });
    }

    return (
        <>
            {globalError && (
                <ErrorManager error={globalError} />
            )}

            {articlesLoading &&
                <span className="hloader mt-3"></span>
            }

            {(!articles && !articlesLoading) &&
                <div className="d-flex align-items-center">
                    <em>- Aucun élément trouvé -</em>
                </div>
            }

            {!articlesLoading &&
                <div className="">
                    {articles?.map(article => (
                        <div key={article._id} className="bg-gray-100 px-2 py-1 rounded mb-2">
                            <span className="fw-bold me-2">{article.code}</span><a className="" target="_blank" href={`/${props.folderId}/articles?articleId=${article._id}`}>{article.designation}</a>
                        </div>
                    ))}
                </div>
            }

        </>
    );

}
export default Articles;