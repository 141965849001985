// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#YearMonthInput {
  max-width: 365px;
}
#YearMonthInput .dropdown {
  max-width: 260px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/form-inputs/year-month-input/YearMonthInput.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;AACI;EACI,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,OAAA;EACA,QAAA;AACR","sourcesContent":["#YearMonthInput {\n    max-width: 365px;\n\n    .dropdown {\n        max-width: 260px;\n        margin-left: auto;\n        margin-right: auto;\n        left: 0;\n        right: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
