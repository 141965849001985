import React, { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { Table, Badge, FormControl, Container, InputGroup, Button, Dropdown, DropdownButton, Form, Col, Row, Spinner, Tooltip, Toast, ToastContainer } from 'react-bootstrap';
import { ArticleService } from '../../../../services/ArticleService';
import { PreferenceService } from '../../../../services/PreferenceService';
import { GlobalContext } from "../../../../services/GlobalProvider";
import Pagination from '../../../../components/pagination/Pagination';
import { useOuterClick, currencyFormat, granted, formattedDateInput, getFormErrorMsg } from '../../../../helper/Helper'



import './ArticleSearch.scss';

const ArticleSearch = (props) => {


    const { getPreferences, preferences, folderDetails, globalContextLoaded } = React.useContext(GlobalContext);

    const [descriptionValue, setDescriptionValue] = useState("")
    const [articles, setArticles] = useState()
    const [isGetArticlesLoading, setIsGetArticlesLoading] = useState()
    const [currentPagination, setCurrentPagination] = useState(1)
    const [activeRowIndex, setActiveRowIndex] = useState(0)
    const [isPanelOpen, setIsPanelOpen] = useState(false)
    /* const [filterChecked, setFilterChecked] = useState(preferences.brouillons.articleSearchFilter) */
    const [filterChecked, setFilterChecked] = useState()
    const [currentOperatorValue, setCurrentOperatorValue] = useState("")

    const searchRef = useRef(null);

    const outerClick = useOuterClick(ev => {
        if (isPanelOpen) {
            setIsPanelOpen(false)
            searchRef.current.blur()
        }
    });

    let articlesParams = useRef({
        'start': 0,
        'limit': 10,
        'search': null,
        'family': null,
        'filters': {},
        'fields': "code,designation,famille.designation,prix_vente_ttc,fournisseurs",
        'sort': 'code',
        'order': 'asc'
    });

    /* useEffect(() => {
        !props.disabled && searchRef.current.focus()
    }, [props.disabled]); */

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    useEffect(() => {
        if (isPanelOpen) {
            articlesParams.current['search'] = null
            getArticles()
        }
    }, [isPanelOpen]);

    function handleKeyDown(e) {

        if ((activeRowIndex != null) && (isPanelOpen)) {
            const { code } = e;
            switch (code) {
                case "ArrowUp":
                    e.preventDefault();
                    setActiveRowIndex(activeRowIndex != 0 && activeRowIndex - 1)
                    break;
                case "ArrowDown":
                    e.preventDefault();
                    setActiveRowIndex((activeRowIndex < articles?.articles.length - 1) ? activeRowIndex + 1 : activeRowIndex)
                    break;
                case "Enter":
                    addArticle(articles.articles[activeRowIndex])
                    break;
                case "ArrowRight":
                    break;
                case "ArrowLeft":
                    break;
                case "Space":
                    break;
                case "Escape":
                    resetSearch()
                    searchRef.current.blur()
                    break;
                default:
                    break;
            }
        }
    }

    function getArticles() {
        setIsGetArticlesLoading(true)
        setActiveRowIndex()
        ArticleService.getArticles(props.folderId, articlesParams.current).then(res => {
            setArticles(res.data.data)
            setActiveRowIndex(0)
            setIsGetArticlesLoading(false)
        }).catch(error => {
            setActiveRowIndex()
            setIsGetArticlesLoading(false)
            //setGlobalError(error)
        });
    }

    function addArticle(article) {
        props.addElement(article)
        resetSearch()
    }

    const addADescription = (e) => {
        props.addDescription(descriptionValue)
        setDescriptionValue("")
    }
    const addSubtotal = (e) => {
        props.addSubtotal()
    }

    let searchTimeOut;
    function changeSearchValue(e) {

        window.clearTimeout(searchTimeOut);
        searchTimeOut = window.setTimeout(function () {

            if (filterChecked) {
                articlesParams.current['search'] = null
            } else {
                articlesParams.current['search'] = e.target.value
            }

            let firstLetter = Array.from(e.target.value)[0]

            function setFilter(name) {
                articlesParams.current['search'] = null
                searchRef.current.value = e.target.value.slice(1)
                setFilterChecked(name)
                articlesParams.current['filters'] = { [`filter[${name}]`]: [{ operator: currentOperatorValue, value: searchRef.current.value }] }
            }


            switch (firstLetter) {
                case "#":
                    setFilter("code")
                    break;
                case "/":
                    setFilter("designation")
                    break;
                case "*":
                    setFilter("fournisseurs.reference_fournisseur")
                    break;
                case "!":
                    setFilter("prix_vente_ttc")
                    break;
                default:
                    if (filterChecked) {
                        articlesParams.current['filters'] = { [`filter[${filterChecked}]`]: [{ operator: currentOperatorValue, value: searchRef.current.value }] }
                    }
                    break;
            }

            setCurrentPagination(1)
            articlesParams.current['start'] = (articlesParams.current.limit * 1) - articlesParams.current.limit
            getArticles()

        }, 1000);
    }

    function handleFilterChange(name) {

        articlesParams.current['search'] = null
        setFilterChecked(name)
        articlesParams.current['filters'] = { [`filter[${name}]`]: [{ operator: currentOperatorValue, value: searchRef.current.value }] }

        setCurrentPagination(1)
        articlesParams.current['start'] = (articlesParams.current.limit * 1) - articlesParams.current.limit

        if (name == "prix_vente_ttc") {
            setCurrentOperatorValue("")
            {
                Object.keys(articlesParams.current['filters']).map((key1, index1) => {
                    articlesParams.current['filters'][key1][0].operator = ""
                })
            }
        }

        getArticles()
    }

    function handleStartByChange(e) {
        if (e.target.checked) {
            setCurrentOperatorValue("^")
            {
                Object.keys(articlesParams.current['filters']).map((key1, index1) => {
                    articlesParams.current['filters'][key1][0].operator = "^"
                })
            }
        } else {
            setCurrentOperatorValue("")
            {
                Object.keys(articlesParams.current['filters']).map((key1, index1) => {
                    articlesParams.current['filters'][key1][0].operator = ""
                })
            }
        }
        getArticles()
    }

    function removeSearchValue() {
        resetSearch()
    }

    function resetSearch() {
        setArticles()
        searchRef.current.value = null
        articlesParams.current['filters'] = {}
        setFilterChecked()
        setCurrentOperatorValue("")
        setActiveRowIndex(0)
        setIsPanelOpen(false)
    }

    function handleDescriptionChange(e) {
        setDescriptionValue(e.target.value)
    }



    function changePagination(p) {
        setCurrentPagination(p)
        articlesParams.current['start'] = (articlesParams.current.limit * p) - articlesParams.current.limit
        getArticles()
    }


    /* function updatePreference(key, value) {
        PreferenceService.updatePreferences(folderDetails._id, { prefs: { ['brouillons']: { [key]: value } } }).then(res => {
            getPreferences()
        })
    } */

    return (
        <Row id="ArticleSearch" className="flex-fill">
            <Col xs="3" className="position-relative">

                <InputGroup>
                    <i className="material-icons input-icon text-gray-400">search</i>
                    {filterChecked &&
                        <span className="search-prefix d-flex justify-content-center align-items-center bg-gray-200 rounded">
                            {filterChecked == "code" && <>#</>}
                            {filterChecked == "designation" && <>/</>}
                            {filterChecked == "fournisseurs.reference_fournisseur" && <>*</>}
                            {filterChecked == "prix_vente_ttc" && <>!</>}
                        </span>
                    }
                    <FormControl
                        className="input-icon-space rounded-start"
                        type="text"
                        ref={searchRef}
                        onChange={changeSearchValue}
                        onFocus={() => setIsPanelOpen(true)}
                        placeholder="Rechercher un article"
                        disabled={props.disabled}
                    />
                    {searchRef?.current?.value &&
                        <Button disabled={props.disabled} onClick={() => removeSearchValue()} variant="secondary">
                            Effacer
                        </Button>
                    }
                </InputGroup>


                {isPanelOpen && (
                    <span ref={outerClick} className={"zindex-100 border border-gray-500 rounded p-3 position-absolute bg-white shadow-lg " + (props.dropBottom ? "drop-container-bottom" : "drop-container-top")}>

                        {/* <i onClick={() => setIsPanelOpen(false)} className="material-icons text-gray-300 fs-4 position-absolute top-0 end-0 me-2 mt-1 cursor-pointer">close</i> */}

                        {isGetArticlesLoading &&
                            <span className="hloader"></span>
                        }

                        {(!isGetArticlesLoading && !articles?.articles) &&
                            <em>- Aucun élément trouvé -</em>
                        }

                        {(!isGetArticlesLoading && articles?.articles) &&
                            <>
                                <Table size="sm" hover className="rounded">
                                    <thead className="text-uppercase text-gray-600 small">
                                        <tr>
                                            <th>Code</th>
                                            <th>Désignation</th>
                                            <th>Famille</th>
                                            <th>Réf. Fournisseur</th>
                                            <th>Prix TTC</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {articles?.articles?.map((article, i) => (
                                            <tr key={article?._id} className={"cursor-pointer position-relative " + (i == activeRowIndex ? "table-active selected zindex-1 " : "")} onClick={() => addArticle(article)}>
                                                <td>{article?.code}</td>
                                                <td>{article?.designation}</td>
                                                <td>{article?.famille?.designation}</td>
                                                <td>{article?.fournisseurs.filter(f => f.fournisseur.length > 0).map(fournisseur => (
                                                    <Badge key={fournisseur.fournisseur_id} size="sm" bg="gray-200 text-dark">{fournisseur.reference_fournisseur} {fournisseur.fournisseur[0]?.nom && <>({fournisseur.fournisseur[0]?.nom})</>}</Badge>
                                                ))}</td>
                                                <td className="text-end">{article?.prix_vente_ttc}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                                <Pagination size="sm" itemsLength={articles?.total} elementsPerPage={articlesParams.current.limit} currentPagination={currentPagination} changePagination={p => changePagination(p)} />


                            </>

                        }

                        <hr />

                        <div className="d-flex align-items-center">
                            <h6 className="p-0 me-5 text-nowrap">Rechercher dans :</h6>
                            <Form.Group className="d-flex">

                                <div className="p-2 rounded border">
                                    <div className="text-nowrap ">

                                        <Form.Check
                                            inline
                                            onChange={() => handleFilterChange("code")}
                                            checked={filterChecked == "code"}
                                            label="Code"
                                            type="radio"
                                            id={`inline-1`}
                                        />
                                        <Form.Check
                                            inline
                                            onChange={() => handleFilterChange("designation")}
                                            checked={filterChecked == "designation"}
                                            label="Désignation"
                                            type="radio"
                                            id={`inline-2`}
                                        />
                                        <Form.Check
                                            inline
                                            onChange={() => handleFilterChange("fournisseurs.reference_fournisseur")}
                                            checked={filterChecked == "fournisseurs.reference_fournisseur"}
                                            label="Réf. Fournisseur"
                                            type="radio"
                                            id={`inline-3`}
                                        />
                                    </div>
                                    <Form.Group className="text-gray-700 small text-nowrap " controlId="formBasicCheckbox">
                                        <Form.Check disabled={filterChecked !== "code" && filterChecked !== "designation" && filterChecked !== "fournisseurs.reference_fournisseur"} checked={currentOperatorValue == "^"} type="checkbox" label='Filtre : "Commence par" ' onChange={handleStartByChange} />
                                    </Form.Group>
                                </div>

                                <div className="p-2 ms-2 rounded border">
                                    <Form.Check
                                        inline
                                        onChange={() => handleFilterChange("prix_vente_ttc")}
                                        checked={filterChecked == "prix_vente_ttc"}
                                        label="Prix"
                                        type="radio"
                                        id={`inline-3`}
                                    />
                                </div>

                                <div className="p-2 ms-2 rounded bg-gray-100 d-flex align-items-center">
                                    <i className="material-icons text-gray-500 px-2 i-lg">
                                        help
                                    </i>
                                    <span className="text-gray-600 lh-1 small">Préfixer la recherche de <span className="me-1 px-2 rounded bg-gray-300 text-gray-800 fw-bold">#</span> <span className="me-1 px-2 rounded bg-gray-300 text-gray-800 fw-bold">/</span><span className="me-1 px-2 rounded bg-gray-300 text-gray-800 fw-bold">*</span> ou <span className="me-1 px-2 rounded bg-gray-300 text-gray-800 fw-bold">!</span> pour filtrer la recherche sur le code, la description, la réf. fournisseur ou le prix.</span>
                                </div>

                                {/* <div className="p-2 ms-2 rounded border d-flex flex-column">
                                    <span className="aaa">Préfixer la recherche de "#", "#", "#" ou "#" pour filtrer la recherche sur le code, la description, la réf. fournisseur ou le prix :</span>
                                    <span className="text-gray-600">"#" pour filtrer sur la description</span>
                                    <span className="text-gray-600">"#" pour filtrer sur le code</span>
                                    <span className="text-gray-600">"#" pour filtrer sur le prix</span>
                                    <span className="text-gray-600">"#" pour filtrer sur la réf. fournisseur</span>
                                </div> */}


                            </Form.Group>
                        </div>


                    </span>
                )}


            </Col>
            <Col>

                <InputGroup>
                    <i className="material-icons input-icon text-gray-400">notes</i>
                    <FormControl
                        className="input-icon-space rounded-start"
                        placeholder="Ajouter une description"
                        value={descriptionValue}
                        onChange={handleDescriptionChange}
                        type="text"
                        disabled={props.disabled}
                        onKeyDown={e => e.key === 'Enter' && addADescription()}
                    />

                    <Button variant="secondary" disabled={props.disabled} onClick={addADescription}>
                        Ajouter
                    </Button>
                </InputGroup>
            </Col>
            <Col xs="auto">
                <Button variant="secondary" disabled={props.disabled} onClick={addSubtotal}><i className="material-icons float-start me-2 i-lg">control_point_duplicate</i>Ajouter un sous-total</Button>
            </Col>
        </Row>
    );
}

export default ArticleSearch;