// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#factureInput {
  overflow: visible;
}
#factureInput .drop-container {
  margin-top: 10px;
  width: max-content;
  max-width: 1000px;
  min-width: 500px;
}
#factureInput tr.selected {
  box-shadow: 0px 0px 0px 2px #01a3ff;
}`, "",{"version":3,"sources":["webpack://./src/components/form-inputs/facture-input/FactureInput.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;AACJ;AAAI;EACI,gBAAA;EACA,kBAAA;EACA,iBAAA;EACA,gBAAA;AAER;AAEQ;EACI,mCAAA;AAAZ","sourcesContent":["#factureInput{\n    overflow: visible;\n    .drop-container{\n        margin-top: 10px;\n        width: max-content;\n        max-width: 1000px;\n        min-width: 500px;\n    }\n\n    tr{\n        &.selected{\n            box-shadow: 0px 0px 0px 2px #01a3ff;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
