import React, { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { Table, Button, Dropdown, DropdownButton, Alert, Col, Row, Spinner, Modal, Accordion, InputGroup, Form } from 'react-bootstrap';
import { EditionService } from '../../../services/EditionService';
import { AbonnementService } from '../../../services/AbonnementService';
import ErrorManager from "../../../components/error-manager/ErrorManager";

const MailList = (props) => {

    const [checkedList, setCheckedList] = useState([]);

    /*     let inputRef = useRef();
    
        const navigate = useNavigate(); */

    useEffect(() => {
        setCheckedList([])
    }, [props.isDataLoading]);

    useEffect(() => {
        props.setCheckedElementIdList(checkedList)
    }, [checkedList]);


    function handleCheckboxChange(e) {

        let checkedListCopy = [...checkedList]

        if (e.target.checked) {
            checkedListCopy.push(e.target.value)
        } else {
            const index = checkedListCopy.indexOf(e.target.value);
            if (index > -1) {
                checkedListCopy.splice(index, 1);
            }
        }



        //props.setCheckedElementIdList(checkedListCopy)

        setCheckedList(checkedListCopy)
    }

    return (
        <div id="MailList">

            <Table className="">
                <thead>
                    <tr>
                        <th className='text-uppercase text-gray-600 small'></th>
                        <th className='text-uppercase text-gray-600 small'>Code</th>
                        <th className='text-uppercase text-gray-600 small'>Client</th>
                        <th className='text-uppercase text-gray-600 small'>Interlocuteur</th>
                        <th className='text-uppercase text-gray-600 small'>Mails</th>
                    </tr>
                </thead>
                <tbody className="align-middle">
                    {(!props.isDataLoading && props.tableDataList) && props.tableDataList.map((data, i) => (
                        <tr key={i}>
                            <td className="pe-2">
                                <input type="checkbox" value={data._id} className="form-check-input" onChange={e => handleCheckboxChange(e)} />
                            </td>
                            <td>{data.code}</td>
                            <td>{data.client_nom}</td>
                            <td>{data?.interlocuteur?.nom}</td>
                            <td>{data?.interlocuteur?.mails.map(mail => (
                                <span className="px-2 py-1 me-2 bg-gray-100 rounded text-gray-700 d-inline-flex align-items-center">
                                    <i className="material-icons me-1 d-inline-flex">
                                        email
                                    </i>
                                    {mail}
                                </span>
                            ))}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {props.isDataLoading &&
                <span className="hloader mt-2"></span>
            }

            {(!props.isDataLoading && !props.tableDataList) &&
                <em className="mt-2">- Aucun élément trouvé -</em>
            }

        </div>
    )
}

export default MailList;
