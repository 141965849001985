import React, { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Table, Badge, FormControl, Container, InputGroup, Button, Dropdown, DropdownButton, Form, Col, Row, Spinner, OverlayTrigger, Tooltip, Toast, ToastContainer } from 'react-bootstrap';
import { ArticleService } from '../../../../services/ArticleService';
import Pagination from '../../../../components/pagination/Pagination';
import { deepFind, getInvalidObject, getChangedValues, reorder, updateObjectValue } from '../../../../helper/Helper'


const FieldsLabel = ({ fieldList, focusId, path, descParam, configInitial, configToUpload, setFocusId, onChange, disabled }) => {

    const [focusIndex, setFocusIndex] = useState()

    useEffect(() => {

    }, []);

    function defineFocusElement(path, key, value) {
        fieldList.focus = `${path}.${key}`
        setFocusId(path)
    }

    function handleChange(e, path) {
        let element = {
            target: {
                id: `${path}.${e.target.name}.title`,
                value: e.target.value
            }
        }
        onChange(element)
    }

    const Input = ({InputPath: inputPath}) => {
        return (
            <>
                {deepFind(configInitial, inputPath) && Object.entries(deepFind(configInitial, inputPath)).map(([objKey, value], i) => (
                    <div key={i}>
                        {objKey != "focus" &&
                            <>
                                {typeof value.title === 'string' ?
                                    <InputGroup size="sm" className="" style={{marginBottom : -1 }}>
                                        <InputGroup.Text className="w-50 rounded-0">{objKey}</InputGroup.Text>
                                        <Form.Control
                                            className="rounded-0"
                                            autoFocus={focusId && (fieldList.focus == `${inputPath}.${objKey}`)}
                                            autoComplete="off"
                                            onChange={e => handleChange(e, inputPath)}
                                            name={objKey}
                                            onFocus={() => defineFocusElement(inputPath, objKey, value)}
                                            disabled={disabled}
                                            defaultValue={deepFind(configToUpload, `${inputPath}.${objKey}.title`) ? deepFind(configToUpload, `${inputPath}.${objKey}.title`) : value.title}
                                        />
                                    </InputGroup>
                                    :
                                    <>
                                        <div className="rounded border p-3 my-2">
                                            <p>{objKey}</p>
                                            <Input InputPath={`${path}.${objKey}`} />
                                        </div>
                                    </>
                                }
                            </>
                        }
                    </div>
                ))}
            </>
        )
    }

    return (
        <>
            <h6 className="mb-2">{descParam}</h6>

            <div className="rounded border p-3">

                <Input InputPath={path} />

            </div>

        </>
    );
}

export default FieldsLabel;