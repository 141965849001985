import {axiosAPI} from "./axiosClient"
import qs from "qs"
import { getParams } from '../helper/Helper'

export const TagService = {

  getTags(folderId, params) {
    return axiosAPI.get(`/${folderId}/tags?lookup=true&${getParams(params)}`)
  },

  getTag(folderId, tagId){
    return axiosAPI.get(`/${folderId}/tags/${tagId}?lookup=true`)
  },

  updateTag(folderId, dataPost){
    return axiosAPI.put(`/${folderId}/tags`, qs.stringify(dataPost))
  },

  postTag(folderId, dataPost){
    return axiosAPI.post(`/${folderId}/tags`, qs.stringify(dataPost))
  },

  removeTag(folderId, tagId) {
    return axiosAPI.delete(`/${folderId}/tags/${tagId}`)
  },

  removeTags(folderId, tagIdList) {
    var params = '?tags[]=' + tagIdList.join('&tags[]=');
    return axiosAPI.delete(`/${folderId}/tags${params}`)
  },


  /* Clients */

  postClientListTag(folderId, dataPost){
    return axiosAPI.post(`/${folderId}/tags/clients`, qs.stringify(dataPost))
  },

  postAddTagToClientList(folderId, tagId, dataPost){
    return axiosAPI.post(`/${folderId}/tags/${tagId}/clients`, qs.stringify(dataPost))
  },

  removeClientListTag(folderId, tagId, clientIdList){
    var params = '?clients[]=' + clientIdList.join('&clients[]=')
    return axiosAPI.delete(`/${folderId}/tags/${tagId}/clients${params}`)
  },
  
  /* Articles */

  postArticleListTag(folderId, dataPost){
    return axiosAPI.post(`/${folderId}/tags/articles`, qs.stringify(dataPost))
  },

  postAddTagToArticleList(folderId, tagId, dataPost){
    return axiosAPI.post(`/${folderId}/tags/${tagId}/articles`, qs.stringify(dataPost))
  },

  removeArticleListTag(folderId, tagId, articleIdList){
    var params = '?articles[]=' + articleIdList.join('&articles[]=')
    return axiosAPI.delete(`/${folderId}/tags/${tagId}/articles${params}`)
  },


}