import React, { useEffect, useState, useRef } from "react";
import { Container, Button, Dropdown, DropdownButton, Form, Col, Row, Accordion, Label, Modal, Spinner, Tooltip, InputGroup, Alert } from 'react-bootstrap';
import { ContributionService } from '../../../services/ContributionService';


const ContributionsInput = (props) => {

    const [error, setError] = useState(null);
    const [contributionGroups, setContributionGroups] = useState(null);
    const [filteredContributionGroups, setFilteredContributionGroups] = useState(null);
    const [selectedContributionIdList, setSelectedContributionIdList] = useState([]);
    const [searchValue, setSearchValue] = useState(null)
    
    const searchInput = useRef("");

    useEffect(() => {
        getContributions()
    }, []);

    useEffect(() => {
        if (props?.value?.length > 0) {
            let propsContribIdList = []
            props.value.map(c => {
                propsContribIdList.push(c)
            })
            setSelectedContributionIdList(propsContribIdList)
        }
    }, [props.value]);

    function setContributionProps(contribList) {
        props.setContributionList({
            target: {
                name: props.name,
                value: contribList.length > 0 ? contribList : null
            }
        })
    }

    function getContributions() {
        setError(null)
        ContributionService.getContributions(props.folderId).then(res => {
            formatContributions(res.data.data.contributions)
        }).catch(err => {
            setError(err)
        });
    }

    function formatContributions(contributions) {
        let grouppedByType = contributions.reduce(function (rv, x) {
            (rv[x['type']] = rv[x['type']] || []).push(x);
            return rv;
        }, {});
        setContributionGroups(grouppedByType)
        setFilteredContributionGroups(grouppedByType)
    }

    function removeContribution(e, contribution) {

        e.stopPropagation()
        let selectedContributionIdListCopy = [...selectedContributionIdList];

        selectedContributionIdListCopy = selectedContributionIdListCopy.filter(function (c) {
            return c !== contribution;
        });

        setSelectedContributionIdList(selectedContributionIdListCopy)
        setContributionProps(selectedContributionIdListCopy)

    }

    function addContributionId(contributionId) {

        let selectedContributionIdListCopy = [...selectedContributionIdList];

        if (selectedContributionIdListCopy.indexOf(contributionId) == -1) {
            selectedContributionIdListCopy.push(contributionId)
            setSelectedContributionIdList(selectedContributionIdListCopy)
            setContributionProps(selectedContributionIdListCopy)
        }

    }

    function changeSearchValue(e) {
        let contributionGroupsCopy = {}
        for (let contribution in contributionGroups) {
            let showArr = []
            contributionGroups[contribution]?.map(c => {
                if(e?.target?.value){
                    if (c.libelle.toLowerCase().includes(e.target.value.toLowerCase())) {
                        showArr.push(c)
                    }
                }else{
                    showArr.push(c)
                }
            })
            if (showArr.length) {
                contributionGroupsCopy[contribution] = showArr
            }
            setFilteredContributionGroups(contributionGroupsCopy)
        }
    }

    function isDisabled(contribution) {
        let value = false
        for (let contrib of filteredContributionGroups[contribution.type]) {
            selectedContributionIdList.map(cId => {
                if (cId == contrib._id) {
                    value = true
                }
            })
        }
        return value
    }

    function getContributionName(contributionId) {
        let value = contributionId

        filteredContributionGroups && Object.entries(filteredContributionGroups).map(([contributionGroup, contributionGroupList], i) => {
            for (let contribution of contributionGroupList) {
                if (contributionId == contribution._id) {
                    value = contribution.libelle
                }
            }
        })
        return value
    }

    function removeSearchValue() {
        searchInput.current.value = ""
        changeSearchValue()
    }

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <>
            <span onClick={(e) => { onClick(e) }} className={(props.isInvalid ? "is-invalid " : "") + "form-control cursor-pointer text-secondary"}>
                {selectedContributionIdList?.map(contributionId => (
                    <span key={contributionId} className="small bg-primary-200 me-2 my-1 d-inline-flex text-primary">
                        <span className="rounded-pill rounded-end px-2 py-1">{getContributionName(contributionId)}</span>
                        <span onClick={e => removeContribution(e, contributionId)} className="hover-bg-primary-300 px-2 d-flex cursor-pointer">
                            <i className="material-icons align-self-center">close</i>
                        </span>
                    </span>
                ))}
                <span className="d-inline-block">Choisir une contribution</span>
            </span>
            <Form.Control.Feedback type="invalid">{props.feedback}</Form.Control.Feedback>
        </>
    ));

    return (
        <>
            <Dropdown id="ContributionsInput" autoClose="outside" className="mb-3">
                <Dropdown.Toggle as={CustomToggle} />
                <Dropdown.Menu className="mt-2">

                    <InputGroup className="mx-3 my-2 w-auto mb-3">
                        <Form.Control
                            autoFocus
                            ref={searchInput}
                            type="text"
                            onChange={e => changeSearchValue(e)}
                            placeholder="Rechercher"
                        />
                        {searchInput.current.value &&
                            <Button className="border border-start-0" variant="gray-100" onClick={() => removeSearchValue()}><i className="material-icons">close</i></Button>
                        }
                    </InputGroup>

                    {filteredContributionGroups && Object.entries(filteredContributionGroups).map(([contributionGroup, contributionGroupList], i) => (
                        <span key={i}>
                            <h6 className="dropdown-header mt-2">{contributionGroup}</h6>
                            {contributionGroupList.map((contribution, i) => (
                                <Dropdown.Item
                                    key={contribution._id}
                                    onClick={() => addContributionId(contribution._id)}
                                    disabled={isDisabled(contribution)}
                                >
                                    <span>
                                        {contribution.libelle}<span className="small ms-2"></span>
                                    </span>
                                </Dropdown.Item>
                            ))}
                        </span>
                    ))}
                </Dropdown.Menu>
            </Dropdown>

        </>
    )
}
export default ContributionsInput;
