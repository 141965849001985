import React, { useEffect, useState } from "react";
import { Container, Button, Dropdown, DropdownButton, Form, Col, Row, InputGroup, Label, Modal, Spinner, Tooltip, OverlayTrigger, Alert } from 'react-bootstrap';

const TextArrayInput = (props) => {

    const [textArrayList, setTextArrayList] = useState([]);

    useEffect(() => {
        if (props?.value?.length > 0) {
            setTextArrayList(props.value)
        }
    }, [props.value]);

    function returnValue(textArrayList) {
        props.setTextArrayList({
            target: {
                name: props.name,
                value: textArrayList.length > 0 ? textArrayList : null
            }
        })
    }

    function removeTextArray(index) {
        let textArrayListCopy = textArrayList.filter((_, i) => i !== index)
        setTextArrayList(textArrayListCopy)
        returnValue(textArrayListCopy)
    }

    function addNew() {
        let textArrayListCopy = [...textArrayList];
        textArrayListCopy.push('')
        setTextArrayList(textArrayListCopy)
    }

    function handleChange(value, i) {
        let textArrayListCopy = [...textArrayList];
        textArrayListCopy[i] = value
        setTextArrayList(textArrayListCopy)
        returnValue(textArrayListCopy)

    }

    function isInvalid(propsInvalid, value){
        if(propsInvalid){
            const separateLines = propsInvalid.split(/\r?\n|\r|\n/g);
            for(let line of separateLines){
                let mySubString = line.substring(0, line.indexOf(" - "));
                if(value == mySubString){return true}
            }
        }
        return false
    }

    return (

        <div className="p-2 my-1 border border-gray-400 rounded">
            {textArrayList.map((text, i) => (

                    <InputGroup className="d-flex flex-row mb-1" key={i}>
                        <Form.Control
                            autoFocus={!text}
                            className=""
                            size={props.size}
                            placeholder={props.placeHolder || "Valeur"}
                            type="text"
                            onChange={e => handleChange(e.target.value, i)}
                            value={text}
                            isInvalid={isInvalid(props.isInvalid, text)}
                        />
                        <Button size={props.size} variant="secondary"  onClick={() => removeTextArray(i)}>
                            Supprimer
                        </Button>
                    </InputGroup>
            ))}
            <Button size="sm" variant="link" className="p-0" onClick={() => addNew()}>{props.addLabel}</Button>
        </div>
    )
}
export default TextArrayInput;