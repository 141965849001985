import React, { Component, useState, useEffect } from 'react';
import './index.scss';
import { render } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
  useParams
} from "react-router-dom";
import Factures from './pages/factures/Factures';
import Reglements from './pages/reglements/Reglements';
import Proformas from './pages/proformas/Proformas';
import Abonnements from './pages/abonnements/Abonnements';
import Brouillon from './pages/brouillon/Brouillon';
import Administration from './pages/administration/Administration';
import Dossiers from './pages/dossiers/Dossiers';
import Fournisseurs from './pages/fournisseurs/Fournisseurs';
import Clients from './pages/clients/Clients';
import Tarifs from './pages/tarifs/Tarifs';
import Editions from './pages/editions/Editions';
import Articles from './pages/articles/Articles';
import Gettoken from './pages/authentication/Gettoken';
import Logout from './pages/authentication/Logout';
import './config.inc';
import TopNav from './components/navigation/TopNav';
import Rapports from './components/rapports/Rapports';
import { GlobalProvider } from './services/GlobalProvider';
import Accueil from './pages/accueil/Accueil';
import NotFound from './pages/not-found/NotFound';
import { forceInputTypeNumber, getInvalidObject, granted, getChangedValues } from './helper/Helper'

forceInputTypeNumber()

render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Index />}>
        <Route path=":folderId/" >
          <Route path="" element={<Accueil />} />
          <Route path="accueil" element={<Accueil />} />
          <Route path="articles" element={<Articles />} />
          <Route path="fournisseurs" element={<Fournisseurs />} />
          <Route path="reglements" element={<Reglements />} />
          <Route path="factures" element={<Factures />} />
          <Route path="proformas" element={<Proformas />} />
          <Route path="abonnements" element={<Abonnements />} />
          <Route path="clients" element={<Clients />} />
          <Route path="tarifs" element={<Tarifs />} />
          <Route path="factures/brouillon" element={<Brouillon />} />
          <Route path="proformas/brouillon" element={<Brouillon />} />
          <Route path="abonnements/brouillon" element={<Brouillon />} />
          <Route path="administration" element={<Administration />} />
          <Route path="editions" element={<Editions />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="dossiers" element={<Dossiers />} />
        <Route path='/not-found' element={<NotFound />} />
      </Route>
      <Route path="/authentication/gettoken" element={<Gettoken />} />
      <Route path="/authentication/logout" element={<Logout />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById("root")
);

function Index() {
  return (
    <div>
      <GlobalProvider>
        <TopNav />
        <Rapports />
        <div className="section-content">
          <Outlet />
        </div>
      </GlobalProvider>
    </div>
  )
}
