// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#Brouillon .modified-border {
  border: 4px solid rgba(254, 254, 0, 0.862745098);
  border-radius: 3px;
}
#Brouillon .modified-bg {
  background: #fcff00;
  color: #11111a;
  margin-left: -2px;
  margin-right: -2px;
  border-right: 2px solid #fcfe00;
  border-left: 2px solid #fcfe00;
  border-radius: 3px;
}`, "",{"version":3,"sources":["webpack://./src/pages/brouillon/Brouillon.scss"],"names":[],"mappings":"AACI;EACI,gDAAA;EACA,kBAAA;AAAR;AAEI;EACI,mBAAA;EACA,cAAA;EACA,iBAAA;EACA,kBAAA;EACA,+BAAA;EACA,8BAAA;EACA,kBAAA;AAAR","sourcesContent":["#Brouillon {\n    .modified-border {\n        border: 4px solid #fefe00dc;\n        border-radius: 3px;\n    }\n    .modified-bg {\n        background: #fcff00;\n        color: #11111a;\n        margin-left: -2px;\n        margin-right: -2px;\n        border-right: 2px solid #fcfe00;\n        border-left: 2px solid #fcfe00;\n        border-radius: 3px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
