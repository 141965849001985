import {axiosAPI} from "./axiosClient"
import qs from "qs"

export const FamilyService = {
  
  getFamilies(folderId) {
    return axiosAPI.get(`/${folderId}/familles?lookup=true`)
  },

/*   getFamilies(folderId, sort) {
    const params = {
      'sort': sort ? sort : 'designation'
    }
    return axiosAPI.get(`/${folderId}/familles?lookup=true&querytree=true&${qs.stringify(params)}`)
  }, */

  getTreeFamilies(folderId) {
    return axiosAPI.get(`/${folderId}/familles?querytree=true&sort=code`)
  },

  /* getTreeFamilies(folderId, sort) {
    const params = {
      'sort': sort ? sort : 'designation'
    }
    return axiosAPI.get(`/${folderId}/familles?querytree=true&${qs.stringify(params)}`)
  }, */

  getFamily(folderId, familyId) {
    return axiosAPI.get(`/${folderId}/familles/${familyId}`)
  },

  postFamily(folderId, dataPost) {
    return axiosAPI.post(`/${folderId}/familles`, qs.stringify(dataPost))
  },

  updateFamily(folderId, dataPost) {
    return axiosAPI.put(`/${folderId}/familles`, qs.stringify(dataPost))
  },

  removeFamily(folderId, familyId) {
    return axiosAPI.delete(`/${folderId}/familles?familles[0]=${familyId} `)
  },

  getNextCode(folderId, prefix) {
    return axiosAPI.get(`/${folderId}/familles/nextcode/${prefix}`)
  },

}