import React, { useEffect, useState } from 'react';
import { ArticleService } from '../../../../../services/ArticleService';
import { FournisseurService } from '../../../../../services/FournisseurService';
import { Form, Spinner, Card, Tabs, Tab, Row, Col, Accordion, Button, OverlayTrigger, Alert, Tooltip, Dropdown, Badge } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import ErrorManager from "../../../../error-manager/ErrorManager";
import ConfirmDialog from '../../../../confirm-dialog/ConfirmDialog';
import Pagination from '../../../../../components/pagination/Pagination';
import { getFormErrorMsg, getInvalidObject, getChangedValues } from '../../../../../helper/Helper'
import TextArrayInput from "../../../../form-inputs/text-array-input/TextArrayInput";


const Interlocuteurs = (props) => {

    const [currentInterlocuteur, setCurrentInterlocuteur] = useState()
    const [currentInterlocuteurInitial, setCurrentInterlocuteurInitial] = useState()
    const [invalidData, setInvalidData] = useState()
    const [globalError, setGlobalError] = useState()

    useEffect(() => {
    }, []);

    function postInterlocuteur() {
        FournisseurService.postInterlocuteur(props.folderId, props.fournisseur._id, { interlocuteurs: [currentInterlocuteur] }).then(res => {
            setCurrentInterlocuteur()
            setGlobalError()
            props.onInterlocuteurChange()
        }).catch(error => {
            setGlobalError(error)
            setInvalidData(getInvalidObject(error.data?.data))
        });
    }

    function updateInterlocuteur() {
        FournisseurService.updateInterlocuteur(props.folderId, props.fournisseur._id, { interlocuteurs: [getChangedValues(currentInterlocuteur, currentInterlocuteurInitial, [], ["_id"], ["tel", "mails"])] }).then(res => {
            setCurrentInterlocuteur()
            setGlobalError()
            props.onInterlocuteurChange()
        }).catch(error => {
            setGlobalError(error)
            setInvalidData(getInvalidObject(error.data?.data))
        });
    }

    function removeInterlocuteur() {
        FournisseurService.removeInterlocuteur(props.folderId, props.fournisseur._id, currentInterlocuteur._id).then(res => {
            setCurrentInterlocuteur()
            setGlobalError()
            props.onInterlocuteurChange()
        }).catch(error => {
            setGlobalError(error)
        });
    }

    function editNewInterlocuteur() {
        setCurrentInterlocuteur({
            nom: '',
            prenom: '',
            service: '',
            tel: [],
            mails: [],
            observation: '',
            principal: props.fournisseur?.interlocuteurs?.length == 0 ? true : false,
        })
    }

    function editInterlocuteur(i) {
        setCurrentInterlocuteur(i)
        setCurrentInterlocuteurInitial(i)
    }


    function handleChange(e) {
        let value
        e.target.type == "checkbox" ? value = e.target.checked : value = e.target.value
        setCurrentInterlocuteur({ ...currentInterlocuteur, [e.target.name]: value })
        setInvalidData({ ...invalidData, [e.target.name]: null })
    }

    return (
        <>

            {globalError && (
                <ErrorManager error={globalError} />
            )}

            {props.isFournisseurLoading &&
                <span className="hloader mt-3"></span>
            }

            {!props.isFournisseurLoading &&
                <>
                    <div className="d-flex align-items-center">
                        {(props.fournisseur?.interlocuteurs?.length == 0 && !currentInterlocuteur) &&
                            <em>- Aucun élément trouvé -</em>
                        }
                        <Button disabled={currentInterlocuteur} className="ms-auto" onClick={() => editNewInterlocuteur()}>Ajouter un interlocuteur</Button>
                    </div>
                    
                    {(currentInterlocuteur && !currentInterlocuteur._id) &&
                        <div className="p-4 border rounded mt-2 mb-4">
                            <Form.Group className="d-flex align-items-center">
                                <Form.Label column sm={3}>Nom</Form.Label>
                                <Col sm={9}>
                                    <Form.Control size="sm" name="nom" value={currentInterlocuteur.nom} isInvalid={getFormErrorMsg(invalidData?.nom)} onChange={handleChange} type="text" />
                                    <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.nom)}</Form.Control.Feedback>
                                </Col>
                            </Form.Group>

                            <Form.Group className="d-flex align-items-center">
                                <Form.Label column sm={3}>Prénom</Form.Label>
                                <Col sm={9}>
                                    <Form.Control size="sm" name="prenom" value={currentInterlocuteur.prenom} isInvalid={getFormErrorMsg(invalidData?.prenom)} onChange={handleChange} type="text" />
                                    <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.prenom)}</Form.Control.Feedback>
                                </Col>
                            </Form.Group>

                            <Form.Group className="d-flex align-items-center">
                                <Form.Label column sm={3}>Service</Form.Label>
                                <Col sm={9}>
                                    <Form.Control size="sm" name="service" value={currentInterlocuteur.service} isInvalid={getFormErrorMsg(invalidData?.service)} onChange={handleChange} type="text" />
                                    <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.service)}</Form.Control.Feedback>
                                </Col>
                            </Form.Group>

                            <Form.Group className="d-flex align-items-center">
                                <Form.Label column sm={3}>Téléphone</Form.Label>
                                <Col sm={9}>
                                    <TextArrayInput value={currentInterlocuteur.tel} addLabel="Ajouter un numéro de tel" size="sm" width="100" setTextArrayList={handleChange} name="tel" placeHolder="Tel" isInvalid={getFormErrorMsg(invalidData?.tel)} feedback={getFormErrorMsg(invalidData?.tel)} />
                                </Col>
                            </Form.Group>

                            <Form.Group className="d-flex align-items-center">
                                <Form.Label column sm={3}>Mails</Form.Label>
                                <Col sm={9}>
                                    <TextArrayInput value={currentInterlocuteur.mails} addLabel="Ajouter un email" size="sm" width="100" setTextArrayList={handleChange} name="mails" placeHolder="E-mail" isInvalid={getFormErrorMsg(invalidData?.mails)} feedback={getFormErrorMsg(invalidData?.mails)} />
                                </Col>
                            </Form.Group>

                            <Form.Group className="d-flex align-items-center">
                                <Form.Label column sm={3}>Observation</Form.Label>
                                <Col sm={9}>
                                    <Form.Control size="sm" name="observation" value={currentInterlocuteur.observation} isInvalid={getFormErrorMsg(invalidData?.observation)} onChange={handleChange} type="text" />
                                    <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.observation)}</Form.Control.Feedback>
                                </Col>
                            </Form.Group>

                            <Form.Group className="mt-2">
                                <Col sm={12}>
                                    <Form.Check type="checkbox" id="interlocuteur-principal">
                                        <Form.Check.Input disabled={props.fournisseur?.interlocuteurs?.length == 0} name="principal" value={currentInterlocuteur.principal} checked={currentInterlocuteur.principal ? true : false} type="checkbox" onChange={handleChange} isInvalid={getFormErrorMsg(invalidData?.principal)} />
                                        <Form.Check.Label>Interlocuteur principal</Form.Check.Label>
                                        <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.principal)}</Form.Control.Feedback>
                                    </Form.Check>
                                </Col>
                            </Form.Group>
                            <Row>
                                <Col className="d-flex justify-content-end mt-2">
                                    <Button variant="secondary" className="me-2" onClick={() => setCurrentInterlocuteur()}>Annuler</Button>
                                    <Button onClick={postInterlocuteur}>Enregistrer l'interlocuteur</Button>
                                </Col>
                            </Row>
                        </div>
                    }

                    {props.fournisseur?.interlocuteurs?.map((interlocuteur, i, arr) => (
                        <div key={interlocuteur._id} >
                            {(interlocuteur._id != currentInterlocuteur?._id) &&
                                <div className={"p-4 rounded bg-gray-100 mt-2 " + (arr.length - 1 === i ? "" : "mb-2")}>
                                    <Row>
                                        <Col>
                                            <p className="m-0">{interlocuteur.nom} {interlocuteur.prenom} - {interlocuteur.service}. {interlocuteur.principal && (<span>(Interlocuteur principal)</span>)}</p>
                                        </Col>
                                        <Col className="col-auto">
                                            <Button variant="secondary" size="sm" onClick={() => editInterlocuteur(interlocuteur)}>Modifier</Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="12">
                                            <p className="m-0">
                                                Tél :
                                                <span>
                                                    {interlocuteur?.tel?.map((tel, i) =>
                                                        <Badge key={i} bg="secondary" text="dark" className="ms-1">{tel}</Badge>
                                                    )}
                                                </span>
                                            </p>
                                            <p className="m-0">
                                                Mails :
                                                <span>
                                                    {interlocuteur?.mails?.map((mail, i) =>
                                                        <Badge key={i} bg="secondary" text="dark" className="ms-1">{mail}</Badge>
                                                    )}
                                                </span>
                                            </p>
                                            <p className="m-0 mt-2">{interlocuteur.observation}</p>
                                        </Col>
                                    </Row>
                                </div>
                            }
                            {(interlocuteur._id == currentInterlocuteur?._id) &&
                                <div className="p-4 border rounded mt-2">
                                    <Form.Group className="d-flex align-items-center">
                                        <Form.Label column sm={3}>Nom</Form.Label>
                                        <Col sm={9}>
                                            <Form.Control size="sm" name="nom" value={currentInterlocuteur.nom} isInvalid={getFormErrorMsg(invalidData?.nom)} onChange={handleChange} type="text" />
                                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.nom)}</Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group className="d-flex align-items-center">
                                        <Form.Label column sm={3}>Prénom</Form.Label>
                                        <Col sm={9}>
                                            <Form.Control size="sm" name="prenom" value={currentInterlocuteur.prenom} isInvalid={getFormErrorMsg(invalidData?.prenom)} onChange={handleChange} type="text" />
                                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.prenom)}</Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group className="d-flex align-items-center">
                                        <Form.Label column sm={3}>Service</Form.Label>
                                        <Col sm={9}>
                                            <Form.Control size="sm" name="service" value={currentInterlocuteur.service} isInvalid={getFormErrorMsg(invalidData?.service)} onChange={handleChange} type="text" />
                                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.service)}</Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group className="d-flex align-items-center">
                                        <Form.Label column sm={3}>Téléphone</Form.Label>
                                        <Col sm={9}>
                                            <TextArrayInput value={currentInterlocuteur.tel} addLabel="Ajouter un numéro de tel" size="sm" width="100" setTextArrayList={handleChange} name="tel" placeHolder="Tel" isInvalid={getFormErrorMsg(invalidData?.tel)} feedback={getFormErrorMsg(invalidData?.tel)} />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group className="d-flex align-items-center">
                                        <Form.Label column sm={3}>Mails</Form.Label>
                                        <Col sm={9}>
                                            <TextArrayInput value={currentInterlocuteur.mails} addLabel="Ajouter un email" size="sm" width="100" setTextArrayList={handleChange} name="mails" placeHolder="E-mail" isInvalid={getFormErrorMsg(invalidData?.mails)} feedback={getFormErrorMsg(invalidData?.mails)} />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group className="d-flex align-items-center">
                                        <Form.Label column sm={3}>Observation</Form.Label>
                                        <Col sm={9}>
                                            <Form.Control size="sm" name="observation" value={currentInterlocuteur.observation} isInvalid={getFormErrorMsg(invalidData?.observation)} onChange={handleChange} type="text" />
                                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.observation)}</Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group className="mt-2">
                                        <Col sm={12}>
                                            <Form.Check type="checkbox" id="interlocuteur-principal">
                                                <Form.Check.Input disabled={props.fournisseur?.interlocuteurs?.length == 1} name="principal" value={currentInterlocuteur.principal} checked={currentInterlocuteur.principal ? true : false} type="checkbox" onChange={handleChange} isInvalid={getFormErrorMsg(invalidData?.principal)} />
                                                <Form.Check.Label>Interlocuteur principal</Form.Check.Label>
                                                <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.principal)}</Form.Control.Feedback>
                                            </Form.Check>
                                        </Col>
                                    </Form.Group>
                                    <Row>
                                        <Col className="d-flex justify-content-end mt-2">
                                            <Button variant="danger" className="me-auto" onClick={removeInterlocuteur}>Supprimer</Button>
                                            <Button variant="secondary" className="me-2" onClick={() => setCurrentInterlocuteur()}>Annuler</Button>
                                            <Button onClick={updateInterlocuteur}>Enregistrer les modifications</Button>
                                        </Col>
                                    </Row>
                                </div>
                            }
                        </div>
                    ))}
                </>
            }


        </>
    );

}
export default Interlocuteurs;