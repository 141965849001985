import React, { useEffect, useState } from 'react';
import { FournisseurService } from '../../../../services/FournisseurService';
import { Form, Spinner, Card, Tabs, Tab, Row, Col, Accordion, Button, OverlayTrigger, Alert, Tooltip, Dropdown, Badge } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import ErrorManager from "../../../error-manager/ErrorManager";
import { getFormErrorMsg } from '../../../../helper/Helper'
//import './FournisseurDetails.scss';
import Interlocuteurs from "./interlocuteurs/Interlocuteurs";
import Adresses from "./adresses/Adresses";
import Articles from "./articles/Articles";
import { Editor, convertFromHTML, EditorState, createWithContent, convertFromRaw, ContentState, convertToRaw } from "draft-js";
import { markdownToDraft } from 'markdown-draft-js';

const FournisseurDetails = (props) => {

    let [openElement, setOpenElement] = useState("fournisseur")
    let [fournisseur, setFournisseur] = useState()
    let [adressEditing, setAdressEditing] = useState()
    let [interlocuteurEditing, setInterlocuteurEditing] = useState()
    const [invalidData, setInvalidData] = useState()

    const [interlocuteurError, setInterlocuteurError] = useState()
    const [adressError, setAdressError] = useState()


    const [getFournisseurLoading, setGetFournisseurLoading] = useState()

    useEffect(() => {
        setFournisseur()
        getFournisseur()
    }, [props.fournisseurId]);

    function handleTabSelect(key) {
        setOpenElement(key)
    }

    function getFournisseur() {
        setGetFournisseurLoading(true)
        FournisseurService.getFournisseur(props.folderId, props.fournisseurId).then(res => {
            setFournisseur(res.status == 200 ? res.data.data : { warning: res.statusText })
            setGetFournisseurLoading(false)
        }).catch(error => {
            setFournisseur({ error: error })
            setGetFournisseurLoading(false)
        });
    }

    function Split({ title, value, border }) {
        return (
            <div className={"d-flex justify-content-between py-2 " + (border ? "border-bottom" : "")}>
                <span className="pe-4 text-gray-500">{title}</span>
                <span className="">{value}</span>
            </div>
        )
    }

    /*     function editInterlocuteur(i){
            // setInterlocuteurError()
            // setInvalidData()
            setInterlocuteurEditing(i)
        } */

    function editAdress(a) {
        // setAdressError()
        // setInvalidData()
        setAdressEditing(a)
    }

    function tabChange() {
        getFournisseur()
        props.onTabChange()
    }

    return (
        <div id="FournisseurDetails">

            {(!fournisseur && getFournisseurLoading) &&
                <span className="hloader mt-3"></span>
            }

            {fournisseur?.warning &&
                <div className="d-inline-block w-100 mt-4">
                    <Alert variant="warning">
                        {fournisseur?.warning}
                    </Alert>
                </div>
            }

            {fournisseur?.error &&
                <div className="d-inline-block w-100 mt-4">
                    <ErrorManager error={fournisseur?.error} />
                </div>
            }

            {
                (fournisseur && !fournisseur.warning && !fournisseur.error) && (

                    <>
                        <div className="d-flex mb-4 align-items-center">
                            <div className="me-auto">
                                <h6 className="fw-bolder m-0">{fournisseur.nom}</h6>
                                <h6 className="fw-bolder m-0 text-secondary">{fournisseur.code}</h6>
                            </div>
                            {props.elementActions.length > 0 &&
                                <Dropdown className="float-end ms-2 me-3">
                                    <OverlayTrigger
                                        placement="left"
                                        delay={{ show: 800 }}
                                        overlay={
                                            <Tooltip>
                                                Plus d'actions
                                            </Tooltip>
                                        }
                                    >
                                        <Dropdown.Toggle variant="secondary" className='no-btn-caret'>
                                            <i className="material-icons i-lg">more_horiz</i>
                                        </Dropdown.Toggle>
                                    </OverlayTrigger>
                                    <Dropdown.Menu>
                                        {props.elementActions.map((element) => (
                                            <Dropdown.Item key={element.title} onClick={() => props.setElementAction(element.action, fournisseur)}>{element.title}</Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            }
                        </div>

                        <div>

                            <Tabs variant="pills" defaultActiveKey="fournisseur" onSelect={handleTabSelect} className="mb-3">
                                <Tab eventKey="fournisseur" title="Fournisseur" className="light-v-scroll height-full-min-250 pe-2">
                                    {openElement == "fournisseur" &&
                                        <>
                                            <Split title="Nom" border value={fournisseur.nom} />
                                            <Split title="Code" border value={fournisseur.code} />
                                            <Split title="Observation" />
                                            {fournisseur.observation &&
                                                <Editor editorState={EditorState.createWithContent(convertFromRaw(markdownToDraft(fournisseur.observation)))} readOnly={true} />
                                            }
                                            {!fournisseur.observation &&
                                                <em>- Aucune observation rédigée -</em>
                                            }
                                        </>
                                    }
                                </Tab>

                                <Tab eventKey="adresses" title="Adresses" className="light-v-scroll height-full-min-250 pe-2">
                                    {openElement == "adresses" &&
                                        <Adresses folderId={props.folderId} fournisseur={fournisseur} isFournisseurLoading={getFournisseurLoading} onAdresseChange={() => tabChange()} />
                                    }
                                </Tab>
                                <Tab eventKey="interlocuteurs" title="Interlocuteurs" className="light-v-scroll height-full-min-250 pe-2">
                                    {openElement == "interlocuteurs" &&
                                        <Interlocuteurs folderId={props.folderId} fournisseur={fournisseur} isFournisseurLoading={getFournisseurLoading} onInterlocuteurChange={() => tabChange()} />
                                    }
                                </Tab>
                                <Tab eventKey="articles" title="Articles" className="light-v-scroll height-full-min-250 pe-2">
                                    {openElement == "articles" &&
                                        <Articles folderId={props.folderId} fournisseur={fournisseur} />
                                    }
                                </Tab>
                            </Tabs>

                        </div>

                    </>

                )
            }
        </div>

    )

}

export default FournisseurDetails;