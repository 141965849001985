import {axiosAPI} from "./axiosClient"
import { UserService } from './UserService';
import { useParams, useSearchParams } from "react-router-dom";
import qs from "qs";
import { getParams } from '../helper/Helper'


export const ClientService = {

  getClients(folderId, params) {
    return axiosAPI.get(`/${folderId}/clients?lookup=true&${getParams(params)}`)
  },

  getClientsPDF(folderId, params) {
    return axiosAPI.get(`/${folderId}/clients?lookup=true&${getParams(params)}&format=pdf`, {responseType: 'blob'})
  },

  getClient(folderId, clientId){
    return axiosAPI.get(`/${folderId}/clients/${clientId}?lookup=true`)
  },

  updateClient(folderId, dataPost){
    return axiosAPI.put(`/${folderId}/clients`, qs.stringify(dataPost))
  },

  postClient(folderId, dataPost){
    return axiosAPI.post(`/${folderId}/clients`, qs.stringify(dataPost))
  },
  
  removeClients(folderId, clientIdList) {
    var params = '?clients[]=' + clientIdList.join('&clients[]=');
    return axiosAPI.delete(`/${folderId}/clients${params}`)
  },
  
  updateAdresse(folderId, clientId, dataPost){
    return axiosAPI.put(`/${folderId}/clients/${clientId}/adresses`, qs.stringify(dataPost))
  },
  
  postAdresse(folderId, clientId, dataPost){
    return axiosAPI.post(`/${folderId}/clients/${clientId}/adresses`, qs.stringify(dataPost))
  },
  
  removeAdresse(folderId, clientId, adresseId) {
    return axiosAPI.delete(`/${folderId}/clients/${clientId}/adresses/${adresseId}`)
  },

  updateInterlocuteur(folderId, clientId, dataPost){
    return axiosAPI.put(`/${folderId}/clients/${clientId}/interlocuteurs`, qs.stringify(dataPost))
  },
  
  postInterlocuteur(folderId, clientId, dataPost){
    return axiosAPI.post(`/${folderId}/clients/${clientId}/interlocuteurs`, qs.stringify(dataPost))
  },
  
  removeInterlocuteur(folderId, clientId, interlocuteurId) {
    return axiosAPI.delete(`/${folderId}/clients/${clientId}/interlocuteurs/${interlocuteurId}`)
  },
  
  getFactures(folderId, clientId, params){
    return axiosAPI.get(`/${folderId}/clients/${clientId}/factures?lookup=true&${getParams(params)}`)
  },
  
  getProformas(folderId, clientId, params){
    return axiosAPI.get(`/${folderId}/clients/${clientId}/proformas?${getParams(params)}`)
  },
  
  getNextCode(folderId) {
    return axiosAPI.get(`/${folderId}/clients/nextcode`)
  },
  
  postTag(folderId, clientId, tagId){
    return axiosAPI.post(`/${folderId}/clients/${clientId}/tags/${tagId}`)
  },

  removeTag(folderId, clientId, tagId){
    return axiosAPI.delete(`/${folderId}/clients/${clientId}/tags/${tagId}`)
  },

}