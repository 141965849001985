import React, { useEffect, useState } from 'react';
import { FournisseurService } from '../../../../services/FournisseurService';
import { Spinner, Card, Tabs, Tab, Row, Col, Accordion, Button, Table, Alert, Form, Dropdown, FloatingLabel } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import ErrorManager from "../../../error-manager/ErrorManager";
import { getFormErrorMsg, getInvalidObject, getChangedValues } from '../../../../helper/Helper'
import WysiwygInput from "../../../../components/form-inputs/wysiwyg-input/WysiwygInput";

const FournisseurEdition = (props) => {

    const [form, setForm] = useState({
        "_id": "",
        "code": "",
        "nom": "",
        "observation": ""
    })

    const [invalidData, setInvalidData] = useState()
    const [globalError, setGlobalError] = useState()
    const [getFournisseurLoading, setGetFournisseurLoading] = useState(false)
    const [fournisseurInitial, setFournisseurInitial] = useState()
    const [inputChanged, setInputChanged] = useState(false)
    const [isCodeLoading, setIsCodeLoading] = useState(false)
    const [isSaveLoading, setIsSaveLoading] = useState(false)

    useEffect(() => {
        if (props.fournisseurEditingId) {
            getFournisseur(props.fournisseurEditingId)
        } else {
            setGetFournisseurLoading(false)
            setNextCode()
        }
    }, []);

    function handleChange(e) {
        setInputChanged(true)
        let value
        e.target.type == "checkbox" ? value = e.target.checked : value = e.target.value
        setForm({ ...form, [e.target.name]: value })
        setInvalidData({ ...invalidData, [e.target.name]: null })
    }

    function getFournisseur(fournisseurId) {
        FournisseurService.getFournisseur(props.folderId, fournisseurId).then(res => {
            mapForm(res.data.data)
            setGetFournisseurLoading(false)
            setFournisseurInitial(res.data.data)
        }).catch(error => {
            setGlobalError(error)
            setGetFournisseurLoading(false)
        });
    }

    function setNextCode() {
        setIsCodeLoading(true)
        FournisseurService.getNextCode(props.folderId).then(res => {
            setIsCodeLoading(false)
            setForm({ ...form, ['code']: res.data.data })
        }).catch(error => {
            setIsCodeLoading(false)
            setGlobalError(error)
        });
    }

    function mapForm(apiObject) {
        let formCopy = {}
        for (let key in form) {
            formCopy[key] = apiObject[key]
        }
        setForm(formCopy)
    }

    function addFournisseur() {
        setIsSaveLoading(true)
        FournisseurService.postFournisseur(props.folderId, { fournisseurs: [form] }).then(res => {
            props.onSetSaved(res.data.data.fournisseurs[0])
        }).catch(error => {
            setGlobalError(error)
            setInvalidData(getInvalidObject(error.data?.data))
            setIsSaveLoading(false)
        });
    }

    function updateFournisseur() {
        setIsSaveLoading(true)
        FournisseurService.updateFournisseur(props.folderId, { fournisseurs: [getChangedValues(form, fournisseurInitial, null, ["_id"])] }).then(res => {
            props.onSetSaved(res.data.data.fournisseurs[0])
        }).catch(error => {
            setGlobalError(error)
            setInvalidData(getInvalidObject(error.data?.data))
            setIsSaveLoading(false)
        });
    }

    return (
        <>

            <Form.Group className="mb-3 float-end me-2 d-flex flex-column" controlId="formBasicEmail">
                {!props.fournisseurEditingId &&
                    <Button
                        disabled={isSaveLoading || !form.code || !form.nom}
                        variant="primary"
                        onClick={() => addFournisseur()}>
                        {isSaveLoading &&
                            <Spinner animation="border" size="sm" className="me-2" />
                        }
                        Enregistrer
                    </Button>
                }
                {props.fournisseurEditingId &&
                    <Button
                        disabled={isSaveLoading || !inputChanged || !form.code || !form.nom}
                        variant="primary"
                        onClick={() => updateFournisseur()}>
                        {isSaveLoading &&
                            <Spinner animation="border" size="sm" className="me-2" />
                        }
                        Enregistrer les modifications
                    </Button>
                }
            </Form.Group>

            {!props.fournisseurEditingId &&
                <div className="d-flex  mb-4 ps-2">
                    <div className="pe-3 w-100">
                        <h6 className="text-secondary m-0">Créer un</h6>
                        <h6 className="fw-bolder m-0">Nouveau fournisseur</h6>
                    </div>
                </div>
            }

            {props.fournisseurEditingId &&
                <div className="d-flex  mb-4 ps-2">
                    <div className="pe-3 w-100">
                        <h6 className="text-secondary m-0">Modification du fournisseur</h6>
                        <h6 className="fw-bolder m-0">{form?.nom}</h6>
                    </div>
                </div>
            }

            {getFournisseurLoading &&
                <span className="hloader mt-3"></span>
            }

            {!getFournisseurLoading &&
                <div className="light-v-scroll pe-3 ps-2 pb-5 w-100">

                    {globalError && (
                        <ErrorManager error={globalError} fixed="true" />
                    )}

                    <Form>


                        <Form.Group className="mb-3">
                            <Form.Label>Code<span className="text-danger">*</span></Form.Label>
                            {isCodeLoading &&
                                <Spinner animation="border" size="sm" className="ms-2 text-gray-400" />
                            }
                            <Form.Control disabled={isCodeLoading} autoFocus name="code" value={form?.code} isInvalid={getFormErrorMsg(invalidData?.code)} onChange={handleChange} type="text" />
                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.code)}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Nom<span className="text-danger">*</span></Form.Label>
                            <Form.Control name="nom" value={form?.nom} isInvalid={getFormErrorMsg(invalidData?.nom)} onChange={handleChange} type="text" />
                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.nom)}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Observation</Form.Label>
                            <WysiwygInput value={form?.observation} placeHolder="Observations..." changeValue={handleChange} name="observation" isInvalid={getFormErrorMsg(invalidData?.observation)} feedback={getFormErrorMsg(invalidData?.observation)} />
                        </Form.Group>

                    </Form>
                </div>
            }
        </>

    )

}

export default FournisseurEdition;