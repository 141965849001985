import axios from 'axios';
import '../config.inc';
import { UserService } from './UserService'
import { getDomainFromUrl, setCookie, getCookie } from '../helper/Helper';

/// axiosAPI /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


const createAxiosInstance = (baseURL, isTokenRequired) => {
    const instance = axios.create({
        baseURL,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        withCredentials: false,
    });

    // Intercepteur pour ajouter le token d'authentification
    instance.interceptors.request.use((config) => {
        if(isTokenRequired){
            const refreshToken = getCookie('refresh_token');
            const accessToken = sessionStorage.getItem('access_token');
            if (refreshToken) {
                config.headers.Authorization = `Bearer ${accessToken}`;
            }else{
                UserService.redirectToExternalLogin();
                return
            }
        }
        return config;
    });

    return instance;
};

// Gestion du rafraîchissement du token
const setupTokenRefreshInterceptor = (instance) => {
    instance.interceptors.response.use(
        (response) => response,
        async (error) => {
            if (error.response?.status === 401) {
                const refreshToken = getCookie('refresh_token');
                if (!refreshToken) {
                    UserService.redirectToExternalLogin();
                    return Promise.reject(error);
                }

                const dataPost = {
                    client_id: global.config._OAUTH_CLIENT_ID_,
                    grant_type: 'refresh_token',
                    refresh_token: refreshToken,
                    client_secret: global.config._OAUTH_CLIENT_SECRET_,
                };

                try {
                    const res = await UserService.getToken(dataPost);
                    sessionStorage.setItem('access_token', res.data.access_token);
                    setCookie('id_token', res.data.id_token, 24, getDomainFromUrl(global.config._FRONT_URL_));
                    setCookie('refresh_token', res.data.refresh_token, 168, getDomainFromUrl(global.config._FRONT_URL_));

                    // Réexécution de la requête avec le nouveau token
                    error.config.headers.Authorization = `Bearer ${res.data.access_token}`;
                    return axios.request(error.config);
                } catch {
                    UserService.redirectToExternalLogin();
                    return Promise.reject(error);
                }
            }
            return Promise.reject(error);
        }
    );
};

export const axiosAPI = createAxiosInstance(global.config._API_URL_, true);
setupTokenRefreshInterceptor(axiosAPI);

export const axiosOAuth = createAxiosInstance(global.config._OAUTH_PUBLIC_URL_, true);
setupTokenRefreshInterceptor(axiosOAuth);

export const axiosOAuthNoTokenRequired = createAxiosInstance(global.config._OAUTH_PUBLIC_URL_, false);

export const axiosLoginXl = createAxiosInstance(global.config._PROFIL_API_URL_, true);