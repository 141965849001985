import React, { useEffect, useState } from 'react';
import { TarifService } from '../../../../services/TarifService';
import { Spinner, Card, Tabs, Tab, Row, Col, Accordion, Button, Table, InputGroup, Form, Dropdown, FloatingLabel } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import ErrorManager from "../../../error-manager/ErrorManager";
import { getFormErrorMsg, getInvalidObject, getChangedValues, formattedDateInput } from '../../../../helper/Helper'
import WysiwygInput from "../../../../components/form-inputs/wysiwyg-input/WysiwygInput";

const TarifEdition = (props) => {

    const [form, setForm] = useState({
        "_id": "",
        "type": "",
        "code": "",
        "libelle": "",
        "portee": "",
        "date_debut": formattedDateInput(new Date()),
        "date_fin": "",
        "prix_vente_ht": "",
        "prix_vente_ttc": "",
        "coefficient_pr": "",
        "taux_remise": "",
    })

    const [invalidData, setInvalidData] = useState()
    const [globalError, setGlobalError] = useState()
    const [getTarifLoading, setGetTarifLoading] = useState(false)
    const [tarifInitial, setTarifInitial] = useState()
    const [inputChanged, setInputChanged] = useState(false)
    const [isCodeLoading, setIsCodeLoading] = useState(false)
    const [currentType, setCurrentType] = useState()
    const [currentValue, setCurrentValue] = useState()
    const [isSaveLoading, setIsSaveLoading] = useState(false)

    useEffect(() => {
        if (props.tarifEditingId) {
            getTarif(props.tarifEditingId)
        } else {
            setGetTarifLoading(false)
            setNextCode()
        }
    }, []);

    function handleChange(e) {

        setInputChanged(true)
        let value
        e.target.type == "checkbox" ? value = e.target.checked : value = e.target.value

        let formCopy = { ...form }
        formCopy[e.target.name] = value

        if (e.target.name == "type" && e.target.value == "tarif") {
            formCopy["portee"] = "debit"
        }

        setForm(formCopy)
        setInvalidData({ ...invalidData, [e.target.name]: null })

    }

    function getTarif(tarifId) {
        TarifService.getTarif(props.folderId, tarifId).then(res => {
            mapForm(res.data.data)
            setGetTarifLoading(false)
            setTarifInitial(res.data.data)
        }).catch(error => {
            setGlobalError(error)
            setGetTarifLoading(false)
        });
    }

    function setNextCode() {
        setIsCodeLoading(true)
        TarifService.getNextCode(props.folderId).then(res => {
            setIsCodeLoading(false)
            setForm({ ...form, ['code']: res.data.data })
        }).catch(error => {
            setIsCodeLoading(false)
            setGlobalError(error)
        });
    }

    function mapForm(apiObject) {

        let formCopy = {}
        for (let key in form) {
            if (apiObject[key] !== null) {
                formCopy[key] = apiObject[key]
            } else {
                formCopy[key] = ""
            }
        }
        setForm(formCopy)

        let currVal
        if (apiObject["coefficient_pr"] !== null) { currVal = apiObject["coefficient_pr"] }
        if (apiObject["prix_vente_ttc"] !== null) { currVal = apiObject["prix_vente_ttc"] }
        if (apiObject["prix_vente_ht"] !== null) { currVal = apiObject["prix_vente_ht"] }
        if (apiObject["taux_remise"] !== null) { currVal = apiObject["taux_remise"] }
        setCurrentValue(currVal)

        let currType
        if (apiObject["coefficient_pr"] !== null) { currType = "coefficient_pr" }
        if (apiObject["prix_vente_ttc"] !== null) { currType = "prix_vente_ttc" }
        if (apiObject["prix_vente_ht"] !== null) { currType = "prix_vente_ht" }
        if (apiObject["taux_remise"] !== null) { currType = "taux_remise" }
        setCurrentType(currType)

    }

    function addTarif() {
        setIsSaveLoading(true)
        TarifService.postTarif(props.folderId, { tarifs: [form] }).then(res => {
            props.onSetSaved(res.data.data.tarifs[0])
        }).catch(error => {
            setGlobalError(error)
            setInvalidData(getInvalidObject(error.data?.data))
            setIsSaveLoading(false)
        });
    }

    function updateTarif() {
        setIsSaveLoading(true)
        let formCopy = { ...form }
        formCopy["date_debut"] = formCopy["date_debut"] ? formCopy["date_debut"] : "00/00/0000"
        formCopy["date_fin"] = formCopy["date_fin"] ? formCopy["date_fin"] : "00/00/0000"
        TarifService.updateTarif(props.folderId, { tarifs: [getChangedValues(formCopy, tarifInitial, null, ["_id"])] }).then(res => {
            props.onSetSaved(res.data.data.tarifs[0])
        }).catch(error => {
            setGlobalError(error)
            setInvalidData(getInvalidObject(error.data?.data))
            setIsSaveLoading(false)
        });
    }

    function handleChangeType(e) {
        setInputChanged(true)
        setCurrentType(e.target.value)
        let formCopy = { ...form }
        formCopy['prix_vente_ht'] = ""
        formCopy['prix_vente_ttc'] = ""
        formCopy['coefficient_pr'] = ""
        formCopy['taux_remise'] = ""
        formCopy[e.target.value] = currentValue ? currentValue : ""
        setForm(formCopy)
        setInvalidData({ ...invalidData, [e.target.value]: null })
    }

    function handleChangeValue(e) {
        setInputChanged(true)
        setCurrentValue(e.target.value)
        let formCopy = { ...form }
        formCopy[currentType] = e.target.value
        setForm(formCopy)
        setInvalidData({ ...invalidData, [currentType]: null })
    }

    return (
        <>

            <Form.Group className="mb-3 float-end me-2 d-flex flex-column" controlId="formBasicEmail">
                {!props.tarifEditingId &&
                    <Button
                        disabled={isSaveLoading || !form.code || !form.type || !form.libelle}
                        variant="primary"
                        onClick={() => addTarif()}>
                        {isSaveLoading &&
                            <Spinner animation="border" size="sm" className="me-2" />
                        }
                        Enregistrer
                    </Button>
                }
                {props.tarifEditingId &&
                    <Button
                        disabled={isSaveLoading || !inputChanged || !form.code || !form.type || !form.libelle}
                        variant="primary"
                        onClick={() => updateTarif()}>
                        {isSaveLoading &&
                            <Spinner animation="border" size="sm" className="me-2" />
                        }
                        Enregistrer les modifications
                    </Button>
                }
            </Form.Group>

            {!props.tarifEditingId &&
                <div className="d-flex  mb-4 ps-2">
                    <div className="pe-3 w-100">
                        <h6 className="text-secondary m-0">Créer un</h6>
                        <h6 className="fw-bolder m-0">Nouveau tarif</h6>
                    </div>
                </div>
            }

            {props.tarifEditingId &&
                <div className="d-flex  mb-4 ps-2">
                    <div className="pe-3 w-100">
                        <h6 className="text-secondary m-0">Modification du tarif</h6>
                        <h6 className="fw-bolder m-0">{form?.libelle}</h6>
                    </div>
                </div>
            }

            {getTarifLoading &&
                <span className="hloader mt-3"></span>
            }

            {!getTarifLoading &&
                <div className="light-v-scroll pe-3 ps-2 pb-5 w-100">

                    {globalError && (
                        <ErrorManager error={globalError} fixed="true" />
                    )}

                    <Form>

                        <Form.Group className="mb-3">
                            <Form.Label>Code<span className="text-danger">*</span></Form.Label>
                            {isCodeLoading &&
                                <Spinner animation="border" size="sm" className="ms-2 text-gray-400" />
                            }
                            <Form.Control disabled={isCodeLoading} autoFocus name="code" value={form?.code} isInvalid={getFormErrorMsg(invalidData?.code)} onChange={handleChange} type="text" />
                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.code)}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Libelle<span className="text-danger">*</span></Form.Label>
                            <Form.Control name="libelle" value={form?.libelle} isInvalid={getFormErrorMsg(invalidData?.libelle)} onChange={handleChange} type="text" />
                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.libelle)}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Type<span className="text-danger">*</span></Form.Label>
                            <Form.Select name="type" value={form?.type} isInvalid={getFormErrorMsg(invalidData?.type)} onChange={handleChange}>
                                <option value="">- Aucun -</option>
                                <option value="tarif">Tarif</option>
                                <option value="promotion">Promotion</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.type)}</Form.Control.Feedback>
                        </Form.Group>

                        {form.type != "tarif" &&
                            <Form.Group className="mb-3">
                                <Form.Label>Portée</Form.Label>
                                <Form.Select name="portee" value={form?.portee} isInvalid={getFormErrorMsg(invalidData?.portee)} onChange={handleChange}>
                                    <option value="" disabled>- Sélectionner -</option>
                                    <option value="debit">Débit</option>
                                    <option value="comptant">Comptant</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.type)}</Form.Control.Feedback>
                            </Form.Group>
                        }


                        <Form.Group className="mb-3">
                            <Form.Label>Date de début<span className="text-danger">*</span></Form.Label>
                            <Form.Control name="date_debut" value={form?.date_debut && formattedDateInput(new Date(form?.date_debut))} isInvalid={getFormErrorMsg(invalidData?.date_debut)} onChange={handleChange} placeholder="Date de début" type="date" />
                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.date_debut)}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-4">
                            <Form.Label>Date de fin</Form.Label>
                            <Form.Control name="date_fin" value={form?.date_fin && formattedDateInput(new Date(form?.date_fin))} isInvalid={getFormErrorMsg(invalidData?.date_fin)} onChange={handleChange} placeholder="Date de fin" type="date" />
                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.date_fin)}</Form.Control.Feedback>
                        </Form.Group>

                        <Accordion defaultActiveKey="">
                            <Accordion.Item>
                                <Accordion.Header>Conditions tarifaires par défaut (optionnel)</Accordion.Header>
                                <Accordion.Body>
                                    <p className="text-gray-600">Cette information est une valeur de référence qui pourra être remplacée lors de l'application de ce tarif aux articles ou à des familles d'articles. </p>

                                    <Form.Group className="d-flex">

                                        <Form.Check
                                            inline
                                            onChange={handleChangeType}
                                            label="Taux remise"
                                            name="groupeType"
                                            value="taux_remise"
                                            checked={currentType == "taux_remise"}
                                            type="radio"
                                            id={`inline-1`}
                                        />
                                        <Form.Check
                                            inline
                                            onChange={handleChangeType}
                                            label="Prix vente HT"
                                            name="groupeType"
                                            value="prix_vente_ht"
                                            checked={currentType == "prix_vente_ht"}
                                            type="radio"
                                            id={`inline-2`}
                                        />
                                        <Form.Check
                                            inline
                                            onChange={handleChangeType}
                                            label="Prix vente TTC"
                                            name="groupeType"
                                            value="prix_vente_ttc"
                                            checked={currentType == "prix_vente_ttc"}
                                            type="radio"
                                            id={`inline-3`}
                                        />
                                        <Form.Check
                                            inline
                                            onChange={handleChangeType}
                                            label="Coef. Prix de revient"
                                            name="groupeType"
                                            value="coefficient_pr"
                                            checked={currentType == "coefficient_pr"}
                                            type="radio"
                                            id={`inline-4`}
                                        />
                                    </Form.Group>
                                    <div className="d-flex mt-2">

                                        <InputGroup className="w-100">

                                            <Form.Control disabled={!currentType} defaultValue={currentValue} onChange={handleChangeValue} isInvalid={(invalidData && currentType) && getFormErrorMsg(invalidData[currentType])} type="number" />

                                            {(currentType == "taux_remise") &&
                                                <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                                            }

                                            {(currentType == "prix_vente_ht") &&
                                                <InputGroup.Text id="basic-addon2">F. HT</InputGroup.Text>
                                            }

                                            {(currentType == "prix_vente_ttc") &&
                                                <InputGroup.Text id="basic-addon2">F. TTC</InputGroup.Text>
                                            }

                                            {(currentType == "coefficient_pr") &&
                                                <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                                            }

                                            <Form.Control.Feedback type="invalid">{(invalidData && currentType) && getFormErrorMsg(invalidData[currentType])}</Form.Control.Feedback>

                                        </InputGroup>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>



                    </Form>
                </div>
            }
        </>

    )

}

export default TarifEdition;