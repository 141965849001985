// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#ArticleSearch {
  overflow: visible;
}
#ArticleSearch .drop-container-top {
  bottom: 100%;
  margin-bottom: 10px;
  width: max-content;
  max-width: 1000px;
  min-width: 500px;
}
#ArticleSearch .drop-container-bottom {
  top: 100%;
  margin-top: 10px;
  width: max-content;
  max-width: 1000px;
  min-width: 500px;
}
#ArticleSearch tr.selected {
  box-shadow: 0px 0px 0px 2px #01a3ff;
}
#ArticleSearch tr td {
  white-space: nowrap;
  max-width: none !important;
}
#ArticleSearch .search-prefix {
  position: absolute;
  z-index: 15;
  top: 5px;
  bottom: 5px;
  left: 5px;
  width: 26px;
  font-family: "Plus Jakarta Sans", sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/pages/brouillon/details-table/article-search/ArticleSearch.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;AACJ;AACI;EACI,YAAA;EACA,mBAAA;EACA,kBAAA;EACA,iBAAA;EACA,gBAAA;AACR;AAEI;EACI,SAAA;EACA,gBAAA;EACA,kBAAA;EACA,iBAAA;EACA,gBAAA;AAAR;AAIQ;EACI,mCAAA;AAFZ;AAKQ;EACI,mBAAA;EACA,0BAAA;AAHZ;AAOI;EACI,kBAAA;EACA,WAAA;EACA,QAAA;EACA,WAAA;EACA,SAAA;EACA,WAAA;EACA,4CAAA;AALR","sourcesContent":["#ArticleSearch {\n    overflow: visible;\n\n    .drop-container-top {\n        bottom: 100%;\n        margin-bottom: 10px;\n        width: max-content;\n        max-width: 1000px;\n        min-width: 500px;\n    }\n\n    .drop-container-bottom {\n        top: 100%;\n        margin-top: 10px;\n        width: max-content;\n        max-width: 1000px;\n        min-width: 500px;\n    }\n\n    tr {\n        &.selected {\n            box-shadow: 0px 0px 0px 2px #01a3ff;\n        }\n\n        td {\n            white-space: nowrap;\n            max-width: none !important;\n        }\n    }\n\n    .search-prefix {\n        position: absolute;\n        z-index: 15;\n        top: 5px;\n        bottom: 5px;\n        left: 5px;\n        width: 26px;\n        font-family: 'Plus Jakarta Sans', sans-serif;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
