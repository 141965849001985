import React, { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { Table, Badge, FormControl, Container, Button, Dropdown, DropdownButton, Form, Col, Row, Alert, OverlayTrigger, Tooltip, Accordion, Modal } from 'react-bootstrap';
import { getFormErrorMsg, getMonthArrayFromDecimal, currencyFormat, getInvalidObject } from '../../../helper/Helper'
import { BrouillonService } from '../../../services/BrouillonService';
import { ReglementService } from '../../../services/ReglementService';
import ErrorManager from "../../../components/error-manager/ErrorManager";
import { GlobalContext } from "../../../services/GlobalProvider";
import './Scheduled.scss';
import ConfirmDialog from '../../../components/confirm-dialog/ConfirmDialog';


const Scheduled = (props) => {

    const { preferences, folderDetails } = React.useContext(GlobalContext);
    const [invalidData, setInvalidData] = useState()
    const [valuesArray, setValuesArray] = useState([])
    const [scheduledHasChanged, setScheduledHasChanged] = useState(false)

    const formEdit = useRef();

    const scheduledMonthMap = [
        {
            libelle: 'Janv.',
            value: '1'
        },
        {
            libelle: 'Févr.',
            value: '2'
        },
        {
            libelle: 'Mars',
            value: '4'
        },
        {
            libelle: 'Avr.',
            value: '8'
        },
        {
            libelle: 'Mai',
            value: '16'
        },
        {
            libelle: 'Juin',
            value: '32'
        },
        {
            libelle: 'Juill.',
            value: '64'
        },
        {
            libelle: 'Août',
            value: '128'
        },
        {
            libelle: 'Sept.',
            value: '256'
        },
        {
            libelle: 'Oct.',
            value: '512'
        },
        {
            libelle: 'Nov.',
            value: '1024'
        },
        {
            libelle: 'Déc.',
            value: '2048'
        }
    ]

    useEffect(() => {
        scheduledHasChanged ? props.setIsComponentEditing(true) : props.setIsComponentEditing(false)
    }, [scheduledHasChanged]);

    useEffect(() => {
        if (props?.brouillon?.scheduled) {
            setValuesArray(convert(props?.brouillon?.scheduled))
        }
    }, [props?.brouillon?.scheduled]);

    function convert(number) {
        let binary = number.toString(2);
        let result = [];
        for (let i = 0; i < binary.length; i++) {
            if (binary[i] === '1') {
                let decimalValue = Math.pow(2, binary.length - 1 - i);
                result.push(decimalValue.toString());
            }
        }
        return result;
    }

    function toogleValue(value) {
        setScheduledHasChanged(true)
        let valuesArrayCopy = [...valuesArray]
        const index = valuesArrayCopy.indexOf(value);
        index > -1 ? valuesArrayCopy.splice(index, 1) : valuesArrayCopy.push(value)
        setValuesArray(valuesArrayCopy)

    }

    function selectAll() {
        setScheduledHasChanged(true)
        setValuesArray(convert(4095))
    }

    function deselectAll() {
        setScheduledHasChanged(true)
        setValuesArray(convert(0))
    }

    function save() {
        props.changeValue({
            target: {
                name: "scheduled",
                value: valuesArray.reduce((acc, curr) => acc + parseInt(curr), 0)
            }
        })
        setScheduledHasChanged(false)
    }

    function cancel() {
        setValuesArray(convert(props?.brouillon?.scheduled))
        setScheduledHasChanged(false)
    }


    return (
        <div id="Scheduled">
            <div className="d-flex mb-4">
                <h6 className="d-flex align-items-center fw-normal m-0"><i className="me-2 fs-4 material-icons">calendar_month</i>Planification</h6>

                {scheduledHasChanged &&
                    <>
                        <Button size="sm" variant="outline-light" className="ms-auto" onClick={() => cancel()}>Annuler</Button>
                        <Button size="sm" variant="light" className="ms-2" onClick={() => save()}>Enregistrer</Button>
                    </>
                }

            </div>
            <div className={"mb-3 d-flex flex-wrap " + ((props.showChanges && props.modifiedValues?.scheduled) ? "modified-border" : "")}>
                {scheduledMonthMap.map((month, index) => (
                    <span key={index} className="w-25 d-flex ">
                        <span onClick={() => toogleValue(month.value)} className={"small d-flex w-100 px-2 py-2 border border-2 cursor-pointer m-1 " + ((valuesArray.indexOf(month.value) > -1) ? "border-white bg-white text-dark " : "border-primary-400 text-primary-100 hover-bg-primary-400 ")}>
                            {month.libelle}
                            {valuesArray.indexOf(month.value) > -1 &&
                                <i className="material-icons ms-2 text-gray-600">
                                    check
                                </i>
                            }
                        </span>
                    </span>
                ))}
            </div>
            <div className="d-flex justify-content-center">
                {valuesArray.length < 12 ?
                    <Button size="sm" variant="outline-light" onClick={() => selectAll()}>Tout cocher</Button>
                    :
                    <Button size="sm" variant="outline-light" onClick={() => deselectAll()}>Tout décocher</Button>
                }
            </div>
        </div>
    );
}

export default Scheduled;
