import React, { useState, useEffect } from 'react';
import { UserService } from '../../services/UserService';
import { FolderService } from '../../services/FolderService';
import DynamicTable from '../../components/table/dynamic-table/DynamicTable';
import ColManager from '../../components/table/dynamic-table/col-manager/ColManager';
import { GlobalContext } from "../../services/GlobalProvider";
import { useNavigate } from "react-router-dom";
import { Navbar, Container, Nav, NavDropdown, Dropdown, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import './Dossiers.scss';



const Dossiers = () => {


  const { setFolderDetails, folders } = React.useContext(GlobalContext);

  const [dossiers, setDossiers] = useState(null)

  const navigate = useNavigate();

  useEffect(() => {
    if(folders && folders.length == 1){
      setFolderDetails(folders[0])
      navigate(`/${folders[0]._id}/accueil`)
    }else{
      setDossiers(folders)
    }
  }, [folders]);

  return (
    <Container fluid id="Dossiers" className="py-4">
      <div className="row mt-5">
        {(dossiers?.length > 0) &&
          <>
            <div className="col-md-3 offset-md-1 text-end">
              <h6 className="mb-2 text-primary">Choisir un dossier</h6>
              <h3 className="fw-bolder">Sur quel dossier<br></br>souhaitez vous travailler ?</h3>
            </div>

            <div className="col-4">
              <div className="ms-5">
                {dossiers.map((dossier, i) => (
                  <a href={`/${dossier._id}/accueil`} className="card w-100 d-flex flex-row align-items-center col-2 mb-2 py-3 px-4 bg-white text-dark hover-bg-primary-600 rounded cursor-pointer" key={dossier._id} /* onClick={() => navigate(`/${dossier._id}/accueil`)} */>
                    <i className="material-icons-outlined me-2 ">folder</i>
                    <h6 className="m-0">{dossier.nom}</h6>
                  </a>
                ))
                }
              </div>
            </div>
          </>
        }
        {!dossiers &&
          <em>- Aucun dossier trouvé -</em>
        }
      </div>
    </Container>
  );
}

export default Dossiers;
