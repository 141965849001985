import React, { useEffect, useState } from 'react';
import { AbonnementService } from '../../../../../services/AbonnementService';
import { Form, Spinner, Card, Tabs, Tab, Row, Col, Accordion, Button, OverlayTrigger, Alert, Tooltip, Dropdown, Badge } from 'react-bootstrap';
import ErrorManager from "../../../../error-manager/ErrorManager";
import { getFormErrorMsg, getInvalidObject, getChangedValues, formattedDateInput } from '../../../../../helper/Helper'

const Generated = (props) => {

    const [currentGenerate, setCurrentGenerate] = useState()
    const [invalidData, setInvalidData] = useState()
    const [globalError, setGlobalError] = useState()
    const [isLoading, setIsLoading] = useState(false)
    


    function postGenerate() {
        setIsLoading(true)
        AbonnementService.postGenerate(props.folderId, currentGenerate).then(res => {
            setIsLoading(false)
            setCurrentGenerate()
            setGlobalError()
            props.onGeneratedChange()
        }).catch(error => {
            setIsLoading(false)
            setGlobalError(error)
            setInvalidData(getInvalidObject(error.data?.data))
        });
    }

    function editNewGenerated() {
        setCurrentGenerate({
            mois_abonnement:'',
            date_execution:'',
            abonnements:[props.abonnement._id]
        })
    }

    function handleChange(e) {
        let value
        e.target.type == "checkbox" ? value = e.target.checked : value = e.target.value
        setCurrentGenerate({ ...currentGenerate, [e.target.name]: value })
        setInvalidData({ ...invalidData, [e.target.name]: null })
    }

    return (
        <>

            {props.isAbonnementLoading &&
                <span className="hloader mt-3"></span>
            }

            {!props.isAbonnementLoading &&
                <>
                    {/* <div className="d-flex align-items-center">
                        {(props.abonnement?.generated?.length == 0 && !currentGenerate) &&
                            <em>- Aucun élément trouvé -</em>
                        }
                        <Button disabled={currentGenerate} className="ms-auto" onClick={() => editNewGenerated()}>Générer nouveau</Button>
                    </div> */}

                    {globalError && (
                        <ErrorManager error={globalError} />
                    )}

                    {/* {(currentGenerate && !currentGenerate._id) &&
                        <div className="p-4 border rounded mt-2 mb-4">

                            <Form.Group className="d-flex align-items-center">
                                <Form.Label column sm={4}>Mois d'abonnement</Form.Label>
                                <Col sm={8}>
                                    <Form.Control size="sm" placeholder="2024-10" name="mois_abonnement" value={currentGenerate.mois_abonnement} isInvalid={getFormErrorMsg(invalidData?.mois_abonnement)} onChange={handleChange} type="month" />
                                    <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.mois_abonnement)}</Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group className="d-flex align-items-center">
                                <Form.Label column sm={4}>Date d'exécution</Form.Label>
                                <Col sm={8}>
                                    <Form.Control size="sm" name="date_execution" value={currentGenerate.date_execution} isInvalid={getFormErrorMsg(invalidData?.date_execution)} onChange={handleChange} type="date" />
                                    <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.date_execution)}</Form.Control.Feedback>
                                </Col>
                            </Form.Group>

                            <Row>
                                <Col className="d-flex justify-content-end mt-2">
                                    <Button disabled={isLoading} variant="secondary" className="me-2" onClick={() => setCurrentGenerate()}>Annuler</Button>
                                    <Button disabled={!currentGenerate.date_execution || !currentGenerate.mois_abonnement || isLoading} onClick={postGenerate}>Enregistrer la génération</Button>
                                </Col>
                            </Row>
                        </div>
                    } */}

                    {props.abonnement?.generated?.length > 0 && props.abonnement.generated.map(facture => (
                        <div key={facture.facture_id} className="mt-2 bg-gray-100 rounded py-2 px-3 d-flex">
                            <span className="fw-bold me-1">Date :</span><span>{new Date(facture.date).toLocaleDateString()}</span>
                            <span className="fw-bold ms-3 me-1">Mois abonnement :</span><span>{facture.mois_abonnement}</span>
                            <a className="ms-auto d-block" href={`/${props.folderId}/factures/?factureId=${facture.facture_id}`}>Voir la facture</a>
                        </div>
                    ))}

                    {props.abonnement?.generated?.length == 0 && 
                        <em>- Aucun élément trouvé -</em>
                    }

                </>
            }


        </>
    );

}
export default Generated;