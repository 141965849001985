import { axiosAPI, axiosOAuth, axiosOAuthNoTokenRequired, axiosLoginXl } from "./axiosClient"
import qs from "qs"
import React, { createContext } from 'react';
import axios from 'axios';
import { getParams } from '../helper/Helper';

export const UserService = {

  getToken(dataPost) {
    return axiosOAuthNoTokenRequired.post(`/oauth2/token`, new URLSearchParams(dataPost))
  },

  revoke(dataPost) {
    return axiosOAuth.post(`/oauth2/revoke`, new URLSearchParams(dataPost))
  },

  getUserInfos() {
    return axiosOAuth.get(`/userinfo`)
  },

  updatePassword(dataPost) {
    return axiosLoginXl.put(`/user/motdepasse`, qs.stringify(dataPost))
  },

  rapport(dataPost) {
    return axiosAPI.post(`/user/rapport`, qs.stringify(dataPost))
  },

  getUserGrants(folderId) {
    return axiosAPI.get(`${folderId}/user/grants`)
  },

  redirectToExternalLogin() {
    let randomNumber = Math.floor(Math.random() * (9000000000 - 1000000000 + 1) + 10)
    //localStorage.setItem('randomNumber', randomNumber)
    window.location.href = global.config._OAUTH_PUBLIC_URL_ + "/oauth2/auth?client_id=" + global.config._OAUTH_CLIENT_ID_ + "&response_type=code&state=" + randomNumber +"&scope=openid+offline_access+stockweb+loginxl"
  },

}
