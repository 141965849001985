import React, { useEffect, useState } from "react";
import { Container, Button, Dropdown, DropdownButton, Form, Col, Row, Accordion, Label, Modal, Spinner, Tooltip, OverlayTrigger, Alert } from 'react-bootstrap';
import { FamilyService } from '../../../services/FamilyService';
import { GlobalContext } from "../../../services/GlobalProvider";
import './ModalEditFamily.scss';
import { useParams } from "react-router-dom";
import ErrorManager from "../../error-manager/ErrorManager";


const ModalEditFamily = (props) => {

    const [show, setShow] = useState(false);
    const [codeValue, setCodeValue] = useState(null);
    const [designationValue, setDesignationValue] = useState(null);
    const [natureAnalytiqueValue, setNatureAnalytiqueValue] = useState(null);
    const [parentValue, setParentValue] = useState(null);
    const [addLoading, setAddLoading] = useState(false);
    const [treeFamilyList, setTreeFamilyList] = useState(null)
    const [closedFamilies, setClosedFamilies] = useState([])
    const [error, setError] = useState(null);

    const { folderDetails, parametres } = React.useContext(GlobalContext);

    useEffect(() => {
        if (props.object && folderDetails) {
            setShow(props.object.show)
            setCodeValue(props.object.family?.code)
            setDesignationValue(props.object.family?.designation)
            setNatureAnalytiqueValue(props.object.family?.nature_analytique)
            setParentValue(props.object.family?.parent)
            getTreeFamilies()
            setError(null)
        }
    }, [props.object, folderDetails]);

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    function handleKeyDown(e) {
        if (show) {
            const { code } = e;
            switch (code) {
                case "Escape":
                    props.setClose({ reload: false })
                    break;
                default:
                    break;
            }
        }
    }

    function getTreeFamilies() {
        setError(null)
        FamilyService.getTreeFamilies(folderDetails._id).then(res => {
            setTreeFamilyList(res.data.data.familles)
        }).catch(err => {
            setError(err)
        });
    }

    function editFamily() {
        const dataPost = {
            familles: [
                {
                    "code": codeValue,
                    "designation": designationValue,
                    "nature_analytique": natureAnalytiqueValue,
                    "_id": props.object.family?._id,
                    "parent": parentValue
                }
            ]
        }

        setAddLoading(true)
        setError(null)
        return FamilyService.updateFamily(folderDetails._id, dataPost).then(res => {
            props.setClose({ reload: true })
            setAddLoading(false)
        }).catch(err => {
            setError(err)
            setAddLoading(false)
        });

    }

    function openClose(familyId) {
        let closedFamiliesCopy = [...closedFamilies];
        const index = closedFamiliesCopy.indexOf(familyId);
        index > -1 ? closedFamiliesCopy.splice(index, 1) : closedFamiliesCopy.push(familyId)
        setClosedFamilies(closedFamiliesCopy)
    }

    function changeParent(family) {

        if (family && family._id != props.object.family?._id) {
            setParentValue(family._id)
        } else {
            setParentValue(null)
        }

    }

    const TreeNode = (TreeNodeProps) => {

        return (
            <>
                {TreeNodeProps.childList?.map(family => (

                    <span className="family-tree" key={family._id}>

                        <OverlayTrigger
                            placement="left"
                            delay={{ show: 800 }}
                            overlay={
                                <Tooltip>
                                    Déplacer ici
                                </Tooltip>
                            }
                        >
                            <span className={"family cursor-pointer hover-bg-gray-200 " + (family._id == parentValue ? 'text-primary bg-primary-200' : '') + ((family._id == props.object.family?._id || TreeNodeProps.disabled) ? 'text-secondary disabled' : '')} >
                                {family.children.length > 0 &&
                                    <i className={"caret cursor-pointer material-icons text-muted " + (closedFamilies.indexOf(family._id) > -1 ? 'caret-close' : '')} onClick={() => openClose(family._id)} >arrow_forward_ios</i>
                                }
                                <span onClick={() => changeParent(family)}>
                                    <span className="me-2 fw-bold">{family.code}</span>
                                    {family.designation}
                                </span>

                            </span>
                        </OverlayTrigger>

                        <span className={"subfamilies " + (closedFamilies.indexOf(family._id) > -1 ? 'close' : '')}>
                            <TreeNode childList={family.children} disabled={family._id == props.object.family?._id || TreeNodeProps.disabled} />
                        </span>

                    </span>
                ))}
            </>
        );
    };

    return (
        <>
            <Modal show={show} centered>

                <Modal.Header className="border-0 pb-0">
                    <h5>Modifier</h5>
                </Modal.Header>

                <Modal.Body className="">
                    <Form>
                        <Row>
                            <Form.Group as={Col} xs="4">
                                <Form.Label>Code</Form.Label>
                                <Form.Control value={codeValue ?? ""} type="text" placeholder="Code de la famille" onChange={e => setCodeValue(e.target.value)} />
                            </Form.Group>

                            <Form.Group as={Col} xs="8">
                                <Form.Label>Désignation</Form.Label>
                                <Form.Control autoFocus value={designationValue ?? ""} type="text" placeholder="Désignation de la famille" onChange={e => setDesignationValue(e.target.value)} />
                            </Form.Group>
                        </Row>
                        {parametres?.general?.export_comptable?.comptabilite_analytique &&
                            <Row className="mt-3">
                                <Form.Group as={Col} xs="12">
                                    <Form.Label>Nature Analytique</Form.Label>
                                    <Form.Control value={natureAnalytiqueValue ?? ""} type="text" placeholder="Nature Analytique" onChange={e => setNatureAnalytiqueValue(e.target.value)} />
                                </Form.Group>
                            </Row>
                        }
                        <Row className="mt-3">
                            <Form.Group as={Col} xs="12" >
                                <Form.Label>Famille parent</Form.Label>

                                <span id="ModalEditParentFamilyContainer" className="light-v-scroll d-block">

                                    <span className="family-tree ms-0 p-4 border rounded">
                                        <OverlayTrigger
                                            placement="left"
                                            delay={{ show: 800 }}
                                            overlay={
                                                <Tooltip>
                                                    Déplacer ici
                                                </Tooltip>
                                            }
                                        >
                                            <span className={"family cursor-pointer hover-bg-gray-200 " + (!parentValue ? 'text-primary bg-primary-200' : '')} >
                                                <span className="fw-bold" onClick={() => changeParent(null)}>Famille - racine -</span>
                                            </span>
                                        </OverlayTrigger>
                                        <TreeNode childList={treeFamilyList} />
                                    </span>
                                </span>
                            </Form.Group>
                        </Row>
                        <Row className="mt-3">
                            <Col xs="12">
                                <ErrorManager error={error} />
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>

                <Modal.Footer className="border-0">
                    <Button variant="secondary" onClick={() => props.setClose({ reload: false })}>
                        Annuler
                    </Button>
                    <Button disabled={!codeValue || !designationValue} variant="primary" onClick={() => editFamily()}>
                        {addLoading &&
                            <Spinner animation="border" size="sm" className="me-2" />
                        }
                        Enregistrer
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default ModalEditFamily;
