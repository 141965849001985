// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#ModalEditParentFamilyContainer {
  max-height: calc(100vh - 400px);
  overflow: auto;
}
#ModalEditParentFamilyContainer .family-tree {
  margin-left: 16px;
  display: flex;
  flex-direction: column;
}
#ModalEditParentFamilyContainer .family-tree .family {
  display: flex;
  padding: 1px 0px 0px 6px;
  align-items: center;
  border-radius: 20px;
  position: relative;
}
#ModalEditParentFamilyContainer .family-tree .family.disabled {
  cursor: not-allowed;
  pointer-events: none;
}
#ModalEditParentFamilyContainer .family-tree .family > span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 0.9rem;
  width: 100%;
}
#ModalEditParentFamilyContainer .family-tree .family .caret {
  width: 12px;
  margin-left: -22px;
  position: absolute;
  transform: rotate(90deg);
  font-size: 12px;
}
#ModalEditParentFamilyContainer .family-tree .family .caret.caret-close {
  transform: rotate(0deg);
}
#ModalEditParentFamilyContainer .family-tree .subfamilies {
  display: block;
}
#ModalEditParentFamilyContainer .family-tree .subfamilies.close {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/families/modal-edit-family/ModalEditFamily.scss"],"names":[],"mappings":"AAAA;EACI,+BAAA;EACA,cAAA;AACJ;AAEI;EACI,iBAHK;EAIL,aAAA;EACA,sBAAA;AAAR;AACQ;EACI,aAAA;EACA,wBAAA;EACA,mBAAA;EACA,mBAAA;EACA,kBAAA;AACZ;AACY;EACI,mBAAA;EACA,oBAAA;AAChB;AACY;EACI,uBAAA;EACA,mBAAA;EACA,gBAAA;EACA,iBAAA;EACA,WAAA;AAChB;AACY;EACI,WAAA;EACA,kBAAA;EACA,kBAAA;EACA,wBAAA;EACA,eAAA;AAChB;AACgB;EACI,uBAAA;AACpB;AAIQ;EACI,cAAA;AAFZ;AAGY;EACI,aAAA;AADhB","sourcesContent":["#ModalEditParentFamilyContainer{\n    max-height: calc(100vh - 400px);\n    overflow: auto;\n    $spacer: 16px;\n\n    .family-tree{\n        margin-left: $spacer;\n        display: flex;\n        flex-direction: column;\n        .family{\n            display: flex;\n            padding: 1px 0px 0px 6px;\n            align-items: center;\n            border-radius: 20px;\n            position: relative;\n\n            &.disabled{\n                cursor: not-allowed;\n                pointer-events: none;\n            }\n            &>span{\n                text-overflow: ellipsis;\n                white-space: nowrap;\n                overflow: hidden;\n                font-size: 0.9rem;\n                width: 100%;\n            }\n            .caret{\n                width: 12px;\n                margin-left: -22px;\n                position: absolute;\n                transform: rotate(90deg);\n                font-size: 12px;\n        \n                &.caret-close{\n                    transform: rotate(0deg);\n                }\n            }\n            \n        }\n        .subfamilies{\n            display: block;\n            &.close{\n                display: none;\n            }\n        }\n    }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
