import React, { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { Table, Badge, FormControl, Container, Button, Dropdown, DropdownButton, Form, Col, Row, Alert, OverlayTrigger, Tooltip, Accordion, Modal } from 'react-bootstrap';
import { getFormErrorMsg, formattedDateInput, currencyFormat, getInvalidObject } from '../../../helper/Helper'
import { BrouillonService } from '../../../services/BrouillonService';
import { ReglementService } from '../../../services/ReglementService';
import ErrorManager from "../../../components/error-manager/ErrorManager";
import { GlobalContext } from "../../../services/GlobalProvider";
import './Reglements.scss';
import ConfirmDialog from '../../../components/confirm-dialog/ConfirmDialog';


const Reglements = (props) => {

    const { preferences, folderDetails } = React.useContext(GlobalContext);

    const [globalError, setGlobalError] = useState()
    const [reglementsModes, setReglementsModes] = useState()
    const [currentMode, setCurrentMode] = useState()
    const [showModalReglements, setShowModalReglements] = useState(false)
    const [invalidData, setInvalidData] = useState()

    const formEdit = useRef();

    const [confirmDialog, setConfirmDialog] = useState({ show: false, dialogInfos: null })

    useEffect(() => {
        getReglementsModes()
    }, []);

    useEffect(() => {
        setInvalidData()
        setGlobalError()
        formEdit.current = {
            "date_valeur": formattedDateInput(new Date()),
            "facture_id": `${props.brouillon._id}`,
            "client_id": `${props.brouillon.client_id}`,
            "mode_paiement_id": "",
            "organisme": "",
            "numero_piece": "",
            "observation": "",
            "montant": `${props.brouillon.reglements?.reste_a_payer}`
        }
    }, [showModalReglements]);

    useEffect(() => {
        formEdit.current["montant"] = props.brouillon.reglements?.reste_a_payer
    }, [props.brouillon.reglements?.reste_a_payer]);

    useEffect(() => {
        formEdit.current["client_id"] = props.brouillon.client_id
    }, [props.brouillon.client_id]);
    

    useEffect(() => {

        function handleKeyDown(e) {
            const { code } = e;
            switch (code) {
                case "KeyR":
                    if (e.ctrlKey) {
                        setShowModalReglements(true)
                        e.preventDefault()
                        return;
                    }
                    break;
                case "Escape":
                    closeModal()
                    break;
                default:
                    break;
            }
        }

        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener('keyup', handleKeyDown);
        };
    }, []);

    function handleChange(e) {
        setInvalidData()
        formEdit.current[e.target.name] = e.target.value
        if (e.target.name == "mode_paiement_id") {
            let mode = reglementsModes.find(mode => mode._id === e.target.value)
            setCurrentMode(mode)
            if (!mode.piece_comptable) {
                formEdit.current['organisme'] = ""
                formEdit.current['numero_piece'] = ""
            }
        }
    }

    function getReglementsModes() {
        ReglementService.getReglementsModes(folderDetails._id).then(res => {
            setReglementsModes(res.data.data.modespaiements)
        }).catch(error => {
            setGlobalError(error)
        });
    }

    function addReglement() {
        BrouillonService.postReglement(folderDetails._id, props.brouillon._id, { reglements: [formEdit.current] }).then(res => {
            setShowModalReglements(false)
            props.reloadBrouillon()
        }).catch(error => {
            setGlobalError(error)
            setInvalidData(getInvalidObject(error.data.data))
        });
    }

    function removeReglement(reglement) {
        BrouillonService.removeReglement(folderDetails._id, props.brouillon._id, reglement._id).then(res => {
            props.reloadBrouillon()
            setConfirmDialog({ show: false });
        }).catch(error => {
            setConfirmDialog({
                ...confirmDialog,
                dialogInfos: { ...confirmDialog.dialogInfos, error: error }
            })
        });
    }

    function removeReglementConfirm(r) {
        setConfirmDialog({
            show: true,
            dialogInfos: {
                title: 'Supprimer',
                description: `Voulez vous supprimer le règlement de ${r.client_nom} ?`,
                actionName: 'Supprimer',
                btnVariant: 'danger',
                element: r,
                error: false,
                confirmAction: 'removeReglement'
            }
        })
    }
    function closeModal() {
        setShowModalReglements(false)
    }

    return (
        <>
            <ConfirmDialog
                show={confirmDialog.show}
                dialogInfos={confirmDialog.dialogInfos}
                setCancel={() => setConfirmDialog({ show: false })}
                setConfirm={(a, e) => eval(a)(e)}
            />

            {/* {globalError && (
                <Col xs="12" className="mt-2">
                    <ErrorManager error={globalError} />
                </Col>
            )} */}


            {props.brouillon.type == "avoir" ?
                <h6 className="d-inline-flex align-items-center fw-normal mb-3"><i className="me-2 fs-4 material-icons">credit_card</i>Remboursements</h6>
                :
                <h6 className="d-inline-flex align-items-center fw-normal mb-3"><i className="me-2 fs-4 material-icons">credit_card</i>Règlements</h6>
            }

            {props.brouillon.client?.type == "interne" &&
                <em className="d-flex">
                    - Indisponible pour les clients de type "Interne" -
                </em>
            }

            {/* {props.brouillon.type == "proforma" &&
                <em className="d-flex">
                    - Indisponible pour les brouillons de type "Proforma" -
                </em>
            } */}

            {props.brouillon.client?.type != "interne" &&
                <>

                    <Button /* disabled={props?.brouillon?.reglements?.reste_a_payer == 0} */ variant="light" className="float-end" onClick={() => setShowModalReglements(true)}>Ajouter</Button>

                    {props.brouillon.type != "avoir" &&
                        <h6>Reste à règler : {currencyFormat(props?.brouillon?.reglements?.reste_a_payer)} F</h6>
                    }

                    {props.brouillon?.reglements?.reglements?.length == 0 &&
                        <>
                            {props.brouillon.type == "avoir" ?
                                <em className="d-flex">- Aucun remboursement saisi -</em>
                                :
                                <em className="d-flex">- Aucun règlement saisi -</em>
                            }
                        </>

                    }

                    {props.brouillon?.reglements?.reglements?.length > 0 &&
                        <div className="mt-3">
                            {props.brouillon?.reglements?.reglements?.map((r, i) => (
                                <div key={r._id} className={"d-flex align-items-center mb-2 " + ((props.showChanges && props.modifiedValues?.reglements?.[r._id]) ? "modified-bg" : "")}>

                                    <div className="me-auto w-100">
                                        <div className="float-start">
                                            <span className="d-block">
                                                {r.client_nom}

                                                {r.observation &&
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip>
                                                                {r.observation}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <i className="material-icons ms-1 float-end cursor-default">textsms</i>
                                                    </OverlayTrigger>
                                                }
                                            </span>
                                            <span className="d-block fw-light">{r.mode_paiement_libelle}</span>
                                        </div>
                                        <div className="float-end me-3">
                                            <span className="d-block">{currencyFormat(r.montant)} F</span>
                                            <span className="d-block fw-light">Le {new Date(r.date_valeur).toLocaleString().split(' ')[0]}</span>
                                        </div>
                                    </div>

                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 800 }}
                                        overlay={
                                            <Tooltip>
                                                Supprimer
                                            </Tooltip>
                                        }
                                    >
                                        <Button onClick={() => removeReglementConfirm(r)} variant="outline-light" className="ms-2">
                                            <i className="material-icons">delete</i>
                                        </Button>
                                    </OverlayTrigger>
                                </div>
                            ))}

                        </div>
                    }

                </>
            }

            <Modal show={showModalReglements} centered>

                <Modal.Header className="border-0 pb-0">
                    <h5>Ajouter un règlement</h5>
                </Modal.Header>

                <Modal.Body >

                    {globalError && (
                        <Row>
                            <Col xs="12" className="mt-2">
                                <ErrorManager error={globalError} />
                            </Col>
                        </Row>
                    )}

                    {/* Formulaire paiement */}
                    {formEdit.current &&
                        <Row>
                            <Col xs="12" className="mb-2">
                                <Form.Group>
                                    <Form.Label>Date valeur (date d'exécution)</Form.Label>
                                    <Form.Control isInvalid={getFormErrorMsg(invalidData?.date_valeur)} name="date_valeur" defaultValue={formattedDateInput(formEdit.current?.date_valeur)} onChange={handleChange} type="date" />
                                    <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.date_valeur)}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs="12" className="mb-2">
                                <Form.Group>
                                    <Form.Label>Mode de paiement<span className="text-danger">*</span></Form.Label>
                                    <Form.Select isInvalid={getFormErrorMsg(invalidData?.mode_paiement_id)} autoFocus name="mode_paiement_id" defaultValue={formEdit.current.mode_paiement_id} onChange={handleChange}>
                                        <option value="" disabled>- Choisir -</option>
                                        {reglementsModes?.map(mode =>
                                            <option key={mode._id} value={mode._id}>{mode.libelle}</option>
                                        )}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.mode_paiement_id)}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            {currentMode?.piece_comptable &&
                                <>
                                    <Col xs="12" className="mb-2">
                                        <Form.Group>
                                            <Form.Label>Organisme bancaire</Form.Label>
                                            <Form.Control isInvalid={getFormErrorMsg(invalidData?.organisme)} name="organisme" defaultValue={formEdit.current?.organisme} onChange={handleChange} type="text" />
                                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.organisme)}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col xs="12" className="mb-2">
                                        <Form.Group>
                                            <Form.Label>Numéro de pièce</Form.Label>
                                            <Form.Control isInvalid={getFormErrorMsg(invalidData?.numero_piece)} name="numero_piece" defaultValue={formEdit.current?.numero_piece} onChange={handleChange} type="text" />
                                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.organisme)}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </>
                            }

                            <Col xs="12" className="mb-2">
                                <Form.Group>
                                    <Form.Label>Montant<span className="text-danger">*</span></Form.Label>
                                    <Form.Control isInvalid={getFormErrorMsg(invalidData?.montant)} name="montant" defaultValue={formEdit.current?.montant} onChange={handleChange} type="number" />
                                    <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.montant)}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs="12" className="mb-2">
                                <Form.Group>
                                    <Form.Label>Observations</Form.Label>
                                    <Form.Control isInvalid={getFormErrorMsg(invalidData?.observation)} autoComplete="off" name="observation" defaultValue={formEdit.current?.observation} onChange={handleChange} type="text" />
                                    <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.observation)}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                        </Row>
                    }
                </Modal.Body>

                <Modal.Footer className="border-0">
                    {!props.brouillon?.client_id &&
                        <Alert variant="warning">
                            Veuillez ajouter un client à votre brouillon pour enregistrer un règlement.
                        </Alert>
                    }
                    <Button variant="secondary" className="float-end" onClick={() => closeModal()}>Fermer</Button>
                    <Button disabled={!props.brouillon?.client_id} variant="primary" onClick={() => addReglement()}>Enregistrer</Button>
                </Modal.Footer>

            </Modal>

        </>
    );
}

export default Reglements;
