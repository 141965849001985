import React, { useEffect, useState } from 'react';
import { FournisseurService } from '../../../../services/FournisseurService';
import { Form, Spinner, Card, Tabs, Table, Tab, Row, Col, Accordion, Button, OverlayTrigger, Alert, Tooltip, Dropdown, Badge } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import ErrorManager from "../../../error-manager/ErrorManager";
import { getFormErrorMsg } from '../../../../helper/Helper'
//import './FournisseurDetails.scss';
import TextArrayInput from "../../../form-inputs/text-array-input/TextArrayInput";
import { AbonnementService } from '../../../../services/AbonnementService';
import { reorder, getMonthArrayFromDecimal, currencyFormat } from '../../../../helper/Helper'
import './AbonnementDetails.scss';
import { Editor, convertFromHTML, EditorState, createWithContent, convertFromRaw, ContentState, convertToRaw } from "draft-js";
import { markdownToDraft } from 'markdown-draft-js';
import { GlobalContext } from "../../../../services/GlobalProvider";
import Suspensions from "./suspensions/Suspensions";
import Generated from "./generated/Generated";


const AbonnementDetails = (props) => {

    const [abonnement, setAbonnement] = useState()
    const [getAbonnementLoading, setGetAbonnementLoading] = useState()
    let [openElement, setOpenElement] = useState("infos")

    const { globalContextLoaded, preferences, parametres, folderDetails, grants, getPreferences } = React.useContext(GlobalContext);

    useEffect(() => {
        setAbonnement()
        getAbonnement()
        setOpenElement("infos")
    }, [props.abonnementId]);

    function handleTabSelect(key) {
        setOpenElement(key)
    }

    function getAbonnement() {
        setGetAbonnementLoading(true)
        AbonnementService.getAbonnement(props.folderId, props.abonnementId).then(res => {
            setAbonnement(res.status == 200 ? res.data.data : { warning: res.statusText })
            setGetAbonnementLoading(false)
        }).catch(error => {
            setAbonnement({ error: error })
            setGetAbonnementLoading(false)
        });
    }

    function tabChange() {
        getAbonnement()
        props.onTabChange()
    }

    function Split({ title, value, border }) {
        return (
            <div className={"d-flex justify-content-between py-2 " + (border ? "border-bottom" : "")}>
                <span className="pe-4 text-gray-500">{title}</span>
                <span className="">{value}</span>
            </div>
        )
    }
    return (

        <div id="AbonnementDetails">

            {(!abonnement && getAbonnementLoading) &&
                <span className="hloader mt-3"></span>
            }

            {abonnement?.warning &&
                <div className="d-inline-block w-100 mt-4">
                    <Alert variant="warning">
                        {abonnement?.warning}
                    </Alert>
                </div>
            }

            {abonnement?.error &&
                <div className="d-inline-block w-100 mt-4">
                    <ErrorManager error={abonnement?.error} />
                </div>
            }


            {(abonnement && !abonnement.warning && !abonnement.error) && (
                <>
                    <div className="d-flex mb-4">
                        <div className="me-auto">
                            <div className="me-3">
                                <h6 className="fw-bolder m-0">{abonnement.client_nom}</h6>
                            </div>
                            <div className="me-3">
                                <Badge bg="light" text="dark" className="me-2">Type : {abonnement.type}</Badge>
                                <Badge bg="light" text="dark" className="me-2">Date : {new Date(abonnement.date).toLocaleString()}</Badge>
                            </div>
                        </div>
                        {props.elementActions.length > 0 &&
                            <Dropdown className="float-end ms-2 me-3">
                                <OverlayTrigger
                                    placement="left"
                                    delay={{ show: 800 }}
                                    overlay={
                                        <Tooltip>
                                            Plus d'actions
                                        </Tooltip>
                                    }
                                >
                                    <Dropdown.Toggle variant="secondary" className='no-btn-caret'>
                                        <i className="material-icons i-lg">more_horiz</i>
                                    </Dropdown.Toggle>
                                </OverlayTrigger>
                                <Dropdown.Menu>
                                    {props.elementActions.map((element) => (
                                        <Dropdown.Item key={element.title} onClick={() => props.setElementAction(element.action, abonnement)}>{element.title}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        }
                    </div>

                    <div className="">
                        <Tabs variant="pills" defaultActiveKey="infos" onSelect={handleTabSelect} className="mb-3">

                            <Tab eventKey="infos" title="Infos" className="light-v-scroll height-full-min-250 pe-2">
                                {openElement == "infos" &&

                                    <Accordion defaultActiveKey={['description', 'abonnement', 'details', 'observations', 'adresse-livraison', 'adresse-facturation', 'client']} alwaysOpen>


                                        <Accordion.Item eventKey="description" className="border-0 mb-3">
                                            <Accordion.Header>Description</Accordion.Header>
                                            <Accordion.Body className="px-1">
                                                {abonnement.description &&
                                                    <p>{abonnement.description}</p>
                                                }
                                                {!abonnement.description &&
                                                    <em>- Aucune description rédigée -</em>
                                                }
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="client" className="border-0 mb-3">
                                            <Accordion.Header>Client</Accordion.Header>
                                            <Accordion.Body className="px-1">
                                                {abonnement.client._id && <Split title="Client" border value={<a href={`/${props.folderId}/clients?clientId=${abonnement.client._id}`} target="_blank">Afficher le client</a>} />}
                                                <Split border title="Nom" value={abonnement.client.nom} />
                                                <Split border title="Code" value={abonnement.client.code} />
                                                <Split border title="Profession" value={abonnement.client.profession} />
                                                <Split title="Bloquer" value={abonnement.client.bloquer ? "Oui" : "Non"} />
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="abonnement" className="border-0 mb-3">
                                            <Accordion.Header>Abonnement</Accordion.Header>
                                            <Accordion.Body className="px-1">
                                                <Split border title="Code" value={abonnement.code} />
                                                <Split border title="Utilisateur" value={abonnement.user.user_login} />
                                                <Split title="Planification" value={abonnement.scheduled} />
                                                <span className="d-inline-flex w-100 fw-bold small mb-2">
                                                    {getMonthArrayFromDecimal(abonnement.scheduled).map(month => (
                                                        <span className={"d-flex flex-column align-items-center border border-white py-1 " + (month.value == true ? "bg-primary-600 text-white " : "bg-gray-200 ")} key={month.libelle} style={{ width: (100 / 12 + '%') }}>
                                                            <span>{month.libelle}</span>
                                                        </span>
                                                    ))}
                                                </span>
                                                <Split border title="Vendeur" value={abonnement.vendeur.user_login} />
                                                <Split border title="Date d'exécution" value={new Date(abonnement.date_execution).toLocaleDateString()} />
                                                {abonnement?.comptabilisee == true &&
                                                    <Split border title="Comptabilisée" value="Oui" />
                                                }
                                                {abonnement?.comptabilisee == false &&
                                                    <Split border title="Comptabilisée" value="Non" />
                                                }
                                                {parametres?.general?.export_comptable.comptabilite_analytique &&
                                                    <Split border title="Secteur analytique" value={abonnement.secteur_analytique} />
                                                }
                                                <Split border title="Total prix revient" value={currencyFormat(abonnement.total_prix_revient)} />
                                                <Split border title="Total brut hors taxes" value={currencyFormat(abonnement.total_brut_ht)} />
                                                <Split border title="Total net hors taxes" value={currencyFormat(abonnement.total_net_ht)} />
                                                <Split border title="Total remise" value={currencyFormat(abonnement.total_remise)} />
                                                <Split border title="Total TTC" value={currencyFormat(abonnement.total_ttc)} />
                                                <Split border title="Taux de marge global" value={currencyFormat(abonnement.taux_marge_global_calc)} />
                                                <Split border title="Taux de marque global" value={currencyFormat(abonnement.taux_marque_global_calc)} />
                                                <Split border title="Coefficient global" value={currencyFormat(abonnement.coefficient_global_calc)} />
                                                <Split border title="Statut" value={abonnement.statut} />
                                                <Split title="Total montant taxes" value={currencyFormat(abonnement.total_taxes?.montant_total)} />
                                                <div className="">
                                                    {abonnement.total_taxes?.montants_details.map(montant => (
                                                        <Badge key={montant.contribution_id} bg="gray-200" text="dark" className="me-2">{montant.libelle}</Badge>
                                                    ))}
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="details" className="border-0 mb-3">
                                            <Accordion.Header>Details</Accordion.Header>
                                            <Accordion.Body className="px-1 overflow-auto bg-gray-100">

                                                {(abonnement.details.length > 0) &&
                                                    <div className="table-container mt-2">
                                                        <Table borderless className="small w-100">
                                                            <thead>
                                                                <tr className='text-gray-600 text-uppercase'>
                                                                    <th>Code</th>
                                                                    <th style={{ minWidth: 220 }}>Désignation</th>
                                                                    <th>Qté</th>
                                                                    <th>Prix H.T</th>
                                                                    <th>Montant H.T</th>
                                                                    <th>Taux remise</th>
                                                                    <th>Montant remise</th>
                                                                    <th>Taux contrib.</th>
                                                                    <th>Montant contri.</th>
                                                                    <th>Px. de revient</th>
                                                                    <th>Px. de vente TTC</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="align-middle">
                                                                {abonnement.details.map(d =>
                                                                    <tr key={d._id}>
                                                                        <td>{d.code}</td>
                                                                        <td>
                                                                            {d.article_id && <a href={`/${props.folderId}/articles/?articleId=${d.article_id}`}>{d.designation}</a>}
                                                                            {!d.article_id && <span>{d.designation}</span>}
                                                                        </td>
                                                                        <td>{d.quantite}</td>
                                                                        <td>{currencyFormat(d.prix_vente_ht)}</td>
                                                                        <td>{currencyFormat(d.montant_net_ht)}</td>
                                                                        <td>{d.remise?.taux}</td>
                                                                        <td>{d.remise?.montant}</td>
                                                                        <td>
                                                                            {d.contributions?.map(c => (
                                                                                <span key={c.contribution_id} className="rounded bg-gray-300 px-1 me-1">
                                                                                    {c.valeur}
                                                                                </span>
                                                                            ))}
                                                                        </td>
                                                                        <td>
                                                                            {d.contributions?.map(c => (
                                                                                <span key={c.contribution_id} className="rounded bg-gray-300 px-1 me-1">
                                                                                    {currencyFormat(c.montant)}
                                                                                </span>
                                                                            ))}
                                                                        </td>
                                                                        <td>{currencyFormat(d.prix_revient)}</td>
                                                                        <td>{currencyFormat(d.montant_ttc)}</td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                }
                                                {(abonnement.details.length == 0) &&
                                                    <em>- Aucune ligne de détail n'a été saisie -</em>
                                                }
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="adresse-livraison" className="border-0 mb-3">
                                            <Accordion.Header>Adresse de livraison</Accordion.Header>
                                            <Accordion.Body className="px-1">
                                                <Split border title="Libelle" value={abonnement.adresse_livraison?.libelle} />
                                                <Split border title="Adresse" value={abonnement.adresse_livraison?.adresse} />
                                                <Split border title="Code_postal" value={abonnement.adresse_livraison?.code_postal} />
                                                <Split border title="Ville" value={abonnement.adresse_livraison?.ville} />
                                                <Split border title="Pays" value={abonnement.adresse_livraison?.pays} />
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="adresse-facturation" className="border-0 mb-3">
                                            <Accordion.Header>Adresse de facturation</Accordion.Header>
                                            <Accordion.Body className="px-1">
                                                <Split border title="Libelle" value={abonnement.adresse_livraison?.libelle} />
                                                <Split border title="Adresse" value={abonnement.adresse_livraison?.adresse} />
                                                <Split border title="Code_postal" value={abonnement.adresse_livraison?.code_postal} />
                                                <Split border title="Ville" value={abonnement.adresse_livraison?.ville} />
                                                <Split border title="Pays" value={abonnement.adresse_livraison?.pays} />
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="observations" className="border-0 mb-3">
                                            <Accordion.Header>Observations</Accordion.Header>
                                            <Accordion.Body className="px-1">
                                                {abonnement.observation &&
                                                    <Editor editorState={EditorState.createWithContent(convertFromRaw(markdownToDraft(abonnement.observation)))} readOnly={true} />
                                                }
                                                {!abonnement.observation &&
                                                    <em>- Aucune observation rédigée -</em>
                                                }
                                            </Accordion.Body>
                                        </Accordion.Item>

                                    </Accordion>
                                }
                            </Tab>

                            <Tab eventKey="suspensions" title="Suspensions" className="light-v-scroll height-full-min-250 pe-2">
                                {openElement == "suspensions" &&
                                    <Suspensions folderId={props.folderId} abonnement={abonnement} isAbonnementLoading={getAbonnementLoading} onSuspensionChange={() => tabChange()} />
                                }
                            </Tab>

                            <Tab eventKey="generated" title="Generated" className="light-v-scroll height-full-min-250 pe-2">
                                {openElement == "generated" &&
                                    <Generated folderId={props.folderId} abonnement={abonnement} isAbonnementLoading={getAbonnementLoading} onGeneratedChange={() => tabChange()} />
                                }
                            </Tab>

                            {/* <Tab eventKey="generated" title="Factures générées" className="light-v-scroll height-full-min-250 pe-2">
                                {openElement == "generated" &&
                                    <>
                                        {abonnement?.generated?.length > 0 && abonnement.generated.map(facture => (
                                            <a key={facture.facture_id} className="d-block" href={`/${props.folderId}/factures/?factureId=${facture.facture_id}`}>Facture du {facture.mois_abonnement}</a>
                                        ))}

                                        {abonnement?.generated?.length == 0 &&
                                            <em>- Aucun élément trouvé -</em>
                                        }
                                    </>

                                }
                            </Tab> */}

                        </Tabs>
                    </div>



                </>
            )}
        </div>
    )

}

export default AbonnementDetails;

