// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selected {
  box-shadow: 0px 0px 0px 2px #01a3ff;
}`, "",{"version":3,"sources":["webpack://./src/pages/brouillon/secteur-analytique/SecteurAnalytique.scss"],"names":[],"mappings":"AAAA;EACI,mCAAA;AACJ","sourcesContent":[".selected{\n    box-shadow: 0px 0px 0px 2px #01a3ff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
