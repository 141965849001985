// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#DynamicTable {
  overflow: auto;
  padding: 2px;
  min-height: 290px;
}
#DynamicTable .picture {
  width: 32px;
  height: 32px;
  display: block;
  background-size: cover;
}
#DynamicTable .w-checkbox {
  width: 0px;
}
#DynamicTable tr:hover .actions {
  display: block;
}
#DynamicTable tr.selected {
  box-shadow: 0px 0px 0px 2px #01a3ff;
}
#DynamicTable .actions {
  display: none;
  position: absolute;
  border: 0;
  top: 1px;
  bottom: 1px;
  right: 0;
}
#DynamicTable .actions.sideIsOpen {
  transition: right 0.4s;
  right: 700px;
}
#DynamicTable .actions.historyIsOpen {
  right: 1000px;
}
#DynamicTable .actions span {
  display: flex;
  align-items: center;
  height: 100%;
  position: absolute;
  right: 0;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 50%) !important;
  padding-left: 70px;
  box-shadow: 20px 0px 0px 0px #ffffff !important;
}
#DynamicTable .actions span i {
  font-size: 20px;
  padding: 1px 5px;
}
#DynamicTable .table-header-dropdown {
  font-weight: initial;
}
#DynamicTable .not-found {
  margin-bottom: 150px;
}`, "",{"version":3,"sources":["webpack://./src/components/table/dynamic-table/DynamicTable.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,YAAA;EACA,iBAAA;AACJ;AAAI;EACI,WAAA;EACA,YAAA;EACA,cAAA;EACA,sBAAA;AAER;AAAI;EACI,UAAA;AAER;AAEY;EACI,cAAA;AAAhB;AAGQ;EACI,mCAAA;AADZ;AAII;EACI,aAAA;EACA,kBAAA;EACA,SAAA;EACA,QAAA;EACA,WAAA;EACA,QAAA;AAFR;AAIQ;EACI,sBAAA;EACA,YAAA;AAFZ;AAIQ;EACI,aAAA;AAFZ;AAIQ;EACI,aAAA;EACA,mBAAA;EACA,YAAA;EACA,kBAAA;EACA,QAAA;EACA,qFAAA;EACA,kBAAA;EACA,+CAAA;AAFZ;AAGY;EACI,eAAA;EACA,gBAAA;AADhB;AAKI;EACI,oBAAA;AAHR;AAKI;EACI,oBAAA;AAHR","sourcesContent":["#DynamicTable{\n    overflow: auto;\n    padding: 2px;\n    min-height: 290px;\n    .picture{\n        width: 32px;\n        height: 32px;\n        display: block;\n        background-size: cover;\n    }\n    .w-checkbox{\n        width: 0px;\n    }\n    tr{\n        &:hover{\n            .actions{\n                display: block;\n            }\n        }\n        &.selected{\n            box-shadow: 0px 0px 0px 2px #01a3ff;\n        }\n    }\n    .actions{\n        display: none;\n        position: absolute;\n        border: 0;\n        top: 1px;\n        bottom: 1px;\n        right: 0;\n\n        &.sideIsOpen{\n            transition: right .4s;\n            right: 700px;\n        }\n        &.historyIsOpen{\n            right: 1000px;\n        }\n        span{\n            display: flex;\n            align-items: center;\n            height: 100%;\n            position: absolute;\n            right: 0;\n            background: linear-gradient(90deg, #ffffff00 0%, #ffffff 50%) !important;\n            padding-left: 70px;\n            box-shadow: 20px 0px 0px 0px #ffffff !important;\n            i{\n                font-size: 20px;\n                padding: 1px 5px;\n            }\n        }\n    }\n    .table-header-dropdown{\n        font-weight: initial;\n    }\n    .not-found{\n        margin-bottom: 150px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
