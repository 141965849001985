import React, { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { Table, Badge, FormControl, Container, Button, Dropdown, DropdownButton, Form, Col, Row, Alert, OverlayTrigger, Tooltip, Accordion, Modal } from 'react-bootstrap';
import { getFormErrorMsg, formattedDateInput, currencyFormat, getInvalidObject } from '../../../helper/Helper'
import { BrouillonService } from '../../../services/BrouillonService';
import { ReglementService } from '../../../services/ReglementService';
import ErrorManager from "../../../components/error-manager/ErrorManager";
import { GlobalContext } from "../../../services/GlobalProvider";
import ConfirmDialog from '../../../components/confirm-dialog/ConfirmDialog';
import WysiwygInput from "../../../components/form-inputs/wysiwyg-input/WysiwygInput";


const Observations = (props) => {

    const [value, setValue] = useState(props.brouillon.observation)
    const [valueHasChanged, setValueHasChanged] = useState(false)

    useEffect(() => {
        valueHasChanged ? props.setIsComponentEditing(true) : props.setIsComponentEditing(false)
    }, [valueHasChanged]);

    function handleChange(e) {
        console.log(e.target.value)
        setValue(e.target.value)
        setValueHasChanged(true)
    }

    function cancel() {
        setValue(props.brouillon.observation)
        setValueHasChanged(false)
    }

    function save() {
        props.changeValue({
            target: {
                name: "observation",
                value: value
            }
        })
        setValueHasChanged(false)
    }

    return (
        <div className={((props.showChanges && props.modifiedValues?.observation) ? "modified-border" : "")}>
            <div className="d-flex align-items-center mb-3">
                <h6 className="text-dark d-flex align-items-center fw-normal m-0"><i className="text-primary-600 me-2 fs-4 material-icons">notes</i>Observations</h6>
                {valueHasChanged &&
                    <span className="ms-auto">
                        <Button size="sm" variant="secondary" onClick={() => cancel()}>Annuler</Button>
                        <Button size="sm" className="ms-2" onClick={() => save()}>Enregistrer</Button>
                    </span>
                }
            </div>
            <WysiwygInput value={value} placeHolder="Rédiger les informations relatives au document..." changeValue={handleChange} name="observation" isInvalid={getFormErrorMsg(props.invalidData?.observation)} feedback={getFormErrorMsg(props.invalidData?.observation)} />
        </div>
    );
}

export default Observations;
