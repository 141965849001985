import React, { useEffect, useState } from 'react';
import { ClientService } from '../../../../services/ClientService';
import { Form, Spinner, Card, Tabs, Tab, Row, Col, Accordion, Button, OverlayTrigger, Alert, Tooltip, Dropdown, Badge } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import ErrorManager from "../../../error-manager/ErrorManager";
import { isDark, granted } from '../../../../helper/Helper'
import Interlocuteurs from "./interlocuteurs/Interlocuteurs";
import Factures from "./factures/Factures";
import Proformas from "./proformas/Proformas";
import Adresses from "./adresses/Adresses";
import Tags from "./tags/Tags";
import { TarifService } from '../../../../services/TarifService';
import Reglements from './reglements/Reglements';
import { Editor, convertFromHTML, EditorState, createWithContent, convertFromRaw, ContentState, convertToRaw } from "draft-js";
import { markdownToDraft } from 'markdown-draft-js';
import { GlobalContext } from "../../../../services/GlobalProvider";


const ClientDetails = (props) => {

    const { grants } = React.useContext(GlobalContext);

    let [client, setClient] = useState()
    const [getClientLoading, setGetClientLoading] = useState()
    const [tags, setTags] = useState([])
    let [openElement, setOpenElement] = useState()
    let [showReglementForm, setShowReglementForm] = useState(false)

    useEffect(() => {

        setClient()
        getClient()
        setOpenElement(props.openTab ? props.openTab : "client")

        if (props.openTab == "reglements") {
            setShowReglementForm(true)
        } else {
            setShowReglementForm(false)
        }

    }, [props.clientId]);

    function handleTabSelect(key) {
        setOpenElement(key)
    }

    function getClient() {
        setGetClientLoading(true)
        ClientService.getClient(props.folderId, props.clientId).then(res => {
            setClient(res.status == 200 ? res.data.data : { warning: res.statusText })
            setGetClientLoading(false)
            setTags(res?.data?.data?.tags)
        }).catch(error => {
            setClient({ error: error })
            setGetClientLoading(false)
        });
    }

    function Split({ title, value, border }) {
        return (
            <div className={"d-flex justify-content-between py-2 " + (border ? "border-bottom" : "")}>
                <span className="pe-4 text-gray-500">{title}</span>
                <span className="">{value}</span>
            </div>
        )
    }

    function tabChange() {
        getClient()
        props.onTabChange()
    }

    return (
        <div id="ClientDetails">

            {(!client && getClientLoading) &&
                <span className="hloader mt-3"></span>
            }

            {client?.warning &&
                <div className="d-inline-block w-100 mt-4">
                    <Alert variant="warning">
                        {client?.warning}
                    </Alert>
                </div>
            }

            {client?.error &&
                <div className="d-inline-block w-100 mt-4">
                    <ErrorManager error={client?.error} />
                </div>
            }

            {
                (client && !client.warning && !client.error) && (

                    <>
                        <div className="d-flex mb-4">
                            <div className="me-auto">
                                <h6 className="fw-bolder m-0">{client.nom}<span className="text-secondary ms-2">{client.code}</span></h6>
                                {tags?.length > 0 &&
                                    <span className="bubble-tags-container border mt-1">
                                        Tags :
                                        {tags.map(tag => (
                                            <span key={tag._id} className="bubble" style={{ backgroundColor: tag.couleur }}>
                                                <span style={{ backgroundColor: tag.couleur }} className={"label " + (isDark(tag.couleur) ? "text-dark" : "text-white")}>{tag.tag}</span>
                                            </span>
                                        ))}
                                    </span>
                                }
                            </div>
                            {props.elementActions.length > 0 &&
                                <Dropdown className="float-end ms-2 me-3">
                                    <OverlayTrigger
                                        placement="left"
                                        delay={{ show: 800 }}
                                        overlay={
                                            <Tooltip>
                                                Plus d'actions
                                            </Tooltip>
                                        }
                                    >
                                        <Dropdown.Toggle variant="secondary" className='no-btn-caret'>
                                            <i className="material-icons i-lg">more_horiz</i>
                                        </Dropdown.Toggle>
                                    </OverlayTrigger>
                                    <Dropdown.Menu>
                                        {props.elementActions.map((element) => (
                                            <Dropdown.Item key={element.title} onClick={() => props.setElementAction(element.action, client)}>{element.title}</Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            }
                        </div>

                        <div>

                            <Tabs variant="pills" defaultActiveKey={openElement} onSelect={handleTabSelect} className="mb-3">

                                <Tab eventKey="client" title="Client" className="light-v-scroll height-full-min-250 pe-2">
                                    {openElement == "client" &&
                                        <>

                                            {client.bloquer == true &&
                                                <div className="mb-3 p-3 bg-danger rounded">
                                                    <h6 className="text-white p-0 m-0">Client bloqué</h6>
                                                </div>
                                            }

                                            <Accordion defaultActiveKey={['infos', 'conditions', 'infos_bancaires', 'localisation', 'echeance_comptable', 'observation']} alwaysOpen>
                                                <Accordion.Item eventKey="infos" className="border-0 mb-3">
                                                    <Accordion.Header>Informations générales</Accordion.Header>
                                                    <Accordion.Body className="px-1">
                                                        <Split title="Code" border value={client.code} />
                                                        <Split title="Nom" border value={client.nom} />
                                                        <Split title="Profession" border value={client.profession} />
                                                        <Split title="Utilisateur" border value={client.user.user_login} />
                                                        <Split title="Ridet" border value={client.ridet} />
                                                        <Split title="Code APE" value={client.code_ape} />
                                                    </Accordion.Body>
                                                </Accordion.Item>

                                                <Accordion.Item eventKey="observation" className="border-0 mb-3">
                                                    <Accordion.Header>Observation</Accordion.Header>
                                                    <Accordion.Body className="px-1">
                                                        {client.observation &&
                                                            <Editor editorState={EditorState.createWithContent(convertFromRaw(markdownToDraft(client.observation)))} readOnly={true} />
                                                        }
                                                        {!client.observation &&
                                                            <em>- Aucune observation rédigée -</em>
                                                        }
                                                    </Accordion.Body>
                                                </Accordion.Item>

                                                <Accordion.Item eventKey="conditions" className="border-0 mb-3">
                                                    <Accordion.Header>Conditions de vente</Accordion.Header>
                                                    <Accordion.Body className="px-1">
                                                        {/*                                                         <Split title="Tarif" border value={
                                                            tarifList.filter(t => t._id == client.tarif_id).map(tarif => (
                                                                <span key={tarif._id}>{tarif.code}</span>
                                                            ))
                                                        } /> */}
                                                        <Split title="Type" border value={client.type} />
                                                        <Split title="Interdire" border value={client.interdir} />
                                                        {client.exonerer_taxes == false &&
                                                            <Split title="Exonerer taxes" border value="Non" />
                                                        }
                                                        {client.exonerer_taxes == true &&
                                                            <Split title="Exonerer taxes" value="Oui" />
                                                        }
                                                        {client.exonerer_eco_participation == true &&
                                                            <Split title="Exonerer eco participation" border value="Oui" />
                                                        }
                                                        {client.exonerer_eco_participation == false &&
                                                            <Split title="Exonerer eco participation" border value="Non" />
                                                        }
                                                        <Split title="Taux de remise" border value={client.taux_remise} />
                                                        <Split title="Débit max" border value={client.debit_max} />
                                                        <Split title="Condition de paiement" border value={client.condition_paiement} />
                                                        {client?.tarif?._id && <Split title="Tarif" value={<a href={`/${props.folderId}/tarifs?tarifId=${client?.tarif?._id}`} target="_blank">{client?.tarif?.libelle}</a>} />}


                                                    </Accordion.Body>
                                                </Accordion.Item>

                                                <Accordion.Item eventKey="infos_bancaires" className="border-0 mb-3">
                                                    <Accordion.Header>Informations bancaires</Accordion.Header>
                                                    <Accordion.Body className="px-1">
                                                        <Split title="RIB" border value={client.rib} />
                                                        <Split title="Iban" border value={client.iban} />
                                                        <Split title="Titulaire" value={client.titulaire} />
                                                    </Accordion.Body>
                                                </Accordion.Item>

                                                <Accordion.Item eventKey="localisation" className="border-0 mb-3">
                                                    <Accordion.Header>Localisation</Accordion.Header>
                                                    <Accordion.Body className="px-1">
                                                        <Split title="GPS" value={`Lat : ${client.gps[0] || "N/C"} Lon : ${client.gps[1] || "N/C"}`} />
                                                    </Accordion.Body>
                                                </Accordion.Item>

                                                <Accordion.Item eventKey="echeance_comptable" className="border-0 mb-3">
                                                    <Accordion.Header>Échéance comptable</Accordion.Header>
                                                    <Accordion.Body className="px-1">
                                                        <Split title="Nombre de jours" border value={client.echeance_comptable?.nombre_jours} />
                                                        <Split title="Jour du mois" border value={client.echeance_comptable?.jour_du_mois} />
                                                        {client.echeance_comptable?.fin_mois == true &&
                                                            <Split title="Fin de mois" value="Oui" />
                                                        }
                                                        {client.echeance_comptable?.fin_mois == false &&
                                                            <Split title="Fin de mois" value="Non" />
                                                        }
                                                    </Accordion.Body>
                                                </Accordion.Item>


                                            </Accordion>

                                        </>
                                    }
                                </Tab>

                                {granted(grants, "tags") &&
                                    <Tab eventKey="tags" title="Tags" className="light-v-scroll height-full-min-250 pe-2">
                                        {openElement == "tags" &&
                                            <Tags folderId={props.folderId} client={client} setTags={(tags) => setTags(tags)} isClientLoading={getClientLoading} onTagChange={() => tabChange()} />
                                        }
                                    </Tab>
                                }


                                <Tab eventKey="adresses" title="Adresses" className="light-v-scroll height-full-min-250 pe-2">
                                    {openElement == "adresses" &&
                                        <Adresses folderId={props.folderId} client={client} isClientLoading={getClientLoading} onAdresseChange={() => tabChange()} />
                                    }
                                </Tab>

                                <Tab eventKey="interlocuteurs" title="Interlocuteurs" className="light-v-scroll height-full-min-250 pe-2">
                                    {openElement == "interlocuteurs" &&
                                        <Interlocuteurs folderId={props.folderId} client={client} onInterlocuteurChange={() => tabChange()} />
                                    }
                                </Tab>

                                <Tab eventKey="factures" title="Factures" className="light-v-scroll height-full-min-250 pe-2">
                                    {openElement == "factures" &&
                                        <Factures folderId={props.folderId} client={client} />
                                    }
                                </Tab>

                                <Tab eventKey="proformas" title="Proformas" className="light-v-scroll height-full-min-250 pe-2">
                                    {openElement == "proformas" &&
                                        <Proformas folderId={props.folderId} client={client} />
                                    }
                                </Tab>

                                <Tab eventKey="reglements" title="Règlements" className="light-v-scroll height-full-min-250 pe-2">
                                    {openElement == "reglements" &&
                                        <Reglements showReglementForm={showReglementForm} folderId={props.folderId} client={client} onReglementChange={() => tabChange()} />
                                    }
                                </Tab>
                            </Tabs>

                        </div>

                    </>

                )
            }
        </div>

    )

}

export default ClientDetails;