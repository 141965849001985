import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { UserService } from '../../services/UserService'
import { getDomainFromUrl, setCookie, getCookie } from '../../helper/Helper';
import ErrorManager from "../../components/error-manager/ErrorManager";


export default function Gettoken() {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams()
    const codeParam = searchParams.get("code")
    
    // const errorParam = searchParams.get("error")
    // const errorDescriptionParam = searchParams.get("error_description")

    const dataPost = {
        grant_type: "authorization_code",
        code: codeParam,
        client_secret: global.config._OAUTH_CLIENT_SECRET_,
        client_id: global.config._OAUTH_CLIENT_ID_
    }
    UserService.getToken(dataPost).then(res => {
        sessionStorage.setItem("access_token", res.data.access_token)
        setCookie("id_token", res.data.id_token, 24, getDomainFromUrl(global.config._FRONT_URL_))
        setCookie("refresh_token", res.data.refresh_token, 168, getDomainFromUrl(global.config._FRONT_URL_))
        navigate("/dossiers")
    }).catch(error => {
        console.log('REDIRECT LOGIN DDD')
        UserService.redirectToExternalLogin()
    });

    /* if(codeParam){

    }else{
        console.log("Erreur de récupération du code dans l'url")
    } */

    return null
}