import React, { createContext, useState, useEffect } from "react";
import { UserService } from './UserService';
import { PreferenceService } from './PreferenceService';
import { ParametreService } from './ParametreService';
import { FolderService } from './FolderService';
import { useNavigate, useParams, useSearchParams, useLocation } from "react-router-dom";
import { granted, getDomainFromUrl, setCookie, getCookie } from '../helper/Helper'


const GlobalContext = createContext(undefined);

function GlobalProvider({ children }) {

  const [folders, setFolders] = useState();
  const [folderDetails, setFolderDetails] = useState();
  const [userDetails, setUserDetails] = useState();
  const [preferences, setPreferences] = useState();
  const [parametres, setParametres] = useState();
  const [grants, setGrants] = useState();
  const [apiVersion, setApiVersion] = useState();

  const [globalContextLoaded, setGlobalContextLoaded] = useState(false);

  let { folderId } = useParams();

  const navigate = useNavigate();

  let location = useLocation();

  useEffect(() => {

    getFolders()

    // broadcast : si une déconnexion eu lieu (appel à Logout page) via un autre onglet du navigateur
    const bc = new BroadcastChannel('authentication');
    bc.onmessage = function (ev) {
      if (ev.data == "logout") {
        sessionStorage.removeItem('access_token');
        setCookie("id_token", '', -10, getDomainFromUrl(global.config._FRONT_URL_))
        setCookie("refresh_token", '', -10, getDomainFromUrl(global.config._FRONT_URL_))
      }
    }

  }, []);

  useEffect(() => {
    
    folders && (

      getUser(),

      // Redirection vers les dossiers si aucune page dans l'url
      !location.pathname.split("/")[1] && navigate(`/dossiers`) 
    )

  }, [folders]);

  useEffect(() => {
    folderDetails && (
      getPreferences(),
      getParametres(),
      getGrants()
    )
  }, [folderDetails]);

  useEffect(() => {
    if (folderDetails && userDetails && preferences && parametres && grants) {
      setGlobalContextLoaded(true)
    }
  }, [folderDetails, userDetails, preferences, parametres, grants]);

  function getFolders() {

    FolderService.getFolders().then((res) => {

      setFolders(res.data.data)
      setApiVersion(res.data.status.api_version)

      /* Define folderDetails */
      let folderFound = res.data.data?.find(f => f.dossier._id == folderId);
      if (folderFound) {
        setFolderDetails(folderFound)
      }

      /* Redirection si le dossier n'a pas été trouvé mais que quelque chose a été saisi dans l'URL */
      if (!folderFound && folderId) {
        navigate(`/not-found?folder-not-found=true`)
      }

    }).catch(error => {
    });
  }

  function getUser() {
    UserService.getUserInfos()
      .then((res) => {
        setUserDetails(res.data)
      }).catch(error => {
      });
  }

  function getPreferences() {
    folderDetails &&
      PreferenceService.getPreferences(folderDetails?.dossier?._id, false, false).then(res => {
        setPreferences(res.data.data.preferences)
      }).catch(error => {
      });
  }

  function getParametres() {
    folderDetails &&
      ParametreService.getParametres(folderDetails?.dossier?._id, false, false, { params: ["general", "articles", "brouillons", "clients", "contributions", "factures", "familles", "fournisseurs", "proformas", "abonnements", "reglements", "tags", "tarifs"] }).then(res => {
        setParametres(res.data.data.parametres)
      }).catch(error => {
      });
  }

  function getGrants() {
    folderDetails &&
      UserService.getUserGrants(folderDetails?.dossier?._id).then((res) => {
        setGrants(res.data.data.grants)
      }).catch(error => {
      });
  }

  return (
    <GlobalContext.Provider value={{ globalContextLoaded, userDetails, folderDetails, preferences, getPreferences, parametres, getParametres, grants, getGrants, folders, getFolders, setFolderDetails, apiVersion }}>
      {userDetails && children}
    </GlobalContext.Provider>
  );
}

export { GlobalProvider, GlobalContext };
