// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#ErrorManager .error-fixed {
  position: fixed;
  right: 40px;
  bottom: 22px;
  animation-duration: 0.3s;
  animation-name: slideup;
  margin: 0;
}
@keyframes slideup {
  0% {
    bottom: -100px;
  }
  60% {
    bottom: 30px;
  }
  100% {
    bottom: 22px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/error-manager/ErrorManager.scss"],"names":[],"mappings":"AACI;EACI,eAAA;EACA,WAAA;EACA,YAAA;EACA,wBAAA;EACA,uBAAA;EACA,SAAA;AAAR;AAGI;EACI;IACI,cAAA;EADV;EAIM;IACI,YAAA;EAFV;EAKM;IACI,YAAA;EAHV;AACF","sourcesContent":["#ErrorManager {\n    .error-fixed {\n        position: fixed;\n        right: 40px;\n        bottom: 22px;\n        animation-duration: .3s;\n        animation-name: slideup;\n        margin: 0;\n    }\n\n    @keyframes slideup {\n        0% {\n            bottom: -100px;\n        }\n\n        60% {\n            bottom: 30px;\n        }\n\n        100% {\n            bottom: 22px;\n        }\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
