import React, { useEffect, useState } from 'react';
import { Button, Badge, Dropdown, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useParams, useSearchParams } from "react-router-dom";
import './SideDetails.scss';

const SideDetails = (props) => {

    const [isEnlarged, setIsEnlarged] = useState(null)
    const [isContracted, setIsContracted] = useState(null)
    const [isHistoryVisible, setIsHistoryVisible] = useState(null)
    const [elementHistoryList, setElementHistoryList] = useState(null)
    const [historySelectedId, setHistorySelectedId] = useState(null)

    const [searchParams, setSearchParams] = useSearchParams();

    let sideWidth = 700
    let historyWidth = 300

    useEffect(() => {
        setElementHistoryList(localStorage.getItem(window.location.pathname) ? JSON.parse(localStorage.getItem(window.location.pathname)) : [])
    }, []);

    useEffect(() => {
        if (props.editMode) {
            isEnlarged && contractSideDetails()
            setIsHistoryVisible(false)
        }
    }, [props.editMode]);

    useEffect(() => {
        if (props.currentElement) { setHistoryList(props.currentElement) }
    }, [props.currentElement]);

    useEffect(() => {
        let totalSideWidth = sideWidth
        isHistoryVisible && (totalSideWidth += historyWidth)
        props.setSideWidth(totalSideWidth);
    }, [isHistoryVisible]);

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    function handleKeyDown(e) {
        if (!props.editMode) {
            const { code } = e;
            switch (code) {
                case "Escape":
                    e.stopPropagation()
                    closeSideDetails()
                    break;
                default:
                    break;
            }
        }
    }

    function closeSideDetails() {
        props.setSideWidth(0);
        props.setSideClose();
        props.changeCurrentElement();
    }

    function cancelSideDetails() {
        props.setSideCancel();
    }

    function toggleHistory() {
        setIsHistoryVisible(!isHistoryVisible)
    }

    function enlargeSideDetails() {
        setIsEnlarged(true)
        setIsContracted(false)
    }

    function contractSideDetails() {
        setIsEnlarged(false)
        setIsContracted(true)
    }

    function setCurrentElement(item) {
        setHistorySelectedId(item._id)
        props.changeCurrentElement(item)
    }

    function removeElement(itemId) {
        let localStorageList = localStorage.getItem(window.location.pathname) ? JSON.parse(localStorage.getItem(window.location.pathname)) : []
        let elementHistoryListCopy = [...localStorageList];

        let index = elementHistoryListCopy.findIndex(function (a) {
            return a._id === itemId;
        })
        if (index !== -1) elementHistoryListCopy.splice(index, 1);

        localStorage.setItem(window.location.pathname, JSON.stringify(elementHistoryListCopy))

        setElementHistoryList(elementHistoryListCopy)

    }

    function setHistoryList(element) {

        if (!element.id) { return }

        setHistorySelectedId(element.id)

        let localStorageList = localStorage.getItem(window.location.pathname) ? JSON.parse(localStorage.getItem(window.location.pathname)) : []

        let elementHistoryListCopy = [...localStorageList];

        // chercher l'élément dans elementHistoryList
        let elementHistoryFound = elementHistoryListCopy.find(a => a._id == element.id);

        // si depuis l'élément parent, mettre en haut de la liste
        if (elementHistoryFound && element.id != historySelectedId) {

            // si déjà présent dans la liste, le supprimer
            let index = elementHistoryListCopy.findIndex(function (a) {
                return a._id === elementHistoryFound._id;
            })
            if (index !== -1) elementHistoryListCopy.splice(index, 1);

            // ajout de l'element au tableau
            elementHistoryListCopy.unshift(elementHistoryFound)

        }

        // Si non présent, créer un nouvel élément et l'ajouter
        if (!elementHistoryFound) {
            elementHistoryListCopy.unshift({
                _id: element.id,
                label: element.label,
                title: element.title
            })
        }

        // limiter la liste à 25 éléments
        if (elementHistoryListCopy.length >= 25) {
            elementHistoryListCopy = elementHistoryListCopy.slice(0, 25);
        }

        setElementHistoryList(elementHistoryListCopy)

        localStorage.setItem(window.location.pathname, JSON.stringify(elementHistoryListCopy))

    }

    return (
        <div id="SideDetails" className={"border-start bg-white p-5 shadow-lg " + (props.editMode ? 'hideOutside ' : '') + (isEnlarged ? 'enlarge ' : '') + (isContracted ? 'contract ' : '')}>

            {!props.editMode &&
                <Button className={"float-end " + (!props.editMode ? "rounded-0 rounded-end border-start-0" : "")} variant="secondary" onClick={() => closeSideDetails()} >Fermer</Button>
            }

            {props.editMode &&
                <Button className={"float-end " + (!props.editMode ? "rounded-0 rounded-end border-start" : "")} variant="secondary" onClick={() => cancelSideDetails()} >Annuler</Button>
            }

            {!props.editMode && !isEnlarged &&
                <OverlayTrigger placement="left" delay={{ show: 800 }} overlay={<Tooltip>Agrandir la fenêtre</Tooltip>}>
                    <Button className="float-end d-flex justify-content-center align-content-between rounded-0 rounded-start" variant="secondary" onClick={() => enlargeSideDetails()} ><i className="material-icons i-lg">open_in_full</i></Button>
                </OverlayTrigger>
            }
            
            {!props.editMode && isEnlarged &&
                <OverlayTrigger placement="left" delay={{ show: 800 }} overlay={<Tooltip>Réduire la fenêtre</Tooltip>}>
                    <Button className="float-end d-flex justify-content-center align-content-between rounded-0 rounded-start" variant="secondary" onClick={() => contractSideDetails()} ><i className="material-icons i-lg">close_fullscreen</i></Button>
                </OverlayTrigger>
            }

            {!props.editMode && !isEnlarged &&
                <OverlayTrigger placement="left" delay={{ show: 800 }} overlay={<Tooltip>Historique des consultations</Tooltip>}>
                    <Button className="history-btn" variant="secondary" onClick={() => toggleHistory()} >
                        <i className="material-icons i-lg">history</i>
                    </Button>
                </OverlayTrigger>

            }

            {(isHistoryVisible || isEnlarged) &&
                <div className="history-container bg-white border-start shadow-lg p-4">
                    <div className="light-v-scroll">
                        <h6>Historique des consultations</h6>
                        {elementHistoryList.map((item, i) => (
                            <span key={item._id} className="position-relative d-block">
                                <span className={"position-absolute cursor-pointer end-0 top-0 rounded px-2 py-1 " + (item._id == historySelectedId ? 'text-primary-500' : 'text-gray-400')} onClick={() => removeElement(item._id)}>
                                    <i className="material-icons">close</i>
                                </span>
                                <div className={"rounded mb-2 p-2 cursor-pointer " + (item._id == historySelectedId ? 'bg-primary-300 text-primary-800' : 'bg-gray-100 hover-bg-gray-200')} onClick={() => setCurrentElement(item)}>
                                    <Badge bg={(item._id == historySelectedId ? 'primary-100' : 'gray-300')} text={(item._id == historySelectedId ? 'primary-600 ' : 'dark')} className="mb-2">{item.label}</Badge>
                                    <small className="d-block">{item.title}</small>
                                </div>
                            </span>
                        ))}
                    </div>
                </div>
            }

            {props.detailsComponent}

        </div>
    );
}

export default SideDetails;