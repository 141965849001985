import React, { useEffect, useState, useRef } from "react";
import { Container, Button, Dropdown, DropdownButton, Form, InputGroup, Row, Badge, Label, Table, Tooltip, Spinner, OverlayTrigger, FormControl } from 'react-bootstrap';
import { ContributionService } from '../../../services/ContributionService';
import { FactureService } from '../../../services/FactureService';
import Pagination from '../../../components/pagination/Pagination';
import './FactureInput.scss';
import ErrorManager from "../../../components/error-manager/ErrorManager";
import { GlobalContext } from "../../../services/GlobalProvider";


const FactureInput = (props) => {


    const [selectedFacture, setSelectedFacture] = useState()
    const [currentPagination, setCurrentPagination] = useState(1)
    const [isGetFacturesLoading, setIsGetFacturesLoading] = useState(false)
    const [facturesError, setFacturesError] = useState()
    const [factures, setFactures] = useState()
    const [factureLoading, setFactureLoading] = useState(false)
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const [activeRowIndex, setActiveRowIndex] = useState(1)
    const [globalError, setGlobalError] = useState()
    const [factureInputLabel, setFactureInputLabel] = useState("")

    const { parametres, folderDetails, userDetails, grants } = React.useContext(GlobalContext);

    const searchRef = useRef(null);

    let facturesParams = useRef({
        'start': 0,
        'limit': 8,
        'sort': "date",
        'order': "desc",
        'filters': props.filters || {}
    });

    useEffect(() => {
        if (!isDropdownOpen) {
            searchRef.current.value = factureInputLabel
        }
    }, [isDropdownOpen]);

    useEffect(() => {
        if (props.factureId) {
            getFacture(props.factureId)
        } else {
            setSelectedFacture()
            setFactureInputLabel()
            searchRef.current.value = null
        }
    }, [props.factureId]);


    function getFacture(factureId) {
        setFactureLoading(true)
        FactureService.getFacture(props.folderId, factureId).then(res => {
            setSelectedFacture(res.data.data)
            setFactureInputLabel(`${res.data.data.code} - ${res.data.data.client_nom}`)
            searchRef.current.value = `${res.data.data.code} - ${res.data.data.client_nom}`
            setFactureLoading(false)
        }).catch(error => {
            setSelectedFacture()
            setFactureLoading(false)
            setGlobalError(error)
        });
    }

    function addFacture(facture) {
        setIsDropdownOpen(false)
        props.setFacture(facture)
    }

    function showFactureList() {
        setIsDropdownOpen(true)
        searchRef.current.value = null
        getFactures()
    }

    function removeFacture() {
        setIsDropdownOpen(false)
        searchRef.current.value = null
        props.setFacture()
    }

    function getFactures() {
        setIsGetFacturesLoading(true)
        setFacturesError()
        setActiveRowIndex()
        FactureService.getFactures(props.folderId, facturesParams.current).then(res => {
            setIsGetFacturesLoading(false)
            setFactures(res.data ? res.data.data : null)
            setActiveRowIndex(0)
        }).catch(error => {
            setActiveRowIndex()
            setIsGetFacturesLoading(false)
            setFacturesError(error)
        });
    }

    function changePagination(p) {
        setCurrentPagination(p)
        facturesParams.current['start'] = (facturesParams.current.limit * p) - facturesParams.current.limit
        getFactures()
    }

    let searchTimeOut;
    function changeSearchValue(e) {
        window.clearTimeout(searchTimeOut);
        setIsDropdownOpen(true)
        searchTimeOut = window.setTimeout(function () {
            setCurrentPagination(1)
            searchRef.current.value = e.target.value
            facturesParams.current['start'] = 0
            facturesParams.current['search'] = searchRef.current.value
            getFactures()
        }, 1000);
    }

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    function handleKeyDown(e) {
        if (activeRowIndex != null && isDropdownOpen) {
            const { code } = e;
            switch (code) {
                case "ArrowUp":
                    e.preventDefault();
                    setActiveRowIndex(activeRowIndex != 0 && activeRowIndex - 1)
                    break;
                case "ArrowDown":
                    e.preventDefault();
                    setActiveRowIndex((activeRowIndex < factures?.factures.length - 1) ? activeRowIndex + 1 : activeRowIndex)
                    break;
                case "Enter":
                    addFacture(factures.factures[activeRowIndex])
                    break;
                case "Escape":
                    setIsDropdownOpen(false)
                    break;
                default:
                    break;
            }
        }
    }

    return (
        <div id="factureInput" className="position-relative">

            <InputGroup hasValidation={props.isInvalid}>

                {!factureLoading &&
                    <i className="material-icons input-icon text-gray-400">search</i>
                }

                {factureLoading &&
                    <i className="input-icon text-gray-400">
                        <Spinner animation="border" size="sm" className="me-2" />
                    </i>
                }

                <FormControl
                    isInvalid={props.isInvalid}
                    disabled={factureLoading || props.disabled}
                    className={"input-icon-space rounded-start " /* + ((!props.factureComptantButton && !selectedFacture) ? "rounded" : "") */}
                    type="text"
                    ref={searchRef}
                    onChange={changeSearchValue}
                    placeholder="Rechercher une facture"
                />

                <OverlayTrigger
                    delay={{ show: 800 }}
                    overlay={
                        <Tooltip>
                            Afficher les factures
                        </Tooltip>
                    }
                >
                    <Button /* disabled={clientLoading || props.disabled} */ onClick={() => showFactureList()} variant="secondary">
                        <i className="material-icons i-lg">expand_more</i>
                    </Button>
                </OverlayTrigger>

                {selectedFacture &&
                    <OverlayTrigger
                        delay={{ show: 800 }}
                        overlay={
                            <Tooltip>
                                Supprimer
                            </Tooltip>
                        }
                    >
                        <Button disabled={factureLoading || props.disabled} onClick={() => removeFacture()} variant="secondary">
                            <i className="material-icons i-lg">delete_forever</i>
                        </Button>
                    </OverlayTrigger>

                }
                {props.isInvalid &&
                    <Form.Control.Feedback type="invalid">
                        {props.isInvalid}
                    </Form.Control.Feedback>
                }

            </InputGroup>

            {globalError && (
                <ErrorManager error={globalError} />
            )}

            {isDropdownOpen && (
                <span className="drop-container zindex-100 border border-gray-500 rounded p-3 position-absolute top-100 bg-white shadow-lg">

                    <div className="w-100 mb-3">
                        <i onClick={() => setIsDropdownOpen(false)} className="material-icons text-gray-300 fs-4 position-absolute top-0 end-0 me-2 mt-1 cursor-pointer">close</i>
                    </div>

                    {isGetFacturesLoading &&
                        <span className="hloader"></span>
                    }

                    {(!isGetFacturesLoading && !factures?.factures) &&
                        <em>- Aucun élément trouvé -</em>
                    }

                    {(!isGetFacturesLoading && factures?.factures) &&
                        <>
                            <Table size="sm" hover className="rounded">
                                <thead className="text-uppercase text-gray-600 small">
                                    <tr>
                                        <th>Code Facture</th>
                                        <th>Nom Client</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {factures?.factures?.map((facture, i) => (
                                        <tr key={facture?._id} className={"cursor-pointer position-relative " + (i == activeRowIndex ? "table-active selected zindex-1 " : "")} onClick={() => addFacture(facture)}>
                                            <td>{facture?.code}</td>
                                            <td>{facture?.client_nom}</td>
                                            <td>{new Date(facture.date).toLocaleString()}</td>
                                            {/* <td>
                                                {facture?.nom}
                                                <Badge className="float-end" bg="secondary">{facture?.type}</Badge>
                                                {facture?.bloquer &&
                                                    <Badge className="me-2 float-end" bg="danger">Bloqué</Badge>
                                                }


                                            </td> */}
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                            <Pagination size="sm" itemsLength={factures?.total} elementsPerPage={facturesParams.current.limit} currentPagination={currentPagination} changePagination={p => changePagination(p)} />

                        </>
                    }

                </span>
            )}

        </div>

    )
}
export default FactureInput;
