// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#TagsFilter {
  width: 400px;
}

#TagsFilterButton .bubble-tags-container .bubble:hover .label {
  right: -5px;
  left: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/tags-filter/TagsFilter.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;;AAKY;EACI,WAAA;EACA,UAAA;AAFhB","sourcesContent":["#TagsFilter {\n    width: 400px;\n}\n\n#TagsFilterButton {\n    .bubble-tags-container {\n        .bubble:hover {\n            .label {\n                right: -5px;\n                left: auto;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
