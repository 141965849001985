// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#FamilyTree {
  margin-left: 14px;
  display: flex;
  flex-direction: column;
}
#FamilyTree .family {
  display: flex;
  padding: 0px 8px 2px 6px;
  align-items: center;
  border-radius: 20px;
  position: relative;
}
#FamilyTree .family:hover .light-dropdown .dropdown-toggle {
  visibility: visible;
}
#FamilyTree .family > span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 0.9625rem;
  width: 100%;
}
#FamilyTree .family .caret {
  width: 12px;
  margin-left: -22px;
  position: absolute;
  transform: rotate(90deg);
  font-size: 12px;
}
#FamilyTree .family .caret.caret-close {
  transform: rotate(0deg);
}
#FamilyTree .family .light-dropdown {
  display: flex;
}
#FamilyTree .family .light-dropdown .dropdown-toggle {
  visibility: hidden;
}
#FamilyTree .subfamilies {
  display: block;
}
#FamilyTree .subfamilies.close {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/families/Families.scss"],"names":[],"mappings":"AAEA;EACI,iBAHK;EAIL,aAAA;EACA,sBAAA;AADJ;AAEI;EACI,aAAA;EACA,wBAAA;EACA,mBAAA;EACA,mBAAA;EACA,kBAAA;AAAR;AAIgB;EACI,mBAAA;AAFpB;AAMQ;EACI,uBAAA;EACA,mBAAA;EACA,gBAAA;EACA,oBAAA;EACA,WAAA;AAJZ;AAMQ;EACI,WAAA;EACA,kBAAA;EACA,kBAAA;EACA,wBAAA;EACA,eAAA;AAJZ;AAMY;EACI,uBAAA;AAJhB;AAOQ;EACI,aAAA;AALZ;AAMY;EACI,kBAAA;AAJhB;AASI;EACI,cAAA;AAPR;AAQQ;EACI,aAAA;AANZ","sourcesContent":["$spacer: 14px;\n\n#FamilyTree{\n    margin-left: $spacer;\n    display: flex;\n    flex-direction: column;\n    .family{\n        display: flex;\n        padding: 0px 8px 2px 6px;\n        align-items: center;\n        border-radius: 20px;\n        position: relative;\n        \n        &:hover{\n            .light-dropdown{\n                .dropdown-toggle{\n                    visibility: visible;\n                }\n            }\n        }\n        &>span{\n            text-overflow: ellipsis;\n            white-space: nowrap;\n            overflow: hidden;\n            font-size: 1.1rem * .875;\n            width: 100%;\n        }\n        .caret{\n            width: 12px;\n            margin-left: -22px;\n            position: absolute;\n            transform: rotate(90deg);\n            font-size: 12px;\n    \n            &.caret-close{\n                transform: rotate(0deg);\n            }\n        }\n        .light-dropdown{\n            display: flex;\n            .dropdown-toggle{\n                visibility: hidden;\n            }\n        }\n        \n    }\n    .subfamilies{\n        display: block;\n        &.close{\n            display: none;\n        }\n    }\n    \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
