/* eslint-disable no-fallthrough */
import React, { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { InputGroup, FormControl, Container, Button, Dropdown, Tabs, Form, Col, Row, Tab, SplitButton, Tooltip, Badge, ToastContainer, Spinner } from 'react-bootstrap';

import { ArticleService } from '../../services/ArticleService';
import { EditionService } from '../../services/EditionService';

import DynamicTable from '../../components/table/dynamic-table/DynamicTable';
import Pagination from '../../components/pagination/Pagination';
import SideDetails from '../../components/side-details/SideDetails';
import ClientDetails from '../../components/side-details/client/client-details/ClientDetails';
import ClientEdition from '../../components/side-details/client/client-edition/ClientEdition';
import ColManager from '../../components/table/dynamic-table/col-manager/ColManager';
import Families from '../../components/families/Families';
import './Editions.scss';
import { PreferenceService } from '../../services/PreferenceService';
import ConfirmDialog from '../../components/confirm-dialog/ConfirmDialog';
import ErrorManager from "../../components/error-manager/ErrorManager";
import { GlobalContext } from "../../services/GlobalProvider";
import { formatTableHeadersFromPrefs, granted, getFile } from '../../helper/Helper'


const Editions = () => {

  const { globalContextLoaded, preferences, parametres, folderDetails, grants, getPreferences } = React.useContext(GlobalContext);

  const [globalError, setGlobalError] = useState()
  const [currentEdition, setCurrentEdition] = useState()
  const [memorizedFilters, setMemorizedFilters] = useState([])
  const [isDocumentDownloading, setIsDocumentDownloading] = useState(false)
  const [isDataLoading, setIsDataLoading] = useState(false)
  const [isForceRegenerateChecked, setIsForceRegenerateChecked] = useState(false)

  const [exportsComptableList, setExportsComptableList] = useState([
    {
      id: "ebatch",
      label: "Générer les écritures comptables",
      description: "",
      APIUrl: "export/ebatch",
      filters: {
        'filter[date]': [
          { libelle: 'Date début', operator: ">=", type: 'date' },
          { libelle: 'Date fin', operator: "<=", type: 'date' }
        ]
      }
    },
  ])

  const [analyseList, setAnalyseList] = useState([
    {
      id: "journal_factures",
      label: "Journal factures / période",
      description: "Analyse des ventes : Total des factures débit et comptant par période",
      APIUrl: "analyses/factures",
      filters: {
        'filter[date]': [
          { libelle: 'Date début', operator: ">=", type: 'date' },
          { libelle: 'Date fin', operator: "<=", type: 'date' }
        ],
        'filter[client.type]': [
          { libelle: 'Type client', operator: "=", type: 'select', options: [{ libelle: "Comptant", value: "comptant" }, { libelle: "Débit", value: "debit" }, { libelle: "Interne", value: "interne" }] }
        ],
        'filter[client.code]': [
          { libelle: 'Code client du (Indisponible)', operator: ">", type: 'text' },
          { libelle: 'au (Indisponible)', operator: "<", type: 'text' }
        ],
        'filter[client.tag]': [
          { libelle: 'Tag client (Indisponible)', operator: "=", type: 'text' }
        ],
        'filter[vendeur.user_id]': [
          { libelle: 'Id vendeur', operator: "=", type: 'text' }
        ]
      }
    },
    {
      id: "test_fab",
      label: "Test Fab Analyse 2",
      description: "Test Fab Analyse des ventes par période",
      APIUrl: "analyses/factures",
      filters: {
        'filter[date]': [
          { libelle: 'Date début', operator: ">=", type: 'date' },
          { libelle: 'Date fin', operator: "<=", type: 'date' }
        ],
        'filter[client.type]': [
          { libelle: 'Type client', operator: "=", type: 'select', options: [{ libelle: "Comptant", value: "comptant" }, { libelle: "Débit", value: "debit" }, { libelle: "Interne", value: "interne" }] }
        ],
        'filter[code]': [
          { libelle: 'Code fact. de', operator: ">=", type: 'number' },
          { libelle: 'à', operator: "<=", type: 'number' }
        ],
        'filter[nb_details]': [
          { libelle: 'De', operator: ">=", type: 'number' },
          { libelle: 'à', operator: "<=", type: 'number' }
        ]
      }
    }
  ])


  function getFilterInput(filter, filterName) {


    let defaultValue = memorizedFilters.find(el => (el.filterName === filterName) && (el.operator === filter.operator))?.value


    function changeFilter(e) {

      const value = e?.target?.value || null
      const filterIsDefined = memorizedFilters.some(m => (m.filterName == filterName) && (m.operator == filter.operator))

      if (value) {
        if (filterIsDefined) {
          let memorizedFiltersCopy = [...memorizedFilters];
          memorizedFiltersCopy.find(el => (el.filterName === filterName && el.operator === filter.operator)).value = e.target.value
          setMemorizedFilters(memorizedFiltersCopy);
        } else {
          setMemorizedFilters(memorizedFilters => [...memorizedFilters,
          {
            filterName: filterName,
            operator: filter.operator,
            value: e.target.value
          }]);
        }

      } else {
        let memorizedFiltersCopy = [...memorizedFilters];
        let index = memorizedFiltersCopy.findIndex(item => (item.filterName == filterName && item.operator == filter.operator));
        if (index > -1) {
          memorizedFiltersCopy.splice(index, 1)
          setMemorizedFilters(memorizedFiltersCopy)
          //defaultValue = ""
        }
      }
    }



    switch (filter.type) {
      case "date":
        return <>
          <Form.Label>{filter.libelle}</Form.Label>
          <InputGroup className="mb-2 me-2 filter-input">
            <Form.Control className={(defaultValue ? "border-2 border-dark" : "")} name={filterName} value={defaultValue || ''} type="date" onChange={e => changeFilter(e)} />
            {defaultValue &&
              <Button variant="dark" onClick={() => changeFilter()}>
                <i className="material-icons">close</i>
              </Button>
            }
          </InputGroup>
        </>
      case "number":
        return <>
          <Form.Label>{filter.libelle}</Form.Label>
          <InputGroup className="mb-2 me-2 filter-input">
            <Form.Control className={(defaultValue ? "border-2 border-dark" : "")} name={filterName} value={defaultValue || ''} type="number" onChange={e => changeFilter(e)} />
            {defaultValue &&
              <Button variant="dark" onClick={() => changeFilter()}>
                <i className="material-icons">close</i>
              </Button>
            }
          </InputGroup>
        </>
      case "text":
        return <>
          <Form.Label>{filter.libelle}</Form.Label>
          <InputGroup className="mb-2 me-2 filter-input">
            <Form.Control className={(defaultValue ? "border-2 border-dark" : "")} name={filterName} value={defaultValue || ''} type="text" onChange={e => changeFilter(e)} />
            {defaultValue &&
              <Button variant="dark" onClick={() => changeFilter()}>
                <i className="material-icons">close</i>
              </Button>
            }
          </InputGroup>
        </>
      case "select":
        return <>
          <Form.Label>{filter.libelle}</Form.Label>
          <InputGroup className="mb-2 me-2 filter-input">
            <Form.Select className={(defaultValue ? "border-2 border-dark" : "")} name={filterName} value={defaultValue || ''} onChange={e => changeFilter(e)}>
              <option value={""}>- Choisir -</option>
              {filter.options.map(option => (
                <option key={option.value} value={option.value}>{option.libelle}</option>
              ))}
            </Form.Select>
            {defaultValue &&
              <Button variant="dark" onClick={() => changeFilter()}>
                <i className="material-icons">close</i>
              </Button>
            }
          </InputGroup>
        </>
      default:
        return;
    }
  }

  /* function downloadAnalyses() {
    for (let analyseGroup of analyseGroupList) {
      for (let analyse of analyseGroup.analyseList.filter(a => a.selected)) {
        EditionService.getEditionsPDF(folderDetails?.dossier?._id, { 'filters': analyse.filters }, analyse.APIUrl).then(res => {
          if (!res?.data) {
            console.log('ERROR')
          } else {
            getFile(res.data, `Analyses`, "pdf")
          }
        }).catch(error => {
          setGlobalError(error)
        });
      }
    }
  } */

  /* function downloadExportsComptable() {
    for (let exportComptable of exportsComptableList.filter(e => e.selected)) {
      EditionService.getEditions(folderDetails?.dossier?._id, { 'filters': exportComptable.filters, 'force_regenerate': isForceRegenerateChecked }, exportComptable.APIUrl).then(res => {
        if (!res?.data?.data) {
          console.log('ERROR')
        } else {
          getFile(res.data.data, `ExportsComptable`, "json")
        }
      }).catch(error => {
        setGlobalError(error)
      });
    }
  } */

  function getEditions(format) {

    format ? setIsDocumentDownloading(true) : setIsDataLoading(true)

    let memorizedFiltersCopy = [...memorizedFilters];
    let filtersToSend = {}

    Object.keys(currentEdition.filters).map((key, i) => {
      filtersToSend[key] = []
      for (let filter of currentEdition.filters[key]) {
        let filterFound = memorizedFiltersCopy.find(el => (el.filterName === key && el.operator === filter.operator))
        if (filterFound) {
          filtersToSend[key].push({
            "operator": filterFound.operator,
            "value": filterFound.value
          })
        }
      }
    })

    let params = {
      'filters': filtersToSend,
      'force_regenerate': ((currentEdition.id == "ebatch" && isForceRegenerateChecked) ? true : false)
    }

    EditionService.getEditions(folderDetails?.dossier?._id, params, currentEdition.APIUrl, format ? format : null).then(res => {
      if (res.status == 204 || !res?.data) {
        setGlobalError({ statusText: `Aucun résultat n'a été trouvé (${res.statusText})` })
      } else {
        if (format) {
          getFile(res.data, currentEdition.id, format)
        }
      }
      format ? setIsDocumentDownloading(false) : setIsDataLoading(false)
    }).catch(error => {
      setGlobalError(error)
      format ? setIsDocumentDownloading(false) : setIsDataLoading(false)
    });
  }

  return (
    <Container fluid id="Editions">

      <ErrorManager error={globalError} fixed="true" />

      <Row>

        <Col sm="5" xxl="3" className="border-end pe-4 pt-4 height-full-min-80">

          <h5 className="ms-3">Exports comptable</h5>
          {exportsComptableList.map(exportComptable => (
            <div key={exportComptable.id} onClick={() => setCurrentEdition(exportComptable)} className={"cursor-pointer p-3 rounded mb-2 " + (currentEdition?.id == exportComptable.id ? "bg-primary-200 text-primary-900" : "hover-bg-primary-100")}>
              <h6>{exportComptable.label}</h6>
              <p className="m-0">{exportComptable.description}</p>
            </div>
          ))}

          <h5 className="ms-3 mt-2">Analyses de vente</h5>
          {analyseList.map(analyse => (
            <div key={analyse.id} onClick={() => setCurrentEdition(analyse)} className={"cursor-pointer p-3 rounded mb-2 " + (currentEdition?.id == analyse.id ? "bg-primary-200 text-primary-900" : "hover-bg-primary-100")}>
              <h6>{analyse.label}</h6>
              <p className="m-0">{analyse.description}</p>
            </div>
          ))}

        </Col>

        <Col sm="7" xxl="9" className="">
          {currentEdition?.filters &&
            <Row className="border-bottom p-4">
              <Col>
                <>
                  <div className="d-flex align-items-center mb-3">
                    <h6 className="me-auto">Filtres de recherche</h6>
                    {currentEdition.id == "ebatch" &&
                      <div className="me-3 bg-gray-100 rounded py-1 px-2">
                        <Form.Check
                          className="m-0"
                          type="checkbox"
                          defaultChecked={isForceRegenerateChecked}
                          onClick={() => setIsForceRegenerateChecked(!isForceRegenerateChecked)}
                          id={`id-force-regenerate`}
                          label="Re-générer en compta les factures déjà archivées"
                        />
                      </div>
                    }
                    <div>
                      <Button disabled={isDataLoading} variant="secondary" onClick={() => getEditions("raw")}>
                        {isDataLoading &&
                          <Spinner animation="border" size="sm" className="me-2" />
                        }
                        Afficher les résultats
                      </Button>
                      {currentEdition.id == "ebatch" ?
                        <Button disabled={isDocumentDownloading} className="ms-2" onClick={() => getEditions("json")}>
                          {isDocumentDownloading &&
                            <Spinner animation="border" size="sm" className="me-2" />
                          }
                          Télécharger le Json
                        </Button>
                        :
                        <Button disabled={isDocumentDownloading} className="ms-2" onClick={() => getEditions("pdf")}>
                          {isDocumentDownloading &&
                            <Spinner animation="border" size="sm" className="me-2" />
                          }
                          Télécharger le PDF
                        </Button>
                      }
                    </div>
                  </div>
                  {Object.keys(currentEdition.filters).map((key, i) => (
                    <span className="ps-2 pt-2 bg-gray-100 me-2 rounded d-inline-flex mb-2" key={key}>
                      {currentEdition.filters[key].map((filter, j) => (
                        <div key={j}>{getFilterInput(filter, key)}</div>
                      ))}
                    </span>
                  ))}
                </>
              </Col>
            </Row>
          }
          <Row className="p-4">
            <Col>
              {currentEdition &&
                <h4>{currentEdition.label}</h4>
              }
            </Col>
          </Row>
        </Col>
      </Row>

    </Container>

  );
}

export default Editions;