import { axiosAPI } from "./axiosClient"
import { UserService } from './UserService';
import { useParams, useSearchParams } from "react-router-dom";
import qs from "qs";
import { getParams } from '../helper/Helper'

export const EditionService = {

  getEditions(folderId, params, APIUrl) {
    return axiosAPI.get(`/${folderId}/editions/${APIUrl}?${getParams(params)}`)
  },

  getEditionsPDF(folderId, params, APIUrl) {
    //return axiosAPI.get(`/${folderId}/editions/ventes/factures?filter%5Bdate%5D=%3E2022-08-01%26%3C2022-08-31&format=pdf`, {responseType: 'blob'})
    return axiosAPI.get(`/${folderId}/editions/${APIUrl}?${getParams(params)}&format=pdf`, { responseType: 'blob' })
  },

  getFacture(folderId, factureId, format) {
    return axiosAPI.get(`/${folderId}/editions/factures/${factureId}?lookup=true&format=${format}`, { responseType: 'blob' })
  },

  getFactures(folderId, params) {
    return axiosAPI.get(`/${folderId}/editions/factures?format=pdf&${getParams(params)}`, { responseType: 'blob' })
  },

  getProforma(folderId, proformaId, format) {
    return axiosAPI.get(`/${folderId}/editions/proformas/${proformaId}?lookup=true&format=${format}`, { responseType: 'blob' })
  },

  getProformas(folderId, params) {
    return axiosAPI.get(`/${folderId}/editions/proformas?format=pdf&${getParams(params)}`, { responseType: 'blob' })
  },

  getAbonnement(folderId, abonnementId, format) {
    return axiosAPI.get(`/${folderId}/editions/abonnements/${abonnementId}?lookup=true&format=${format}`, { responseType: 'blob' })
  },

  getAbonnements(folderId, params) {
    return axiosAPI.get(`/${folderId}/editions/abonnements?format=pdf&${getParams(params)}`, { responseType: 'blob' })
  },

  getSendDocumentMail(folderId, documentType, documentId, emailAdress) {
    return axiosAPI.get(`/${folderId}/editions/${documentType}/${documentId}?lookup=true&email=${emailAdress}`, { responseType: 'blob' })
  },

  getSendDocumentMails(folderId, documentType, documentId, params) {
    return axiosAPI.get(`/${folderId}/editions/${documentType}/${documentId}?lookup=true&${getParams(params)}`, { responseType: 'blob' })
  },
  
  getReglement(folderId, reglementId, format) {
    return axiosAPI.get(`/${folderId}/editions/reglements/${reglementId}?lookup=true&format=${format}`, { responseType: 'blob' })
  },
  
  getReglements(folderId, params) {
    return axiosAPI.get(`/${folderId}/editions/reglements?format=pdf&${getParams(params)}`, { responseType: 'blob' })
  },

  getListe(folderId, params, docType, format) {
    return axiosAPI.get(`/${folderId}/editions/listes/${docType}?lookup=true&${getParams(params)}&format=${format}`, { responseType: 'blob' })
  },

  /* getListeArticles(folderId, params, familyId, format) {
    familyId ? (params['filter[famille_id]'] = familyId) : (params['filter[famille_id]'] = null)
    return axiosAPI.get(`/${folderId}/editions/listes/articles?lookup=true&${getParams(params)}&format=${format}`, {responseType: 'blob'})
  }, */

  // editions/listes/{collection:[a-z]+}[/{format:(pdf|html|xls)}] 
  /* getListeClients(folderId, params, , format) {
    familyId ? (params['filter[famille_id]'] = familyId) : (params['filter[famille_id]'] = null)
    return axiosAPI.get(`/${folderId}/editions/listes/articles?lookup=true&${getParams(params)}&format=${format}`, {responseType: 'blob'})
  }, */

  getBrouillon(folderId, brouillonId, format) {
    return axiosAPI.get(`/${folderId}/editions/brouillon/${brouillonId}?lookup=true&format=${format}`, { responseType: 'blob' })
  },

  getBrouillonByEmail(folderId, brouillonId, email) {
    return axiosAPI.get(`/${folderId}/editions/brouillon/${brouillonId}?lookup=true&format=pdf&email=${email}`, { responseType: 'blob' })
  },

}



/* 
/editions/factures[/{format}]
/editions/proforma[/{format}]

parametre email

parametre factures/proformas => tableau qui contient les _id des documents

idem pour reglements :
/editions/reglements[/{format}]

editions/listes/{collection}

envoyer les paramètres format et email

format = pdf|html|xls => si plusieurs docs, zip retourné sinon format demandé
 */

