import React, { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { InputGroup, FormControl, Container, Button, Dropdown, DropdownButton, Col, Row, Badge, OverlayTrigger, Tooltip, Toast, ToastContainer, Spinner } from 'react-bootstrap';

import { EditionService } from '../../services/EditionService';
import { FactureService } from '../../services/FactureService';

import DynamicTable from '../../components/table/dynamic-table/DynamicTable';
import Pagination from '../../components/pagination/Pagination';
import SideDetails from '../../components/side-details/SideDetails';
import FactureDetails from '../../components/side-details/facture/facture-details/FactureDetails';
//import FactureEdition from '../../components/side-details/facture/facture-edition/FactureEdition';
import ColManager from '../../components/table/dynamic-table/col-manager/ColManager';
import Families from '../../components/families/Families';
import './Factures.scss';
import { PreferenceService } from '../../services/PreferenceService';
import ConfirmDialog from '../../components/confirm-dialog/ConfirmDialog';
import ErrorManager from "../../components/error-manager/ErrorManager";
import SendMailDialog from "../../components/send-mail-dialog/SendMailDialog";
import Brouillons from "../../components/brouillons/Brouillons";
import { GlobalContext } from "../../services/GlobalProvider";
import { formatTableHeadersFromPrefs, granted, getFile } from '../../helper/Helper'


const Factures = () => {

  const { globalContextLoaded, preferences, parametres, folderDetails, grants, getPreferences } = React.useContext(GlobalContext);

  let facturesParams = useRef({
    'start': 0,
    'limit': null,
    'search': null,
    'filters': null,
    'sort': null,
    'order': null
  });

  let openTab = useRef();

  const navigate = useNavigate();

  const [globalError, setGlobalError] = useState()
  const [searchParams, setSearchParams] = useSearchParams();
  const [factures, setFactures] = useState()
  const [isGetFacturesLoading, setIsGetFacturesLoading] = useState()
  const [isPDFLoading, setIsPDFLoading] = useState()
  const [sideWidth, setSideWidth] = useState()
  const [tableHeaders, setTableHeaders] = useState()
  const [checkedElementIdList, setCheckedElementIdList] = useState([])
  const [currentPagination, setCurrentPagination] = useState(1)
  const [isTableFilterVisible, setIsTableFilterVisible] = useState(false)
  const [confirmDialog, setConfirmDialog] = useState({ show: false, dialogInfos: null })
  const [currentFacture, setCurrentFacture] = useState()
  const [sendMailDialog, setSendMailDialog] = useState({})

  let searchRef = useRef(null);

  const [tableRowElementActions] = useState([
    { icon: "edit", title: "Modifier la facture (crée un brouillon)", action: "editFacture" },
    { icon: "reply", title: "Faire un avoir (crée un brouillon)", action: "createAvoir", disableKeyValues: { 'type': ['avoir'] } },
    { icon: "file_copy", title: "Dupliquer (crée un brouillon)", action: "duplicateFacture", disableKeyValues: { 'type': ['avoir'] } },
    { icon: "article", title: "Convertir en proforma (crée un brouillon)", action: "convertToProforma", disableKeyValues: { 'type': ['avoir'] } },
    { icon: "credit_card", title: "Ajouter un règlement", action: "addReglement" },
    { icon: "print", title: "Imprimer la facture", action: "downloadPDF" },
    { icon: "email", title: "Envoyer par mail", action: "sendEmail" }
  ])

  const [factureElementActions] = useState([
    { icon: "edit", title: "Modifier la facture (crée un brouillon)", action: "editFacture" },
    { icon: "reply", title: "Faire un avoir (crée un brouillon)", action: "createAvoir", disableKeyValues: { 'type': ['avoir'] } },
    { icon: "file_copy", title: "Dupliquer (crée un brouillon)", action: "duplicateFacture", disableKeyValues: { 'type': ['avoir'] } },
    { icon: "article", title: "Convertir en proforma", action: "convertToProforma", disableKeyValues: { 'type': ['avoir'] } },
    { icon: "print", title: "Imprimer la facture", action: "downloadPDF" },
    { icon: "email", title: "Envoyer par mail", action: "sendEmail" }
  ])

  useEffect(() => {
    if (grants) {

      !grants.factures.valeur && setGlobalError({ statusText: `Factures : vous n'avez pas les droit d'accès à cette ressource.` })

      granted(grants, "factures.remove") && tableRowElementActions.push({ icon: "delete_forever", title: "Annuler", action: "removeFactureDialog" })
      granted(grants, "factures.remove") && factureElementActions.push({ icon: "delete_forever", title: "Annuler", action: "removeFactureDialog" })
    }
  }, [grants]);

  useEffect(() => {
    if (globalContextLoaded) {
      searchParams.get('factureId') && getFacture(searchParams.get('factureId'))
      setPreferences()
      getFactures()

      // Si un brouillon est en cours d'édition on redirige.
      if (!searchParams.get('factureId') && preferences.factures.currentDraft) {
        navigate(`/${folderDetails?.dossier?._id}/factures/brouillon?brouillonId=${preferences.factures.currentDraft}&from=factures`)
      }

    }
  }, [globalContextLoaded]);

  function setPreferences() {
    setTableHeaders(formatTableHeadersFromPrefs(preferences.factures['tableHeaders'], parametres.factures['fieldsLabel']))
    facturesParams.current['sort'] = preferences.factures['tableSort']
    facturesParams.current['order'] = preferences.factures['tableOrder']
    facturesParams.current['limit'] = preferences.factures['tableCount'] || 5
    facturesParams.current['fields'] = Object.keys(preferences.factures['tableHeaders']).join(',')
  }

  function getFacture(factureId) {
    FactureService.getFacture(folderDetails?.dossier?._id, factureId).then(res => {
      if (!res.data?.data) {
        setGlobalError({ statusText: res.statusText })
      } else {
        changeCurrentFacture(res.data.data)
      }
    }).catch(error => {
      setGlobalError(error)
    });
  }

  function getFactures() {
    setCheckedElementIdList([])
    if (granted(grants, "factures.consult")) {
      setIsGetFacturesLoading(true)
      FactureService.getFactures(folderDetails?.dossier?._id, facturesParams.current).then(res => {
        setFactures(res.data.data)
        setIsGetFacturesLoading(false)
      }).catch(error => {
        setIsGetFacturesLoading(false)
        setGlobalError(error)
      });
    }
  }

  function getFacturesPDF(idList) {
    if (granted(grants, "factures.consult")) {
      !idList && setIsPDFLoading(true)
      let params = {
        'start': 0,
        'search': facturesParams.current.search,
        'sort': facturesParams.current.sort,
        'order': facturesParams.current.order,
        'filters': facturesParams.current.filters
      }

      if (idList) {
        params['factures'] = idList
      }

      EditionService.getListe(folderDetails?.dossier?._id, params, "factures", "pdf").then(res => {
        setIsPDFLoading(false)
        if (!res?.data) {
          setGlobalError({ statusText: res.statusText })
        } else {
          getFile(res.data, `Factures - Liste`, "pdf")
        }
      }).catch(error => {
        setIsPDFLoading(false)
        setGlobalError(error)
      });
    }
  }

  function removeFactureList(factureIdList) {
    FactureService.removeFactures(folderDetails?.dossier?._id, factureIdList).then(res => {
      setConfirmDialog({ show: false });
      getFactures()
      changeCurrentFacture()
      setSideWidth()
      uncheckElementList(factureIdList)
    }).catch(err => {
      setConfirmDialog({
        ...confirmDialog,
        dialogInfos: { ...confirmDialog.dialogInfos, error: err }
      })
    });
  }

  function uncheckElementList(idList) {
    let checkedElementIdListCopy = [...checkedElementIdList];
    idList.map(id => {
      let index = checkedElementIdListCopy.indexOf(id)
      if (index >= 0) {
        checkedElementIdListCopy.splice(index, 1)
      }
    })
    setCheckedElementIdList(checkedElementIdListCopy)
  }

  function saveTableHeadersPrefs(e) {
    const newHeadersObject = {}
    e.forEach(object => {
      newHeadersObject[object['dataTarget']] = { position: object['position'], hidden: object['hidden'] }
    });
    updatePreference({ ["tableHeaders"]: newHeadersObject })
  }

  function updatePreference(prefs) {
    PreferenceService.updatePreferences(folderDetails?.dossier?._id, { prefs: { ['factures']: prefs } }).then(res => {
      getPreferences()
    }).catch(error => {
      setGlobalError(error)
    });
  }

  function changeCurrentFacture(facture) {
    openTab.current = null
    facture?._id ? setSearchParams({ factureId: facture._id }) : setSearchParams(searchParams.delete('factureId'))
    setCurrentFacture(facture)
  }

  function changeTableHeaders(e) {
    setTableHeaders(e)
    saveTableHeadersPrefs(e)
  }

  function changePagination(p) {
    setCurrentPagination(p)
    facturesParams.current['start'] = (facturesParams.current.limit * p) - facturesParams.current.limit
    getFactures()
  }

  function changeLimit(l) {
    facturesParams.current['limit'] = l
    updatePreference({ ["tableCount"]: l })
    changePagination(1)
  }

  let searchTimeOut;
  function changeSearchValue(value) {
    window.clearTimeout(searchTimeOut);
    searchTimeOut = window.setTimeout(function () {
      setCurrentPagination(1)
      facturesParams.current['start'] = 0
      facturesParams.current['search'] = value ? value : ""
      searchRef.current.value = value ? value : ""
      getFactures()
    }, 1000);
  }

  let filtersTimeOut;
  function changeFilters(f) {
    window.clearTimeout(filtersTimeOut);
    filtersTimeOut = window.setTimeout(function () {
      setCurrentPagination(1)
      facturesParams.current['start'] = 0
      facturesParams.current['filters'] = f
      getFactures()
    }, 1000);
  }

  function clearFilters() {
    setCurrentPagination(1)
    facturesParams.current['start'] = 0
    facturesParams.current['filters'] = null
    getFactures()
    setIsTableFilterVisible(false)
  }

  function changeSortOrder(s, o) {
    facturesParams.current['sort'] = s
    facturesParams.current['order'] = o
    updatePreference({ ["tableSort"]: s, ["tableOrder"]: o })
    getFactures()
  }

  function onFactureSaved(factureId) {
    getFacture(factureId)
    getFactures()
  }

  function onTabChange() {
    getFactures()
  }

  function closeSide() {
    setCurrentFacture()
  }

  function openBrouillon(brouillonId, documentType) {
    navigate(`/${folderDetails?.dossier?._id}/${documentType}/brouillon?brouillonId=${brouillonId}&from=factures`)
  }

  function createNewFacture() {
    let newFacture = {
      "type": "facture"
    }
    FactureService.postFacture(folderDetails?.dossier?._id, { factures: [newFacture] }).then(res => {
      for (var brouillonId in res.data.data.brouillons) {
        openBrouillon(brouillonId, "factures")
      }
    }).catch(error => {
      setGlobalError(error)
    });
  }

  function removeFactureListDialog(factureIdList) {
    setConfirmDialog({
      show: true,
      dialogInfos: {
        title: `Supprimer ${factureIdList.length} facture(s) ?`,
        description: 'Voulez vous supprimer les éléments sélectionnés ?',
        actionName: 'Supprimer la sélection',
        btnVariant: 'danger',
        element: factureIdList,
        error: false,
        confirmAction: 'removeFactureList'
      }
    })
  }

  function removeFactureDialog(facture) {
    setConfirmDialog({
      show: true,
      dialogInfos: {
        title: facture.nom,
        description: 'Voulez vous supprimer cet élément ?',
        actionName: 'Supprimer',
        btnVariant: 'danger',
        element: [facture._id],
        error: false,
        confirmAction: 'removeFactureList'
      }
    })
  }

  function downloadPDF(facture) {
    EditionService.getFacture(folderDetails?.dossier?._id, facture._id, "pdf").then(res => {

      // todo : utiliser le fileName retourné
      const filename = res.headers['content-disposition'].split('"')[1].replace(/\.[^/.]+$/, "");
      console.log('----filename')
      console.log(filename)

      if (!res.data) {
        setGlobalError({ statusText: res.statusText })
      } else {
        getFile(res.data, `Facture ${facture.code} - ${facture.client_nom}`, "pdf")
      }
    }).catch(error => {
      setGlobalError(error)
    });
  }

  function sendEmail(facture) {
    setSendMailDialog({
      "clientId": facture.client_id,
      "folderId": folderDetails?.dossier?._id,
      "documentType": "factures",
      "documentId": facture._id,
      "show": true
    })
  }

  function downloadCheckedFactures() {
    EditionService.getFactures(folderDetails?.dossier?._id, { factures: checkedElementIdList }).then(res => {
      if (!res.data) {
        setGlobalError({ statusText: res.statusText })
      } else {
        if (checkedElementIdList.length > 1) {
          getFile(res.data, `Factures`, "zip")
        } else {
          getFile(res.data, `Facture`, "pdf")
        }
      }
    }).catch(error => {
      setGlobalError(error)
    });
  }

  function addReglement(facture) {
    openTab.current = "reglements"
    facture?._id ? setSearchParams({ factureId: facture._id }) : setSearchParams(searchParams.delete('factureId'))
    setCurrentFacture(facture)
  }

  function createAvoir(facture) {
    FactureService.createAvoir(folderDetails?.dossier?._id, facture._id).then(res => {
      if (!res.data?.data) {
        setGlobalError({ statusText: res.statusText })
      } else {
        let brouillonId = Object.keys(res.data.data.brouillons)[0]
        openBrouillon(brouillonId, "factures")
      }
    }).catch(error => {
      setGlobalError(error)
    });
  }

  function editFacture(facture) {
    FactureService.updateFacture(folderDetails?.dossier?._id, facture._id).then(res => {
      if (!res.data?.data) {
        setGlobalError({ statusText: res.statusText })
      } else {
        let brouillonId = Object.keys(res.data.data.brouillons)[0]
        openBrouillon(brouillonId, "factures")
      }
    }).catch(error => {
      setGlobalError(error)
    });
  }

  function duplicateFacture(facture) {
    FactureService.duplicateFacture(folderDetails?.dossier?._id, facture._id).then(res => {
      if (!res.data?.data) {
        setGlobalError({ statusText: res.statusText })
      } else {
        let brouillonId = Object.keys(res.data.data.brouillons)[0]
        openBrouillon(brouillonId, "factures")
      }
    }).catch(error => {
      setGlobalError(error)
    });
  }

  function convertToProforma(facture) {
    FactureService.convertToProforma(folderDetails?.dossier?._id, facture._id).then(res => {
      if (!res.data?.data) {
        setGlobalError({ statusText: res.statusText })
      } else {
        let brouillonId = Object.keys(res.data.data.brouillons)[0]
        openBrouillon(brouillonId, "proformas")
      }
    }).catch(error => {
      setGlobalError(error)
    });
  }

  return (
    <Container fluid id="Factures" className="py-4">

      <div className="pb-2 d-flex align-items-center justify-content-between">
        <h4 className="">Factures</h4>
        <div>
          {/* Brouillons en cours */}
          <Brouillons brouillonTypes={["facture", "avoir"]} openBrouillon={b => openBrouillon(b, "factures")} />

          {granted(grants, "factures.edit") &&
            <Button variant="primary" className="ms-2" onClick={() => createNewFacture()}>Créer une facture</Button>
          }
        </div>
      </div>

      <ErrorManager error={globalError} fixed="true" />

      <SendMailDialog clientId={sendMailDialog.clientId} folderId={sendMailDialog.folderId} documentType={sendMailDialog.documentType} documentId={sendMailDialog.documentId} show={sendMailDialog.show} setCloseModal={() => setSendMailDialog({ show: false })} />

      <ConfirmDialog
        show={confirmDialog.show}
        dialogInfos={confirmDialog.dialogInfos}
        setCancel={() => setConfirmDialog({ show: false })}
        setConfirm={(a, e) => eval(a)(e)}
      />

      <Row>

        <Col xs="12">
          <Row>
            {granted(grants, "factures.consult") &&
              <>
                <Col xs="5" xl="3">
                  <InputGroup>
                    <i className="material-icons input-icon text-gray-400">search</i>
                    <FormControl
                      className="input-icon-space rounded-start"
                      placeholder="Recherche globale"
                      type="text"
                      onChange={e => changeSearchValue(e.target.value)}
                      ref={searchRef}
                    />
                    {facturesParams.current['search'] &&
                      <Button onClick={() => changeSearchValue()} variant="gray-100" className="border border-start-0">
                        <i className="material-icons i-lg">clear</i>
                      </Button>
                    }
                  </InputGroup>
                </Col>
                <Col className="d-flex">
                  {!facturesParams.current.filters &&
                    <OverlayTrigger placement="right" delay={{ show: 800 }} overlay={<Tooltip>Filtres de recherche</Tooltip>}>
                      <Button className="me-2" variant="secondary" onClick={() => setIsTableFilterVisible(!isTableFilterVisible)}>
                        <i className="material-icons i-lg">filter_alt</i>
                      </Button>
                    </OverlayTrigger>
                  }
                  {facturesParams.current.filters &&
                    <Button className="me-2" variant="warning" onClick={() => clearFilters()}>
                      Suppr. les filtres
                      <i className="material-icons i-lg ms-2 d-inline-flex">clear</i>
                    </Button>
                  }
                  <ColManager tableHeaders={tableHeaders} changeTableHeaders={e => changeTableHeaders(e)} />
                  <Button disabled={isPDFLoading} variant="secondary" className="me-2" onClick={() => getFacturesPDF()}>
                    {!isPDFLoading && <i className="material-icons i-lg float-start me-2">print</i>}
                    {isPDFLoading && <Spinner animation="border" size="sm" className="me-2" />}
                    Imprimer la liste ({factures?.total})
                  </Button>

                  {checkedElementIdList?.length > 0 &&
                    <Dropdown className="d-inline-block me-2">
                      <Dropdown.Toggle variant="dark" id="dropdown-selectionnes">
                        {checkedElementIdList.length} Sélectionné{checkedElementIdList?.length > 1 && <span>s</span>}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {/* <Dropdown.Item onClick={() => setCheckedElementIdList([])}>Tout désélectionner</Dropdown.Item> */}
                        <Dropdown.Item onClick={() => downloadCheckedFactures()}>Imprimer les factures</Dropdown.Item>

                        {granted(grants, "factures.remove") &&
                          <>
                            <Dropdown.Item onClick={() => getFacturesPDF(checkedElementIdList)}>Imprimer la liste sélectionnée</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item className="text-danger" onClick={() => removeFactureListDialog(checkedElementIdList)}>Supprimer</Dropdown.Item>
                          </>
                        }
                      </Dropdown.Menu>
                    </Dropdown>
                  }
                </Col>
              </>
            }
          </Row>

          {granted(grants, "factures.consult") &&
            <Row className="mt-3">
              <Col xs="12">
                <div className="p-3 bg-white rounded border overflow-auto">
                  <DynamicTable
                    isDataLoading={isGetFacturesLoading}
                    elementActions={tableRowElementActions}
                    setElementAction={(a, e) => eval(a)(e)}
                    sideWidth={sideWidth}
                    tableHeaders={tableHeaders}
                    tableDataList={factures?.factures}
                    currentElement={currentFacture}
                    changeCurrentElement={e => changeCurrentFacture(e)}
                    checkedElementIdList={checkedElementIdList}
                    setCheckedElementIdList={e => setCheckedElementIdList(e)}
                    changeFilters={f => changeFilters(f)}
                    isTableFilterVisible={isTableFilterVisible}
                    changeSortOrder={(s, o) => changeSortOrder(s, o)}
                    params={facturesParams.current}
                  />
                  <Pagination className="mt-3" itemsLength={factures?.total} elementsPerPage={facturesParams.current.limit} currentPagination={currentPagination} changePagination={p => changePagination(p)} showChangeLimit changeLimit={l => changeLimit(l)} />
                </div>
              </Col>
            </Row>
          }

          {currentFacture &&
            <SideDetails
              currentElement={{ id: currentFacture?._id, label: new Date(currentFacture?.date).toLocaleString(), title: currentFacture?.client_nom }}
              changeCurrentElement={e => changeCurrentFacture(e)}
              setSideClose={closeSide}
              setSideWidth={e => setSideWidth(e)}
              detailsComponent={
                <FactureDetails openTab={openTab.current} folderId={folderDetails?.dossier?._id} factureId={currentFacture._id} elementActions={factureElementActions} setElementAction={(a, e) => eval(a)(e)} onTabChange={() => onTabChange()} />
              }
            />
          }
        </Col>
      </Row>

    </Container>


  );
}

export default Factures;