import React, { useEffect, useState } from 'react';
import { TarifService } from '../../../../services/TarifService';
import { Form, Spinner, Card, Tabs, Tab, Row, Col, Accordion, Button, OverlayTrigger, Alert, Tooltip, Dropdown, Badge } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import ErrorManager from "../../../error-manager/ErrorManager";
import { getFormErrorMsg } from '../../../../helper/Helper'
//import './TarifDetails.scss';
//import Interlocuteurs from "./interlocuteurs/Interlocuteurs";
//import Factures from "./factures/Factures";
//import Proformas from "./proformas/Proformas";
//import Reglements from './reglements/Reglements';
import Familles from "./familles/Familles";
import Articles from "./articles/Articles";
import Clients from "./clients/Clients";
import { Editor, convertFromHTML, EditorState, createWithContent, convertFromRaw, ContentState, convertToRaw } from "draft-js";
import { markdownToDraft } from 'markdown-draft-js';

const TarifDetails = (props) => {

    let [openElement, setOpenElement] = useState("tarif")
    let [tarif, setTarif] = useState()
    let [tarifList, setTarifList] = useState([]);
    let [adressEditing, setAdressEditing] = useState()
    let [interlocuteurEditing, setInterlocuteurEditing] = useState()
    const [invalidData, setInvalidData] = useState()
    const [interlocuteurError, setInterlocuteurError] = useState()
    const [adressError, setAdressError] = useState()
    const [getTarifLoading, setGetTarifLoading] = useState()

    useEffect(() => {
        setTarif()
        getTarif()
        setOpenElement("tarif")
    }, [props.tarifId]);

    function handleTabSelect(key) {
        setOpenElement(key)
    }

    function getTarif() {
        setGetTarifLoading(true)
        TarifService.getTarif(props.folderId, props.tarifId).then(res => {
            setTarif(res.status == 200 ? res.data.data : { warning: res.statusText })
            setGetTarifLoading(false)
            res.data?.data?.tarif_id && getTarifs()
        }).catch(error => {
            setTarif({ error: error })
            setGetTarifLoading(false)
        });
    }

    function getTarifs() {
        TarifService.getTarifs(props.folderId).then(res => {
            setTarifList(res.data.data.tarifs)
        }).catch(error => {
            setTarif({ error: error })
        });
    }

    function Split({ title, value, border }) {
        return (
            <div className={"d-flex justify-content-between py-2 " + (border ? "border-bottom" : "")}>
                <span className="pe-4 text-gray-500">{title}</span>
                <span className="">{value}</span>
            </div>
        )
    }

    /*     function editInterlocuteur(i){
            // setInterlocuteurError()
            // setInvalidData()
            setInterlocuteurEditing(i)
        } */

    function editAdress(a) {
        // setAdressError()
        // setInvalidData()
        setAdressEditing(a)
    }

    return (
        <div id="TarifDetails">

            {(!tarif && getTarifLoading) &&
                <span className="hloader mt-3"></span>
            }

            {tarif?.warning &&
                <div className="d-inline-block w-100 mt-4">
                    <Alert variant="warning">
                        {tarif?.warning}
                    </Alert>
                </div>
            }

            {tarif?.error &&
                <div className="d-inline-block w-100 mt-4">
                    <ErrorManager error={tarif?.error} />
                </div>
            }

            {
                (tarif && !tarif.warning && !tarif.error) && (

                    <>
                        <div className="d-flex mb-4 align-items-center">
                            <div className="me-auto">
                                <h6 className="fw-bolder m-0">{tarif.code}</h6>
                                <h6 className="fw-bolder m-0 text-secondary">{tarif.libelle}</h6>
                            </div>
                            {props.elementActions.length > 0 &&
                                <Dropdown className="float-end ms-2 me-3">
                                    <OverlayTrigger
                                        placement="left"
                                        delay={{ show: 800 }}
                                        overlay={
                                            <Tooltip>
                                                Plus d'actions
                                            </Tooltip>
                                        }
                                    >
                                        <Dropdown.Toggle variant="secondary" className='no-btn-caret'>
                                            <i className="material-icons i-lg">more_horiz</i>
                                        </Dropdown.Toggle>
                                    </OverlayTrigger>
                                    <Dropdown.Menu>
                                        {props.elementActions.map((element) => (
                                            <Dropdown.Item key={element.title} onClick={() => props.setElementAction(element.action, tarif)}>{element.title}</Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            }
                        </div>

                        <div>

                            <Tabs variant="pills" defaultActiveKey="tarif" onSelect={handleTabSelect} className="mb-3">

                                <Tab eventKey="tarif" title="Tarif" className="light-v-scroll height-full-min-250 pe-2">
                                    {openElement == "tarif" &&
                                        <>
                                            <Split title="Type" border value={tarif.type} />
                                            <Split title="Code" border value={tarif.code} />
                                            <Split title="Libelle" border value={tarif.libelle} />
                                            <Split title="Portée" border value={tarif.portee} />
                                            <Split title="Date de création" border value={tarif.created_at && new Date(tarif.created_at).toLocaleString()} />
                                            <Split title="Date de début" border value={tarif.date_debut && new Date(tarif.date_debut).toLocaleString()} />
                                            <Split title="Date de fin" value={tarif.date_fin && new Date(tarif.date_fin).toLocaleString()} />
                                            <hr />
                                            <Split title="Conditions par défaut :" value="" />
                                            <div className="p-3 pt-0">
                                                <Split title="Taux remise" border value={tarif.taux_remise} />
                                                <Split title="Prix de vente HT" border value={tarif.prix_vente_ht} />
                                                <Split title="Prix de vente TTC" border value={tarif.prix_vente_ttc} />
                                                <Split title="Coef. Prix de revient" value={tarif.coefficient_pr} />
                                            </div>
                                        </>
                                    }
                                </Tab>

                                <Tab eventKey="familles" title="Familles" className="light-v-scroll height-full-min-250 pe-2">
                                    {openElement == "familles" &&
                                        <Familles folderId={props.folderId} tarif={tarif} familleSaved={() => getTarif()} />
                                    }
                                </Tab>

                                <Tab eventKey="articles" title="Articles" className="light-v-scroll height-full-min-250 pe-2">
                                    {openElement == "articles" &&
                                        <Articles folderId={props.folderId} tarif={tarif} articleSaved={() => getTarif()} />
                                    }
                                </Tab>

                                {tarif.type != "promotion" &&
                                    <Tab eventKey="clients" title="Clients" className="light-v-scroll height-full-min-250 pe-2">
                                        {openElement == "clients" &&
                                            <Clients folderId={props.folderId} tarif={tarif} articleSaved={() => getTarif()} />
                                        }
                                    </Tab>
                                }

                            </Tabs>

                        </div>

                    </>

                )
            }
        </div>

    )

}

export default TarifDetails;