import React, { useEffect, useState, useCallback, useRef } from "react";
import qs from "qs";

export function getFormErrorMsg(errData) {
    if (!errData) { return null }
    if (typeof errData === 'string' && errData.substring(0, 9) == "Erreur : ") {
        return errData.replace('Erreur : ', '')
    }
    if (Object.prototype.toString.call(errData) === '[object Array]') {
        let joinedErrors = ""
        for (let subElement of errData) {
            if (typeof subElement === 'string' && subElement.substring(0, 9) == "Erreur : ") {
                let str = subElement.replace('Erreur : ', '')
                joinedErrors += str + "\n"
            }
        }
        return joinedErrors.toString()
    }
    return null
}

export function getInvalidObject(data) {
    let object
    data?.map(d => {
        if (d._id) {
            object = d
        }
    })
    return object
}



// Used for schedule (for exemple), convert a decimal value to an array of month (ex : 4095 -> [{libelle: "Janv.", value: true},{libelle: "Fév.", value: false}...])
export function getMonthArrayFromDecimal(decimalValue) {

    if (!decimalValue) { return }

    const scheduledMonthMap = [
        {
            libelle: 'Janv.',
            libelleSm: 'J',
            value: '1'
        },
        {
            libelle: 'Févr.',
            libelleSm: 'F',
            value: '2'
        },
        {
            libelle: 'Mars',
            libelleSm: 'M',
            value: '4'
        },
        {
            libelle: 'Avr.',
            libelleSm: 'A',
            value: '8'
        },
        {
            libelle: 'Mai',
            libelleSm: 'M',
            value: '16'
        },
        {
            libelle: 'Juin',
            libelleSm: 'J',
            value: '32'
        },
        {
            libelle: 'Juill.',
            libelleSm: 'J',
            value: '64'
        },
        {
            libelle: 'Août',
            libelleSm: 'A',
            value: '128'
        },
        {
            libelle: 'Sept.',
            libelleSm: 'S',
            value: '256'
        },
        {
            libelle: 'Oct.',
            libelleSm: 'O',
            value: '512'
        },
        {
            libelle: 'Nov.',
            libelleSm: 'N',
            value: '1024'
        },
        {
            libelle: 'Déc.',
            libelleSm: 'D',
            value: '2048'
        }
    ]

    let binary = decimalValue.toString(2);
    let result = [];
    let monthArray = []

    for (let i = 0; i < binary.length; i++) {
        if (binary[i] === '1') {
            let decimalValue = Math.pow(2, binary.length - 1 - i);
            result.push(decimalValue.toString());
        }
    }
    scheduledMonthMap?.map((month, index) => {
        if (result.indexOf(month.value) > -1) {
            monthArray.push({
                libelle: [month.libelle],
                libelleSm: [month.libelleSm],
                value: true
            })
        } else {
            monthArray.push({
                libelle: [month.libelle],
                libelleSm: [month.libelleSm],
                value: false
            })
        }
    })
    return monthArray
}

// Force input type["number"] to allow keys by regex validation. (Used for Firefox)
export function forceInputTypeNumber() {
    window.addEventListener("keydown", function (e) {

        const regex = new RegExp(/(^\d*\.?\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight|ArrowUp|ArrowDown)/)
        var key = e.which || e.keyCode; // keyCode detection
        var ctrl = e.ctrlKey ? e.ctrlKey : ((key === 17) ? true : false); // ctrl detection

        if (e.target.type == "number") {
            if (key == 86 && ctrl) { // Ctrl + V Pressed
                return !e.target.value.match(regex) && e.preventDefault();
            } else {
                return !e?.key?.match(regex) && e.preventDefault();
            }
        }

    }, false);
}


export function formatTableHeadersFromPrefs(tableHeadersPrefs, tableHeadersParams) {

    let formattedTableHeaders = []

    var deep_value = function (obj, path) {
        for (var i = 0, path = path.split('.'), len = path.length; i < len; i++) {
            obj = obj ? obj[path[i]] : null;
        };
        return obj;
    };

    Object.entries(tableHeadersPrefs)?.map(([key, value]) => {


        let thParam = deep_value(tableHeadersParams, key)

        // label: "Prix Hors Taxe", // nom de la colone
        // colSize: 1, // largeur de la colonne par rapport aux autres
        // hidden: true, // la colonne est masquée par défaut mais peut être activée dans l'interface
        // dataTarget: "prix_vente_ht" // nom de la data retournée par l'api.
        // align: 'right', // aligne les éléments de la colone à gauche ou à droite, pratique pour les sommes par exemple.
        // type: "string", // type de la data (string, number, tags)
        // filters: true, // si les filtres sont applicables sur cette data (par exemple les filtres sont impossibles sur les data "join" ex: article.famille.code)
        // sortable: true, // si il est possible d'appliquer un "sort / order" sur la data (impossible par exemple d'ordonner sur les data jointes, ex: article.famille.code)


        let tableHeaderElement = {
            label: thParam?.title || `${key}`,
            dataTarget: key,
            type: value.displayType,
            hidden: value.hidden,
            position: value.position,
            align: value.align,
            colSize: value.colSize,
            filters: value.filters,
            sortable: value.sortable
        }

        formattedTableHeaders.push(tableHeaderElement)

    })

    formattedTableHeaders.sort(function (a, b) {
        return parseFloat(a.position) - parseFloat(b.position);
    });

    return formattedTableHeaders
}

export function useOuterClick(callback) {
    /* // Comment l'utiliser :
    const outerClick = useOuterClick(ev => {
        console.log('OUT !')
    });
    <div ref={outerClick}>
    */
    const callbackRef = useRef();
    const innerRef = useRef();
    useEffect(() => { callbackRef.current = callback; });
    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => document.removeEventListener("mousedown", handleClick);
        function handleClick(e) {
            if (innerRef.current && callbackRef.current && !innerRef.current.contains(e.target)) callbackRef.current(e);
        }
    }, []);
    return innerRef;
}

// Reorder the list items
export function reorder(list, initialIndex, destinationIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(initialIndex, 1);
    result.splice(destinationIndex, 0, removed);
    return result;
};

export function sortByNumber(details, champ) {
    return details.sort((a, b) => a[champ] - b[champ]);
}

export function getChangedValues(obj1, obj2, exclude, maintainIfEqual, returnObj1Values) {

    var returnedObject = {};

    if (!exclude) exclude = [];

    for (var prop in obj1) {
        if (obj1.hasOwnProperty(prop) && prop != '__proto__') {


            if (exclude?.indexOf(prop) == -1) {

                // if props are equal and mentionned in maintainIfEqual array
                if ((obj2[prop] == obj1[prop]) && maintainIfEqual?.indexOf(prop) > -1) returnedObject[prop] = obj1[prop]

                // check if obj2 has prop
                if (!obj2.hasOwnProperty(prop)) returnedObject[prop] = obj1[prop];

                // check if prop is object and 
                // NOT a JavaScript engine object (i.e. __proto__), if so, recursive diff
                else if (obj1[prop] === Object(obj1[prop])) {
                    var difference = getChangedValues(obj1[prop], obj2[prop]);
                    if (Object.keys(difference).length > 0) returnedObject[prop] = difference;
                }

                // check if obj1 and obj2 are equal
                else if (obj1[prop] !== obj2[prop]) {
                    if (obj1[prop] === undefined)
                        returnedObject[prop] = 'undefined';
                    if (obj1[prop] === null)
                        returnedObject[prop] = null;
                    else if (typeof obj1[prop] === 'function')
                        returnedObject[prop] = 'function';
                    else if (typeof obj1[prop] === 'object')
                        returnedObject[prop] = 'object';
                    else
                        returnedObject[prop] = obj1[prop];
                }

                // return complete object, not only differences between obj1 and obj2
                if (returnObj1Values?.indexOf(prop) > -1) {
                    returnedObject[prop] = obj1[prop]
                }
            }
        }

    }

    return returnedObject;
}

export function granted(grants, path) {

    let properties = path.split(".")

    for (let index = 0; index < properties.length; index++) {

        var cutArray = path.split(".")
        cutArray.length = index + 1
        let valuePath = cutArray.join(".") + ".valeur"

        if (deepFind(grants, valuePath) == false) {
            return false
        }

    }
    return true

}

// Utilisé par les services pour parser les params transmis dans l'URL
export function getParams(paramsList) {
    const params = {}
    if (paramsList) {
        for (const [key, value] of Object.entries(paramsList)) {

            (value !== null && key != 'filters') && (params[`${key}`] = value)

            if (key == 'filters' && value) {
                for (const filter in value) {
                    let operations = []
                    value[filter].map(f => operations.push(f.operator + f.value))
                    params[filter] = operations.join("&")
                }
            }
        }
    }
    return qs.stringify(params)
}

// Formatte les valeur avec un espace tous les 3 caractères
export function currencyFormat(value) {
    return value?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
}

// renvoie true ou false selon que la couleur soit foncée ou claire
export function isDark(hexColour) {
    var c = hexColour.substring(1);      // strip #
    var rgb = parseInt(c, 16);   // convert rrggbb to decimal
    var r = (rgb >> 16) & 0xff;  // extract red
    var g = (rgb >> 8) & 0xff;  // extract green
    var b = (rgb >> 0) & 0xff;  // extract blue

    var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

    if (luma > 200) {
        return true
    }

    return false
}

// Ajouter ou changer l'élément de l'objet via un chemin spécifique
export function updateObjectValue(obj, path, val) {

    // https://gomakethings.com/adding-items-to-an-object-at-a-specific-path-with-vanilla-js/
    // updateObjectValue(lunch, 'sandwich.toppings[]', 'mayo');
    // updateObjectValue(lunch, 'sides.chips', 'Cape Cod');
    // updateObjectValue(lunch, 'sides.cookie', true);

    function stringToPath(path) {
        if (typeof path !== 'string') return path;
        let output = [];
        path.split('.').forEach(function (item) {
            item.split(/\[([^}]+)\]/g).forEach(function (key) {
                if (key.length > 0) {
                    output.push(key);
                }
            });
        });
        return output;
    }

    path = stringToPath(path);
    let length = path.length;
    let current = obj;

    path.forEach(function (key, index) {
        let isArray = key.slice(-2) === '[]';
        key = isArray ? key.slice(0, -2) : key;
        if (isArray && !Array.isArray(current[key])) {
            current[key] = [];
        }
        if (index === length - 1) {
            if (isArray) {
                current[key].push(val);
            } else {
                current[key] = val;
            }
        }
        else {
            if (!current[key]) {
                current[key] = {};
            }
            current = current[key];
        }
    });

}

export function deepFind(obj, path) { // trouver une valeur dans un Objet via son "path" 

    /* console.log('-------------------------obj / path')
    console.log(obj)
    console.log(path) */

    if (!obj || !path) { return null }

    let paths = path.split('.')
    let current = obj

    for (let i = 0; i < paths.length; ++i) {
        if (current[paths[i]] == undefined) {
            return undefined;
        } else {
            current = current[paths[i]]
        }
    }
    return current;
}

export function formattedDateInput(date) { // retourne la date formattée pour un input type 'date'
    if (date) {
        return new Date(new Date(date).getTime() + Math.abs(new Date(date).getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
    }
}

export function getBase64(file, cb) {
    /*
        let imgB64
        getBase64(e.target.files[0], (result) => {
            imgB64 = result
        });
    */
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        cb(reader.result)
    };
    reader.onerror = function (err) {
        console.log(err);
    };
}

export function getFile(data, filename, type) {
    let fileURL = undefined
    if (type == "pdf") {
        fileURL = window.URL.createObjectURL(data);
    }
    if (type == "json") {
        const jsonString = JSON.stringify(data, null, 2);
        const blob = new Blob([jsonString], { type: 'application/json' });
        fileURL = window.URL.createObjectURL(blob);
    }
    let alink = document.createElement('a');
    alink.href = fileURL;
    filename = filename + '.' + type
    alink.download = filename;
    alink.click();
}

export function setCookie(name, value, hours, domain) {
    let secure = true
    let samesite = "Lax" // None Lax Strict
    let path = "/"
    let httponly = false
    var date = new Date();
    date.setTime(date.getTime() + (hours * 60 * 60 * 1000));
    document.cookie = `${name}=${value || ''}; domain=${domain}; path=${path}; expires=${date.toUTCString()}; SameSite=${samesite}; ${secure ? 'Secure' : ''}; ${httponly ? 'HttpOnly' : ''}; `;
}

export function getCookie(name) {
    let cookieArr = document.cookie.split(";");
    for (let i = 0; i < cookieArr.length; i++) {
        let cookiePair = cookieArr[i].split("=");
        if (name == cookiePair[0].trim()) {
            return decodeURIComponent(cookiePair[1]);
        }
    }
    return null;
}

export function getDomainFromUrl(url) {
    let domain = (new URL(url));
    return (domain.host)
}

/* export function getCompressedFile(data, filename) {
    let elm = document.createElement('a');  // CREATE A LINK ELEMENT IN DOM
    elm.href = URL.createObjectURL(data);  // SET LINK ELEMENTS CONTENTS
    elm.setAttribute('download', filename + '.zip'); // SET ELEMENT CREATED 'ATTRIBUTE' TO DOWNLOAD, FILENAME PARAM AUTOMATICALLY
    elm.click()
} */