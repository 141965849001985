// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#Abonnements .generate-dropdown {
  min-width: 300px;
}
#Abonnements .generate-dropdown {
  min-width: 300px;
}
#Abonnements .mois-abo-dropdown {
  min-width: 240px;
}`, "",{"version":3,"sources":["webpack://./src/pages/abonnements/Abonnements.scss"],"names":[],"mappings":"AACI;EACI,gBAAA;AAAR;AAEI;EACI,gBAAA;AAAR;AAEI;EACI,gBAAA;AAAR","sourcesContent":["#Abonnements{\n    .generate-dropdown{\n        min-width: 300px;\n    }\n    .generate-dropdown{\n        min-width: 300px;\n    }\n    .mois-abo-dropdown{\n        min-width: 240px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
