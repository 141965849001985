module.exports = global.config = {
  "_DEBUG_" : false,

  // URL api stockweb
//  "_API_URL_" : "http://stockxl-dev.vpn.xlnet.nc:8078", //DEV
  "_API_URL_" : "https://api.xlprog.nc/stockweb/v0", //PROD

  // URL du serveur OAuth partie publique
//  "_OAUTH_PUBLIC_API_" : "http://loginxl-dev.vpn.xlnet.nc:4444", //DEV
  "_OAUTH_PUBLIC_API_" : "https://oauth.xlprog.nc", //PROD


  // Infos du client OAuth
  "_OAUTH_CLIENT_ID_" : "3d1178bf-d945-42c0-86e9-c31ab1783697", //DEV ET PROD
  "_OAUTH_CLIENT_SECRET_" : "secret"
};
