// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#TagsDialog .color-dot {
  display: inline-flex;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
#TagsDialog .color-container {
  right: 0;
  left: 30%;
}
#TagsDialog .color-container .color-group {
  width: 5.55%;
}
#TagsDialog .color-container .color-group .color-cell {
  width: 100%;
  height: 18px;
  display: block;
}
#TagsDialog .color-container .color-group .color-cell:hover {
  box-shadow: inset 0px 0px 1px 2px white;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/tags-dialog/TagsDialog.scss"],"names":[],"mappings":"AACI;EACI,oBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;AAAR;AAGI;EACI,QAAA;EACA,SAAA;AADR;AAGQ;EACI,YAAA;AADZ;AAGY;EACI,WAAA;EACA,YAAA;EACA,cAAA;AADhB;AAGgB;EACI,uCAAA;EACA,eAAA;AADpB","sourcesContent":["#TagsDialog {\n    .color-dot {\n        display: inline-flex;\n        width: 15px;\n        height: 15px;\n        border-radius: 50%;\n    }\n\n    .color-container {\n        right: 0;\n        left: 30%;\n\n        .color-group {\n            width: 5.55%;\n\n            .color-cell {\n                width: 100%;\n                height: 18px;\n                display: block;\n\n                &:hover {\n                    box-shadow: inset 0px 0px 1px 2px white;\n                    cursor: pointer;\n                }\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
