import React, { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { Table, Badge, InputGroup, Alert, FormControl, Container, Button, Dropdown, DropdownButton, Form, Col, Row, Spinner, ListGroup, Tooltip, Nav, Tab } from 'react-bootstrap';

import { ArticleService } from '../../services/ArticleService';
import { FournisseurService } from '../../services/FournisseurService';
import { FactureService } from '../../services/FactureService';
import { ParametreService } from '../../services/ParametreService';
import { PreferenceService } from '../../services/PreferenceService';
import { UsersProfilService } from '../../services/UsersProfilService';
import { ClientService } from '../../services/ClientService';
import { GlobalContext } from "../../services/GlobalProvider";
import { getFormErrorMsg, getInvalidObject, getChangedValues, updateObjectValue, granted } from '../../helper/Helper'
import ConfirmDialog from '../../components/confirm-dialog/ConfirmDialog';

import PrefsParams from './prefs-params/PrefsParams'
import UsersProfils from './users-profils/UsersProfils'
import ModesReglements from './modes-reglements/ModesReglements'
import Contributions from './contributions/Contributions'
import Editions from './editions/Editions'

import './Administration.scss';

export default function Administration() {

    let [openElement, setOpenElement] = useState("params-dossier")
    let [isConfirmationNeeded, setIsConfirmationNeeded] = useState(false)
    const [confirmDialog, setConfirmDialog] = useState({ show: false, dialogInfos: null })


    const { grants, apiVersion } = React.useContext(GlobalContext);

    let fields = ["general", "articles", "brouillons", "clients", "contributions", "factures", "familles", "fournisseurs", "proformas", "abonnements", "reglements", "tags", "tarifs"]

    function handleTabSelect(key) {
        if (isConfirmationNeeded) {
            setConfirmDialog({
                show: true,
                dialogInfos: {
                    title: `Quitter sans enregistrer ?`,
                    description: `Voulez vous quitter la page sans enregistrer les modifications apportées ?`,
                    actionName: 'Quitter',
                    btnVariant: 'danger',
                    element: key,
                    error: false,
                    confirmAction: 'changeOpenElement'
                }
            })
        } else {
            setOpenElement(key)
        }
    }

    function changeOpenElement(key) {
        setOpenElement(key)
        setConfirmDialog({ show: false });
        setIsConfirmationNeeded(false)
    }

    return (
        <>

            <ConfirmDialog
                show={confirmDialog.show}
                dialogInfos={confirmDialog.dialogInfos}
                setCancel={() => setConfirmDialog({ show: false })}
                setConfirm={(a, e) => eval(a)(e)}
            />

            <div id="Administration" className="container-fluid">

                <Row>

                    <Col sm={3} xl={2}>
                        <div className="height-full-min-80 pt-5 pb-3">
                            <div className="light-v-scroll d-flex flex-column ps-2">

                                {granted(grants, "admin.access_utils") &&
                                    <>
                                        <h6 className="d-flex"><i className="material-icons me-2 d-flex">lock</i>Paramètres</h6>
                                        <span onClick={() => handleTabSelect("params-dossier")} className={"d-flex ms-3 px-2 py-1 rounded cursor-pointer " + (openElement == "params-dossier" ? "bg-primary-200" : "hover-bg-gray-100")}>Dossier</span>
                                        <span onClick={() => handleTabSelect("params-userprofil")} className={"d-flex ms-3 px-2 py-1 rounded cursor-pointer " + (openElement == "params-userprofil" ? "bg-primary-200" : "hover-bg-gray-100")}><i className="material-icons me-1">subdirectory_arrow_right</i>Profils utilisateur</span>
                                        <h6 className="d-flex mt-3"><i className="material-icons me-2 d-flex">settings</i>Préférences</h6>
                                        <span onClick={() => handleTabSelect("prefs-dossier")} className={"d-flex ms-3 px-2 py-1 rounded cursor-pointer " + (openElement == "prefs-dossier" ? "bg-primary-200" : "hover-bg-gray-100")}>Dossier</span>
                                        <span onClick={() => handleTabSelect("prefs-userProfil")} className={"d-flex ms-3 px-2 py-1 rounded cursor-pointer " + (openElement == "prefs-userProfil" ? "bg-primary-200" : "hover-bg-gray-100")}><i className="material-icons me-1">subdirectory_arrow_right</i>Profils utilisateur</span>
                                        <span onClick={() => handleTabSelect("prefs-user")} className={"d-flex ms-3 px-2 py-1 rounded cursor-pointer " + (openElement == "prefs-user" ? "bg-primary-200" : "hover-bg-gray-100")}><i className="material-icons ms-3 me-1">subdirectory_arrow_right</i>Vos préférences</span>
                                    </>
                                }

                                {granted(grants, "admin.admin_dossier") &&
                                    <>
                                        <h6 className="d-flex mt-3"><i className="material-icons me-2 d-flex">person</i>Utilisateurs</h6>
                                        <span onClick={() => handleTabSelect("users-profils")} className={"d-flex ms-3 px-2 py-1 rounded cursor-pointer " + (openElement == "users-profils" ? "bg-primary-200" : "hover-bg-gray-100")}>Profils & utilisateurs</span>
                                    </>
                                }

                                <>
                                    <h6 className="d-flex mt-3"><i className="material-icons me-2 d-flex">payment</i>Financier</h6>
                                    <span onClick={() => handleTabSelect("modes-reglements")} className={"d-flex ms-3 px-2 py-1 rounded cursor-pointer " + (openElement == "modes-reglements" ? "bg-primary-200" : "hover-bg-gray-100")}>Modes de règlements</span>
                                    <span onClick={() => handleTabSelect("contributions")} className={"d-flex ms-3 px-2 py-1 rounded cursor-pointer " + (openElement == "contributions" ? "bg-primary-200" : "hover-bg-gray-100")}>Contributions</span>
                                </>

                                <>
                                    <h6 className="d-flex mt-3"><i className="material-icons me-2 d-flex">newspaper</i>Editions</h6>
                                    <span onClick={() => handleTabSelect("edition-theme")} className={"d-flex ms-3 px-2 py-1 rounded cursor-pointer " + (openElement == "edition-theme" ? "bg-primary-200" : "hover-bg-gray-100")}>Theme</span>
                                    <span onClick={() => handleTabSelect("edition-facturation-commun")} className={"d-flex ms-3 px-2 py-1 rounded cursor-pointer " + (openElement == "edition-facturation-commun" ? "bg-primary-200" : "hover-bg-gray-100")}>Facturation</span>
                                    <span onClick={() => handleTabSelect("edition-facturation-factures")} className={"d-flex ms-3 px-2 py-1 rounded cursor-pointer " + (openElement == "edition-facturation-factures" ? "bg-primary-200" : "hover-bg-gray-100")}><i className="material-icons me-1">subdirectory_arrow_right</i>Factures</span>
                                    <span onClick={() => handleTabSelect("edition-facturation-proformas")} className={"d-flex ms-3 px-2 py-1 rounded cursor-pointer " + (openElement == "edition-facturation-proformas" ? "bg-primary-200" : "hover-bg-gray-100")}><i className="material-icons me-1">subdirectory_arrow_right</i>Proformas</span>
                                    <span onClick={() => handleTabSelect("edition-facturation-abonnements")} className={"d-flex ms-3 px-2 py-1 rounded cursor-pointer " + (openElement == "edition-facturation-abonnements" ? "bg-primary-200" : "hover-bg-gray-100")}><i className="material-icons me-1">subdirectory_arrow_right</i>Abonnements</span>
                                    <span onClick={() => handleTabSelect("edition-facturation-recus")} className={"d-flex ms-3 px-2 py-1 rounded cursor-pointer " + (openElement == "edition-facturation-recus" ? "bg-primary-200" : "hover-bg-gray-100")}><i className="material-icons me-1">subdirectory_arrow_right</i>Reçus</span>
                                    <span onClick={() => handleTabSelect("edition-listes")} className={"d-flex ms-3 px-2 py-1 rounded cursor-pointer " + (openElement == "edition-listes" ? "bg-primary-200" : "hover-bg-gray-100")}>Listes</span>
                                </>

                                <span className="d-block text-gray-500 mt-4 ms-2">API {apiVersion}</span>

                            </div>
                        </div>
                    </Col>

                    <Col sm={9} xl={10}>

                        {openElement == "params-dossier" &&
                            <PrefsParams
                                setIsConfirmationNeeded={val => setIsConfirmationNeeded(val)}
                                source="dossier"
                                apiObject="parametres"
                                fieldList={fields}
                                getListeService={ParametreService.getParametresListe}
                                getFolderService={ParametreService.getParametresDossier}
                                //getUsersProfilService={ParametreService.getParametresUsersProfil}
                                //getService={ParametreService.getPreferences}
                                removeService={ParametreService.removeParametresDossier}
                                updateService={ParametreService.updateParametresDossier}
                            />
                        }
                        {openElement == "params-userprofil" &&
                            <PrefsParams
                                setIsConfirmationNeeded={val => setIsConfirmationNeeded(val)}
                                source="userprofil"
                                apiObject="parametres"
                                fieldList={fields}
                                getListeService={ParametreService.getParametresListe}
                                getFolderService={ParametreService.getParametresDossier}
                                getUserProfilService={ParametreService.getParametresUserProfil}
                                //getService={ParametreService.getPreferences}
                                removeService={ParametreService.removeParametresUserProfil}
                                updateService={ParametreService.updateParametresUserProfil}
                            />
                        }

                        {openElement == "prefs-dossier" &&
                            <PrefsParams
                                setIsConfirmationNeeded={val => setIsConfirmationNeeded(val)}
                                source="dossier"
                                apiObject="preferences"
                                fieldList={fields}
                                getListeService={PreferenceService.getPreferencesListe}
                                getFolderService={PreferenceService.getPreferencesDossier}
                                //getUsersProfilService={PreferenceService.getPreferencesUsersProfil}
                                //getService={PreferenceService.getPreferences}
                                removeService={PreferenceService.removePreferencesDossier}
                                updateService={PreferenceService.updatePreferencesDossier}
                            />
                        }
                        {openElement == "prefs-userProfil" &&
                            <PrefsParams
                                setIsConfirmationNeeded={val => setIsConfirmationNeeded(val)}
                                source="userprofil"
                                apiObject="preferences"
                                fieldList={fields}
                                getListeService={PreferenceService.getPreferencesListe}
                                getFolderService={PreferenceService.getPreferencesDossier}
                                getUserProfilService={PreferenceService.getPreferencesUserProfil}
                                //getService={PreferenceService.getPreferences}
                                removeService={PreferenceService.removePreferencesUserProfil}
                                updateService={PreferenceService.updatePreferencesUserProfil}
                            />
                        }
                        {openElement == "prefs-user" &&
                            <PrefsParams
                                setIsConfirmationNeeded={val => setIsConfirmationNeeded(val)}
                                source="user"
                                apiObject="preferences"
                                fieldList={fields}
                                getListeService={PreferenceService.getPreferencesListe}
                                getFolderService={PreferenceService.getPreferencesDossier}
                                getUsersProfilService={PreferenceService.getPreferencesUsersProfil}
                                getService={PreferenceService.getPreferences}
                                removeService={PreferenceService.removePreferences}
                                updateService={PreferenceService.updatePreferences}
                            />
                        }

                        {openElement == "users-profils" &&
                            <UsersProfils setIsConfirmationNeeded={val => setIsConfirmationNeeded(val)} />
                        }

                        {openElement == "modes-reglements" &&
                            <ModesReglements setIsConfirmationNeeded={val => setIsConfirmationNeeded(val)} />
                        }
                        {openElement == "contributions" &&
                            <Contributions setIsConfirmationNeeded={val => setIsConfirmationNeeded(val)} />
                        }

                        {openElement == "edition-theme" &&
                            <Editions setIsConfirmationNeeded={val => setIsConfirmationNeeded(val)} tplTitle={"Personnalisation globale"} tplType="factures" paramsPath={"editions.global"} />
                        }
                        {openElement == "edition-facturation-commun" &&
                            <Editions setIsConfirmationNeeded={val => setIsConfirmationNeeded(val)} tplTitle={"Templates communs"} tplType="factures" paramsPath={"editions.facturation.commun"} />
                        }
                        {openElement == "edition-facturation-factures" &&
                            <Editions setIsConfirmationNeeded={val => setIsConfirmationNeeded(val)} tplTitle={"Personnalisez vos factures"} tplType="factures" paramsPath={"editions.facturation.factures"} />
                        }
                        {openElement == "edition-facturation-proformas" &&
                            <Editions setIsConfirmationNeeded={val => setIsConfirmationNeeded(val)} tplTitle={"Personnalisez vos proformas"} tplType="proformas" paramsPath={"editions.facturation.proformas"} />
                        }
                        {openElement == "edition-facturation-abonnements" &&
                            <Editions setIsConfirmationNeeded={val => setIsConfirmationNeeded(val)} tplTitle={"Personnalisez vos abonnements"} tplType="abonnements" paramsPath={"editions.facturation.abonnements"} />
                        }
                        {openElement == "edition-facturation-recus" &&
                            <Editions setIsConfirmationNeeded={val => setIsConfirmationNeeded(val)} tplTitle={"Personnalisez vos reçus"} tplType="recus" paramsPath={"editions.facturation.recus"} />
                        }
                        {openElement == "edition-listes" &&
                            <Editions setIsConfirmationNeeded={val => setIsConfirmationNeeded(val)} tplTitle={"Personnalisez les listes de documents"} tplType="listes" paramsPath={"editions.listes"} />
                        }
                    </Col>
                </Row>
            </div>
        </>
    );
}