import React, { useEffect, useState } from "react";
import { Container, Button, Dropdown, DropdownButton, Form, Col, Row, Accordion, Label, Modal, Spinner, Tooltip, OverlayTrigger, Alert } from 'react-bootstrap';
import { FamilyService } from '../../../services/FamilyService';
import './FamilyInput.scss';

const FamilyInput = (props) => {

    const [parentFamily, setParentFamily] = useState();
    const [treeFamilyList, setTreeFamilyList] = useState()
    const [closedFamilies, setClosedFamilies] = useState([])
    const [isDropDownClose, setIsDropDownClose] = useState(true)

    useEffect(() => {
        getTreeFamilies()
    }, []);

    useEffect(() => {
        if (treeFamilyList && props.familyId) {
            // si on a une famille dans les props, on la cherche dans l'arborescence pour la définir
            treeFamilyList.map(f => {
                if(searchIdInFamilyTree(f, props.familyId)){
                    setParentFamily(searchIdInFamilyTree(f, props.familyId))
                }
            })
        }
    }, [props.familyId, treeFamilyList]);

    function openClose(familyId) {
        let closedFamiliesCopy = [...closedFamilies];
        const index = closedFamiliesCopy.indexOf(familyId);
        index > -1 ? closedFamiliesCopy.splice(index, 1) : closedFamiliesCopy.push(familyId)
        setClosedFamilies(closedFamiliesCopy)
    }

    function changeParent(family) {
        setParentFamily(family)
        props.setFamilyId({
            target: {
                name: props.name,
                value: family?._id ? family._id : null,
                family: family?._id ? family : null
            }
        })
        setIsDropDownClose(true)
    }

    function getTreeFamilies() {
        FamilyService.getTreeFamilies(props.folderId).then(res => {
            setTreeFamilyList(res.data.data.familles)
        }).catch(err => {
            console.log(err)
        });
    }

    function searchIdInFamilyTree(element, id) {
        if (element._id == id) {
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = searchIdInFamilyTree(element.children[i], id);
            }
            return result;
        }
        return null;
    }

    const TreeNode = (TreeNodeProps) => {

        return (
            <>
                {TreeNodeProps.childList?.map(family => (

                    <span className="treenode" key={family._id}>

                        <span className={"family cursor-pointer hover-bg-gray-100 " + (family._id == parentFamily?._id ? 'text-primary bg-primary-200' : '')} >
                            {family.children.length > 0 &&
                                <i className={"caret cursor-pointer material-icons text-muted " + (closedFamilies.indexOf(family._id) > -1 ? 'caret-close' : '')} onClick={() => openClose(family._id)} >arrow_forward_ios</i>
                            }
                            <span onClick={() => changeParent(family)}>
                                <span className="me-2 fw-bold">{family.code}</span>
                                {family.designation}
                            </span>
                        </span>

                        <span className={"subfamilies " + (closedFamilies.indexOf(family._id) > -1 ? 'close' : '')}>
                            <TreeNode childList={family.children} />
                        </span>

                    </span>
                ))}
            </>
        );
    };

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <>
            <Form.Control
                onClick={(e) => { onClick(e); setIsDropDownClose(false) }}
                readOnly="readonly"
                className="cursor-pointer bg-white"
                type="text"
                placeholder="Choisir une famille"
                autoComplete="off"
                isInvalid={props.isInvalid}
                value={parentFamily ? (parentFamily?.code + ' - ' + parentFamily?.designation) : undefined}
            />
            <Form.Control.Feedback type="invalid">{props.feedback}</Form.Control.Feedback>
        </>
    ));

    return (
        <Dropdown id="FamilyInput" autoClose="outside" show={!isDropDownClose} onToggle={() => setIsDropDownClose(!isDropDownClose)} className="mb-3">
            <Dropdown.Toggle as={CustomToggle} />
            <Dropdown.Menu className="p-3 mt-2 border-gray-500">
                <TreeNode childList={treeFamilyList} />
            </Dropdown.Menu>
        </Dropdown>
    )
}
export default FamilyInput;
