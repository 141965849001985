// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#topNav .draft-current {
  position: absolute;
  bottom: -15px;
  font-size: 0.85rem;
  left: 50%;
  text-wrap: nowrap;
  font-weight: 600;
  transform: translateX(-50%);
}`, "",{"version":3,"sources":["webpack://./src/components/navigation/TopNav.scss"],"names":[],"mappings":"AACI;EACI,kBAAA;EACA,aAAA;EACA,kBAAA;EACA,SAAA;EACA,iBAAA;EACA,gBAAA;EACA,2BAAA;AAAR","sourcesContent":["#topNav{\n    .draft-current{\n        position: absolute;\n        bottom: -15px;\n        font-size: 0.85rem;\n        left: 50%;\n        text-wrap: nowrap;\n        font-weight: 600;\n        transform: translateX(-50%);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
