import React, { useEffect, useState, useRef } from 'react';
import { ClientService } from '../../../../services/ClientService';
import { Spinner, Card, Tabs, Tab, Row, Col, Accordion, Button, Table, Alert, Form, Dropdown, FloatingLabel } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import ErrorManager from "../../../error-manager/ErrorManager";
import { getFormErrorMsg, getInvalidObject, getChangedValues } from '../../../../helper/Helper'
import TarifInput from "../../../form-inputs/tarif-input/TarifInput";
import WysiwygInput from "../../../../components/form-inputs/wysiwyg-input/WysiwygInput";


const ClientEdition = (props) => {

    const [form, setForm] = useState({
        "_id": "",
        "code": "",
        "nom": "",
        "type": "",
        "profession": "",
        "observation": "",
        "taux_remise": "",
        "interdir": "",
        "debit_max": "",
        "condition_paiement": "",
        "rib": "",
        "iban": "",
        "titulaire": "",
        "ridet": "",
        "code_ape": "",
        "gps": [undefined, undefined],
        "exonerer_taxes": undefined,
        "exonerer_eco_participation": undefined,
        "bloquer": undefined,
        "tarif_id": "",
        "echeance_comptable": {
            "jour_du_mois": "",
            "fin_mois": undefined,
            "nombre_jours": ""
        }
    })

    const [invalidData, setInvalidData] = useState()
    const [globalError, setGlobalError] = useState()
    const [getClientLoading, setGetClientLoading] = useState(false)
    const [clientInitial, setClientInitial] = useState()
    const [inputChanged, setInputChanged] = useState(false)
    const [isCodeLoading, setIsCodeLoading] = useState(false)
    const [isSaveLoading, setIsSaveLoading] = useState(false)

    const tarifFilters = useRef({
        'filter[type]': [{ operator: "=", value: "tarif" }]
    });

    useEffect(() => {
        if (props.clientEditingId) {
            getClient(props.clientEditingId)
        } else {
            setGetClientLoading(false)
            setNextCode()
        }
    }, []);


    function handleChange(e) {
        setInputChanged(true)
        let value
        e.target.type == "checkbox" ? value = e.target.checked : value = e.target.value
        setForm({ ...form, [e.target.name]: value })
        setInvalidData({ ...invalidData, [e.target.name]: null })
    }

    function handleChangeGPS(e) {
        setInputChanged(true)
        let value = e.target.value
        let pos = e.target.name == 'gpsLat' ? 0 : 1

        const newItems = [(form['gps'][0] ? form['gps'][0] : 0), (form['gps'][1] ? form['gps'][1] : 0)];
        newItems[pos] = value;
        setForm({ ...form, ['gps']: newItems })
        setInvalidData({ ...invalidData, ['gps']: null })
    }

    function handleChangeTarif(t) {
        setInputChanged(true)
        setForm({ ...form, ['tarif_id']: t?._id ? t._id : "" })
    }

    function handleChangeEcheanceComptable(e) {

        let echeanceComptableCopy = Object.assign({}, form.echeance_comptable)

        if (e.target.name == "fin_mois" && e.target.checked) {
            echeanceComptableCopy["fin_mois"] = true
            echeanceComptableCopy["jour_du_mois"] = null
        }

        if (e.target.name == "jour_mois" && e.target.checked) {
            echeanceComptableCopy["fin_mois"] = false
        }

        if (e.target.name == "jour_du_mois") {
            echeanceComptableCopy["jour_du_mois"] = e.target.value
        }

        if (e.target.name == "nombre_jours") {
            echeanceComptableCopy["nombre_jours"] = e.target.value
        }

        setForm({ ...form, ['echeance_comptable']: echeanceComptableCopy })
        setInvalidData({ ...invalidData, [e.target.name]: null })
    }

    function getClient(clientId) {
        ClientService.getClient(props.folderId, clientId).then(res => {
            setClientInitial(res.data.data)
            mapForm(res.data.data)
            setGetClientLoading(false)
        }).catch(error => {
            setGlobalError(error)
            setGetClientLoading(false)
        });
    }

    function setNextCode() {
        setIsCodeLoading(true)
        ClientService.getNextCode(props.folderId).then(res => {
            setIsCodeLoading(false)
            setForm({ ...form, ['code']: res.data.data })
        }).catch(error => {
            setIsCodeLoading(false)
            setGlobalError(error)
        });
    }

    function mapForm(apiObject) {
        let formCopy = {}
        for (let key in form) {
            formCopy[key] = apiObject[key]
        }
        setForm(formCopy)
    }

    function addClient() {
        setIsSaveLoading(true)
        ClientService.postClient(props.folderId, { clients: [form] }).then(res => {
            props.onSetSaved(res.data.data.clients[0])
        }).catch(error => {
            setGlobalError(error)
            setInvalidData(getInvalidObject(error.data?.data))
            setIsSaveLoading(false)
        });
    }

    function updateClient() {
        setIsSaveLoading(true)
        ClientService.updateClient(props.folderId, { clients: [getChangedValues(form, clientInitial, null, ['_id'], ['gps'])] }).then(res => {
            props.onSetSaved(res.data.data.clients[0])
        }).catch(error => {
            setGlobalError(error)
            setInvalidData(getInvalidObject(error.data?.data))
            setIsSaveLoading(false)
        });
    }

    return (
        <>

            <Form.Group className="mb-3 float-end me-2 d-flex flex-column" controlId="formBasicEmail">
                {!props.clientEditingId &&
                    <Button
                        disabled={isSaveLoading || !form.nom || !form.code || !form.type}
                        variant="primary"
                        onClick={() => addClient()}>
                        {isSaveLoading &&
                            <Spinner animation="border" size="sm" className="me-2" />
                        }
                        Enregistrer
                    </Button>
                }
                {props.clientEditingId &&
                    <Button
                        disabled={isSaveLoading || !inputChanged || !form.nom || !form.code || !form.type || !form.interdir}
                        variant="primary"
                        onClick={() => updateClient()}>
                        {isSaveLoading &&
                            <Spinner animation="border" size="sm" className="me-2" />
                        }
                        Enregistrer les modifications
                    </Button>
                }
            </Form.Group>

            {!props.clientEditingId &&
                <div className="d-flex  mb-4 ps-2">
                    <div className="pe-3 w-100">
                        <h6 className="text-secondary m-0">Créer un</h6>
                        <h6 className="fw-bolder m-0">Nouveau client</h6>
                    </div>
                </div>
            }

            {props.clientEditingId &&
                <div className="d-flex  mb-4 ps-2">
                    <div className="pe-3 w-100">
                        <h6 className="text-secondary m-0">Modification du client</h6>
                        <h6 className="fw-bolder m-0">{form?.nom}</h6>
                    </div>
                </div>
            }

            {getClientLoading &&
                <span className="hloader mt-3"></span>
            }

            {!getClientLoading &&
                <div className="light-v-scroll pe-3 ps-2 pb-5 w-100">

                    {globalError && (
                        <ErrorManager error={globalError} fixed="true" />
                    )}

                    <Form>

                        <Form.Group className="mb-3">
                            <Form.Label>Code<span className="text-danger">*</span></Form.Label>
                            {isCodeLoading &&
                                <Spinner animation="border" size="sm" className="ms-2 text-gray-400" />
                            }
                            <Form.Control disabled={isCodeLoading} name="code" value={form?.code} isInvalid={getFormErrorMsg(invalidData?.code)} onChange={handleChange} type="text" />
                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.code)}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Nom<span className="text-danger">*</span></Form.Label>
                            <Form.Control name="nom" value={form?.nom} isInvalid={getFormErrorMsg(invalidData?.nom)} onChange={handleChange} type="text" />
                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.nom)}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Type<span className="text-danger">*</span></Form.Label>
                            <Form.Select name="type" value={form?.type} isInvalid={getFormErrorMsg(invalidData?.type)} onChange={handleChange}>
                                <option value="" disabled>- Choisir -</option>
                                <option value="debit">Debit</option>
                                <option value="comptant">Comptant</option>
                                <option value="interne">Interne</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.type)}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Interdire</Form.Label>
                            <Form.Select name="interdir" value={form?.interdir} isInvalid={getFormErrorMsg(invalidData?.interdir)} onChange={handleChange}>
                                <option value="none">- Aucun -</option>
                                <option value="debit">Debit</option>
                                <option value="comptant">Comptant</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.interdir)}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Profession</Form.Label>
                            <Form.Control name="profession" value={form?.profession} isInvalid={getFormErrorMsg(invalidData?.profession)} onChange={handleChange} type="text" />
                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.profession)}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Observation</Form.Label>
                            <WysiwygInput value={form?.observation} placeHolder="Rédiger les informations relatives au client..." changeValue={handleChange} name="observation" isInvalid={getFormErrorMsg(invalidData?.observation)} feedback={getFormErrorMsg(invalidData?.observation)} />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Taux de remise</Form.Label>
                            <Form.Control name="taux_remise" value={form?.taux_remise} isInvalid={getFormErrorMsg(invalidData?.taux_remise)} onChange={handleChange} type="number" />
                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.taux_remise)}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Tarif</Form.Label>
                            <TarifInput filters={tarifFilters.current} value={form.tarif_id} name="tarif_id" isInvalid={getFormErrorMsg(invalidData?.tarif_id)} tarifId={form.tarif_id} folderId={props.folderId} setTarif={t => handleChangeTarif(t)} />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Débit max</Form.Label>
                            <Form.Control name="debit_max" value={form?.debit_max} isInvalid={getFormErrorMsg(invalidData?.debit_max)} onChange={handleChange} type="number" />
                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.debit_max)}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Condition de paiement</Form.Label>
                            <Form.Control name="condition_paiement" value={form?.condition_paiement} isInvalid={getFormErrorMsg(invalidData?.condition_paiement)} onChange={handleChange} type="text" />
                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.condition_paiement)}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>RIB</Form.Label>
                            <Form.Control name="rib" value={form?.rib} isInvalid={getFormErrorMsg(invalidData?.rib)} onChange={handleChange} type="text" />
                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.rib)}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Iban</Form.Label>
                            <Form.Control name="iban" value={form?.iban} isInvalid={getFormErrorMsg(invalidData?.iban)} onChange={handleChange} type="text" />
                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.iban)}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Titulaire</Form.Label>
                            <Form.Control name="titulaire" value={form?.titulaire} isInvalid={getFormErrorMsg(invalidData?.titulaire)} onChange={handleChange} type="text" />
                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.titulaire)}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Ridet</Form.Label>
                            <Form.Control name="ridet" value={form?.ridet} isInvalid={getFormErrorMsg(invalidData?.ridet)} onChange={handleChange} type="text" />
                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.ridet)}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Code APE</Form.Label>
                            <Form.Control name="code_ape" value={form?.code_ape} isInvalid={getFormErrorMsg(invalidData?.code_ape)} onChange={handleChange} type="text" />
                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.code_ape)}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Row>
                                <Col>
                                    <Form.Label>GPS (Latitude)</Form.Label>
                                    <Form.Control name="gpsLat" value={form?.gps[0]} isInvalid={getFormErrorMsg(invalidData?.gps)} onChange={handleChangeGPS} type="number" />
                                    <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.gps)}</Form.Control.Feedback>
                                </Col>
                                <Col>
                                    <Form.Label>GPS (Longitude)</Form.Label>
                                    <Form.Control name="gpsLon" value={form?.gps[1]} isInvalid={getFormErrorMsg(invalidData?.gps)} onChange={handleChangeGPS} type="number" />
                                    <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.gps)}</Form.Control.Feedback>
                                </Col>
                            </Row>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Check type="checkbox" id="exonerer_taxes">
                                <Form.Check.Input name="exonerer_taxes" value={form?.exonerer_taxes} checked={form?.exonerer_taxes ? true : false} isInvalid={getFormErrorMsg(invalidData?.exonerer_taxes)} onChange={handleChange} type="checkbox" />
                                <Form.Check.Label>Exonerer taxes</Form.Check.Label>
                                <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.exonerer_taxes)}</Form.Control.Feedback>
                            </Form.Check>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Check type="checkbox" id="exonerer_eco_participation">
                                <Form.Check.Input name="exonerer_eco_participation" value={form?.exonerer_eco_participation} checked={form?.exonerer_eco_participation ? true : false} isInvalid={getFormErrorMsg(invalidData?.exonerer_eco_participation)} onChange={handleChange} type="checkbox" />
                                <Form.Check.Label>Exonerer eco participation</Form.Check.Label>
                                <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.exonerer_eco_participation)}</Form.Control.Feedback>
                            </Form.Check>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Check type="checkbox" id="bloquer">
                                <Form.Check.Input name="bloquer" value={form?.bloquer} checked={form?.bloquer ? true : false} isInvalid={getFormErrorMsg(invalidData?.bloquer)} onChange={handleChange} type="checkbox" />
                                <Form.Check.Label>Bloquer</Form.Check.Label>
                                <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.bloquer)}</Form.Control.Feedback>
                            </Form.Check>
                        </Form.Group>

                        <h6 className="mt-5 mb-3">Écheance Comptable</h6>


                        <Form.Group className="mb-3">
                            <Form.Label>Nombre de jours</Form.Label>
                            <Form.Control name="nombre_jours" value={form?.echeance_comptable?.nombre_jours} isInvalid={getFormErrorMsg(invalidData?.echeance_comptable?.nombre_jours)} onChange={handleChangeEcheanceComptable} type="number" />
                            <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.echeance_comptable?.nombre_jours)}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">

                            <Form.Check type="radio" id="fin_mois" inline>
                                <Form.Check.Input name="fin_mois" value={form?.echeance_comptable?.fin_mois} checked={form?.echeance_comptable?.fin_mois ? true : false} isInvalid={getFormErrorMsg(invalidData?.echeance_comptable?.fin_mois)} onChange={handleChangeEcheanceComptable} type="radio" />
                                <Form.Check.Label>Fin de mois</Form.Check.Label>
                                <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.echeance_comptable?.fin_mois)}</Form.Control.Feedback>
                            </Form.Check>

                            <Form.Check type="radio" id="jour_mois" className="ms-3" inline>
                                <Form.Check.Input name="jour_mois" value="" checked={form?.echeance_comptable?.fin_mois ? false : true} isInvalid={getFormErrorMsg(invalidData?.echeance_comptable?.jour_du_mois)} onChange={handleChangeEcheanceComptable} type="radio" />
                                <Form.Check.Label>Jour du mois</Form.Check.Label>
                            </Form.Check>

                        </Form.Group>
                        {!form?.echeance_comptable?.fin_mois &&
                            <Form.Group className="mb-3">
                                <Form.Control name="jour_du_mois" placeholder="Jour du mois" value={form?.echeance_comptable?.jour_du_mois} isInvalid={getFormErrorMsg(invalidData?.echeance_comptable?.jour_du_mois)} onChange={handleChangeEcheanceComptable} type="number" />
                                <Form.Control.Feedback type="invalid">{getFormErrorMsg(invalidData?.echeance_comptable?.jour_du_mois)}</Form.Control.Feedback>
                            </Form.Group>
                        }


                    </Form>
                </div>
            }
        </>

    )

}

export default ClientEdition;