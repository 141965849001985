import React, { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from "react-router-dom";
import { Container, Button, Dropdown, DropdownButton, Alert, Col, Row, Form, OverlayTrigger, Tooltip, Toast, ToastContainer } from 'react-bootstrap';
import './Rapports.scss';
import { UserService } from '../../services/UserService';
import { getBase64 } from '../../helper/Helper'


const Rapports = (props) => {

    const [isOpen, setIsOpen] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const [rapport, setRapport] = useState({});

    function handleChange(e) {
        setRapport({
            "rapport": e.target.value,
            "url": window.location.href
        });
    }

    function sendForm() {

        UserService.rapport(rapport).then(res => {
            setIsSent(true)
        }).catch(error => {
            setIsSent(true)
        });

    }

    function openForm() {
        setRapport()
        setIsOpen(true)
        setIsSent(false)
    }

    return (
        <>

            <div className="cursor-pointer bg-warning rounded p-2 position-fixed bottom-0 start-50 translate-middle-x mb-3 zindex-1000 shadow d-inline-flex align-items-center" onClick={() => openForm()}>
                <i className="material-icons fs-4 me-2 my-n2">
                    report_problem
                </i>
                <h6 className="p-0 m-0">XL Team - Bugs & Suggestions</h6>
            </div>

            {isOpen &&
                <div className="form-container cursor-pointer bg-white border border-warning border-3 rounded p-3 position-fixed bottom-0 start-50 translate-middle-x mb-3 zindex-2000 shadow">

                    <Button variant="secondary" size="sm" className="d-flex float-end mb-3" onClick={() => setIsOpen(false)}>
                        <i className="material-icons me-1">
                            close
                        </i>
                        Fermer
                    </Button>


                    {!isSent &&
                        <div className="w-100 d-flex flex-column">
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Rapport</Form.Label>
                                <Form.Control onChange={handleChange} defaultValue={rapport?.rapport} as="textarea" placeholder="Décrivez un bug rencontré, faites nous part d'une suggestion..." rows={3} />
                            </Form.Group>
                            <Button disabled={!rapport?.rapport} variant="primary" onClick={() => sendForm()}>
                                Envoyer
                            </Button>
                        </div>
                    }
                    {isSent &&
                        <div className="w-100 d-flex flex-column">
                            <Alert variant="success">
                                Rapport envoyé. Merci pour votre retour.
                            </Alert>
                        </div>
                    }

                </div>
            }
        </>
    )
}

export default Rapports;
