import React, { useEffect, useState, useRef } from 'react';
import { ArticleService } from '../../../../../services/ArticleService';
import { FournisseurService } from '../../../../../services/FournisseurService';
import { Spinner, Card, Tabs, Tab, Tooltip, Row, Col, Accordion, Button, Table, Alert, Nav, Dropdown, OverlayTrigger, Popover, InputGroup, FormControl } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import ErrorManager from "../../../../error-manager/ErrorManager";
import ConfirmDialog from '../../../../confirm-dialog/ConfirmDialog';
import Pagination from '../../../../../components/pagination/Pagination';
import { currencyFormat } from '../../../../../helper/Helper'





const Historique = (props) => {

    const [getHistoriqueLoading, setGetHistoriqueLoading] = useState(false)
    const [historiqueData, sethistoriqueData] = useState()


    let historiqueParams = useRef({

    });

    useEffect(() => {
        getHistorique()
    }, []);

    // folderId, articleId, params
    function getHistorique() {
        setGetHistoriqueLoading(true)
        ArticleService.getHistorique(props.folderId, props.article._id, historiqueParams.current).then(res => {
            sethistoriqueData(res.data.data[props.article._id].blocks)
            setGetHistoriqueLoading(false)
        }).catch(error => {
            setGetHistoriqueLoading(false)
        });
    }


    /*
    Renvois l'historique des modifications d'un élément d'une collection
    Get ('/{collection:[a-z_]+}/history[/{_id:[a-fA-F0-9]{10}}]'

    Controle la validité d'une, d'un lot, ou de toutes les blockchains d'une collection
    Get ('/{collection:[a-z_]+}/check[/{_id:[a-fA-F0-9]{10}}]'
    

    Renvois un ensemble (ou tous) des record de la collection a date donnée ou à la création
    Get ('/{collection:[a-z_]+}/fetchstate[/date/{blockDate}]'

    Renvois un, plusieurs ou tous les documents de la collection reconstruit à un index donné
    Get ('/{collection:[a-z_]+}/fetchstate/{_id:[a-fA-F0-9]{10}}[/index/{blockIndex:\d+}]'

    Renvois un, plusieurs ou tous les documents de la collection reconstruit à une date donnée
    Get ('/{collection:[a-z_]+}/fetchstate/{_id:[a-fA-F0-9]{10}}/date/{blockDate}'

    Corrige la blockchain pour un, plusieurs ou tous les documents de la collection
    PUT|PATCH ('/{collection:[a-z_]+}/fix[/{_id:[a-fA-F0-9]{10}}]'
    */

    


    const TreeNode = ({childList}) => {

        if(childList.hasOwnProperty("content")){
            delete childList["_id"];
            delete childList["date"];
            delete childList["type"];
            delete childList["checksum"];
        }
 
        return (
        
            <>
                {Object.prototype.toString.call(childList) == '[object Object]' &&
                    <>
                        {Object.keys(childList).map((key1, index1) => (
                            <div key={index1} className="ms-2">
                                <span className={(key1 != "old" && key1 != "new") ? "text-gray-900 " : (key1 == "old" ? "text-danger" : "") + (key1 == "new" ? "text-success" : "")}>
                                    {key1} :
                                </span>
                                <span className="text-gray-500">
                                    <TreeNode childList={childList[key1]} />
                                </span>
                            </div>
                        ))}
                    </>
                }

                {Object.prototype.toString.call(childList) == '[object Array]' &&
                    <>
                        {Object.keys(childList).map((child, index) => (
                            <TreeNode key={index} childList={childList[child]} />
                        ))}
                    </>
                }

                {typeof childList == 'string' &&
                    <span className="ms-2">{childList}</span>
                }
                
                {typeof childList == 'number' &&
                    <span className="ms-2">{childList}</span>
                }

                {typeof childList == 'boolean' &&
                    <>
                        <span className="ms-2">
                            {childList.toString() == "true" && 
                                <span className="ms-2">Oui</span>
                            }
                            {childList.toString() == "false" && 
                                <span className="ms-2">Non</span>
                            }
                        </span>
                    </>
                }

                
            </>


        )

     
    };

    return (
        <>
        {getHistoriqueLoading && <span className="hloader"></span>}

        {!getHistoriqueLoading && 
            <>
                {historiqueData && Object.keys(historiqueData).toReversed().map((key1, index1) =>
                    <div key={index1}>
                        
                        {/* <TreeNode childList={historiqueData[key1]} /> */}

                        <Accordion>

                            <Accordion.Item eventKey={historiqueData[key1]._id} className="mb-3">

                                <>
                                    <Accordion.Header>{historiqueData[key1].date}<span className="ms-2">({historiqueData[key1].type})</span></Accordion.Header>
                                    <Accordion.Body className="px-0 py-2">
                                        <TreeNode childList={historiqueData[key1].content} />
                                    </Accordion.Body>
                                </>

                                {/* UPDATE */}
                                {/* {historiqueData[key1].type == "update" &&
                                    <>
                                        <Accordion.Header>{historiqueData[key1].date} - {historiqueData[key1].type}</Accordion.Header>
                                        <Accordion.Body className="p-0">
                                            <TreeNode childList={historiqueData[key1]} />
                                        </Accordion.Body>
                                    </>
                                } */}

                            </Accordion.Item>
                        </Accordion>

                    </div>
                )}
            </>
        }

        </>
    );

}
export default Historique;