/* eslint-disable no-fallthrough */
import React, { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { InputGroup, FormControl, Container, Button, Dropdown, Tabs, Form, Col, Row, Tab, SplitButton, Tooltip, Badge, ToastContainer, Accordion } from 'react-bootstrap';

import { ArticleService } from '../../services/ArticleService';
import { EditionService } from '../../services/EditionService';

import DynamicTable from '../../components/table/dynamic-table/DynamicTable';
import Pagination from '../../components/pagination/Pagination';
import SideDetails from '../../components/side-details/SideDetails';
import ClientDetails from '../../components/side-details/client/client-details/ClientDetails';
import ClientEdition from '../../components/side-details/client/client-edition/ClientEdition';
import ColManager from '../../components/table/dynamic-table/col-manager/ColManager';
import Families from '../../components/families/Families';
import './Editions.scss';
import { PreferenceService } from '../../services/PreferenceService';
import ConfirmDialog from '../../components/confirm-dialog/ConfirmDialog';
import ErrorManager from "../../components/error-manager/ErrorManager";
import { GlobalContext } from "../../services/GlobalProvider";
import { formatTableHeadersFromPrefs, granted, getFile } from '../../helper/Helper'


const Editions = () => {

  const { globalContextLoaded, preferences, parametres, folderDetails, grants, getPreferences } = React.useContext(GlobalContext);

  let clientsParams = useRef({});

  const [globalError, setGlobalError] = useState()
  const [isForceRegenerateChecked, setIsForceRegenerateChecked] = useState(false)


  useEffect(() => {

  }, []);

  useEffect(() => {

  }, []);

  const [exportsComptableList, setExportsComptableList] = useState([
    {
      exportComptableId: "ebatch",
      exportComptableTitle: "e-Batch",
      exportComptableDescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In mattis viverra posuere nunc.",
      selected: false,
      filters: { 'filter[date]': [{ operator: ">=", value: '' }, { operator: "<=", value: '' }] },
      APIUrl: "export/ebatch"
    },
  ])

  const [analyseGroupList, setAnalyseGroupList] = useState([
    {
      groupId: "stats_ventes",
      groupTitle: "Stats de ventes",
      analyseList: [
        {
          analyseId: "journal_factures",
          analyseTitle: "Journal des factures",
          analyseDescription: "Analyse des ventes : Total des factures débit et comptant par période",
          selected: false,
          filters: { 'filter[date]': [{ operator: ">=", value: '' }, { operator: "<=", value: '' }] },
          APIUrl: "analyses/factures"

        }
      ]

    }
  ])

  //PLUS D'EXEMPLES
  /* const [analyseGroupList, setAnalyseGroupList] = useState([
    {
      groupId: "stats_ventes",
      groupTitle: "Stats de ventes",
      analyseList: [
        {
          analyseId: "journal_factures",
          analyseTitle: "Journal des factures",
          analyseDescription: "Analyse des ventes : Total des factures débit et comptant par période",
          selected: false,
          filters: { 'filter[date]': [{ operator: ">=", value: '' }, { operator: "<=", value: '' }] },
          APIUrl: "ventes/factures"

        },
        {
          analyseId: "chiffre_affaire_famille",
          analyseTitle: "Chiffre d'affaire / familles",
          analyseDescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In mattis viverra posuere nunc.",
          selected: false,
          filters: {},
          APIUrl: "factures"

        },
        {
          analyseId: "chiffre_affaire_articles",
          analyseTitle: "Chiffre d'affaire / articles",
          analyseDescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In mattis viverra posuere nunc.",
          selected: false,
          filters: {},
          APIUrl: "factures"

        },
        {
          analyseId: "chiffre_affaire_clients",
          analyseTitle: "Chiffre d'affaire / clients",
          analyseDescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In mattis viverra posuere nunc.",
          selected: false,
          filters: {},
          APIUrl: "factures"

        },
        {
          analyseId: "chiffre_affaire_fournisseurs",
          analyseTitle: "Chiffre d'affaire / fournisseurs",
          analyseDescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In mattis viverra posuere nunc.",
          selected: false,
          filters: {},
          APIUrl: "factures"

        },

      ]

    },
    {
      groupId: "etat_stock",
      groupTitle: "État du stock",
      analyseList: [
        {
          analyseId: "liste_articles_pour_fournisseur",
          analyseTitle: "Liste des articles pour un fournisseur",
          analyseDescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In mattis viverra posuere nunc.",
          selected: false,
          filters: {},
          APIUrl: "articles"

        },
        {
          analyseId: "liste_articles_pour_famille",
          analyseTitle: "Liste des articles pour une famille",
          analyseDescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In mattis viverra posuere nunc.",
          selected: false,
          filters: {},
          APIUrl: "articles"

        },
        {
          analyseId: "valorisation_stock",
          analyseTitle: "Valorisation du stock",
          analyseDescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In mattis viverra posuere nunc.",
          selected: false,
          filters: {},
          APIUrl: "articles"

        },
        {
          analyseId: "articles_rupture",
          analyseTitle: "Articles en rupture",
          analyseDescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In mattis viverra posuere nunc.",
          selected: false,
          filters: {},
          APIUrl: "articles"

        },

      ]

    },
    {
      groupId: "stats_entrees",
      groupTitle: "Stats d'entrées",
      analyseList: [
        {
          analyseId: "commande_en_cours",
          analyseTitle: "Commandes en cours",
          analyseDescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In mattis viverra posuere nunc.",
          selected: false,
          filters: {},
          APIUrl: "articles"

        },
        {
          analyseId: "entree_stock_periode",
          analyseTitle: "Entrées en stock sur une période",
          analyseDescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In mattis viverra posuere nunc.",
          selected: false,
          filters: {},
          APIUrl: "articles"

        },
        {
          analyseId: "entree_stock_commande",
          analyseTitle: "Entrées en stock par commande",
          analyseDescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In mattis viverra posuere nunc.",
          selected: false,
          filters: {},
          APIUrl: "articles"

        },
        {
          analyseId: "entree_stock_fournisseur",
          analyseTitle: "Entrées en stock par fournisseurs",
          analyseDescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In mattis viverra posuere nunc.",
          selected: false,
          filters: {},
          APIUrl: "articles"

        },
        {
          analyseId: "entree_stock_article",
          analyseTitle: "Entrées en stock par article",
          analyseDescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In mattis viverra posuere nunc.",
          selected: false,
          filters: {},
          APIUrl: "articles"

        },

      ]

    }
  ]) */


  function downloadAnalyses() {
    for (let analyseGroup of analyseGroupList) {
      for (let analyse of analyseGroup.analyseList.filter(a => a.selected)) {
        EditionService.getEditionsPDF(folderDetails._id, { 'filters': analyse.filters }, analyse.APIUrl).then(res => {
          if (!res?.data) {
            console.log('ERROR')
          } else {
            getFile(res.data, `Analyses`, "pdf")
          }
        }).catch(error => {
          setGlobalError(error)
        });
      }
    }
  }

  function downloadExportsComptable() {
    for (let exportComptable of exportsComptableList.filter(e => e.selected)) {
      EditionService.getEditions(folderDetails._id, { 'filters': exportComptable.filters, 'force_regenerate': isForceRegenerateChecked }, exportComptable.APIUrl).then(res => {
        if (!res?.data?.data) {
          console.log('ERROR')
        } else {
          getFile(res.data.data, `ExportsComptable`, "json")
        }
      }).catch(error => {
        setGlobalError(error)
      });
    }
  }

  function changeSelectExportComptable(exportComptableId, e) {
    setExportsComptableList([...exportsComptableList], exportsComptableList.find(e => e.exportComptableId == exportComptableId).selected = e.target.checked)
  }
  function changeExportComptableFilter(exportComptableId, filterName, operator, e) {
    setExportsComptableList([...exportsComptableList], exportsComptableList.find(e => e.exportComptableId == exportComptableId).filters['filter[' + filterName + ']'].find(filter => filter.operator == operator).value = e.target.value)
  }

  function changeSelectAnalyse(groupId, analyseId, e) {
    setAnalyseGroupList([...analyseGroupList], analyseGroupList.find(g => g.groupId == groupId).analyseList.find(a => a.analyseId == analyseId).selected = e.target.checked)
  }
  function changeAnalyseFilter(groupId, analyseId, filterName, operator, e) { // Ex : (groupId, analyseId, "famille_id", "=", e)
    setAnalyseGroupList([...analyseGroupList], analyseGroupList.find(g => g.groupId == groupId).analyseList.find(a => a.analyseId == analyseId).filters['filter[' + filterName + ']'].find(filter => filter.operator == operator).value = e.target.value)
  }

  function getAnalyseForm(groupId, analyseId) {

    switch (groupId) {
      case "etat_stock":
        switch (analyseId) {
          case "liste_articles_pour_fournisseur":
            return <>
              <Form.Group>
                <Form.Label>Choisissez un fournisseur</Form.Label>
                <Form.Control onChange={e => changeAnalyseFilter(groupId, analyseId, "fournisseurs.fournisseur_id", "=", e)} placeholder="Fournisseur ID" type="text" />
              </Form.Group>
            </>
          case "liste_articles_pour_famille":
            return <>
              <Form.Group>
                <Form.Label>Choisissez une famille</Form.Label>
                <Form.Control onChange={e => changeAnalyseFilter(groupId, analyseId, "famille_id", "=", e)} placeholder="Famille ID" type="text" />
              </Form.Group>
            </>
          default:
            break;
        }
      case "stats_ventes":
        switch (analyseId) {
          case "journal_factures":
            return <>
              <Form.Group as={Col} className="mb-2">
                <Form.Label>Date de début</Form.Label>
                <Form.Control onChange={e => changeAnalyseFilter(groupId, analyseId, "date", ">=", e)} placeholder="Date de début" type="date" />
              </Form.Group>

              <Form.Group as={Col} className="mb-2">
                <Form.Label>Date de fin</Form.Label>
                <Form.Control onChange={e => changeAnalyseFilter(groupId, analyseId, "date", "<=", e)} placeholder="Date de début" type="date" />
              </Form.Group>
            </>
          default:
            break;
        }
      case "stats_entrees":
        switch (analyseId) {
          case "commande_en_cours":
            return <p>commande_en_cours</p>
          default:
            break;
        }
      default:
        break;
    }
  }
  function getExportComptableForm(exportComptableId) {

    switch (exportComptableId) {
      case "ebatch":
        return <>
          <Form.Check
            className="my-2"
            type="checkbox"
            defaultChecked={isForceRegenerateChecked}
            onClick={() => setIsForceRegenerateChecked(!isForceRegenerateChecked)}
            id={`id-force-regenerate`}
            label="Re-générer en compta les factures déjà archivées"
          />

          <Form.Group as={Col} className="mb-2">
            <Form.Label>Date de début</Form.Label>
            <Form.Control onChange={e => changeExportComptableFilter(exportComptableId, "date", ">=", e)} placeholder="Date de début" type="date" />
          </Form.Group>

          <Form.Group as={Col} className="mb-2">
            <Form.Label>Date de fin</Form.Label>
            <Form.Control onChange={e => changeExportComptableFilter(exportComptableId, "date", "<=", e)} placeholder="Date de début" type="date" />
          </Form.Group>
        </>
      case "xxxxxx":
        return <>
          <Form.Group>
            <Form.Label>XXXXXX</Form.Label>
            <Form.Control onChange={e => changeExportComptableFilter(exportComptableId, "XXXXXX", "=", e)} placeholder="XXXXXX" type="text" />
          </Form.Group>
        </>
      default:
        break;
    }
  }

  return (
    <Container fluid id="Editions">

      <ErrorManager error={globalError} fixed="true" />

      <Row>

        <Col md="6" xxl="3" className="border-end pe-4 pt-4 height-full-min-80">

          {/* const [exportsComptableList, setExportsComptableList] = useState([
                {
                  analyseId: "ebatch",
                  analyseTitle: "e-Batch",
                  analyseDescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In mattis viverra posuere nunc.",
                  selected: false,
                  filters: { 'filter[date]': [{ operator: ">=", value: '' }, { operator: "<=", value: '' }] },
                  APIUrl: "ventes/ebatch"
                },
              ]) */}

          <h5 className="mb-3 mt-3">Exports comptable</h5>
          {exportsComptableList.map(exportComptable => (
            <div key={exportComptable.exportComptableId} className="d-flex border-bottom mb-4">
              <div className="me-5">
                <h6>
                  {exportComptable.exportComptableTitle}
                </h6>
                <p className="text-gray-500">{exportComptable.exportComptableDescription}</p>
              </div>
              <div className="ms-auto">
                <Form.Check
                  type="switch"
                  id={exportComptable.exportComptableId}
                  defaultChecked={exportComptable.selected}
                  onChange={e => changeSelectExportComptable(exportComptable.exportComptableId, e)}
                />
              </div>
            </div>
          ))}

          <h4>Analyses</h4>
          {analyseGroupList.map(analyseGroup => (
            <div key={analyseGroup.groupId}>
              <h5 className="mb-3 mt-3">{analyseGroup.groupTitle}</h5>
              {analyseGroup.analyseList.map(analyse => (
                <div key={analyse.analyseId} className="d-flex">
                  <div className="me-5">
                    <h6>
                      {analyse.analyseTitle}
                      {analyse.analyseId != "journal_factures" && <span className="ms-2 text-muted">(Indisponible)</span>}
                    </h6>
                    <p className="text-gray-500">{analyse.analyseDescription}</p>
                  </div>
                  <div className="ms-auto">
                    <Form.Check
                      disabled={analyse.analyseId != "journal_factures"}
                      type="switch"
                      id={analyse.analyseId}
                      defaultChecked={analyse.selected}
                      onChange={e => changeSelectAnalyse(analyseGroup.groupId, analyse.analyseId, e)}
                    />
                  </div>
                </div>

              ))}
            </div>
          ))}
        </Col>

        <Col md="6" xxl="3" className="ps-4 pt-4">
          <Row>
            <Col>

              {((!analyseGroupList.filter(g => g.analyseList.filter(a => a.selected).length > 0).length > 0) && (!exportsComptableList.filter(e => e.selected).length > 0)) &&
                <em>- Cochez les exports comptables et analyses que vous souhaitez éditer -</em>
              }

              {/* const [exportsComptableList, setExportsComptableList] = useState([
                {
                  analyseId: "ebatch",
                  analyseTitle: "e-Batch",
                  analyseDescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In mattis viverra posuere nunc.",
                  selected: false,
                  filters: { 'filter[date]': [{ operator: ">=", value: '' }, { operator: "<=", value: '' }] },
                  APIUrl: "ventes/ebatch"
                },
              ]) */}


              {exportsComptableList.filter(e => e.selected).length > 0 &&
                <div className="w-100 d-flex align-items-center mb-2">
                  <h5>Exports comptable</h5>
                  <Button onClick={() => downloadExportsComptable()} className="ms-auto">Télécharger le fichier (Json)</Button>
                </div>
              }

              {exportsComptableList.filter(e => e.selected).length > 0 &&
                <div className="mb-4">
                  <Accordion className="mb-4">
                    {exportsComptableList.filter(e => e.selected).map(exportComptable => (
                      <Accordion.Item key={exportComptable.exportComptableId}>
                        <Accordion.Header>{exportComptable.exportComptableTitle}</Accordion.Header>
                        <Accordion.Body className="mb-2">
                          {getExportComptableForm(exportComptable.exportComptableId)}
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </div>
              }

              <div className="w-100 d-flex align-items-center mb-3">

                {analyseGroupList.filter(g => g.analyseList.filter(a => a.selected).length > 0).length > 0 &&
                  <>
                    <h5>Analyses</h5>
                    <SplitButton
                      className="ms-auto"
                      onClick={() => downloadAnalyses()}
                      title="Télécharger les PDF"
                    >
                      <Dropdown.Item eventKey="1" disabled>Télécharger en XLS (Indisponible)</Dropdown.Item>
                      <Dropdown.Item eventKey="2" disabled>Télécharger en Html (Indisponible)</Dropdown.Item>
                    </SplitButton>
                  </>
                }

              </div>

              {analyseGroupList.map(analyseGroup => (
                <div key={analyseGroup.groupId}>
                  <h6>{analyseGroup.analyseList.filter(a => a.selected && getAnalyseForm(analyseGroup.groupId, a.analyseId)).length > 0 && analyseGroup.groupTitle}</h6>
                  <Accordion className="mb-4">
                    {analyseGroup.analyseList.filter(a => a.selected && getAnalyseForm(analyseGroup.groupId, a.analyseId)).map(analyse => (
                      <Accordion.Item key={analyse.analyseId}>
                        <Accordion.Header>{analyse.analyseTitle}</Accordion.Header>
                        <Accordion.Body className="mb-2">
                          {getAnalyseForm(analyseGroup.groupId, analyse.analyseId)}
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </div>
              ))}

            </Col>
          </Row>
        </Col>
      </Row>




    </Container>

  );
}

export default Editions;