import React, { useEffect, useState, useRef } from 'react';
import { TarifService } from '../../../../../services/TarifService';
import { Form, Spinner, Card, Tabs, Tab, Row, Col, Accordion, Button, InputGroup, Alert, Tooltip, Dropdown, Badge } from 'react-bootstrap';
import ErrorManager from "../../../../error-manager/ErrorManager";
import { getFormErrorMsg, getInvalidObject, getChangedValues } from '../../../../../helper/Helper'
import FamilyInput from "../../../../form-inputs/family-input/FamilyInput";


const Familles = (props) => {

    const [globalError, setGlobalError] = useState()

    const [getFamillesLoading, setGetFamillesLoading] = useState(false)
    const [postFamillesLoading, setPostFamillesLoading] = useState(false)
    const [removeFamilleIdLoading, setRemoveFamilleIdLoading] = useState()
    const [updateFamilleLoading, setUpdateFamilleLoading] = useState()

    const [familles, setFamilles] = useState(false)
    const [currentFamille, setCurrentFamille] = useState()
    const [currentType, setCurrentType] = useState(getType(props.tarif))
    const [currentValue, setCurrentValue] = useState(getValue(props.tarif))

    const [currentTypeEditing, setCurrentTypeEditing] = useState()
    const [currentValueEditing, setCurrentValueEditing] = useState()

    const [currentFamilleEditing, setCurrentFamilleEditing] = useState()

    useEffect(() => {
        getFamilles()
    }, []);

    function getValue(element) {
        let value = null
        element.coefficient_pr !== null && (value = element.coefficient_pr)
        element.prix_vente_ht !== null && (value = element.prix_vente_ht)
        element.prix_vente_ttc !== null && (value = element.prix_vente_ttc)
        element.taux_remise !== null && (value = element.taux_remise)
        return value
    }

    function getType(element) {
        let type = null
        element.coefficient_pr !== null && (type = "coefficient_pr")
        element.prix_vente_ht !== null && (type = "prix_vente_ht")
        element.prix_vente_ttc !== null && (type = "prix_vente_ttc")
        element.taux_remise !== null && (type = "taux_remise")
        return type
    }

    function handleChangeFamille(e) {
        setCurrentFamille(e.target.family)
    }
    function handleChangeType(e) {
        setCurrentType(e.target.value)
    }
    function handleChangeValue(e) {
        setCurrentValue(e.target.value)
    }

    function handleChangeTypeEditing(e) {
        setCurrentTypeEditing(e.target.value)
    }
    function handleChangeValueEditing(e) {
        setCurrentValueEditing(e.target.value)
    }

    function getFamilles() {
        setGetFamillesLoading(true)
        TarifService.getFamilles(props.folderId, props.tarif._id).then(res => {
            setGetFamillesLoading(false)
            res?.data?.data?.familles ? setFamilles(res.data.data.familles) : setFamilles()
        }).catch(error => {
            setGetFamillesLoading(false)
            setGlobalError(error)
        });
    }

    function saveFamille() {
        setGlobalError()
        setPostFamillesLoading(true)
        TarifService.postFamille(props.folderId, props.tarif._id, { familles: [{ famille_id: currentFamille._id, [currentType]: currentValue }] }).then(res => {
            setPostFamillesLoading(false)
            getFamilles()
            resetInputValues()
        }).catch(error => {
            setGlobalError(error)
            setPostFamillesLoading(false)
        });
    }

    function removeFamille(famille) {
        setGlobalError()
        setRemoveFamilleIdLoading(famille.famille_id)
        TarifService.removeFamille(props.folderId, props.tarif._id, famille.famille_id).then(res => {
            setRemoveFamilleIdLoading()
            getFamilles()
        }).catch(error => {
            setRemoveFamilleIdLoading()
            setGlobalError(error)
        });
    }

    function updateFamille() {
        setGlobalError()
        setUpdateFamilleLoading(true)
        TarifService.updateFamille(props.folderId, props.tarif._id, { familles: [{ famille_id: currentFamilleEditing.famille_id, [currentTypeEditing]: currentValueEditing }] }).then(res => {
            setUpdateFamilleLoading(false)
            getFamilles()
            resetInputEditingValues()
        }).catch(error => {
            setGlobalError(error)
            setUpdateFamilleLoading(false)
        });
    }

    function resetInputValues() {
        setCurrentFamille()
        setCurrentType(getType(props.tarif))
        setCurrentValue(getValue(props.tarif))
    }

    function resetInputEditingValues() {
        setCurrentFamilleEditing()
        setCurrentTypeEditing()
        setCurrentValueEditing()
    }

    function setEditing(famille) {
        setCurrentFamilleEditing(famille)
        setCurrentTypeEditing(getType(famille))
        setCurrentValueEditing(getValue(famille))
    }

    return (
        <>

            {globalError && (
                <ErrorManager error={globalError} />
            )}

            <div className="mb-2 rounded p-3 bg-gray-100">

                <Form.Group>
                    <Form.Label>Ajouter une famille :</Form.Label>
                    <FamilyInput folderId={props.folderId} familyId={currentFamille?._id} setFamilyId={handleChangeFamille} name="famille_id" />
                </Form.Group>

                {currentFamille &&
                    <>


                        <Form.Group className="d-flex mt-2">

                            <Form.Check
                                inline
                                onChange={handleChangeType}
                                label="Taux remise"
                                name="groupeType"
                                value="taux_remise"
                                checked={currentType == "taux_remise"}
                                type="radio"
                                id={`inline-1`}
                            />
                            <Form.Check
                                inline
                                onChange={handleChangeType}
                                label="Prix vente HT"
                                name="groupeType"
                                value="prix_vente_ht"
                                checked={currentType == "prix_vente_ht"}
                                type="radio"
                                id={`inline-2`}
                            />
                            <Form.Check
                                inline
                                onChange={handleChangeType}
                                label="Prix vente TTC"
                                name="groupeType"
                                value="prix_vente_ttc"
                                checked={currentType == "prix_vente_ttc"}
                                type="radio"
                                id={`inline-3`}
                            />
                            <Form.Check
                                inline
                                onChange={handleChangeType}
                                label="Coef. Prix de revient"
                                name="groupeType"
                                value="coefficient_pr"
                                checked={currentType == "coefficient_pr"}
                                type="radio"
                                id={`inline-4`}
                            />
                        </Form.Group>

                        <div className="d-flex mt-2">

                            <InputGroup className="w-100">

                                <Form.Control defaultValue={currentValue} onChange={handleChangeValue} type="number" />

                                {(currentType == "taux_remise") &&
                                    <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                                }

                                {(currentType == "prix_vente_ht") &&
                                    <InputGroup.Text id="basic-addon2">F. HT</InputGroup.Text>
                                }

                                {(currentType == "prix_vente_ttc") &&
                                    <InputGroup.Text id="basic-addon2">F. TTC</InputGroup.Text>
                                }

                                {(currentType == "coefficient_pr") &&
                                    <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                                }

                            </InputGroup>

                            <Button className="ms-2" disabled={postFamillesLoading} variant="secondary" onClick={() => resetInputValues()}>Annuler</Button>
                            <Button className="ms-2 d-flex" disabled={postFamillesLoading || !currentType || !currentValue} onClick={() => saveFamille()}>
                                {postFamillesLoading &&
                                    <Spinner animation="border" size="sm" className="me-2" />
                                }
                                Enregistrer
                            </Button>
                        </div>

                    </>
                }
            </div>

            {(!currentFamille && !familles && !getFamillesLoading) &&
                <em>- Aucun élément trouvé -</em>
            }

            {getFamillesLoading &&
                <span className="hloader mt-3"></span>
            }

            {(!getFamillesLoading && familles) && familles.map(famille => (
                <div key={famille.famille_id}>
                    <span className={"rounded mb-2 d-flex flex-column " + (currentFamilleEditing?.famille_id == famille.famille_id ? "border p-3" : "bg-gray-100 py-1 px-2")}>

                        <div className="d-flex align-items-center">
                            <span className="me-auto">
                                <span className="me-2 fw-bolder">{famille.familles[0].code}</span>
                                {famille.familles[0].designation}
                            </span>

                            {currentFamilleEditing?.famille_id != famille.famille_id &&
                                <>
                                    {(famille.taux_remise !== null && famille.taux_remise !== props.tarif.taux_remise) && <span className="text-gray-600">Taux remise : {famille.taux_remise}</span>}
                                    {(famille.prix_vente_ht !== null && famille.prix_vente_ht !== props.tarif.prix_vente_ht) && <span className="text-gray-600">Prix vente HT : {famille.prix_vente_ht}</span>}
                                    {(famille.prix_vente_ttc !== null && famille.prix_vente_ttc !== props.tarif.prix_vente_ttc) && <span className="text-gray-600">Prix vente TTC : {famille.prix_vente_ttc}</span>}
                                    {(famille.coefficient_pr !== null && famille.coefficient_pr !== props.tarif.coefficient_pr) && <span className="text-gray-600">Coef. Prix de revient : {famille.coefficient_pr}</span>}

                                    <Button disabled={removeFamilleIdLoading == famille.famille_id} size="sm" onClick={() => setEditing(famille)} variant="outline-primary" className="ms-3">
                                        <i className="material-icons i-lg">edit</i>
                                    </Button>
                                </>
                            }
                        </div>

                        {currentFamilleEditing?.famille_id == famille.famille_id &&
                            <div className="">
                                <Form.Group className="d-flex mt-2">

                                    <Form.Check
                                        inline
                                        onChange={handleChangeTypeEditing}
                                        label="Taux remise"
                                        name="groupeTypeEdit"
                                        value="taux_remise"
                                        checked={currentTypeEditing == "taux_remise"}
                                        type="radio"
                                        id={`inlineEdit-1`}
                                    />
                                    <Form.Check
                                        inline
                                        onChange={handleChangeTypeEditing}
                                        label="Prix vente HT"
                                        name="groupeTypeEdit"
                                        value="prix_vente_ht"
                                        checked={currentTypeEditing == "prix_vente_ht"}
                                        type="radio"
                                        id={`inlineEdit-2`}
                                    />
                                    <Form.Check
                                        inline
                                        onChange={handleChangeTypeEditing}
                                        label="Prix vente TTC"
                                        name="groupeTypeEdit"
                                        value="prix_vente_ttc"
                                        checked={currentTypeEditing == "prix_vente_ttc"}
                                        type="radio"
                                        id={`inlineEdit-3`}
                                    />
                                    <Form.Check
                                        inline
                                        onChange={handleChangeTypeEditing}
                                        label="Coef. Prix de revient"
                                        name="groupeTypeEdit"
                                        value="coefficient_pr"
                                        checked={currentTypeEditing == "coefficient_pr"}
                                        type="radio"
                                        id={`inlineEdit-4`}
                                    />
                                </Form.Group>

                                <InputGroup className="mt-2">

                                    <Form.Control defaultValue={currentValueEditing} onChange={handleChangeValueEditing} type="number" />

                                    {(currentTypeEditing == "taux_remise") &&
                                        <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                                    }

                                    {(currentTypeEditing == "prix_vente_ht") &&
                                        <InputGroup.Text id="basic-addon2">F. HT</InputGroup.Text>
                                    }

                                    {(currentTypeEditing == "prix_vente_ttc") &&
                                        <InputGroup.Text id="basic-addon2">F. TTC</InputGroup.Text>
                                    }

                                    {(currentTypeEditing == "coefficient_pr") &&
                                        <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                                    }

                                </InputGroup>

                                <div className="d-flex mt-2">
                                    <Button className="me-auto" disabled={removeFamilleIdLoading == famille.famille_id} onClick={() => removeFamille(famille)} variant="danger">Supprimer</Button>
                                    <Button className="" variant="secondary" onClick={() => resetInputEditingValues()}>Annuler</Button>
                                    <Button className="ms-2" disabled={updateFamilleLoading || !currentTypeEditing || !currentValueEditing} onClick={() => updateFamille()}>
                                        {updateFamilleLoading &&
                                            <Spinner animation="border" size="sm" className="me-2" />
                                        }
                                        Enregistrer les modifications
                                    </Button>
                                </div>
                            </div>
                        }
                    </span>
                </div>
            ))}

        </>
    );

}
export default Familles;