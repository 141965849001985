import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { UserService } from '../../services/UserService';
import { FolderService } from '../../services/FolderService';
import ColManager from '../../components/table/dynamic-table/col-manager/ColManager';
import { GlobalContext, GlobalDispatchContext } from "../../services/GlobalProvider";
import { Link } from "react-router-dom";
import axios from 'axios';
import { Table, Badge, InputGroup, Alert, FormControl, Container, Button, Dropdown, DropdownButton, Form, Col, Row, Spinner, OverlayTrigger, Tooltip, Modal, ToastContainer, Accordion } from 'react-bootstrap';
import '../../config.inc';
import lost from '../../assets/img/illustrations/lost.png'





//folder-not-found


export default function NotFound() {

    const [searchParams, setSearchParams] = useSearchParams();
    const folderNotFound = searchParams.get('folder-not-found') ? true : false

    return (

        <div className="position-relative h-100">
            <div className="position-absolute top-50 start-50 translate-middle d-flex align-items-center">

                <img width={"130px"} className="me-4" src={lost} alt="Page non trouvée XL Prog" />
                <div>
                    <h1 className="text-primary">Page introuvable</h1>
                    {folderNotFound &&
                        <>
                            <h6>Le dossier auquel vous tentez d'accéder est introuvable.</h6>
                            <a href="/dossiers">Voir les dossiers disponibles</a>
                        </>
                    }
                    {!folderNotFound &&
                        <h6>La ressource demandée n'a pas été trouvée.</h6>
                    }
                </div>
            </div>

        </div>

    );
}