import React, { useEffect, useState, useRef } from 'react';
import { TarifService } from '../../../../../services/TarifService';
import { Form, Spinner, Card, Tabs, Tab, Row, Col, Accordion, Button, InputGroup, Alert, Tooltip, Dropdown, Badge } from 'react-bootstrap';
import ErrorManager from "../../../../error-manager/ErrorManager";
import { getFormErrorMsg, getInvalidObject, getChangedValues } from '../../../../../helper/Helper'
import ArticleInput from "./article-input/ArticleInput";


const Articles = (props) => {

    const [globalError, setGlobalError] = useState()

    const [getArticlesLoading, setGetArticlesLoading] = useState(false)
    const [postArticlesLoading, setPostArticlesLoading] = useState(false)
    const [removeArticleIdLoading, setRemoveArticleIdLoading] = useState()
    const [updateArticleLoading, setUpdateArticleLoading] = useState()

    const [articles, setArticles] = useState()
    const [currentArticle, setCurrentArticle] = useState()
    const [currentType, setCurrentType] = useState(getType(props.tarif))
    const [currentValue, setCurrentValue] = useState(getValue(props.tarif))

    const [currentArticleEditing, setCurrentArticleEditing] = useState()
    const [currentTypeEditing, setCurrentTypeEditing] = useState()
    const [currentValueEditing, setCurrentValueEditing] = useState()



    useEffect(() => {
        getArticles()
    }, []);

    function getValue(element) {
        let value = null
        element.coefficient_pr !== null && (value = element.coefficient_pr)
        element.prix_vente_ht !== null && (value = element.prix_vente_ht)
        element.prix_vente_ttc !== null && (value = element.prix_vente_ttc)
        element.taux_remise !== null && (value = element.taux_remise)
        return value
    }

    function getType(element) {
        let type = null
        element.coefficient_pr !== null && (type = "coefficient_pr")
        element.prix_vente_ht !== null && (type = "prix_vente_ht")
        element.prix_vente_ttc !== null && (type = "prix_vente_ttc")
        element.taux_remise !== null && (type = "taux_remise")
        return type
    }

    function handleChange(e) {
        setCurrentValue(e.target.value)
    }

    function getArticles() {
        setGetArticlesLoading(true)
        TarifService.getArticles(props.folderId, props.tarif._id).then(res => {
            setGetArticlesLoading(false)
            res?.data?.data?.articles[0]?.article?._id ? setArticles(res.data.data.articles) : setArticles()
        }).catch(error => {
            setGetArticlesLoading(false)
            setGlobalError(error)
        });
    }

    function handleChangeArticle(article) {
        setCurrentArticle(article)
    }
    function handleChangeType(e) {
        setCurrentType(e.target.value)
    }

    function handleChangeTypeEditing(e) {
        setCurrentTypeEditing(e.target.value)
    }
    function handleChangeValueEditing(e) {
        setCurrentValueEditing(e.target.value)
    }

    function saveArticle() {
        setGlobalError()
        setPostArticlesLoading(true)
        TarifService.postArticle(props.folderId, props.tarif._id, { articles: [{ article_id: currentArticle._id, [currentType]: currentValue }] }).then(res => {
            setPostArticlesLoading(false)
            getArticles()
            resetInputValues()
        }).catch(error => {
            setGlobalError(error)
            setPostArticlesLoading(false)
        });
    }

    function removeArticle(article) {
        setGlobalError()
        setRemoveArticleIdLoading(article.article_id)
        TarifService.removeArticle(props.folderId, props.tarif._id, article.article_id).then(res => {
            setRemoveArticleIdLoading()
            getArticles()
        }).catch(error => {
            setRemoveArticleIdLoading()
            setGlobalError(error)
        });
    }

    function updateArticle() {
        setGlobalError()
        setUpdateArticleLoading(true)
        TarifService.updateArticle(props.folderId, props.tarif._id, { articles: [{ article_id: currentArticleEditing.article_id, [currentTypeEditing]: currentValueEditing }] }).then(res => {
            setUpdateArticleLoading(false)
            getArticles()
            resetInputEditingValues()
        }).catch(error => {
            setGlobalError(error)
            setUpdateArticleLoading(false)
        });
    }
    function resetInputValues() {
        setCurrentArticle()
        setCurrentType(getType(props.tarif))
        setCurrentValue(getValue(props.tarif))
    }

    function resetInputEditingValues() {
        setCurrentArticleEditing()
        setCurrentTypeEditing()
        setCurrentValueEditing()
    }

    function setEditing(article) {
        setCurrentArticleEditing(article)
        setCurrentTypeEditing(getType(article))
        setCurrentValueEditing(getValue(article))
    }

    return (
        <>

            {globalError && (
                <ErrorManager error={globalError} />
            )}

            <div className="mb-2 rounded p-3 bg-gray-100">

                {!currentArticle &&
                    <Form.Group>
                        <Form.Label>Ajouter un article :</Form.Label>
                        <ArticleInput tarifId={props.tarif._id} folderId={props.folderId} value={currentArticle?._id} setArticle={handleChangeArticle} name="article" />
                    </Form.Group>
                }
                {currentArticle &&
                    <span className="py-1 px-2 bg-gray-300 rounded mb-2 d-flex"><span className="me-2 fw-bolder">{currentArticle.code}</span>{currentArticle.designation}</span>
                }


                {currentArticle &&
                    <>
                        <Form.Group className="d-flex mt-2">

                            <Form.Check
                                inline
                                onChange={handleChangeType}
                                label="Taux remise"
                                name="groupeType"
                                value="taux_remise"
                                checked={currentType == "taux_remise"}
                                type="radio"
                                id={`inline-1`}
                            />
                            <Form.Check
                                inline
                                onChange={handleChangeType}
                                label="Prix vente HT"
                                name="groupeType"
                                value="prix_vente_ht"
                                checked={currentType == "prix_vente_ht"}
                                type="radio"
                                id={`inline-2`}
                            />
                            <Form.Check
                                inline
                                onChange={handleChangeType}
                                label="Prix vente TTC"
                                name="groupeType"
                                value="prix_vente_ttc"
                                checked={currentType == "prix_vente_ttc"}
                                type="radio"
                                id={`inline-3`}
                            />
                            <Form.Check
                                inline
                                onChange={handleChangeType}
                                label="Coef. Prix de revient"
                                name="groupeType"
                                value="coefficient_pr"
                                checked={currentType == "coefficient_pr"}
                                type="radio"
                                id={`inline-4`}
                            />
                        </Form.Group>

                        <div className="d-flex mt-2">
                            <InputGroup className="w-100">

                                <Form.Control defaultValue={currentValue} onChange={handleChange} type="number" />

                                {(currentType == "taux_remise") &&
                                    <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                                }

                                {(currentType == "prix_vente_ht") &&
                                    <InputGroup.Text id="basic-addon2">F. HT</InputGroup.Text>
                                }

                                {(currentType == "prix_vente_ttc") &&
                                    <InputGroup.Text id="basic-addon2">F. TTC</InputGroup.Text>
                                }

                                {(currentType == "coefficient_pr") &&
                                    <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                                }


                            </InputGroup>

                            <Button className="ms-2" disabled={postArticlesLoading} variant="secondary" onClick={() => resetInputValues()}>Annuler</Button>
                            <Button className="ms-2 d-flex" disabled={postArticlesLoading || !currentType || !currentValue} onClick={() => saveArticle()}>
                                {postArticlesLoading &&
                                    <Spinner animation="border" size="sm" className="me-2" />
                                }
                                Enregistrer
                            </Button>
                        </div>

                    </>
                }
            </div >

            {(!currentArticle && !getArticlesLoading && !articles) &&
                <em>- Aucun élément trouvé -</em>
            }

            {
                getArticlesLoading &&
                <span className="hloader mt-3"></span>
            }

            {
                (!getArticlesLoading && articles) && articles.map(article => (
                    <div key={article.article_id}>
                        <span className={"rounded mb-2 d-flex flex-column " + (currentArticleEditing?.article_id == article.article_id ? "border p-3" : "bg-gray-100 py-1 px-2")}>

                            <div className="d-flex align-items-center">
                                <span className="me-auto">
                                    <span className="me-2 fw-bolder">{article.article.code}</span>
                                    {article.article.designation}
                                </span>

                                {currentArticleEditing?.article_id != article.article_id &&
                                    <>
                                        {(article.taux_remise !== null && article.taux_remise !== props.tarif.taux_remise) && <span className="text-gray-600">Taux remise : {article.taux_remise}</span>}
                                        {(article.prix_vente_ht !== null && article.prix_vente_ht !== props.tarif.prix_vente_ht) && <span className="text-gray-600">Prix vente HT : {article.prix_vente_ht}</span>}
                                        {(article.prix_vente_ttc !== null && article.prix_vente_ttc !== props.tarif.prix_vente_ttc) && <span className="text-gray-600">Prix vente TTC : {article.prix_vente_ttc}</span>}
                                        {(article.coefficient_pr !== null && article.coefficient_pr !== props.tarif.coefficient_pr) && <span className="text-gray-600">Coef. Prix de revient : {article.coefficient_pr}</span>}

                                        <Button disabled={removeArticleIdLoading == article.article_id} size="sm" onClick={() => setEditing(article)} variant="outline-primary" className="ms-3">
                                            <i className="material-icons i-lg">edit</i>
                                        </Button>
                                    </>
                                }
                            </div>

                            {currentArticleEditing?.article_id == article.article_id &&
                                <div className="">
                                    <Form.Group className="d-flex mt-2">

                                        <Form.Check
                                            inline
                                            onChange={handleChangeTypeEditing}
                                            label="Taux remise"
                                            name="groupeTypeEdit"
                                            value="taux_remise"
                                            checked={currentTypeEditing == "taux_remise"}
                                            type="radio"
                                            id={`inlineEdit-1`}
                                        />
                                        <Form.Check
                                            inline
                                            onChange={handleChangeTypeEditing}
                                            label="Prix vente HT"
                                            name="groupeTypeEdit"
                                            value="prix_vente_ht"
                                            checked={currentTypeEditing == "prix_vente_ht"}
                                            type="radio"
                                            id={`inlineEdit-2`}
                                        />
                                        <Form.Check
                                            inline
                                            onChange={handleChangeTypeEditing}
                                            label="Prix vente TTC"
                                            name="groupeTypeEdit"
                                            value="prix_vente_ttc"
                                            checked={currentTypeEditing == "prix_vente_ttc"}
                                            type="radio"
                                            id={`inlineEdit-3`}
                                        />
                                        <Form.Check
                                            inline
                                            onChange={handleChangeTypeEditing}
                                            label="Coef. Prix de revient"
                                            name="groupeTypeEdit"
                                            value="coefficient_pr"
                                            checked={currentTypeEditing == "coefficient_pr"}
                                            type="radio"
                                            id={`inlineEdit-4`}
                                        />
                                    </Form.Group>

                                    <InputGroup className="mt-2">

                                        <Form.Control defaultValue={currentValueEditing} onChange={handleChangeValueEditing} type="number" />

                                        {(currentTypeEditing == "taux_remise") &&
                                            <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                                        }

                                        {(currentTypeEditing == "prix_vente_ht") &&
                                            <InputGroup.Text id="basic-addon2">F. HT</InputGroup.Text>
                                        }

                                        {(currentTypeEditing == "prix_vente_ttc") &&
                                            <InputGroup.Text id="basic-addon2">F. TTC</InputGroup.Text>
                                        }

                                        {(currentTypeEditing == "coefficient_pr") &&
                                            <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                                        }

                                    </InputGroup>

                                    <div className="d-flex mt-2">
                                        <Button className="me-auto" disabled={removeArticleIdLoading == article.article_id} onClick={() => removeArticle(article)} variant="danger">Supprimer</Button>
                                        <Button className="" variant="secondary" onClick={() => resetInputEditingValues()}>Annuler</Button>
                                        <Button className="ms-2" disabled={updateArticleLoading || !currentTypeEditing || !currentValueEditing} onClick={() => updateArticle()}>
                                            {updateArticleLoading &&
                                                <Spinner animation="border" size="sm" className="me-2" />
                                            }
                                            Enregistrer les modifications
                                        </Button>
                                    </div>
                                </div>
                            }
                        </span>
                    </div>
                ))
            }

        </>
    );

}
export default Articles;