import React, { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { Container, Button, Dropdown, DropdownButton, Alert, Col, Row, Spinner, Modal, Accordion, InputGroup, Form } from 'react-bootstrap';
import { EditionService } from '../../../services/EditionService';
import { AbonnementService } from '../../../services/AbonnementService';
import ErrorManager from "../../../components/error-manager/ErrorManager";

const GenerateDialog = ({ show, setCloseModal, folderId, params }) => {

    const [showModal, setShowModal] = useState(false);
    const [globalError, setGlobalError] = useState();
    const [isGenerateLoading, setIsGenerateLoading] = useState(false);
    const [isEditAboLoading, setIsEditAboLoading] = useState(false);
    const [failedElementList, setFailedElementList] = useState([]);
    const [skippedData, setSkippedData] = useState();
    const [facturesData, setFacturesData] = useState();

    let inputRef = useRef();

    const navigate = useNavigate();

    useEffect(() => {
        setShowModal(show)
        if (show) {
            resetValues()
            generate()
        }
    }, [show]);

    function handleClose(e) {
        setCloseModal()
    }

    function resetValues() {
        setGlobalError()
        setFailedElementList([])
        setSkippedData()
        setFacturesData()
    }

    function generate() {

        setIsGenerateLoading(true)
        AbonnementService.postGenerate(folderId, params).then(res => {

            setIsGenerateLoading(false)

            if (res.data.data.failed) {
                setFailedElementList(getElementErrorList(res.data.data.failed));
            }

            if (res.data.data.skipped) {
                setSkippedData(res.data.data.skipped)
            }

            if (res.data.data.factures) {
                setFacturesData(res.data.data.factures)
            }

        }).catch(error => {
            setIsGenerateLoading(false)
            setGlobalError(error)
        });
    }

    function editAbonnement(abonnementId) {
        setIsEditAboLoading(true)

        AbonnementService.updateAbonnement(folderId, abonnementId).then(res => {
            if (!res.data?.data) {
                setGlobalError({ statusText: res.statusText })
            } else {
                let brouillonId = Object.keys(res.data.data.brouillons)[0]
                //openBrouillon(brouillonId, "abonnements")

                window.open(`/${folderId}/abonnements/brouillon?brouillonId=${brouillonId}&from=abonnements`, '_blank')

            }
            setIsEditAboLoading(false)
        }).catch(error => {
            setIsEditAboLoading(false)
            setGlobalError(error)
        });
    }


    function getElementErrorList(failedObj) {

        let elementErrorList = []

        for (const [key, value] of Object.entries(failedObj)) {

            console.log('-----------------')
            console.log(key)
            console.log(value)

            let errList = []

            getDeepKeys(value)
            function getDeepKeys(obj) {
                for (var key in obj) {
                    if (typeof obj[key] === 'string' && obj[key].substring(0, 9) == "Erreur : ") {
                        let splitString = obj[key].split("Erreur : ");
                        splitString.forEach(str => {
                            if (str != "") {
                                errList.push(str)
                            }
                        });
                    }
                    if (obj.length == 1 && typeof obj[key] === 'string' && obj[key].substring(0, 9) != "Erreur : ") {
                        errList.push(obj[key])
                    }
                    if (typeof obj[key] === "object") {
                        getDeepKeys(obj[key])
                    }
                }
                return;
            }

            if (errList.length > 0) {
                let elementError = {
                    key: key,
                    element: value,
                    errList: errList
                }
                elementErrorList.push(elementError)
            }

        }

        return elementErrorList


    }


    /* function getClient() {
        ClientService.getClient(folderId, clientId).then(res => {
            if (!res.data?.data) {
                setGlobalError({ statusText: res.statusText })
            } else {
                setInterlocuteurList(res.data.data.interlocuteurs)
                addPrincipalMail(res.data.data.interlocuteurs)
            }
        }).catch(error => {
            setGlobalError(error)
        });
    } */


    return (
        <div id="GenerateDialog">
            <Modal scrollable size="xl" show={showModal} onHide={() => handleClose()} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Rapport de génération</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ErrorManager error={globalError} />

                    {isGenerateLoading &&
                        <span className="hloader mt-2"></span>
                    }

                    <Accordion defaultActiveKey="0">

                        {failedElementList.length > 0 &&
                            <Accordion.Item eventKey="0">
                                <Accordion.Header><i className="material-icons i-lg me-2">warning</i>Échoués ({failedElementList.length}) :</Accordion.Header>
                                <Accordion.Body>
                                    {failedElementList?.map((elementError, i) => (
                                        <div key={i} className="my-2">
                                            <div className="d-flex align-items-center ">
                                                <p className="m-0 fw-semibold">
                                                    {elementError.element?.client_nom ?
                                                        <>{elementError.element?.client_nom}</>
                                                        :
                                                        <>{elementError.element?._id}</>
                                                    }
                                                </p>
                                                <Button disabled={isEditAboLoading} size="sm" variant="link" className="ms-2 p-0 d-flex" onClick={() => editAbonnement(elementError?.key)}>
                                                    Modifier l'abbonement
                                                    <i className="d-inline-flex material-icons ms-1">
                                                        open_in_new
                                                    </i>
                                                </Button>
                                            </div>
                                            {elementError.errList.map((error, j) => (
                                                <li className="" key={j}>{error}</li>
                                            ))}
                                        </div>
                                    ))}
                                </Accordion.Body>
                            </Accordion.Item>
                        }


                        {skippedData &&
                            <Accordion.Item eventKey="1">
                                <Accordion.Header><i className="material-icons i-lg me-2">info</i>Non traités ({Object.entries(skippedData).length}) :</Accordion.Header>
                                <Accordion.Body>
                                    {Object.entries(skippedData).map(([key, value], i) =>
                                        <div key={i} className="d-flex align-items-center my-2">
                                            <p className="m-0">
                                                <span className="fw-semibold">{key} : </span>
                                                <span>{value}</span>
                                            </p>
                                        </div>
                                    )}
                                </Accordion.Body>
                            </Accordion.Item>
                        }

                        {facturesData &&
                            <Accordion.Item eventKey="2">
                                <Accordion.Header><i className="material-icons i-lg me-2">check_circle</i>Factures générées ({Object.entries(facturesData).length}) :</Accordion.Header>
                                <Accordion.Body>
                                    {Object.entries(facturesData).map(([key, value], i) =>
                                        <div key={i} className="d-flex align-items-center my-2">
                                            <p className="m-0">
                                                <span className="fw-semibold">{key} : </span>
                                                <span>{value}</span>
                                            </p>
                                        </div>
                                    )}
                                </Accordion.Body>
                            </Accordion.Item>
                        }


                    </Accordion>






                    {/* <h4>Skipped :</h4>
                    {skippedElementList?.map((elementError, i) => (
                        <div key={i}>
                            <h6>{elementError.element?.client_nom}</h6>
                            <Alert variant="danger" className="">
                                {elementError.errList.map((error, j) => (
                                    <li className="small" key={j}>{error}</li>
                                ))}
                            </Alert>
                        </div>
                    ))} */}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default GenerateDialog;
