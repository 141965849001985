import React, { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Table, Badge, FormControl, Container, InputGroup, Button, Dropdown, DropdownButton, Form, Col, Row, Spinner, OverlayTrigger, Tooltip, Toast, ToastContainer } from 'react-bootstrap';
import { ArticleService } from '../../../../services/ArticleService';
import Pagination from '../../../../components/pagination/Pagination';
import { getFormErrorMsg, getInvalidObject, getChangedValues, reorder, deepFind } from '../../../../helper/Helper'
import { ParametreService } from '../../../../services/ParametreService';
import { GlobalContext } from "../../../../services/GlobalProvider";

const TableHeaders = ({ path, descParam, configInitial, configToUpload, setFocusId, onChange, disabled }) => {

    const [arrayTableHeaders, setArrayTableHeaders] = useState([])

    const { preferences, parametres, folderDetails, grants } = React.useContext(GlobalContext);

    useEffect(() => {
        let tableHeaders = deepFind(configToUpload, path) ? deepFind(configToUpload, path) : deepFind(configInitial, path)
        setArrayTableHeaders(sortObjectReturnArray(tableHeaders))
    }, []);

    function sortObjectReturnArray(object) {
        let asArray = []

        Object.entries(object).map(([key, value], i) => {
            asArray.push({ ...value, 'keyName': key })
        })

        var sortedArray = asArray.slice(0);
        sortedArray.sort(function (a, b) {
            return a.position - b.position;
        });

        return sortedArray
    }

    function changeElement(array) {

        let value = {}

        array.map(el => {
            value[el.keyName] = { 'hidden': el.hidden, 'position': el.position }
        })

        let element = {
            target: {
                id: path,
                value: value
            }
        }

        onChange(element)
        setFocusId(path)
    }

    function onDragEnd(result, path) {
        let reordered = reorder(arrayTableHeaders, result.source.index, result.destination.index)
        reordered.map((element, i) => element.position = i)
        setArrayTableHeaders(reordered);
        changeElement(reordered)
    }

    function updateHiddenValue(e) {
        const arrayTableHeadersCopy = arrayTableHeaders.slice()
        let index = arrayTableHeadersCopy.findIndex(x => x.keyName === e.target.name);
        arrayTableHeadersCopy[index].hidden = !arrayTableHeadersCopy[index].hidden
        setArrayTableHeaders(arrayTableHeadersCopy)
        changeElement(arrayTableHeadersCopy)
    }

    return (
        <DragDropContext onDragEnd={result => onDragEnd(result, path)}>
            <Droppable droppableId={"TableHeaders" + path} >
                {(provided, snapshot) => (
                    <div
                        className="my-2"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        {arrayTableHeaders.map((tHead, i) =>
                            <span key={tHead.keyName}>
                                {tHead.keyName != "_id" &&
                                    <Draggable draggableId={"Draggable" + tHead.keyName} index={i} isDragDisabled={disabled}>
                                        {(provided, snapshot) => (
                                            <span
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className={"py-1 px-2 mb-1 " + (snapshot.isDragging ? "bg-gray-400 " : "bg-gray-100 hover-bg-gray-200 rounded d-block")}
                                            >
                                                <span className="d-flex">
                                                    <i className="material-icons me-2">drag_indicator</i>
                                                    <Form.Check
                                                        onChange={e => updateHiddenValue(e)}
                                                        name={tHead.keyName}
                                                        type="checkbox"
                                                        label={deepFind(parametres[path.split(".")[0]]?.fieldsLabel, `${tHead.keyName}.title`) || tHead.keyName}
                                                        checked={!tHead.hidden}
                                                        disabled={disabled}
                                                    />
                                                    <small className="ms-auto text-gray-500">{tHead.keyName}</small>
                                                </span>
                                            </span>
                                        )}
                                    </Draggable>
                                }
                            </span>
                        )}

                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}

export default TableHeaders;
